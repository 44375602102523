<template>
  <div class="chatContent">
    <div :class="lookDialog ? 'fileIndex' : 'shareView'">
      <!-- 预览标志 -->
      <div class="lookDiv">预览</div>
      <!-- 内容 -->
      <div
        :class="{
          width1010: !rightType && !lookDialog, //没有展示
          width10103: !rightType && lookDialog,
          width10101: rightType && !lookDialog,
        }"
        ref="containerRef"
      >
        <template v-if="routerType != 'edit'">
          <div v-for="(item, index) in topicList" :key="index">
            <qaIndex
              type="look"
              :item="item"
              @openFileView="openFileView"
              :id="'talk' + item.conversationId"
            ></qaIndex>
            <div class="fillCenter" v-if="item.answers.length > 1">
              <img
                :src="item.chatActive != 1 ? leftFill : leftNoFill"
                alt=""
                class="fillImg"
                @click="changeItemPage(false, item)"
              />
              <span
                :class="item.chatActive == 1 ? 'fillColor' : ''"
                class="fillText"
                >{{ item.chatActive }}</span
              >
              <span
                class="fillText"
                :class="
                  item.chatActive == item.answers.length ? 'fillColor' : ''
                "
                >/{{ item.answers.length }}</span
              >
              <img
                :src="
                  item.chatActive == item.answers.length
                    ? rightNoFill
                    : rightFill
                "
                alt=""
                class="fillImg"
                @click="changeItemPage(true, item)"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div
            v-for="item in topicList"
            :key="item.mySaveData.conversationId"
            :id="'talk' + item.mySaveData.conversationId"
          >
            <shareQa :item="item" @openFileView="openFileView"></shareQa>
          </div>
        </template>
      </div>
      <!-- 右侧不展示 -->
      <div
        v-if="!rightType && !lookDialog"
        @click="rightType = !rightType"
        class="rightIcon"
      >
        <img :src="sqLeftSvg" alt="" class="wid18" />
      </div>
      <!-- 右侧展示 -->
      <transition name="expand-transition">
        <div v-if="rightType" class="rightDivAbs">
          <div
            :class="routerType == 'look' ? 'flexRightEnd' : 'flexRightCenter'"
          >
            <div class="sc" v-if="routerType == 'edit'" @click="goEdit">
              <img :src="fhbj" alt="" class="marginRight8 wid25" />
              <div>返回编辑</div>
            </div>
            <div class="color2 sc" v-if="routerType == 'edit'" @click="gogogo">
              <img :src="fvyzImg" alt="" class="marginRight8 wid25" />
              <div>发布验证</div>
            </div>
            <div class="iconRightCenter" @click="rightType = !rightType">
              <img :src="sqRightSvg" alt="" class="wid20" />
            </div>
          </div>
          <div class="hrefDiv" v-if="routerType == 'edit'">
            <el-anchor
              :container="containerRef"
              direction="vertical"
              type="underline"
              :offset="30"
            >
              <el-anchor-link
                :href="'#talk' + item.mySaveData.conversationId"
                :title="item.mySaveData.question"
                v-for="(item, index) in topicList"
                :key="index"
              />
            </el-anchor>
          </div>
          <div class="hrefDiv" v-else>
            <el-anchor
              :container="containerRef"
              direction="vertical"
              type="underline"
              :offset="30"
            >
              <el-anchor-link
                :href="'#talk' + item.conversationId"
                :title="item.question"
                v-for="(item, index) in topicList"
                :key="index"
              />
            </el-anchor>
          </div>
        </div>
      </transition>
    </div>
    <!-- 文件预览 -->
    <fileView
      v-if="lookDialog"
      type="chat"
      class="fileViewDiv"
      :fileItem="fileItem"
      :chunkDialog="true"
      @closeFileView="closeFileView"
    ></fileView>
    <!-- 锚点提醒 -->
    <tipView
      v-if="cdDialog"
      title="冲突提醒"
      tip="您当前审阅的问答已有他人完成审阅，可能产生冲突，若多个验证者同时发布验证，发布顺序靠后的需要解决与发布顺序靠前的验证内容冲突，您确认要继续验证该话题吗？"
      cancel="放弃"
      ok="继续验证"
      @cancel="closeCt"
      @ok="gogogo(1)"
    ></tipView>
  </div>
</template>

<script setup>
import sqLeftSvg from "@/assets/chat/sqLeft.svg";
import sqRightSvg from "@/assets/chat/sqRight.svg";
import qaIndex from "@/components/qa/index.vue";
import fvyzImg from "@/assets/share/fbyzView.svg";
import fhbj from "@/assets/share/fhbj.svg";
import leftFill from "@/assets/chat/leftFill.svg";
import rightFill from "@/assets/chat/rightFill.svg";
import leftNoFill from "@/assets/chat/leftNoFill.svg";
import rightNoFill from "@/assets/chat/rightNoFill.svg";
import fileView from "@/components/fileView/index.vue";
import shareQa from "@/components/qa/shareQa.vue";
import { ref, onMounted, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import api from "@/api";
const store = useStore();
const route = useRoute();
const router = useRouter();
const rightType = ref(false);
const containerRef = ref();
const routerType = ref();
const userId = ref(store.state.userId);
const yzItem = ref(store.state.yzItem);
const topicList = ref([]); //渲染列表用
const topicItem = ref(store.state.topicItem);
const fileItem = ref(); //文件详情
const lookDialog = ref(false); //文件弹出
const cdDialog = ref(false);
onMounted(() => {
  routerType.value = route.query.type;
  if (routerType.value == "edit") {
    // 验证进入
    getDesc();
  } else {
    // 预览
    getTopic();
  }
});
watch(
  () => store.state.yzItem,
  (newValue, oldValue) => {
    yzItem.value = store.state.yzItem;
  }
);
watch(
  () => store.state.topicItem,
  (newValue, oldValue) => {
    topicItem.value = store.state.topicItem;
  }
);
// 放弃冲突
const closeCt = () => {
  cdDialog.value = false;
  router.push("/share");
};
// 发布
const gogogo = (isOver) => {
  api.share
    .verifyPublish({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId,
      libraryId: yzItem.value.libraryId,
      isOver: isOver,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        cdDialog.value = false;
        message.success("发布成功");
        router.push("/share");
      } else if (res.returnCode == 201) {
        cdDialog.value = true;
      }
    });
};
// 加减页数
const changeItemPage = (type, item) => {
  if (type && item.chatActive < item.answers.length) {
    item.chatActive++;
  }
  if (!type && item.chatActive > 1) {
    item.chatActive--;
  }
  nextTick(() => {
    scrollBottom();
  });
};
// 获取数据库条数
const getTopic = () => {
  api.share
    .baseDetailQuery({
      userId: userId.value,
      sessionId: topicItem.value.sessionId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.data.length != 0) {
          res.data.forEach((item) => {
            item.chatActive = 1;
            item.resonType = false;
            item.resonList = [];
          });
        }
        topicList.value = res.data;
        rightType.value = topicList.value.length > 1 ? true : false;
        nextTick(() => {
          scrollBottom();
        });
      }
    });
};
const scrollBottom = () => {
  nextTick(() => {
    containerRef.value.scrollTop = containerRef.value.scrollHeight;
  });
};
// 获取详情
const getDesc = () => {
  api.share
    .recordAll({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId, //4,//12,
      libraryId: yzItem.value.libraryId, //必须
    })
    .then((res) => {
      if (res.returnCode == 200) {
        topicList.value = res.data;
        rightType.value = true;
      }
    });
};
// 打开文件预览
const openFileView = (item) => {
  fileItem.value = item;
  rightType.value = false;
  lookDialog.value = true;
};
// 关闭文件预览
const closeFileView = () => {
  lookDialog.value = false;
};
// 返回编辑
const goEdit = () => {
  router.push("/share/details");
};
const openHistory = () => {};
</script>

<style lang="scss" scoped>
.shareView {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0px 0px 16px 0px #dbdfff75;
  padding: 30px;
  position: relative;
}
.fileIndex {
  width: 467px;
  height: calc(100% - 60px);
  background-color: #ffffff;
  border-radius: 30px;
  padding: 30px;
  position: relative;
  margin-right: 20px;
}
.lookDiv {
  background-color: #767bfa;
  padding: 3px 11px;
  color: #ffffff;
  border-radius: 16px;
  font-size: 18px;
  font-family: "Semibold";
  position: absolute;
  top: 30px;
  left: 30px;
  box-shadow: 20px 20px 20px 0px #ffffff;
  z-index: 199;
}
.chatContent {
  width: 100%;
  height: 100%;
  display: flex;
}
.width1010 {
  min-width: 1010px;
  width: calc(100% - 510px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 60px);
  transition: all 0.5s ease;
  overflow: auto;
}
.width10101 {
  min-width: 1010px;
  width: calc(100% - 491px);
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(0px, -50%);
  height: calc(100% - 60px);
  transition: all 0.5s ease;
  overflow: auto;
}

.width10103 {
  width: calc(100% - 60px);
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(0px, -50%);
  height: calc(100% - 60px);
  transition: all 0.5s ease;
  overflow: auto;
}
.rightIcon {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}
.rightIcon:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}
.wid18 {
  width: 18px;
}
.expand-transition-enter-active,
.expand-transition-leave-active {
  transition: all 0.5s ease;
}

.expand-transition-enter-from,
.expand-transition-leave-to {
  transform: translateX(10%);
  opacity: 0;
}

.expand-transition-enter-to,
.expand-transition-leave-from {
  transform: translateX(0);
  opacity: 1;
}
.rightDivAbs {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 330px;
  height: calc(100% - 60px);
}
.flexRightCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
  position: relative;
}
.flexRightEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 35px;
  position: relative;
}
.sc {
  border: 2px solid #edeef6;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  margin-right: 12px;
  cursor: pointer;
}
.marginRight8 {
  margin-right: 8px;
}
.wid25 {
  width: 25px;
}
.color2 {
  background-color: #3376ff;
  color: #ffffff;
  border: 2px solid #3376ff;
  position: relative;
}
.iconRightCenter {
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconRightCenter:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}
.wid20 {
  width: 20px;
}
.hrefDiv {
  padding: 0px 18px;
  max-height: calc(100% - 84px);
  overflow: auto;
}
.fillCenter {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.fillImg {
  width: 24px;
  cursor: pointer;
}
.fillColor {
  color: #d9d9d9;
}
.fillText {
  font-size: 18px;
  font-family: "Semibold";
  color: #8a8a8a;
}
.fileViewDiv {
  width: calc(100% - 547px);
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
}
</style>

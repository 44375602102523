import { App, computed,ref } from 'vue';
import { useStore } from 'vuex';
import { hasPermission } from '@/utils/permission';
 
export default {
  install(app: App) {
    // 在 Vue 的全局属性上添加一个方法用于权限检查
    app.config.globalProperties.$hasPermission = function (permissionId:any) {
      const store = useStore();
      const roles1 = ref(store.state.roles)
      const roles = computed(() => roles1.value);      
      return computed(() => hasPermission(roles.value, permissionId));
    };
  }
};
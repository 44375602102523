const word = require('@/assets/upload/word.svg')
const excel = require('@/assets/upload/excel.svg')
const html = require('@/assets/upload/html.svg')
const pdf = require('@/assets/upload/pdf.svg')
const ppt = require('@/assets/upload/ppt.svg')
const txt = require('@/assets/upload/txt.svg')
const notype = require('@/assets/upload/notype.svg')
const fileFolder = require("@/assets/base/fileFolder.svg")
const wlSvg = require("@/assets/chat/wl.svg")
const bexcel = require("@/assets/upload/bexcel.svg")
const bhtml = require("@/assets/upload/bhtml.svg")
const bpdf = require("@/assets/upload/bpdf.svg")
const btext = require("@/assets/upload/btext.svg")
const bword = require("@/assets/upload/bword.svg")
const bppt = require("@/assets/upload/bppt.svg")
const rexcel = require("@/assets/upload/rexcel.svg")
const rhtml = require("@/assets/upload/rhtml.svg")
const rpdf = require("@/assets/upload/rpdf.svg")
const rtext = require("@/assets/upload/rtxt.svg")
const rword = require("@/assets/upload/rword.svg")
const rppt = require("@/assets/upload/rppt.svg")
const dImg = require("@/assets/index/d.svg")
//返回对应文件图标
export function getFileIcon(item: any) {
    let fileType
    if (item.folderName) {
        return fileFolder
    }
    if (item.sessionName) {
        return dImg
    }
    if (typeof item.fileType != 'number') {
        fileType = item.fileType.toLowerCase();
    } else {
        fileType = item.fileType
        if (fileType == 0) {
            fileType = item.fileName.substring(item.fileName.lastIndexOf(".") + 1);
            fileType = fileType.toLowerCase();
        }
    }
    if (fileType == 'docx' || fileType == 'doc') {
        return word
    } else if (fileType == 'xls' || fileType == 'xlsx') {
        return excel
    } else if (fileType == 'html') {
        return html
    } else if (fileType == 'pdf') {
        return pdf
    } else if (fileType == 'pptx') {
        return ppt
    } else if (fileType == 'txt') {
        return txt
    } else if (fileType == 'txt') {
        return txt
    } else if (fileType == 1) {
        return fileFolder
    } else if (fileType == 2) {
        return dImg
    } else {
        return notype
    }
}
//返回文件size
export function formatFileSize(bytes: any) {
    if (bytes == 0 || bytes == null || bytes == undefined) return "";
    if (typeof bytes==='string') {
        return bytes
    }
    const k = 1024;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return (bytes / Math.pow(k, i)).toFixed(2) + " " + sizes[i];
}
//数据库使用返回图标
export function getBaseFileIcon(item: any) {
    if (!item) {
        return
    }
    if (item.folderName) {
        return fileFolder
    }
    if (item.sessionName) {
        return dImg
    }
    item.fileType = item.fileType || item.fileName.substring(item.fileName.lastIndexOf(".") + 1);
    const fileType = item.fileType.toLowerCase();
    if (fileType == 'docx' || fileType == 'doc') {
        return word
    } else if (fileType == 'xls' || fileType == 'xlsx') {
        return excel
    } else if (fileType == 'html') {
        return html
    } else if (fileType == 'pdf') {
        return pdf
    } else if (fileType == 'pptx') {
        return ppt
    } else if (fileType == 'txt') {
        return txt
    } else {
        return notype
    }
}
//返回文件对应原型图片
export function getChatFileImg(item: any) {
    const fileType = item.fileType.toLowerCase();
    if (fileType == 'docx' || fileType == 'doc') {
        return bword
    } else if (fileType == 'xls' || fileType == 'xlsx') {
        return bexcel
    } else if (fileType == 'html') {
        return bhtml
    } else if (fileType == 'pdf') {
        return bpdf
    } else if (fileType == 'pptx') {
        return bppt
    } else if (fileType == 'txt') {
        return btext
    } else {
        return wlSvg
    }
}
// 返回文件预览对应图片
export function getResonFileImg(item: any) {
    const fileType = item.fileName.substring(item.fileName.lastIndexOf(".") + 1);
    if (fileType == 'docx' || fileType == 'doc') {
        return rword
    } else if (fileType == 'xls' || fileType == 'xlsx') {
        return rexcel
    } else if (fileType == 'html') {
        return rhtml
    } else if (fileType == 'pdf') {
        return rpdf
    } else if (fileType == 'pptx') {
        return rppt
    } else if (fileType == 'txt') {
        return rtext
    }
}
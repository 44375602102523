<script setup>
import { ref, defineProps, watch, onMounted, defineEmits } from "vue";
import { useStore } from "vuex";
import api from "@/api/index";
const store = useStore();
const userId = ref(store.state.userId);
const emit = defineEmits(['handleCloseUser', 'handleAddUser', 'addUserBtn']);
const props = defineProps({
    show: Boolean,
    uploadInfo: Object,
    formType: String,
});
onMounted(() => {
    getRole()
    getDepart()
    getGroup()
})
watch(
    () => props.formType,
    (newVal) => {
        props.formType = newVal;
    }
)
// 部门树形列表
const departmentList = ref([]);
const defaultProps = ref({
    children: "children",
    label: "departmentName",
    id: "departmentId",
});

const getDepart = () => {
    api.manage
        .getDepartmentList({ userId: userId.value, pageId: 0 })
        .then((res) => {
            if (res.returnCode == 200) {
                departmentList.value = res.data;
            }
        })
}
// 角色树形列表
const roleList = ref([]);
const getRole = () => {
    api.manage
        .queryUserRole({ userId: userId.value, pageId: 0 })
        .then((res) => {
            if (res.returnCode == 200) {
                roleList.value = res.data;
            }
        })
}
// 群组下拉
const groupList = ref([]);
const getGroup = () => {
    api.manage
        .queryGroupList({ userId: userId.value })
        .then((res) => {
            if (res.returnCode == 200) {
                groupList.value = res.data;                
            }
        })
}
watch(
    () => props.uploadInfo,
    (val) => {
        props.uploadInfo = val;        
    }
)
const autoAdd = ref(true);
const newPassword = ref('');
const isEmailInfo = ref(true)
const sex = ref(0)
// 关闭编辑/保存
const handleClose = () => {
    emit('handleCloseUser')
}
//保存编辑
const handleSave = () => {
    props.uploadInfo.newUserId = props.uploadInfo.userId    
    emit('handleAddUser', props.uploadInfo)
};
//新增用户校验
const rules = ref({
    userName: [
        {
            required: true,
            message: '请输入用户名',
            trigger: "blur",
        },
        { min: 1, max: 50, message: '请输入用户名', trigger: "blur" },
    ],
    email: [
        {
            type: "email",
            message: '请输入正确的邮箱地址',
        },
        {
            required: true,
            message: '请输入邮箱地址',
        },
    ],
    phone: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        {
            pattern:
                /^(13[0-9]|14[5|7|9]|15[0-3|5-9]|16[2|5|6|7]|17[0-8]|18[0-9]|19[8|9])\d{8}$/,
            message: '手机号格式不正确',
            trigger: "blur",
        },
    ],
    address: [
        { required: true, message: '请输入地址', trigger: 'blur' },
    ],
    sex: [
        { required: true, message: '请选择性别', trigger: 'blur' },
    ],
    isEmailInfo: [
        { required: true, message: '请选择是否发送邮件', trigger: 'blur' },
    ],
})

// 新增取消
const handleCancel = () => {
    emit('handleCloseUser')
}
const formRef = ref(null)
// 新增确定
const handleConfirm = async (formRef) => {
    props.uploadInfo.sex = sex.value;
    props.uploadInfo.isEmailInfo = isEmailInfo.value;
    props.uploadInfo.newPassword = newPassword.value;
    props.uploadInfo.userId = userId.value;
    await formRef.validate((valid) => {
        if (valid) {
            emit('addUserBtn', props.uploadInfo)
        }
    })
};
</script>

<template>
    <div>
        <el-dialog v-model="props.show" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>{{ props.formType=='add' ? '新增用户' : '编辑用户' }}</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleClose" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <el-divider />
            <div class="content">
                <div style="display: flex; justify-content: space-between;" v-if="props.formType=='edit'">
                    <div style="display: flex;">
                       <!-- 启用 -->
                        <div class="tag" v-if="uploadInfo.isActive == true">
                            <div class="tag-icon">
                                <img src="@/assets/manage/dui.svg" alt="">
                            </div>
                            <div class="tag-text">启用</div>
                        </div>
                        <!-- 禁用 -->
                        <div class="tag_cuo" v-else>
                            <div class="tag-icon">
                                <img src="@/assets/manage/cuo.svg" alt="">
                            </div>
                            <div class="tag-text">禁用</div>
                        </div>
                    </div>
                </div>
                <el-form :model="uploadInfo" class="form" :rules="rules" ref="formRef">
                    <el-form-item prop="userName">
                        <div class="form-name">
                            <p class="tit">用户名 <span style="color: #F02D63;">*</span></p>
                            <el-input class="comtent" v-model="uploadInfo.userName" placeholder="请输入用户名"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item prop="sex" v-if="props.formType =='add'">
                        <div class="form-name">
                            <p class="tit">性别 <span style="color: #F02D63;">*</span></p>
                            <el-radio-group v-model="sex">
                                <el-radio :label=0 class="isActive">男</el-radio>
                                <el-radio :label=1>女</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                    <el-form-item prop="email">
                        <div class="form-name">
                            <p class="tit">邮箱 <span style="color: #F02D63;">*</span></p>
                            <el-input class="comtent" v-model="uploadInfo.email" placeholder="请输入邮箱"></el-input>
                            <el-checkbox v-if="props.formType =='add'" v-model="isEmailInfo" label="通过邮箱将登录信息发送给用户" size="large" />
                        </div>
                    </el-form-item>
                    <el-form-item prop="phone">
                        <div class="form-name">
                            <p class="tit">手机<span style="color: #F02D63;">*</span></p>
                            <el-input class="comtent" v-model="uploadInfo.phone" placeholder="请输入手机号" />
                        </div>
                    </el-form-item>
                    <el-form-item prop="address" v-if="props.formType =='add'">
                        <div class="form-name">
                            <p class="tit">地址 <span style="color: #F02D63;">*</span></p>
                            <el-input class="comtent" v-model="uploadInfo.address" placeholder="请输入地址" />
                        </div>
                    </el-form-item>
                    <el-form-item prop="newPassword" v-if="props.formType =='add'">
                        <div class="form-name">
                            <el-checkbox v-model="autoAdd" label="自动创建密码" size="large" />
                            <div v-if="!autoAdd">
                                <div class="tit">密码: <span style="color: #F02D63;">*</span></div>
                                <el-input v-model="newPassword" placeholder="请输入新密码" show-password />
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item prop="departmentId">
                        <div class="form-name">
                            <p class="tit">部门 <span style="color: #F02D63;">*</span></p>
                            <el-tree-select v-model="uploadInfo.departmentId" :data="departmentList" check-strictly
                                :render-after-expand="false" :props="defaultProps" :placeholder="$t('bot.selectAdd')"
                                node-key="departmentId">
                                <template #default="{ data: { departmentName } }">
                                    {{ departmentName }}
                                </template>
                            </el-tree-select>
                        </div>
                    </el-form-item>
                    <el-form-item prop="groupIds">
                        <div class="form-name">
                            <p class="tit">群组 </p>
                            <el-select v-model="uploadInfo.groupIds" clearable multiple @change="changeGroup">
                                <el-option v-for="(items, index) in groupList" :key="index" :label="items.groupName"
                                    :value="items.groupId">
                                </el-option>
                            </el-select>
                        </div>
                    </el-form-item>
                    <el-form-item prop="roleId">
                        <div class="form-name">
                            <p class="tit">角色 <span style="color: #F02D63;">*</span></p>
                            <el-select v-model="uploadInfo.roleId" clearable @change="changeRole">
                                <el-option v-for="(items, index) in roleList" :key="index" :label="items.description"
                                    :value="items.id">
                                </el-option>
                            </el-select>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <div class="dialog-footer" v-if="props.formType =='edit'">
                    <div @click="handleSave" class="confirm">保存编辑</div>
                </div>
                <div class="dialog-footer" v-if="props.formType =='add'">
                    <div @click="handleCancel" class="cancel">取消</div>
                    <div @click="handleConfirm(formRef)" class="confirm">保存</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>



<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-tag.is-closable) {
    height: 35px !important;
    line-height: 35px !important;
    border-radius: 16px !important;
    font-size: 14px !important;
    background-color: #eceeff !important;
    color: #3D3D3D !important;
}

:deep(.el-tag .el-tag__close:hover) {
    background-color: #3D3D3D !important;
}

:deep(.el-tag .el-tag__close) {
    background-color: #a3acec !important;
    width: 12px !important;
    height: 12px !important;
}

:deep(.el-divider) {
    margin: 1px 0 10px !important;
    width: calc(100%) !important;
}

:deep(.el-dialog) {
    --el-dialog-width: 600px;
    border-radius: 30px;
    padding: 30px;
}

:deep(.el-input__wrapper) {
    height: 38px !important;
    width: 500px !important;
}

:deep(.el-select__wrapper) {
    min-height: 42px !important;
    border-radius: 16px !important;
    width: 520px !important;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 24px;
    position: relative;
}

.close-icon {
    font-size: 30px;
}

.passWord {
    color: #F02D63;
    font-size: 12px;
}

.content {
    height: 590px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px 10px;
    font-size: 16px;
    color: #8A8A8A;

    .name {
        color: #3d3d3d;
        font-size: 22px;
        margin: auto 8px auto 12px;
    }

    .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 6px;
        text-align: center;
        line-height: 50px;
        color: #ffffff;
        font-size: 34px;
        font-family: "Semibold";
    }

    .tag {
        display: flex;
        justify-content: space-between;
        background-color: #B8E6CD;
        color: #14AE5C;
        padding: 0px 4px;
        height: 26px;
        line-height: 26px;
        border-radius: 6px;
        margin: auto 0;

        .tag-icon {
            width: 16px;
            height: 16px;
            display: block;
            margin-top: 3px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .tag-text {
            margin-left: 4px;
        }
    }

    .tag_cuo {
        display: flex;
        justify-content: space-between;
        background-color: #FBD4DF;
        color: #F02D63;
        padding: 0px 4px;
        height: 26px;
        line-height: 26px;
        border-radius: 6px;
        margin: auto 0;

        .tag-icon {
            width: 16px;
            height: 16px;
            display: block;
            margin-top: 3px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .tag-text {
            margin-left: 4px;
        }
    }

    .reset {
        color: #0256FF;
        margin: auto 10px
    }
}

.form {
    margin-top: 20px;
}

:deep(.close_icon) {
    font-size: 30px !important;
    color: #A3ACEC;
    margin-left: 6px;

    :hover {
        color: #3D3D3D;
    }
}

.form-name {
    font-size: 18px;
    color: #3D3D3D;

    .el-tag {
        display: flex;
        padding: 2px 9px 2px 2px;
        height: 35px;
        line-height: 35px;
        border-radius: 30px;
        font-size: 16px;

        .tag-img {
            width: 30px;
            height: 30px;
            margin-right: 6px;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

    }

    .tit {
        margin-bottom: 2px;
        font-weight: 600;
    }

    .comtent {
        font-size: 16px;
        margin-bottom: 8px;
    }
}

.isActive {
    margin-right: 6px;
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #8A8A8A;
        padding: 6px 12px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 6px 12px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
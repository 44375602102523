<template>
  <div class="userDialog">
    <div class="passwordDialogTopDiv">
      <div class="passwordDialogTitle">个人中心</div>
      <img :src="closeMM" alt="" class="closeMM" @click="closeUserInfo" />
    </div>
    <div class="bodyItem">
      <!-- 用户姓名 头像 -->
      <div class="titleBetWeen bottom40">
        <div class="messageCenter">
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :disabled="roleId == 4"
            :http-request="httprequest"
          >
            <img
              :src="props.userInfo.avatar ? props.userInfo.avatar : userImg"
              class="userImg"
            />
          </el-upload>
          <div class="userTitleName">{{ props.userInfo.userName }}</div>
          <div class="greenDiv">
            <img :src="greenYes" alt="" class="greenYes" />
            <div>{{ t("header.header28") }}</div>
          </div>
        </div>
        <el-button
          type="text"
          class="userPasswordText"
          @click="openPasswordDialog"
          >{{ t("header.header13") }}</el-button
        >
      </div>
      <!-- 用户名  userId -->
      <el-row>
        <el-col :span="12" class="col12">{{ t("header.header20") }}</el-col>
        <el-col :span="12" class="col12">{{ t("header.header29") }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="row12">{{ props.userInfo.userName }}</el-col>
        <el-col :span="12" class="row12">{{ props.userInfo.userId }}</el-col>
      </el-row>
      <!-- 邮箱 -->
      <el-row>
        <el-col :span="24" class="col12">{{ t("header.header30") }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="row12">{{ props.userInfo.email }}</el-col>
      </el-row>
      <!-- 手机 -->
      <el-row>
        <el-col :span="24" class="col12">{{ t("header.header31") }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="row12">{{ props.userInfo.phone }}</el-col>
      </el-row>
      <!-- 部门 -->
      <el-row>
        <el-col :span="24" class="col12">{{ t("header.header32") }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="flex24">
          <div class="userDepartmentDiv">
            {{ props.userInfo.departmentName }}
          </div>
        </el-col>
      </el-row>
      <!-- 群组 -->
      <el-row>
        <el-col :span="24" class="col12">{{ t("header.header33") }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="flex24">
          <div class="userDepartmentDiv">{{ t("header.header34") }}</div>
        </el-col>
      </el-row>
      <!-- 角色 -->
      <el-row>
        <el-col :span="24" class="col12">{{ t("header.header35") }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="row12">{{ props.userInfo.rouleId }}</el-col>
      </el-row>
      <!-- 性别 -->
      <el-row>
        <el-col :span="24" class="col12">{{ t("header.header36") }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="row12">{{
          props.userInfo.userSex == 0
            ? t("header.header37")
            : props.userInfo.userSex == 1
            ? t("header.header38")
            : t("header.header39")
        }}</el-col>
      </el-row>
      <!-- 地址 -->
      <el-row>
        <el-col :span="24" class="col12">{{ t("header.header40") }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="row12">{{ props.userInfo.address }}</el-col>
      </el-row>
    </div>
  </div>
</template>

<script setup>
import greenYes from "@/assets/header/greenYes.svg";
import { defineEmits, ref, defineProps } from "vue";
import closeMM from "@/assets/header/closeMM.svg";
import { useStore } from "vuex";
import userImg from "@/assets/header/userImg.png";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import api from "@/api";
const { t } = useI18n();
const store = useStore();
const emit = defineEmits([
  "closeUserInfo",
  "successUserInfo",
  "openPasswordDialog",
]);
const props = defineProps(["userInfo"]);
const userId = ref(Number(store.state.userId));
const roleId = ref(store.state.roleId);
const closeUserInfo = () => {
  emit("closeUserInfo");
};
// 打开密码框
const openPasswordDialog = () => {
  emit("openPasswordDialog");
};
const httprequest = () => {};
// 更新个人中心
const saveUserInfo = () => {
  let data = {
    userId: userId.value,
    ...props.userInfo,
  };
  api.header.updateUserInfos(data).then((res) => {
    if (res.returnCode == 200) {
      emit("successUserInfo", props.userInfo);
    }
  });
};

// 上传头像
const beforeAvatarUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.warning(t("header.header41"));
    return;
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.warning(t("header.header42"));
    return;
  }
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (e) => {
    props.userInfo.avatar = e.target.result;
    saveUserInfo();
  };
  return false;
};
</script>

<style lang="scss" scoped>
.userDialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  padding: 30px;
  background-color: #ffffff;
  z-index: 600;
  width: 540px;
  height: 700px;
}
.userImg {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  margin-right: 18px;
}
.userTitleName {
  color: #3d3d3d;
  font-size: 24px;
  font-family: "Medium";
  margin-right: 6px;
}
.greenYes {
  width: 18px;
  margin-right: 6px;
}
.greenDiv {
  background-color: #b8e6cd;
  font-family: "Medium";
  font-size: 18px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 3px 9px;
}
.userPasswordText {
  font-size: 18px;
  color: #0256ff;
  font-family: "Regular";
}
.bottom40 {
  margin-bottom: 40px;
}
.col12 {
  color: #3d3d3d;
  font-size: 18px;
  font-family: "Medium";
  margin-bottom: 12px;
}
.row12 {
  color: #3d3d3d;
  font-size: 18px;
  font-family: "Regular";
  margin-bottom: 24px;
}
.userDepartmentDiv {
  background-color: #dbdfff;
  font-size: 18px;
  border-radius: 30px;
  font-family: "Regular";
  color: #3d3d3d;
  padding: 5px 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.flex24 {
  display: flex;
  margin-bottom: 24px;
}
.passwordDialogTopDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  padding-bottom: 19px;
  border-bottom: 2px solid #edeef6;
  margin-bottom: 24px;
}
.passwordDialogTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}
.closeMM {
  width: 14px;
  cursor: pointer;
}
.titleBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.messageCenter {
  display: flex;
  align-items: center;
}
.bodyItem {
  height: calc(100% - 58px);
  overflow: auto;
}
</style>

<template>
  <div class="flexCenter">
    <Upload
      action=""
      directory
      :before-upload="beforeUpload"
      :file-list="fileLists"
      :multiple="true"
      :showUploadList="false"
      :customRequest="customRequest"
    >
      <slot></slot>
    </Upload>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { Upload } from "ant-design-vue";
const emit = defineEmits(["upFloder"]);
const fileLists = ref([]);
const errorFileList = ref([]);
const timer = ref(null);
const customRequest = () => {};
const getByteLength = (str) => {
  const encoder = new TextEncoder();
  const encoded = encoder.encode(str);
  return encoded.length;
};
const beforeUpload = (file) => {
  file.fileType = file.name
    .substring(file.name.lastIndexOf(".") + 1)
    .toLowerCase();
  // 大小限制
  if (file.size > 20971520) {
    errorFileList.value.push({
      name: file.name,
      fileType: file.fileType,
      type: "文件大小超过20MB",
    });
    return;
  }
  // 名称超长
  let fileNamelen = String(file.name);
  if (getByteLength(fileNamelen) > 255) {
    errorFileList.value.push({
      name: file.name,
      fileType: file.fileType,
      type: "文件名称过长，上传失败！",
    });
    return;
  }

  // 定义一个包含允许文件类型的数组
  const allowedFileTypes = ["txt", "pdf", "docx", "xlsx"];
  // 检查文件类型是否在允许的文件类型列表中
  if (!allowedFileTypes.includes(file.fileType)) {
    // 如果不在，则向错误列表中添加错误信息
    errorFileList.value.push({
      name: file.name,
      fileType: file.fileType,
      type: "不支持的文件类型",
    });
    return;
  }
  // 文件夹名称
  const parentPath = file.webkitRelativePath;
  const path = parentPath.substring(0, parentPath.indexOf("/"));
  // 最后列表
  fileLists.value = [...fileLists.value, file];
  //   失败列表 errorFileList  文件列表fileLists 文件夹名称 path
  // 清除之前的定时器
  clearTimeout(timer.value);
  // 设置新的定时器
  timer.value = setTimeout(() => {
    emit("upFloder", fileLists.value, path, errorFileList.value);
    fileLists.value = [];
    errorFileList.value = [];
  }, 100); // 设置防抖的时间间隔，例如500毫秒
};
</script>

<style lang="scss" scoped>
.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

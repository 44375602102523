<script setup>
import { ref, defineProps, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
const store = useStore();
const userId = ref(store.state.userId);
const emit = defineEmits(['closeReset', 'submitReset']);
const props = defineProps({
    showReset: Boolean,
    resetInfo: Object
});
watch(
    () => props.resetInfo,
    (newVal) => {
        props.resetInfo = newVal
    }
)
const form = ref({
    isEmailInfo: true,
    auto: true,
    userId: userId.value,
    resetUserIds: [],
    newPassword: '',
})
watch(
    () => form.value,
    (newVal) => {
        form.value = newVal
    }
)
// 取消
const handleCancel = () => {
    show.value = false
    form.value = {
        newPassword: '',
        isEmailInfo: true,
        auto: true,
        userId: userId.value,
        resetUserIds: [props.resetInfo.userId],
    }
    emit('closeReset')
}
//同意
const show = ref(false)
const ids = ref([])
const handleConfirm = () => {
    form.value.resetUserIds.push(props.resetInfo.userId)
    if (form.value.auto == false) {
        if (form.value.newPassword.length < 6) {
            show.value = true
        } else {
            emit('submitReset', form.value)
            show.value = false
            form.value.newPassword = ''
            form.value.auto = true
        }
    } else {
        show.value = false
        emit('submitReset', form.value)
    }

};
</script>

<template>
    <div>
        <!-- 重置密码 -->
        <el-dialog v-model="props.showReset" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>重置密码</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleCancel" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <div class="content">
                <el-divider />
                <el-form :model="form" class="formDetail">
                    <el-form-item>
                        <div class="form-name">
                            <span class="tit">用户名:</span>
                            <span class="comtent">{{ resetInfo.departmentName }}</span>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div class="form-name">
                            <span class="tit">邮箱:</span>
                            <span class="comtent">{{ resetInfo.email }}</span>
                        </div>
                    </el-form-item>
                    <el-form-item prop="newPassword">
                        <div>
                            <el-checkbox v-model="form.auto" label="自动创建密码" size="large" />
                            <div v-if="!form.auto">
                                <div class="tit">密码:</div>
                                <el-input v-model="form.newPassword" placeholder="请输入新密码" show-password />
                                <span v-if="show" class="passWord">密码不能小于6位</span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-checkbox v-model="form.isEmailInfo" label="通过邮箱将登录信息发送给用户" size="large" />
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <div @click="handleCancel" class="cancel">取消</div>
                    <div @click="handleConfirm" class="confirm">确定</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>



<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-dialog) {
    --el-dialog-width: 600px;
    border-radius: 30px;
    // height: 800px;
    padding: 30px;
}

:deep(.el-divider) {
    margin: 1px 0 10px !important;
    width: calc(100%) !important;
}

:deep(.el-input__wrapper) {
    height: 40px !important;
    width: 300px !important;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 24px;
    position: relative;
}

.close-icon {
    font-size: 20px;
}

.passWord {
    color: #F02D63;
    font-size: 12px;
}

.form {
    margin-top: 10px;
}

.tit {
    font-size: 16px;
    margin-bottom: 2px;
    font-weight: 600;
    margin-right: 10px;
    color: #3d3d3d;
}

.comtent {
    font-size: 16px;
    margin-bottom: 8px;
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #8A8A8A;
        padding: 6px 12px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 6px 12px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
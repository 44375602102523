export default {
    CN: {
        chatSpeed: '大语言模型响应速度',
        adaSpeed: '文本嵌入模型响应速度',
        header1: '简体中文',
        header2: '繁体中文',
        header3: '英语-美国',
        header4: '验证',
        header5: '点击测速',
        header6: '正在测速中...',
        header7: '验证通知',
        header8: '话题验证',
        header9: '向您发送了一个验证请求',
        header10: '还有',
        header11: '条验证请求',
        header12: '更新动态',
        header13: '重置密码',
        header14: '企业邮箱',
        header15: '发送验证码',
        header16: '请输入新密码',
        header17: '再次输入新密码',
        header18: '取消',
        header19: '个人中心',
        header20: '用户名',
        header21: '退出登录',
        header22: '修改成功',
        header23: '退出成功',
        header24: '请输入NextAI发送的验证码',
        header25: '请输入新密码',
        header26: '密码长度不能少于6位',
        header27: '请确认新密码',
        header28: '启用',
        header29: '用户 ID',
        header30: '邮箱',
        header31: '手机号',
        header32: '部门',
        header33: '群组',
        header34: '业务组',
        header35: '角色',
        header36: '性别',
        header37: '男',
        header38: '女',
        header39: '保密',
        header40: '地址',
        header41: '只能上传图片',
        header42: '图片不能大于2MB！',
        header43: '问答验证',
        header44: '版本更新'
    },
    HK: {
        chatSpeed: '大語言模型響應速度',
        adaSpeed: '文本嵌入模型響應速度',
        header1: "简体中文",
        header2: "繁體中文",
        header3: "英語-美國",
        header4: "驗證",
        header5: "點擊測速",
        header6: "正在測速中...",
        header7: "驗證通知",
        header8: "話題驗證",
        header9: "向您發送咗一個驗證請求",
        header10: "仲有",
        header11: "條驗證請求",
        header12: "更新動態",
        header13: "重置密碼",
        header14: "企業郵箱",
        header15: "發送驗證碼",
        header16: "請輸入新密碼",
        header17: "再次輸入新密碼",
        header18: "取消",
        header19: "個人中心",
        header20: "用戶名",
        header21: "退出登錄",
        header22: "修改成功",
        header23: "退出成功",
        header24: "請輸入NextAI發送嘅驗證碼",
        header25: "請輸入新密碼",
        header26: "密碼長度唔能少於6位",
        header27: "請確認新密碼",
        header28: "啟用",
        header29: "用戶 ID",
        header30: "郵箱",
        header31: "手機號",
        header32: "部門",
        header33: "群組",
        header34: "業務組",
        header35: "角色",
        header36: "性別",
        header37: "男",
        header38: "女",
        header39: "保密",
        header40: "地址",
        header41: "只能上傳圖片",
        header42: "圖片唔能大於2MB！",
        header43: '問答驗證',
        header44: '版本更新'
    },
    US: {
        chatSpeed: 'Language model response speed',
        adaSpeed: 'Text embedding model response speed',
        header1: "Simplified Chinese",
        header2: "Traditional Chinese",
        header3: "English - United States",
        header4: "Verification",
        header5: "Click to Test Speed",
        header6: "Testing Speed...",
        header7: "Verification Notification",
        header8: "Topic Verification",
        header9: "A verification request has been sent to you",
        header10: "There are still",
        header11: "verification requests",
        header12: "Update Status",
        header13: "Reset Password",
        header14: "Enterprise Email",
        header15: "Send Verification Code",
        header16: "Please enter a new password",
        header17: "Re-enter the new password",
        header18: "Cancel",
        header19: "Personal Center",
        header20: "Username",
        header21: "Log Out",
        header22: "Modified Successfully",
        header23: "Logged Out Successfully",
        header24: "Please enter the verification code sent by NextAI",
        header25: "Please enter a new password",
        header26: "Password length must be at least 6 characters",
        header27: "Please confirm the new password",
        header28: "Enable",
        header29: "User ID",
        header30: "Email",
        header31: "Phone Number",
        header32: "Department",
        header33: "Group",
        header34: "Business Group",
        header35: "Role",
        header36: "Gender",
        header37: "Male",
        header38: "Female",
        header39: "Confidential",
        header40: "Address",
        header41: "Only images can be uploaded",
        header42: "Images must not exceed 2MB!",
        header43: 'Q&A validation',
        header44: 'Version updates'
    },
};

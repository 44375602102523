<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import api from "@/api/index";
import { message } from "ant-design-vue";
const store = useStore();
const userId = ref(store.state.userId);
const percentage1 = ref(null)
const percentage2 = ref(null)
const customColor = ref('#11D7B2');

const toWeb = () => {
  window.open("https://www.qinchengsoft.com/");
};
const activeForm = ref({})
const allDays = ref(null)
const remainDays = ref(null)
const allUser = ref(null);
const remainUser = ref(null);
onMounted(() => {
  getActive()
})
// 激活状态
const getActive = () => {
  api.manage
    .getBaseStatusApi({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        activeForm.value = res.data
        console.log(res.data);

        allDays.value = res.data.totalDays.substring(0, res.data.totalDays.indexOf(' days'))
        remainDays.value = res.data.remainDays.substring(0, res.data.remainDays.indexOf(' days'))
        allUser.value = res.data.maximunUsers
        remainUser.value = res.data.maximunUsers - res.data.remainPeople
        percentage2.value = parseInt((remainUser.value / allUser.value) * 100)
        percentage1.value = parseInt(((allDays.value - remainDays.value) / allDays.value) * 100)
        console.log(res.data, '激活状态');
      }
    })
};
</script>

<template>
  <div class="main">
    <div class="company">
      <div class="left">
        <img src="@/assets/manage/QC.svg" alt="">
      </div>
      <div class="right">{{ activeForm.corporateName }}</div>
    </div>
    <div class="cardInfo">
      <div class="leftInfo">
        <div class="tit">
          <div class="tit_img">
            <img src="@/assets/manage/cardTime.svg" alt="">
          </div>
          <div class="info">NextAI Platform™️ 订阅时长</div>
        </div>
        <div class="tag_info">
          已使用时长/总订阅时长
        </div>
        <div class="tag_num">{{ allDays - remainDays }} / {{ allDays }} </div>
        <!-- 进度条 -->
        <el-progress class="progress" :percentage="percentage1" :color="customColor" />
        <div class="jump" @click="toWeb">
          联系NextAI官方订阅续期
        </div>
      </div>
      <div class="leftInfo">
        <div class="tit">
          <div class="tit_img">
            <img src="@/assets/manage/cardUser.svg" alt="">
          </div>
          <div class="info">NextAI Platform™️ 用户席位</div>
        </div>
        <div class="tag_info">
          已使用席位/总用户席位
        </div>
        <div class="tag_num"> {{ activeForm.maximunUsers - activeForm.remainPeople }} / {{ activeForm.maximunUsers }}
        </div>
        <!-- 进度条 -->
        <el-progress class="progress" :percentage="percentage2" :color="customColor" />
        <div class="jump" @click="toWeb">
          联系NextAI官方订阅续期
        </div>
      </div>
    </div>
    <div class="introduce">
      <div class="item">
        <div class="key">产品名：</div>
        <div class="value">
          <div class="logo"><img src="@/assets/manage/logo_N.svg" alt=""></div>
          <div class="text">NextAI Platform™️ 正式版</div>
        </div>
      </div>
      <div class="item">
        <div class="key">版本信息：</div>
        <div class="value">
          <div class="text">V{{ activeForm.version }} 稳定版 Build 4869</div>
        </div>
      </div>
      <div class="item">
        <div class="key">席位信息：</div>
        <div class="value">
          <div class="text">{{ activeForm.maximunUsers }}</div>
          <div class="num"><span>( 剩余</span> <span class="span">{{ activeForm.remainPeople }}</span><span> 席位 )</span>
          </div>
          <div class="jump" @click="toWeb">
            联系NextAI官方订阅续期
          </div>
        </div>
      </div>
      <div class="item">
        <div class="key">订阅时长：</div>
        <div class="value">
          <div class="text">{{ allDays }}</div>
          <div class="num"><span>( 剩余</span> <span class="span">{{ remainDays }}</span><span> 天 )</span></div>
          <div class="jump" @click="toWeb">
            联系NextAI官方订阅续期
          </div>
        </div>
      </div>
      <div class="item">
        <div class="key">激活码：</div>
        <div class="value">
          <div class="text">{{ activeForm.activationCode }}</div>
        </div>
      </div>
      <div class="item item1">
        <div class="key">激活状态：</div>
        <div class="value">
          <!-- 启用 -->
          <div class="tag" v-if="activeForm.activationStatus == 1">
            <div class="tag-icon">
              <img src="@/assets/manage/dui.svg" alt="">
            </div>
            <div class="tag-text">已激活</div>
          </div>
          <!-- 禁用 -->
          <div class="tag_cuo" v-else>
            <div class="tag-icon">
              <img src="@/assets/manage/cuo.svg" alt="">
            </div>
            <div class="tag-text">未激活</div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="key">激活日期：</div>
        <div class="value">
          <div class="text">{{ activeForm.activationStartDate }}</div>
        </div>
      </div>
      <div class="item">
        <div class="key">失效日期：</div>
        <div class="value">
          <div class="text">{{ activeForm.activationEndDate }}</div>
        </div>
      </div>
      <div class="btn" @click="toWeb">
        访问产品官网
      </div>
    </div>
  </div>
</template>



<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main {
  width: 100%;
  padding: 30px;
  background-color: #fff;
}

.item1 {
  margin-bottom: 10px !important;
}

.company {
  display: flex;
  margin-left: 15px;

  .left {
    width: 85px;
    height: 85px;
    border-radius: 12px;
    margin-right: 12px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 12px;
    }
  }

  .right {
    font-size: 30px;
    margin-top: 27px;
    font-weight: 600;
  }
}

.cardInfo {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 1220px;
  height: 270px;
  padding-left: 12px;
  padding-right: 30px;

  .leftInfo {
    width: 560px;
    height: 250px;
    border-radius: 20px;
    background-color: #F7F8FF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 30px;

    .tit {
      display: flex;

      .tit_img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin-right: 12px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .info {
        font-size: 20px;
        color: #3D3D3D;
      }
    }

    .tag_info {
      margin-top: 20px;
      font-size: 18px;
      color: #8A8A8A;
    }

    .tag_num {
      font-size: 25px;
      color: #3D3D3D;
      margin-top: 9px;
    }

    .progress {
      margin-top: 25px;
      margin-bottom: 20px;
    }


  }
}

.jump {
  font-size: 16px;
  color: #3376FF;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
}

.tag {
  display: flex;
  justify-content: space-between;
  background-color: #B8E6CD;
  color: #14AE5C;
  padding: 0px 6px;
  height: 30px;
  line-height: 30px;
  border-radius: 6px;
  margin: auto 0;

  .tag-icon {
    width: 18px;
    height: 18px;
    display: block;
    margin-top: 3px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .tag-text {
    margin-left: 4px;
  }
}

.tag_cuo {
  display: flex;
  justify-content: space-between;
  background-color: #FBD4DF;
  color: #F02D63;
  padding: 0px 6px;
  height: 30px;
  line-height: 30px;
  border-radius: 6px;
  margin: auto 0;

  .tag-icon {
    width: 18px;
    height: 18px;
    display: block;
    margin-top: 3px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .tag-text {
    margin-left: 4px;
  }
}

.introduce {
  margin-top: 20px;
  position: relative;

  .item {
    margin-bottom: 18px;
    font-size: 16px;
    font-weight: 600;
    display: flex;

    .key {
      color: #3D3D3D;
      margin-left: 10px;
    }

    .value {
      display: flex;

      .logo {
        width: 23px;
        height: 23px;
        border-radius: 8px;
        margin-right: 6px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;

        }
      }

      .text {
        color: #3D3D3D;
        font-weight: 400;
        margin-right: 10px;

        .span {
          font-size: 17px;
          color: #F02D63 !important;
          font-weight: 600;
        }
      }

      .num {
        margin-right: 10px;

        span {
          color: #8A8A8A;
          font-size: 15px;
          font-weight: 400;
        }

        .span {
          color: #F02D63;
          font-weight: 600;
        }
      }
    }
  }
}

.btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 6px 10px;
  height: 40px;
  border-radius: 16px;
  background-color: #3376FF;
  font-size: 16px;
  color: #fff;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}
</style>
export default {
    CN: {
        team: '团队',
        department: '用户与部门',
        group: '群组管理',
        role: '角色管理',
        resoure: '资源管理',
        knowledge: '知识',
        tipic: '话题管理',
        micromodel: '知识库管理',
        bot: '智能体管理',
        train: '训练中心',
        deploy: '部署',
        accredit: '平台授权',
        base: '基础配置',
        log: '平台日志',
        qincheng: '沁诚信息',
        userForm: {
            User: '用户',
            Department: '部门',
            SearchDepartment: '搜索部门名',
            departmentName: '部门名称',
            NumberOfUsers: '部门用户数',
            Directusers: '部门直属用户',
            DepartmentHead: '部门负责人',
            DepartmentKnowledge: '部门知识库',
            operation: '操作',
            Details: '详情',
            AddSubDepartment: '新增子部门',
            BatchImport: '批量导入/导出',
            CreateNewDepar: '新建部门',
            addUser: '添加用户',
            batchDisable: '批量禁用',
            resetPassword: '重置密码',
            accountStatus: '账号状态',
            Enable: '启用',
            Disable: '禁用',
            userName: '用户名',
            isActive: '状态',
            totalUsers: '总用户',
            enabledUsers: '启用用户',
            PleaseEnterUsername: '请输入用户名、手机号或邮箱',
            CreateNewUser: '新建用户',
            email: '邮箱',
            address: '地址',
            phone: '手机号',
            roleName: '角色名称',
            isManager: '部门负责人',
            sex: '性别',
            supervisorName: '直属上级',
            setUp: '设置',
            Cancel: '取消',
            Confirm: '确定',
        },
        groupForm: {
            Group: '群组',
            SearchGroup: "搜索群组名或群组成员",
            NumberMembers: '成员人数',
            userName: '用户名或部门名',
            Email: '邮箱',
            Department: '部门',
            Remove: '移除',
            member: '成员',
            addGroup: '新建群组',
            groupRemove: '批量移除',
            addMember: '添加成员',
        },
        roleForm: {
            Details: '详情',
            Add: '添加',
            Operation: '操作',
            ScopeAction: '作用域',
            PermissionRange: '权限范围',
            Member: '成员',
            Name: '名称',
            DefaultRole: '默认角色',
            ExportRole: '导出角色详情',
            Role: '角色',
            SearchRoleName: '搜索角色名称',
            RoleDetails: '角色详情',
            Permission: '权限',
            Range: '范围',
            PleaseEnter: '请输入用户名、手机号、或邮箱',

        },
        resoureForm: {
            Business: '企业',
            department: '部门',
            whitelist: '白名单',
            totalResources: '总资源',
            enabledResources: '启用资源',
            resourcePlatform: '资源平台',
            bulkDisable: '批量禁用',
            disableCurrent: '禁用当前资源平台',
            editQuota: '编辑配额',
            resourceName: '资源名称',
            resourceType: '资源类型',
            inheritQuota: '继承配额',
            disable: '禁用',
            tokens: '令牌',
            maximum: '最多',
            unlimited: '文本生成',
            textToImage: '文生图',
            textEmbedding: '文本嵌入',
            textRecognition: '文本识别',
            objectStorage: '对象存储',
            currentDepartment: '当前部门用户日配额',
            operation: "操作",
            bulkDelete: '批量删除',
            addWhitelist: '添加白名单',
            whitelistName: '白名单名称',
            member: '成员',
            peiResource: '配置资源',
            Details: '详情',
            addMember: '添加成员',
        },
        tipicForm: {
            user: '用户',
            startTime: '起止时间',
            dulkRemove: '批量删除',
            dulkReturn: '批量归档',
            quizzer: '提问者',
            caption: '标题',
            answers: '问答数',
            topicType: '话题类型',
            createTime: '创建时间',
            updateTime: '更新时间',
            operation: '操作',
            Details: '详情',
            return: '归档',
            delete: '删除',
        }
    },
    HK: {
        team: '團隊',
        department: '用戶與部門',
        group: '群組管理',
        role: '角色管理',
        resoure: '資源管理',
        knowledge: '知識',
        tipic: '話題管理',
        micromodel: '微模型管理',
        bot: '智能體管理',
        train: '訓練中心',
        deploy: '部署',
        accredit: '平台授權',
        base: '基礎配置',
        log: '平台日志',
        qincheng: '沁誠信息',
        user: '用戶',
        section: '部門',
        allNumber: '總人數',
        state: '狀態',
        bulkImport: '批量導入/導出',
        addUser: '添加用戶',
        userName: '用戶名',
        accountStatus: '賬號狀態',
        email: '郵箱',
        handle: '操作',
        derivedRole: '導出角色',
        addRole: '創建角色',
        detail: '詳情',
        addMember: '添加成員',
        member: '成員',
        limits: '權限',
        superAdmin: '超級管理員',
        superInfo: '超級管理員可以管理平台嘅所有權限、查睇全部數據。建議將超級管理員嘅人數控制喺2-4人。',
        newDepartment: '新建部門',
        departmentName: '部門名稱',
        departmentNumber: '部門人數',
        admin: '管理員',
        departmentSmall: '部門微模型',
        addSubdivision: '添加子部門',
        userForm: {
            User: '用戶',
            Department: '部門',
            SearchDepartment: '搜索部門名',
            DepartmentName: '部門名稱',
            NumberOfUsers: '部門用戶數',
            Directusers: '部門直屬用戶',
            DepartmentHead: '部門負責人',
            DepartmentKnowledge: '部門知識庫',
            Operation: '操作',
            Details: '詳情',
            AddSubDepartment: '新增子部門',
            BatchImport: '批量導入/導出',
            CreateNewDepar: '新建部門',
            addUser: '添加用戶',
            batchDisable: '批量禁用',
            resetPassword: '重置密碼',
            accountStatus: '賬號狀態',
            Enable: '啟用',
            Disable: '禁用',
            Username: '用戶名',
            Status: '狀態',
            totalUsers: '總用戶',
            enabledUsers: '啟用用戶',
            PleaseEnterUsername: '請輸入用戶名、手機號或郵箱',
        },
        groupForm: {
            Group: '群組',
            SearchGroup: "搜索群組名稱",
            NumberMembers: '成員人數',
            userName: '用戶名或部門名',
            Email: '郵箱',
            Department: '部門',
            Remove: '移除',
        },
        roleForm: {
            Details: '詳情',
            Add: '添加',
            Operation: '操作',
            ScopeAction: '作用域',
            PermissionRange: '權限范圍',
            Member: '成員',
            Name: '名稱',
            DefaultRole: '默認角色',
            ExportRole: '導出角色詳情',
            Role: '角色',
            SearchRoleName: '搜索角色名稱',
            RoleDetails: '角色詳情',
            Permission: '權限',
            Range: '范圍',
            PleaseEnter: '請輸入用戶名、手機號、或郵箱',

        },
        resoureForm: {
            Business: '企業',
            department: '部門',
            whitelist: '白名單',
            totalResources: '總資源',
            enabledResources: '啟用資源',
            resourcePlatform: '資源平台',
            bulkDisable: '批量禁用',
            disableCurrent: '禁用當前資源平台',
            editQuota: '編輯配額',
            resourceName: '資源名稱',
            resourceType: '資源類型',
            inheritQuota: '繼承配額',
            disable: '禁用',
            tokens: '令牌',
            maximum: '最多',
            unlimited: '文本生成',
            textToImage: '文生圖',
            textEmbedding: '文本嵌入',
            textRecognition: '文本識咪',
            objectStorage: '對象存儲',
        }
    },
    US: {
        team: 'team',
        department: 'department',
        group: 'group management',
        role: 'role management',
        resoure: 'resource management',
        knowledge: 'knowledge',
        tipic: 'topic management',
        micromodel: 'micromodel management',
        bot: 'bot management',
        train: 'training center',
        deploy: 'deployment',
        accredit: 'platform authorization',
        base: 'basic configuration',
        log: 'platform log',
        qincheng: 'Qincheng Information',
        user: 'user',
        section: 'department',
        allNumber: 'total number',
        state: 'status',
        bulkImport: 'bulk import/export',
        addUser: 'add user',
        userName: 'user name',
        accountStatus: 'account status',
        email: 'email',
        handle: 'operation',
        derivedRole: 'export role',
        addRole: 'create role',
        detail: 'details',
        addMember: 'add member',
        member: 'member',
        limits: 'permissions',
        superAdmin: 'super administrator',
        superInfo: 'super administrator can manage all platform permissions and view all data. It is recommended to limit the number of super administrators to 2-4 people. ',
        newDepartment: 'New Department',
        departmentName: 'Department Name',
        departmentNumber: 'Department Size',
        admin: 'Administrator',
        departmentSmall: 'Department Miniature',
        addSubdivision: 'Add Sub-Department',
        userForm: {
            User: 'User',
            Department: 'Department',
            SearchDepartment: 'Search Department Name',
            DepartmentName: 'Department Name',
            NumberOfUsers: 'Number of Users',
            Directusers: 'Direct Users',
            DepartmentHead: 'Department Head',
            DepartmentKnowledge: 'Department Knowledge',
            Operation: 'Operation',
            Details: 'Details',
            AddSubDepartment: 'Add Sub-Department',
            BatchImport: 'Batch Import/Export',
            CreateNewDepar: 'Create New Department',
            addUser: 'Add User',
            batchDisable: 'Batch Disable',
            resetPassword: 'Reset Password',
            accountStatus: 'Account Status',
            Enable: 'Enable',
            Disable: 'Disable',
            Username: 'Username',
            Status: 'Status',
            totalUsers: 'Total Users',
            enabledUsers: 'Enabled Users',
            PleaseEnterUsername: 'Please Enter Username',
        },
        groupForm: {
            Group: 'Group',
            SearchGroup: "Search Group Name",
            NumberMembers: 'Number of Members',
            userName: 'User Name or Department Name',
            Email: 'Email Address',
            Department: 'Department',
            Remove: 'Remove',
        },
        roleForm: {
            Details: 'Details',
            Add: 'Add',
            Operation: 'Operation',
            ScopeAction: 'ScopeAction',
            PermissionRange: 'PermissionRange',
            Member: 'Member',
            Name: 'Name',
            DefaultRole: 'DefaultRole',
            ExportRole: 'ExportRole',
            Role: 'Role',
            SearchRoleName: 'SearchRoleName',
            RoleDetails: 'RoleDetails',
            Permission: 'Permission',
            Range: 'Range',
            PleaseEnter: 'Please Enter Username',

        },
        resoureForm: {
            Business: 'Busines',
            department: 'Department',
            whitelist: 'White List',
            totalResources: 'Total Resources',
            enabledResources: 'Enabled Resources',
            resourcePlatform: 'Resource Platform',
            bulkDisable: 'Bulk Disable',
            disableCurrent: 'Disable Current Resource Platform',
            editQuota: 'Edit Quota',
            resourceName: 'Resource Name',
            resourceType: 'Resource Type',
            inheritQuota: 'Inherit Quota',
            disable: 'Disable',
            tokens: 'Tokens',
            maximum: 'Maximum',
            unlimited: 'Unlimited',
            textToImage: 'Text to Image',
            textEmbedding: 'Text Embedding',
            textRecognition: 'Text Recognition',
            objectStorage: 'Object Storage',
        }
    },
};

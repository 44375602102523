<script setup>
import { ref, defineProps, defineEmits,watch } from 'vue'
const props = defineProps({
    showName: Boolean,
    memberInfo: Object
});
const emit = defineEmits(['closeMember','submitMember']);
watch(
    () => props.memberInfo,
    (newVal) => {
        props.memberInfo = newVal;
    }
)
const handleClose = () => {
    emit('closeMember');
}
const handleSave = () => {
    emit('submitMember', props.memberInfo);
};
</script>
<template>
    <div>
        <el-dialog v-model="props.showName" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>修改部门名称</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleClose" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <div class="my-body">
                <el-form :model="memberInfo" class="form">
                    <el-form-item prop="departmentName">
                        <div class="form-name">
                            <p class="tit">部门名称</p>
                            <el-input v-model="memberInfo.departmentName" placeholder="请输入部门名称" />
                        </div>                        
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <div @click="handleClose" class="cancel">放弃</div>
                    <div @click="handleSave" class="confirm">确认</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<style scoped lang="scss">
:deep(.el-dialog) {
    --el-dialog-width: 460px;
    border-radius: 30px;
    padding: 20px;
}
:deep(.el-input__wrapper) {
    height: 38px !important;
    width: 380px !important;
    border-radius: 12px;
}
.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 18px;
    position: relative;
}
.close-icon {
    font-size: 20px;
}
.my-body{
    font-size: 18px;
    color: #3D3D3D;
}
.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #F02D63;
        padding: 8px 20px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 8px 20px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
<template>
  <div class="widhei100">
    <div class="tableDiv">
      <table class="widhei100">
        <thead>
          <tr>
            <th
              v-for="header in props.excelData[sheetInx].data[0]"
              :key="header"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in props.excelBodyData" :key="rowIndex">
            <td v-for="(cell, colIndex) in row" :key="colIndex">
              {{ cell }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btnItem">
      <el-button
        v-for="(g, k) in props.excelData"
        :key="k"
        @click="selectSheet(k)"
        >{{ g.name }}
      </el-button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
const props = defineProps(["excelData", "excelBodyData"]);
const sheetInx = ref(0);
const selectSheet = (k) => {
  sheetInx.value = k;
  props.excelBodyData = props.excelData[k].data.slice(1);
};
</script>

<style lang="scss" scoped>
.widhei100 {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.btnItem {
  display: flex;
  width: calc(100% - 20px);
  overflow: auto;
  height: 50px;
  align-items: center;
  background-color: #ffffff;
  padding: 0px 10px;
}
.tableDiv {
  height: calc(100% - 50px);
  background-color: #ffffff;
  overflow: auto;
}
table {
  overflow: auto;
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  overflow: auto;
}

table,
th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}
</style>

<template>
  <div class="releaseDiv">
    <div class="flexBetween">
      <div class="dialogTitle">{{ props.title }}</div>
      <img class="closeImg" :src="closeFileView" alt="" @click="cancel"/>
    </div>
    <div class="dialogTip">
      {{ props.tip }}
    </div>
    <div class="flexEnd">
      <el-button class="cancelBtn" @click="cancel">{{
        props.cancel
      }}</el-button>
      <el-button class="okBtn" @click="ok">{{ props.ok }}</el-button>
    </div>
  </div>
</template>

<script setup>
import closeFileView from "@/assets/base/closeFileView.svg";
import { defineProps, defineEmits } from "vue";
const props = defineProps(["title", "tip", "cancel", "ok"]);
const emit = defineEmits(["cancel", "ok"]);
const cancel = () => {
  emit("cancel");
};
const ok = () => {
  emit("ok");
};
</script>

<style lang="scss" scoped>
.releaseDiv {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  background-color: #fefefe;
  border-radius: 30px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  z-index: 110;
  padding: 30px;
}
.flexEnd {
  display: flex;
  justify-content: flex-end;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialogTitle {
  font-size: 22px;
  color: #3d3d3d;
  font-family: "Medium";
}
.closeImg {
  width: 15px;
  cursor: pointer;
}
.dialogTip {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Medium";
  margin: 30px 0px;
}
.cancelBtn {
  height: 48px;
  border: 2px solid #edeef6;
  color: #f02d63;
  border-radius: 16px;
  font-size: 20px;
  font-family: "Regular";
}
.okBtn {
  height: 48px;
  background-color: #3376ff;
  border-radius: 16px;
  border: 2px solid #3376ff;
  color: #fefefe;
  font-family: "Regular";
  font-size: 20px;
}
</style>

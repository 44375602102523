<script setup>
import { ref, defineProps, onMounted, watch } from "vue";
import { useStore } from "vuex";
import api from "@/api/index";
const store = useStore();
const userId = ref(store.state.userId);
const emit = defineEmits(['closeEdit', 'submitEdit', 'submitAdd']);
const props = defineProps({
    showEdit: Boolean,
    resoureInfo: Object,
    formType: String
});
const editForm = ref({
    userId: userId.value,
    name: '',
    description: '',
    groupIds: [],
    limits: {},
})
watch(
    () => props.resoureInfo,
    (newVal) => {
        props.resoureInfo = newVal
    })
onMounted(() => {
    // if (props.formType == 'edit') {        
    //     editForm.value = props.resoureInfo
    // }
    getGroupList()
    getNewList()
})
const editNum = ref(true)
// 编辑配额
const editNumBtn = () => {
    editNum.value = false;
}
const limit = ref({})
// input失焦
const newObj = ref({})
const changeInput = (e) => {
    limit.value[e.limit.limitType] = e.limit.value;
    Object.assign(newObj.value, limit.value)
};
const formRef = ref(null);
const rules = ref({
    name: [{
        required: true,
        message: '白名单名称不能为空',
        trigger: "blur",
    }],
    description: [{
        required: true,
        message: '白名单描述不能为空',
        trigger: "blur",
    }],
    groups: [{
        required: true,
        message: '群组不能为空',
        trigger: "blur",
    }],
    resourceInfo: [{
        required: true,
        message: '资源配置不能为空',
        trigger: "blur",
    }],
});
// 保存配额编辑
const submitNumBtn = () => {
    editNum.value = true;
};
// 群组下拉
const groupTree = ref(null);
const groupList = ref([]);
const groupSelect = ref()
const defaultProps = ref({
    label: "groupName",
    id: "groupId",
});
const getGroupList = () => {
    api.manage
        .queryGroupList({
            userId: userId.value,
        })
        .then((res) => {
            if (res.returnCode === 200) {
                groupList.value = res.data;
            }
        })
};
const showGroup = ref(false)
const showChangeGroup = () => {
    showGroup.value = true
}
// 群组下拉打开
const openChange = () => {
    if (props.formType == 'edit') {
        props.resoureInfo.groups.map((item) => {
            groupList.value.map((item1) => {
                if (item1.groupId === item.groupId) {
                    item1.disabled = true;
                }
            });
        });
    }
};
const closeChange = () => {
    showGroup.value = false
    openChange()
}
const handleCheckChange = (data, checked) => {


    if (checked) {
        props.resoureInfo.groups.push(data);
    } else {
        props.resoureInfo.groups = props.resoureInfo.groups.filter((item) => item.groupId !== data.groupId);
    }
}
// 所属群组移除
const removeTag = (val) => {
    const index = props.resoureInfo.groups.indexOf(val);
    if (index > -1) {
        props.resoureInfo.groups.splice(index, 1);
    }
};
const handleDelete1 = (data) => {
    props.resoureInfo.groups = props.resoureInfo.groups.filter((item) => item.groupId !== data.groupId)
};
// 企业租户下所有资源组的资源列表
const resoureSelect = ref()
const resoureList = ref([])
const resoureTree = ref(null)
const showResoure = ref(false)
const defaultProps2 = ref({
    id: 'modelType',
    label: 'modelType'
})
const addResoureBtn = () => {
    showResoure.value = true
}
const getNewList = () => {
    api.manage
        .getResourceListApi({
            departmentId: 1,
            resourceType: -1,
            ifLimit: true,
            userId: userId.value,
        })
        .then((res) => {
            if (res.returnCode == 200) {
                resoureList.value = res.data;
            }
        })
}
// 打开资源列表下拉
const openResoure = () => {
    if (props.formType == 'edit') {
        props.resoureInfo.resourceInfo.map((item) => {
            resoureList.value.map((item1) => {
                if (item1.modelType === item.modelType) {
                    item1.disabled = true;
                }
            });
        });
    }
};
const closeChange2 = () => {
    showResoure.value = false
    openResoure()
}
const handleCheckChange2 = (data, checked) => {
    if (checked) {
        props.resoureInfo.resourceInfo.push(data);
    } else {
        props.resoureInfo.resourceInfo = props.resoureInfo.resourceInfo.filter((item) => item.modelType !== data.modelType);
    }
}
// 所属群组移除
const removeTag2 = (val) => {
    const index = props.resoureInfo.resourceInfo.indexOf(val);
    if (index > -1) {
        props.resoureInfo.resourceInfo.splice(index, 1);
    }
};
// 配置资源移除
const handleDelete = (data) => {
    props.resoureInfo.resourceInfo = props.resoureInfo.resourceInfo.filter((item) => item.index !== data.index)
};
// 拒绝
const handleCancel = () => {
    emit('closeEdit')
}
//提交
const handleConfirm = async (formRef) => {
    editForm.value.name = props.resoureInfo.name,
        editForm.value.description = props.resoureInfo.description,
        editForm.value.groupIds = props.resoureInfo.groups.map((item) => item.groupId),
        editForm.value.limits = newObj.value
        console.log(editForm.value,'传值');
        
    await formRef.validate((valid) => {
        if (valid) {
            emit('submitEdit', editForm.value)
        }
    })
};
// 新增确定
const addSave = async (formRef) => {
    editForm.value.name = props.resoureInfo.name,
    editForm.value.description = props.resoureInfo.description,
    editForm.value.groupIds = props.resoureInfo.groups.map((item) => item.groupId),
        editForm.value.limits = newObj.value
    await formRef.validate((valid) => {
        if (valid) {
            emit('submitAdd',editForm.value)
        }
    })    
};
</script>

<template>
    <!-- 白名单详情 -->
    <div>
        <el-dialog v-model="props.showEdit" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>{{ props.formType === 'edit' ? '编辑' : '新增' }}白名单</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleCancel" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <el-divider />
            <div>
                <el-form ref="formRef" :model="resoureInfo" :rules="rules" label-position="top">
                    <el-form-item prop="name" label="白名单名称" class="input-item">
                        <el-input class="comtent" v-model="resoureInfo.name" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item prop="description" label="白名单描述" class="input-item">
                        <el-input class="comtent" v-model="resoureInfo.description" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item props="groups">
                        <div class="addUser">
                            <div class="left">所属群组</div>
                            <div class="twoBtn">
                                <div class="oneBtn" @click="showChangeGroup">添加群组</div>
                            </div>
                        </div>
                        <!-- 添加群组下拉 -->
                        <el-tree-select v-if="showGroup" multiple v-model="groupSelect" :data="groupList" show-checkbox
                            :props="defaultProps" node-key="groupId" ref="groupTree" @visible-change="openChange"
                            @check-change="handleCheckChange" @blur="closeChange" @remove-tag="removeTag" />
                        <el-table :data="resoureInfo.groups" class="table">
                            <el-table-column prop="groupName" label="群组名" />
                            <el-table-column prop="groupName" label="操作">
                                <template #default="scope">
                                    <div @click="handleDelete1(scope.row)" class="move_tag">移除</div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item props="resourceInfo">
                        <div class="addUser">
                            <div class="left">配置资源</div>
                            <div class="twoBtn">
                                <div class="oneBtn" @click="addResoureBtn">添加资源</div>
                                <div @click="editNumBtn" v-if="editNum" class="oneBtn">编辑配额</div>
                                <div @click="submitNumBtn" v-if="!editNum" class="submitBtn">完成编辑</div>
                            </div>
                        </div>
                        <!-- 企业资源下拉 -->
                        <el-tree-select v-if="showResoure" multiple v-model="resoureSelect" :data="resoureList"
                            show-checkbox :props="defaultProps2" node-key="modelType" ref="resoureTree"
                            @visible-change="openResoure" @check-change="handleCheckChange2" @blur="closeChange2"
                            @remove-tag="removeTag2" />
                        <el-table :data="resoureInfo.resourceInfo" class="table">
                            <el-table-column prop="modelType" label="资源名称" />
                            <el-table-column prop="functionType" label="资源类型" />
                            <el-table-column prop="limit" label="用户日配额">
                                <template #default="scope">
                                    <el-input v-model="scope.row.limit.value" :disabled="editNum"
                                        @blur="changeInput(scope.row)">
                                        <template #append v-if="scope.row.limit.limitType === 'limitAliImageTimes'">
                                            张</template>
                                        <template #append v-if="scope.row.limit.limitType === 'limitBaiduImageTimes'">
                                            张</template>
                                        <template #append v-if="scope.row.limit.limitType === 'limitDocumentChatNum'">
                                            个</template>
                                        <template #append v-if="scope.row.limit.limitType === 'limitDocumentNum'">
                                            个</template>
                                        <template #append v-if="scope.row.limit.limitType === 'limitImageTimes'">
                                            张</template>
                                        <template #append v-else>令牌</template>
                                    </el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="groupName" label="操作">
                                <template #default="scope">
                                    <div @click="handleDelete(scope.row)" class="move_tag">移除</div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <div class="dialog-footer" v-if="props.formType == 'edit'">
                    <div @click="handleCancel" class="cancel">放弃</div>
                    <div @click="handleConfirm(formRef)" class="confirm">提交</div>
                </div>
                <div class="dialog-footer" v-if="props.formType == 'add'">
                    <div @click="handleCancel" class="cancel">取消</div>
                    <div @click="addSave(formRef)" class="confirm">新增</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>



<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-divider) {
    margin: 1px 0 10px !important;
    width: calc(100%) !important;
}

.input-item {
    :deep(.el-input__wrapper) {
        border-radius: 16px;
        width: 130px !important;
        margin-bottom: 10px;
        margin-right: 10px;
    }
}

:deep(.el-dialog) {
    --el-dialog-width: 756px;
    border-radius: 30px;
    padding: 30px;
    max-height: 872px;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 24px;
    position: relative;
}

.twoBtn {
    display: flex;

    .oneBtn {
        width: 80px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        border-radius: 8px;
        background-color: #3376ff;
        cursor: pointer;
        margin-left: 10px;
        color: #fff;
    }
}

.submitBtn {
    width: 80px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    border-radius: 8px;
    background-color: #767bfa;
    cursor: pointer;
    margin-left: 10px;
    color: #fff;
}

.move_tag {
    width: 48px;
    padding: 4px 8px;
    background-color: #F7F8FF;
    border-radius: 8px;
    color: #F02D63;
    cursor: pointer;
}

.close-icon {
    font-size: 20px;
}

.content {
    display: flex;
    color: #3D3D3D;

    .name {
        font-size: 24px;
        font-weight: 600;
        margin-right: 6px;
    }

    .tag {
        display: flex;
        justify-content: space-between;
        background-color: #DBDFFF;
        color: #0256FF;
        padding: 0px 6px;
        height: 28px;
        line-height: 28px;
        border-radius: 6px;
        font-size: 16px;
        margin: auto 0;
    }
}

.introduce {
    margin-top: 12px;
    font-size: 18px;
    height: 25px;
    line-height: 25px;
}

.addUser {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    .left {
        font-size: 18px;
        color: #3D3D3D;
        font-weight: 600;
        height: 36px;
        line-height: 36px;
    }

    .add {
        width: 112px;
        height: 36px;
        font-size: 18px;
        color: #fff;
        background-color: #3376FF;
        line-height: 36px;
        text-align: center;
        border-radius: 12px;
    }
}

.table {
    margin-top: 12px;
    width: 100%;
    height: 220px;
}

.form-name {
    margin-top: 12px;
    font-size: 18px;
    color: #3D3D3D;

    .el-tag {
        display: flex;
        padding: 2px 33px 2px 2px;
        width: 144px;
        height: 35px;
        line-height: 35px;
        border-radius: 30px;
        font-size: 18px;
        margin-top: 12px;

        .tag-img {
            width: 30px;
            height: 30px;
            margin-right: 6px;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    }

    .tit {
        margin-bottom: 10px;
        font-weight: 600;
    }

    .comtent {
        margin-bottom: 12px;
    }
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #8A8A8A;
        padding: 6px 12px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 6px 12px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
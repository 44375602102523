<template>
  <div class="lookContent">
    <div class="deleteDiv" @click="openDelete" v-if="!deleteType">
      <img :src="hszImg" alt="" class="deleteImg" />
      回收站
    </div>
    <div class="gobackDiv" @click="closeDelete" v-if="deleteType">
      <img :src="goBackImg" alt="" class="deleteImg" />
      返回
    </div>
    <div class="lookAllMargin">
      <div class="lookTopDiv">
        <el-input
          placeholder="搜索话题"
          prefix-icon="Search"
          class="inputSearch"
          v-model="namePattern"
          @input="searchList"
        />
        <template v-if="!deleteType">
          <el-button
            @click="getTopicList(index)"
            :class="navActive == index ? 'itemBtnAc' : 'itemBtn'"
            v-for="(item, index) in navList"
            :key="item"
          >
            <img :src="item.url" class="qw" alt="" />
            {{ item.name }}
          </el-button>
          <!-- <el-button class="itemBtn"><img :src="moreImg" alt="" /></el-button> -->
          <div class="optionsDiv">
            <img :src="optionsImg" alt="" class="optionsImg" />
          </div>
        </template>
      </div>
      <div class="lookView" v-loading="topicLoading">
        <lookView
          v-if="topicList.length != 0"
          type="look"
          :list="topicList"
          :deleteType="deleteType"
          @goShare="goShare"
          @goLookView="goLookView"
        ></lookView>
        <img
          v-if="topicList.length == 0 && !topicLoading"
          :src="nodata"
          alt=""
          class="nodata"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import qwImg from "@/assets/look/qw.svg";
import rmImg from "@/assets/look/rm.svg";
import scImg from "@/assets/look/sc.svg";
import moreImg from "@/assets/look/more.svg";
import optionsImg from "@/assets/look/options.svg";
import hszImg from "@/assets/look/hsz.svg";
import goBackImg from "@/assets/look/goBack.svg";
import lookView from "@/components/look/index.vue";
import nodata from "@/assets/nodata.svg";
import api from "@/api";
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
const store = useStore();
const namePattern = ref("");
const topicList = ref([]);
const deleteType = ref(false);
const userId = ref(store.state.userId);
const topicLoading = ref(false);
const pageId=ref(1)
const navList = ref([
  {
    name: "权威话题",
    url: qwImg,
  },
  {
    name: "热门话题",
    url: rmImg,
  },
  {
    name: "收藏夹",
    url: scImg,
  },
]);
// 列表参数
const listFomr = ref({
  userId: store.state.userId,
  namePattern: "",
  pageId: 1,
  perPage: 10,
});
const navActive = ref(null);
onMounted(() => {
  if (route.query.type == 1) {
    openDelete();
  } else {
    getTopic();
  }
});
watch(
  () => route.query,
  (newValue, oldValue) => {
    if (route.query.type == 1) {
      openDelete();
    }
  }
);
const getTopicList = (index) => {
  if (navActive.value == index) {
    navActive.value = null;
    getTopic();
    return;
  }
  navActive.value = index;
  if (index == 0) {
    // 权威
    getQw();
  }
  if (index == 1) {
    // 热门
    getRm();
  }
  if (index == 2) {
    // 收藏
    getSc();
  }
};
const getQw = () => {
  topicLoading.value = true;
  api.share
    .getVerifyQuertApi(listFomr.value)
    .then((res) => {
      topicLoading.value = false;
      if (res.returnCode == 200) {
        topicList.value = res.data;
      }
    })
    .catch((err) => {
      topicLoading.value = false;
    });
};
const getRm = () => {
  topicLoading.value = true;
  api.base
    .hotQuery({ userId: userId.value })
    .then((res) => {
      topicLoading.value = false;
      if (res.returnCode == 200) {
        topicList.value = res.data;
      }
    })
    .catch((err) => {
      topicLoading.value = false;
    });
};
const getSc = () => {
  topicLoading.value = true;
  api.base
    .likeQuery({ userId: userId.value })
    .then((res) => {
      topicLoading.value = false;
      if (res.returnCode == 200) {
        topicList.value = res.data;
      }
    })
    .catch((err) => {
      topicLoading.value = false;
    });
};
// 防止分页
const searchList = () => {
  pageId.value = 1;
  getTopic();
};
// 获取左侧最近话题
const getTopic = () => {
  topicLoading.value = true;
  api.header
    .chatAllQuery({
      userId: userId.value,
      namePattern: namePattern.value,
      operationType: !deleteType.value ? 0 : 1,
    })
    .then((res) => {
      topicLoading.value = false;
      if (res.returnCode == 200) {
        topicList.value = res.data;
      }
    })
    .catch((err) => {
      topicLoading.value = false;
    });
};
// 打开回收站
const openDelete = () => {
  store.commit("SET_MANGER", "回收站");
  deleteType.value = true;
  getTopic();
};
// 关闭回收站
const closeDelete = () => {
  store.commit("SET_MANGER", "");
  deleteType.value = false;
  getTopic();
};
// 去验证
const goShare = (item) => {
  store.commit("SET_YZ", item);
  router.push("/share/details");
};
// 去对话详情
const goLookView = (item) => {
  store.commit("SET_CHATTYPE", "chat");
  store.commit("SET_TOPIC", item);
  router.push("/chat");
};
</script>

<style lang="scss" scoped>
.lookContent {
  padding: 30px;
  height: calc(100% - 60px);
  width: calc(100% - 60px);
  background-color: #fefefe;
  border-radius: 30px;
  box-shadow: 0px 0px 20px 0px #dbdfff78;
  position: relative;
}
.lookTopDiv {
  display: flex;
  align-items: center;
  height: 60px;
  margin-bottom: 20px;
}
.inputSearch {
  width: 236px;
  margin-right: 9px;
  :deep(.el-input__wrapper) {
    border: 2px solid #edeef6;
    box-shadow: 0px 0px 0px 0px #ffffff;
    border-radius: 16px;
    height: 55px;
    font-size: 22px;
    font-family: "Regular";
  }
}
.itemBtn {
  height: 59px;
  color: #8a8a8a;
  border-radius: 16px;
  border: 2px solid #edeef6;
  padding: 12px 15px;
  font-size: 22px;
  font-family: "Regular";
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemBtnAc {
  height: 59px;
  color: #8a8a8a;
  border-radius: 16px;
  border: 2px solid #edeef6;
  background-color: #eceeff;
  padding: 12px 15px;
  font-size: 22px;
  font-family: "Regular";
  display: flex;
  align-items: center;
  justify-content: center;
}
.qw {
  width: 26px;
  margin-right: 6px;
}
.otherImg {
  width: 24px;
  margin-right: 6px;
}
.moreImg {
  width: 33px;
}
.lookView {
  height: calc(100% - 80px);
  overflow: auto;
  position: relative;
}
.optionsDiv {
  background-color: #edeef6;
  width: 36px;
  height: 36px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  cursor: pointer;
}
.optionsImg {
  width: 25px;
}
.lookAllMargin {
  width: 1010px;
  height: 100%;
  margin: 0 auto;
}
.deleteDiv {
  height: 34px;
  border-radius: 12px;
  border: 1px solid #edeef6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #f02d63;
  font-family: "Regular";
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  left: 30px;
  padding: 5px 12px;
}
.deleteImg {
  width: 25px;
  margin-right: 3px;
}
.gobackDiv {
  height: 34px;
  border-radius: 12px;
  border: 1px solid #edeef6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  left: 30px;
  padding: 5px 12px;
}
.nodata {
  width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>

<template>
  <!-- q -->
  <div class="qDiv">
    <div class="itemQuestion">
      {{ props.item.mySaveData.question }}
    </div>
  </div>
  <!-- 来源 -->
  <div
    v-if="
      props.item.mySaveData.fileResource.length != 0 ||
      props.item.mySaveData.internets.length != 0 ||
      props.item.mySaveData.sessionResource.length != 0
    "
    class="resonDialog"
    @click="closeReson"
  >
    <div
      class="reSonUpFile bottom12"
      v-if="props.item.mySaveData.fileResource.length != 0"
    >
      <div class="reSonCenter">
        <img class="reSonIcon" :src="baseReson" alt="" />
        <div class="reSonTitle">
          找到{{ props.item.mySaveData.fileResource.length }}个AI知识库来源
        </div>
      </div>
      <img
        class="reSonUpFileImg"
        :src="upReson"
        alt=""
        @click="props.item.resonOneType = !props.item.resonOneType"
      />
    </div>
    <!-- 文件内容 -->
    <el-collapse-transition>
      <div class="pa" v-show="props.item.resonOneType">
        <img
          :src="rightReson"
          alt=""
          class="leftResonIcon"
          @click="changeLeftReson(resonOne)"
          v-if="
            props.item.mySaveData.fileResource.length +
              props.item.mySaveData.sessionResource.length >
            4
          "
        />
        <div class="flexItemReson" ref="resonOne">
          <fileReSon
            v-for="items in props.item.mySaveData.fileResource"
            :key="items.id"
            :item="items"
            class="resonMargin"
            @click="openFileView(items)"
          ></fileReSon>
          <talkReSon
            class="resonMargin"
            v-for="items in props.item.mySaveData.sessionResource"
            :key="items.sessionId"
            :talkItem="items"
          ></talkReSon>
        </div>
        <img
          v-if="
            props.item.mySaveData.fileResource.length +
              props.item.mySaveData.sessionResource.length >
            4
          "
          :src="rightReson"
          alt=""
          class="rightResonIcon"
          @click="changeRightReson(resonOne)"
        />
      </div>
    </el-collapse-transition>
    <!-- 网页 -->
    <div class="reSonUpFile" v-if="props.item.mySaveData.internets.length != 0">
      <div class="reSonCenter">
        <img class="reSonIcon" :src="netReson" alt="" />
        <div class="reSonTitle">
          找到{{ props.item.mySaveData.internets.length }}个互联网来源
        </div>
      </div>
      <img
        class="reSonUpFileImg"
        :src="upReson"
        alt=""
        @click="props.item.resonTwoType = !props.item.resonTwoType"
      />
    </div>
    <el-collapse-transition>
      <div class="pa" v-show="props.item.resonTwoType">
        <img
          v-if="props.item.mySaveData.internets.length > 4"
          :src="rightReson"
          alt=""
          class="leftResonIcon"
          @click="changeLeftReson(resonTwo)"
        />
        <div class="flexItemReson" ref="resonTwo">
          <htmlReSon
            v-for="items in props.item.mySaveData.internets.length"
            :key="items"
            :htmlItem="items"
            class="resonMargin"
          ></htmlReSon>
        </div>
        <img
          v-if="props.item.mySaveData.internets.length > 4"
          :src="rightReson"
          alt=""
          class="rightResonIcon"
          @click="changeRightReson(resonTwo)"
        />
      </div>
    </el-collapse-transition>
  </div>

  <!-- a -->
  <messageView
    :message="props.item.mySaveData.message"
    class="messageView"
  ></messageView>
</template>

<script setup>
import messageView from "@/components/message/index.vue";
import baseReson from "@/assets/chat/baseReson.svg";
import upReson from "@/assets/chat/upReson.svg";
import rightReson from "@/assets/chat/rightReson.svg";
import netReson from "@/assets/chat/netReson.svg";
import fileReSon from "@/components/shareReson/file.vue";
import htmlReSon from "@/components/shareReson/html.vue";
import talkReSon from "@/components/shareReson/talk.vue";
import { ref, defineProps, defineEmits } from "vue";
const props = defineProps(["item"]);
const emit = defineEmits(["openFileView"]);
const resonOne = ref(null);
const resonTwo = ref(null);
const openFileView = (item) => {
  item.fileId = item.id;
  emit("openFileView", item);
};
// 关闭来源
const closeReson = () => {};
// 想左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
</script>

<style lang="scss" scoped>
.qDiv {
  display: flex;
  justify-content: flex-end;
  margin-left: 110px;
  position: relative;
}
.itemQuestion {
  display: flex;
  background-color: #f7f8ff;
  border-radius: 16px;
  padding: 12px 26px;
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Regular";
  margin-bottom: 52px;
}
.messageView {
  margin-bottom: 52px;
}
.resonDialog {
  width: calc(100% - 30px);
  max-height: 302px;
  background-color: #f7f8ff;
  border-radius: 30px;
  padding: 17px 15px;
  position: relative;
  margin-bottom: 20px;
}
.reSonUpFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.bottom12 {
  margin-bottom: 12px;
}
.reSonCenter {
  display: flex;
  align-items: center;
}
.reSonIcon {
  width: 25px;
  margin-right: 10px;
}
.reSonTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Semibold";
}
.reSonUpFileImg {
  width: 24px;
}
.pa {
  position: relative;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(25px, -50%);
    width: 30px;
    z-index: 10;
    background: linear-gradient(180deg, #f7f8ff 0%, #fff 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0), #ffffff);
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: 35px;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
    background: linear-gradient(180deg, #f7f8ff 0%, #fff 100%);
    background: linear-gradient(to left, rgba(0, 0, 0, 0), #ffffff);
  }
}
.flexItemReson {
  display: flex;
  margin: 0px 0px 12px 32px;
  align-content: flex-start;
  justify-content: flex-start;
  overflow: auto; /* 使div可滚动 */
  scrollbar-width: none; /* 对于现代浏览器，隐藏滚动条 */
  -ms-overflow-style: none;
  position: relative;
}
.resonMargin {
  margin-right: 12px;
}
</style>

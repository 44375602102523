<script setup>
import { Search } from "@element-plus/icons-vue";
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import api from "@/api/index";
import { useRouter } from "vue-router";
import lookView from "@/components/look/index.vue";
import noData from "@/assets/nodata.svg";
const router = useRouter();
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const canverify = ref([]); //可验证列表
onMounted(() => {
  topicList();
  getList();
  getInitiateVerify();
});
const navActive = ref(0);
const loading = ref(false);
const navList = ref([
  {
    name: "权威话题",
    url: require("@/assets/share/verified.svg"),
  },
  {
    name: "热门话题",
    url: require("@/assets/share/hot.svg"),
  },
  {
    name: "收藏夹",
    url: require("@/assets/share/collect.svg"),
  },
  {
    name: "我可验证",
    url: "",
  },
  {
    name: "请求验证",
    url: "",
  },
]);
// 列表参数
const listFomr = ref({
  userId: store.state.userId,
  namePattern: "",
  pageId: 1,
  perPage: 10,
});
// 我可验证列表
const initiateVerList = ref([]);
const list = ref([]);
// 切换nav
const changeNav = (index) => {
  if (navActive.value == index) return;
  navActive.value = index;
  list.value = [];
  if (index == 0) {
    // 权威
    getList();
  } else if (index == 1) {
    // 热门
    getHot();
  } else if (index == 2) {
    // 收藏
    getLike();
  } else if (index == 3) {
    // 我可验证
    getRight();
  } else if (index == 4) {
    // 我申请验证
    getBottom();
  }
};
// 跳转对应微模型
const goBase = (id) => {
  api.base
    .customLibraryDesc({
      userId: userId.value,
      libraryId: id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        store.commit("SET_BASEITEM", res.data);
        router.push("/base/details");
      }
    });
};
// 热门话题
const getHot = () => {
  loading.value = true;
  api.base
    .hotQuery({
      userId: userId.value,
    })
    .then((res) => {
      loading.value = false;
      if (res.returnCode == 200) {
        list.value = res.data;
        console.log(res.data);
      }
    })
    .catch((err) => {
      loading.value = true;
    });
};
// 收藏话题
const getLike = () => {
  loading.value = true;
  api.base
    .likeQuery({
      userId: userId.value,
    })
    .then((res) => {
      loading.value = false;
      if (res.returnCode == 200) {
        list.value = res.data;
      }
    })
    .catch((err) => {
      loading.value = true;
    });
};
// 获取列表
const getList = () => {
  loading.value = true;
  api.share
    .getVerifyQuertApi(listFomr.value)
    .then((res) => {
      loading.value = false;
      if (res.returnCode == 200) {
        list.value = res.data;
      }
    })
    .catch((err) => {
      loading.value = false;
    });
};
// nav点击我可验证
const getRight = () => {
  loading.value = true;
  api.share
    .getVerifyApi({
      userId: userId.value,
    })
    .then((res) => {
      loading.value = false;
      if (res.returnCode == 200) {
        list.value = res.data;
      }
    })
    .catch((err) => {
      loading.value = false;
    });
};
// nav点击我申请的验证
const getBottom = () => {
  loading.value = true;
  api.share
    .getInitiateVerifyApi({ userId: userId.value })
    .then((res) => {
      loading.value = false;
      if (res.returnCode == 200) {
        list.value = res.data;
      }
    })
    .catch((err) => {
      loading.value = false;
    });
};
// 去预览
const goBaseDetails = (item) => {
  store.commit("SET_CHATTYPE", "base");
  store.commit("SET_TOPIC", item);
  router.push("/chat");
};
// 我申请的验证
const getInitiateVerify = () => {
  api.share.getInitiateVerifyApi({ userId: userId.value }).then((res) => {
    if (res.returnCode == 200) {
      initiateVerList.value = res.data;
    }
  });
};
// 我可验证的话题列表
const topicList = () => {
  api.share
    .getVerifyApi({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        canverify.value = res.data;
      }
    });
};
const toList = (item) => {
  store.commit("SET_CHATTYPE", "base");
  store.commit("SET_TOPIC", item);
  store.commit("SET_YZ", item);
  router.push("/share/details");
};
</script>

<template>
  <div class="container">
    <!-- 左侧 -->
    <div class="left">
      <!-- 搜索框 -->
      <div style="display: flex">
        <el-input
          class="baseInput"
          placeholder="搜索话题"
          :prefix-icon="Search"
        />
        <div
          :class="navActive == index ? 'navActiveItem formItem' : 'formItem'"
          v-for="(item, index) in navList"
          :key="index"
          @click="changeNav(index)"
        >
          <img :src="item.url" v-if="item.url" alt="" class="iconImg" />
          <div class="spanText">{{ item.name }}</div>
        </div>
        <div class="formItem sort">
          <img src="@/assets/share/more.svg" alt="" />
        </div>
      </div>
      <!-- 知识共享列表 -->
      <el-scrollbar class="scrollbar" v-loading="loading">
        <lookView
          :type="navActive == 3 || navActive == 4 ? 'yz' : 'share'"
          :list="list"
          @goShare="toList"
          @goLookView="goBaseDetails"
          @goBase="goBase"
        ></lookView>
        <img
          :src="noData"
          class="noData"
          v-if="!loading && list.length == 0"
          alt=""
        />
      </el-scrollbar>
    </div>
    <!-- 右侧 -->
    <div class="right">
      <div class="card">
        <div style="display: flex; justify-content: space-between">
          <div class="ellipsis">
            {{ $t("share.rightTitle") }}
          </div>
          <div class="span">
            {{ $t("share.more") }}
            <el-icon>
              <TopRight />
            </el-icon>
          </div>
        </div>
        <!-- 验证话题列表 -->
        <div
          class="listItem"
          v-for="(item, index) in canverify.slice(0, 2)"
          :key="index"
        >
          <div class="title">{{ item.title }}</div>
          <div class="itemContent">
            <div class="itemImg">
              <img src="@/assets/share/user.svg" alt="" />
            </div>
            <div class="question">
              {{ item.firstQuestion }}
            </div>
            <el-button @click="toList(item)" class="toList">
              {{ $t("share.toVerify") }}
              <el-icon class="icon">
                <TopRight />
              </el-icon>
            </el-button>
          </div>
        </div>
      </div>
      <div class="card">
        <div style="display: flex; justify-content: space-between">
          <div class="ellipsis">
            {{ $t("share.rightApply") }}
          </div>
          <div class="span">
            {{ $t("share.more") }}
            <el-icon>
              <TopRight />
            </el-icon>
          </div>
        </div>
        <!-- 申请验证列表 -->
        <div
          class="listItem"
          v-for="(item, index) in initiateVerList.slice(0, 2)"
          :key="index"
        >
          <div class="title">{{ item.title }}</div>
          <div class="itemContent">
            <div class="itemImg">
              <img src="@/assets/share/user.svg" alt="" />
            </div>
            <div class="question">
              {{ item.firstQuestion }}
            </div>
            <el-button @click="toList(item)" class="toList">
              {{ $t("share.toVerify") }}
              <el-icon class="icon">
                <TopRight />
              </el-icon>
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-input__wrapper) {
  border-radius: 16px;
}

:deep(.el-button.is-round) {
  border-radius: 16px;
}

.container {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.baseInput {
  width: 236px;
  height: 52px;
  font-size: 17px;
  color: #8a8a8a;
  font-family: "Regular";
}

.formItem {
  display: flex;
  border-radius: 16px;
  border: 2px solid #edeef6;
  height: 52px;
  line-height: 52px;
  padding: 0 20px;
  margin-left: 14px;
  font-size: 17px;
  color: #8a8a8a;
  cursor: pointer;

  .iconImg {
    display: inline-block;
    width: 26px;
    height: 23px;
    margin-right: 4px;
    margin-top: 14px;
  }

  .spanText {
    padding-top: -24px;
  }
}

.formItem:hover {
  border: 2px solid #dbdfff;
  background-color: #eceeff;
  color: #0256ff;
}
.navActiveItem {
  border: 2px solid #dbdfff;
  background-color: #eceeff;
  color: #0256ff;
}
.sort {
  padding: 12px !important;

  img {
    width: 25px;
    height: 25px;
  }
}

.rightList {
  display: flex;
  justify-content: end;
  margin-top: 16px;
}

.rightTag {
  margin-right: 12px;
  font-size: 16px;
  color: #3d3d3d;
  height: 30px;
  line-height: 27px;
  padding: 0 8px;
  border-radius: 8px;
  border: #dbdfff 1px solid;
  display: flex;

  .tagImg {
    width: 18px;
    height: 18px;
    margin-top: 4px;
    margin-right: 6px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.rightTag1 {
  margin-left: 12px;
  border-radius: 8px;
  border: #dbdfff 1px solid;
  width: 30px;
  height: 30px;
  cursor: pointer;
  img {
    display: block;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-top: 4px;
  }
}

.searchInput {
  width: 180px;
  height: 52px;
}

.title {
  display: flex;
  min-width: 190px;
  height: 55px;
  line-height: 55px;
  font-size: 40px;
  color: #3d3d3d;
  font-family: "Regular";
  margin-top: 10px;

  .tit-img {
    margin-top: 4px;
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.iconImg {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 4px;
}

.left {
  width: 70%;

  .scrollbar {
    margin-top: 20px;
    height: calc(100% - 80px);
    position: relative;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.queryForm {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 10px;
}

.right {
  width: 28%;
  height: 100%;
  justify-content: space-around;
  display: flex;
  flex-direction: column;

  .card {
    width: 100%;
    height: calc(100% - 40px);
    border-radius: 26px;
    background-color: #fafbff;
    padding: 30px 20px;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;

    .span {
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      margin-top: 8px;
      display: flex;
    }

    .card {
      width: 100%;
      height: calc(100% - 40px);
      border-radius: 26px;
      background-color: #fafbff;
      padding: 30px 20px;
      font-size: 20px;
      font-weight: 600;

      .span {
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        margin-top: 8px;
        display: flex;
      }
    }

    .card:nth-child(2) {
      margin-top: 20px;
    }
  }
}

.listItem {
  min-width: 300px;
  height: 110px;
  border: 2px solid #dee2ff;
  background-color: #fff;
  padding: 6px;
  border-radius: 8px;
  margin-top: 22px;

  .title {
    font-size: 19px;
    height: 20px;
    width: 100%;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .itemContent {
    display: flex;
    margin-top: 20px;
    line-height: 30px;

    .itemImg {
      width: 18px;
      height: 18px;
      margin: 2px 4px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .question {
      overflow: hidden;
      color: #ccc;
      font-size: 18px;
      font-weight: 400;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .toList {
    width: 58px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 8px;
    background-color: #dbdfff;
    font-weight: 400;
    color: #1e68ff;
    font-size: 14px;
    display: none;

    .icon {
      font-size: 16px;
    }
  }
}

.listItem:hover {
  background-color: #eceeff;
  .toList {
    display: block;
    border: none;
  }
}
.cardList:hover {
  background-color: #f7f8ff;
  .toList {
    border: none;
  }
}
.noData {
  width: 300px;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "cardBox" }
const _hoisted_2 = { class: "cardRow" }
const _hoisted_3 = { class: "statistic-card8" }
const _hoisted_4 = { class: "statistic ellipsis" }
const _hoisted_5 = { class: "center8" }
const _hoisted_6 = { class: "bottom8" }
const _hoisted_7 = { class: "text" }
const _hoisted_8 = {
  key: 0,
  class: "rateDown"
}
const _hoisted_9 = {
  key: 1,
  class: "rateUp"
}
const _hoisted_10 = { class: "statistic-card4" }
const _hoisted_11 = { class: "statistic ellipsis" }
const _hoisted_12 = { class: "center4" }
const _hoisted_13 = { class: "bottom4" }
const _hoisted_14 = { class: "text" }
const _hoisted_15 = {
  key: 0,
  class: "rateDown"
}
const _hoisted_16 = {
  key: 1,
  class: "rateUp"
}
const _hoisted_17 = { class: "statistic-card4" }
const _hoisted_18 = { class: "statistic ellipsis" }
const _hoisted_19 = { class: "center4" }
const _hoisted_20 = { class: "bottom4" }
const _hoisted_21 = { class: "text" }
const _hoisted_22 = {
  key: 0,
  class: "rateDown"
}
const _hoisted_23 = {
  key: 1,
  class: "rateUp"
}
const _hoisted_24 = { class: "statistic-card8" }
const _hoisted_25 = { class: "statistic ellipsis" }
const _hoisted_26 = { class: "center8" }
const _hoisted_27 = { class: "bottom8" }
const _hoisted_28 = { class: "text" }
const _hoisted_29 = {
  key: 0,
  class: "rateDown"
}
const _hoisted_30 = {
  key: 1,
  class: "rateUp"
}
const _hoisted_31 = { class: "statistic-card4" }
const _hoisted_32 = { class: "statistic ellipsis cardDiv" }
const _hoisted_33 = { class: "center4" }
const _hoisted_34 = { class: "bottom4" }
const _hoisted_35 = { class: "text" }
const _hoisted_36 = {
  key: 0,
  class: "rateDown"
}
const _hoisted_37 = {
  key: 1,
  class: "rateUp"
}
const _hoisted_38 = { class: "statistic-card4" }
const _hoisted_39 = { class: "statistic ellipsis" }
const _hoisted_40 = { class: "center4" }
const _hoisted_41 = { class: "bottom4" }
const _hoisted_42 = { class: "text" }
const _hoisted_43 = {
  key: 0,
  class: "rateDown"
}
const _hoisted_44 = {
  key: 1,
  class: "rateUp"
}
const _hoisted_45 = { class: "statistic-card4" }
const _hoisted_46 = { class: "statistic ellipsis" }
const _hoisted_47 = { class: "center4" }
const _hoisted_48 = { class: "bottom4" }
const _hoisted_49 = { class: "text" }
const _hoisted_50 = {
  key: 0,
  class: "rateDown"
}
const _hoisted_51 = {
  key: 1,
  class: "rateUp"
}
const _hoisted_52 = { class: "statistic-card4" }
const _hoisted_53 = { class: "statistic ellipsis" }
const _hoisted_54 = { class: "center4" }
const _hoisted_55 = { class: "bottom4" }
const _hoisted_56 = { class: "text" }
const _hoisted_57 = {
  key: 0,
  class: "rateDown"
}
const _hoisted_58 = {
  key: 1,
  class: "rateUp"
}
const _hoisted_59 = { class: "statistic-card4" }
const _hoisted_60 = { class: "statistic ellipsis" }
const _hoisted_61 = { class: "center4" }
const _hoisted_62 = { class: "bottom4" }
const _hoisted_63 = { class: "text" }
const _hoisted_64 = {
  key: 0,
  class: "rateDown"
}
const _hoisted_65 = {
  key: 1,
  class: "rateUp"
}
const _hoisted_66 = { class: "statistic-card4" }
const _hoisted_67 = { class: "statistic ellipsis" }
const _hoisted_68 = { class: "center4" }
const _hoisted_69 = { class: "bottom4" }
const _hoisted_70 = { class: "text" }
const _hoisted_71 = {
  key: 0,
  class: "rateDown"
}
const _hoisted_72 = {
  key: 1,
  class: "rateUp"
}
const _hoisted_73 = { class: "statistic-card16" }
const _hoisted_74 = { class: "statistic ellipsis" }
const _hoisted_75 = { class: "colorBox" }
const _hoisted_76 = { class: "statistic-card82" }
const _hoisted_77 = { class: "statistic ellipsis" }

import * as echarts from 'echarts';
import { ref, onMounted, reactive, nextTick, PropType, onUnmounted } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'card',
  setup(__props) {

const outputValue = ref(172000)
// 上升下降判断
const down = ref(false)

const lineChartDom = ref<HTMLDivElement | null>(null);
const pieChartDom = ref<HTMLDivElement | null>(null);
// 声明两个变量来保存图表实例的引用  
let pieChartInstance: echarts.ECharts | null = null;
let lineChartInstance: echarts.ECharts | null = null;
const lineChartOption = {
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    },
    yAxis: {
        type: 'value'
    },

    series: [
        {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            color: [
                "#14AE5C",
            ],
            areaStyle: {}
        }
    ]
};
const initLineChart = () => {
    if (lineChartDom.value) {
        lineChartInstance = echarts.init(lineChartDom.value)
        lineChartInstance.setOption(lineChartOption);
    }
};
const pieChartOption = {
    tooltip: {
        trigger: 'item'

    },
    legend: {
        top: '5%',
        left: 'center'
    },
    series: [
        {
            type: 'pie',
            color: [
                "#3376ff",
                "#38aee5",
                "#767bfa",
                "#f02d63",
                "#ffc300"
            ],
            textStyle: {

            },
            radius: ['50%', '60%'],
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'center'
            },
            labelLine: {
                show: false
            },
            data: [
                { value: 1048, name: '文本嵌入' },
                { value: 735, name: '大模型输入' },
                { value: 580, name: '大模型输出' },
            ]
        }
    ]
};
const initPieChart = () => {
    if (pieChartDom.value) {
        pieChartInstance = echarts.init(pieChartDom.value)
        pieChartInstance.setOption(pieChartOption);
    }
};
// 窗口大小变化的监听器函数  
const handleResize = () => {
    if (pieChartInstance) {
        pieChartInstance.resize();
    }
    if (lineChartInstance) {
        lineChartInstance.resize();
    }
};
// 在组件挂载后初始化图表  
onMounted(() => {
    initLineChart();
    initPieChart();
    window.addEventListener('resize', handleResize);
});
//在组件卸载前移除监听器
onUnmounted(() => {
    if (pieChartInstance) {
        pieChartInstance.dispose();
    }
    if (lineChartInstance) {
        lineChartInstance.dispose();
    }
    window.removeEventListener('resize', handleResize);
});


return (_ctx: any,_cache: any) => {
  const _component_el_statistic = _resolveComponent("el-statistic")!
  const _component_CaretBottom = _resolveComponent("CaretBottom")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_CaretTop = _resolveComponent("CaretTop")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('statistics.allTokens')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_statistic, {
            class: "valueStyle1",
            value: outputValue.value
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(outputValue.value), 1)
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('statistics.link')), 1),
          (down.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_el_icon, { style: {"color":"red"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretBottom)
                  ]),
                  _: 1
                }),
                _cache[0] || (_cache[0] = _createTextVNode(" 27% "))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_el_icon, { style: {"color":"#49CF43"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretTop)
                  ]),
                  _: 1
                }),
                _cache[1] || (_cache[1] = _createTextVNode(" 27% "))
              ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('statistics.department')), 1),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_el_statistic, {
            class: "valueStyle2",
            value: outputValue.value
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('statistics.link')), 1),
          (down.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createVNode(_component_el_icon, { style: {"color":"red"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretBottom)
                  ]),
                  _: 1
                }),
                _cache[2] || (_cache[2] = _createTextVNode(" 27% "))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createVNode(_component_el_icon, { style: {"color":"#49CF43"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretTop)
                  ]),
                  _: 1
                }),
                _cache[3] || (_cache[3] = _createTextVNode(" 27% "))
              ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('statistics.group')), 1),
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_el_statistic, {
            class: "valueStyle3",
            value: outputValue.value
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('statistics.link')), 1),
          (down.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createVNode(_component_el_icon, { style: {"color":"red"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretBottom)
                  ]),
                  _: 1
                }),
                _cache[4] || (_cache[4] = _createTextVNode(" 27% "))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createVNode(_component_el_icon, { style: {"color":"#49CF43"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretTop)
                  ]),
                  _: 1
                }),
                _cache[5] || (_cache[5] = _createTextVNode(" 27% "))
              ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t('statistics.employee')), 1),
        _createElementVNode("div", _hoisted_26, [
          _createVNode(_component_el_statistic, {
            class: "valueStyle4",
            value: outputValue.value
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('statistics.link')), 1),
          (down.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                _createVNode(_component_el_icon, { style: {"color":"red"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretBottom)
                  ]),
                  _: 1
                }),
                _cache[6] || (_cache[6] = _createTextVNode(" 27% "))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_30, [
                _createVNode(_component_el_icon, { style: {"color":"#49CF43"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretTop)
                  ]),
                  _: 1
                }),
                _cache[7] || (_cache[7] = _createTextVNode(" 27% "))
              ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_31, [
        _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$t('statistics.ADA')), 1),
        _createElementVNode("div", _hoisted_33, [
          _createVNode(_component_el_statistic, {
            class: "valueStyle5",
            value: outputValue.value
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.$t('statistics.link')), 1),
          (down.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                _createVNode(_component_el_icon, { style: {"color":"red"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretBottom)
                  ]),
                  _: 1
                }),
                _cache[8] || (_cache[8] = _createTextVNode(" 27% "))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_37, [
                _createVNode(_component_el_icon, { style: {"color":"#49CF43"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretTop)
                  ]),
                  _: 1
                }),
                _cache[9] || (_cache[9] = _createTextVNode(" 27% "))
              ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_38, [
        _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.$t('statistics.multiModel')), 1),
        _createElementVNode("div", _hoisted_40, [
          _createVNode(_component_el_statistic, {
            class: "valueStyle2",
            value: outputValue.value
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_41, [
          _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.$t('statistics.link')), 1),
          (down.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                _createVNode(_component_el_icon, { style: {"color":"red"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretBottom)
                  ]),
                  _: 1
                }),
                _cache[10] || (_cache[10] = _createTextVNode(" 27% "))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_44, [
                _createVNode(_component_el_icon, { style: {"color":"#49CF43"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretTop)
                  ]),
                  _: 1
                }),
                _cache[11] || (_cache[11] = _createTextVNode(" 27% "))
              ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_45, [
        _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.$t('statistics.largeModel')), 1),
        _createElementVNode("div", _hoisted_47, [
          _createVNode(_component_el_statistic, {
            class: "valueStyle3",
            value: outputValue.value
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_48, [
          _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.$t('statistics.link')), 1),
          (down.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                _createVNode(_component_el_icon, { style: {"color":"red"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretBottom)
                  ]),
                  _: 1
                }),
                _cache[12] || (_cache[12] = _createTextVNode(" 27% "))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_51, [
                _createVNode(_component_el_icon, { style: {"color":"#49CF43"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretTop)
                  ]),
                  _: 1
                }),
                _cache[13] || (_cache[13] = _createTextVNode(" 27% "))
              ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_52, [
        _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.$t('statistics.DCR')), 1),
        _createElementVNode("div", _hoisted_54, [
          _createVNode(_component_el_statistic, {
            class: "valueStyle5",
            value: outputValue.value
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_55, [
          _createElementVNode("div", _hoisted_56, _toDisplayString(_ctx.$t('statistics.link')), 1),
          (down.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                _createVNode(_component_el_icon, { style: {"color":"red"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretBottom)
                  ]),
                  _: 1
                }),
                _cache[14] || (_cache[14] = _createTextVNode(" 27% "))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_58, [
                _createVNode(_component_el_icon, { style: {"color":"#49CF43"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretTop)
                  ]),
                  _: 1
                }),
                _cache[15] || (_cache[15] = _createTextVNode(" 27% "))
              ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_59, [
        _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.$t('statistics.Bing')), 1),
        _createElementVNode("div", _hoisted_61, [
          _createVNode(_component_el_statistic, {
            class: "valueStyle3",
            value: outputValue.value
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_62, [
          _createElementVNode("div", _hoisted_63, _toDisplayString(_ctx.$t('statistics.link')), 1),
          (down.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                _createVNode(_component_el_icon, { style: {"color":"red"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretBottom)
                  ]),
                  _: 1
                }),
                _cache[16] || (_cache[16] = _createTextVNode(" 27% "))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_65, [
                _createVNode(_component_el_icon, { style: {"color":"#49CF43"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretTop)
                  ]),
                  _: 1
                }),
                _cache[17] || (_cache[17] = _createTextVNode(" 27% "))
              ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_66, [
        _createElementVNode("div", _hoisted_67, _toDisplayString(_ctx.$t('statistics.Blob')), 1),
        _createElementVNode("div", _hoisted_68, [
          _createVNode(_component_el_statistic, {
            class: "valueStyle2",
            value: outputValue.value
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_69, [
          _createElementVNode("div", _hoisted_70, _toDisplayString(_ctx.$t('statistics.link')), 1),
          (down.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_71, [
                _createVNode(_component_el_icon, { style: {"color":"red"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretBottom)
                  ]),
                  _: 1
                }),
                _cache[18] || (_cache[18] = _createTextVNode(" 27% "))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_72, [
                _createVNode(_component_el_icon, { style: {"color":"#49CF43"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaretTop)
                  ]),
                  _: 1
                }),
                _cache[19] || (_cache[19] = _createTextVNode(" 27% "))
              ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_73, [
        _createElementVNode("div", _hoisted_74, _toDisplayString(_ctx.$t('statistics.tokens')), 1),
        _createElementVNode("div", _hoisted_75, [
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "color-icon" }, [
            _createElementVNode("span")
          ], -1)),
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('statistics.granulity')), 1)
        ]),
        _createElementVNode("div", {
          id: "main",
          ref_key: "lineChartDom",
          ref: lineChartDom,
          class: "id"
        }, null, 512)
      ]),
      _createElementVNode("div", _hoisted_76, [
        _createElementVNode("div", _hoisted_77, _toDisplayString(_ctx.$t('statistics.serve')), 1),
        _createElementVNode("div", {
          id: "cake",
          class: "cake",
          ref_key: "pieChartDom",
          ref: pieChartDom
        }, null, 512)
      ])
    ])
  ]))
}
}

})
<template>
  <!-- 设置过期时间 -->
  <div  class="dialog">
    <div class="dialogBetWeen">
      <div class="dialogTitle">设置过期时间</div>
      <img
        :src="closeFileViewImg"
        alt=""
        class="closeFileViewImg"
        @click="closeTime"
      />
    </div>
    <div class="dialogTip">过期时间 <span style="color: #f02d63">*</span></div>
    <el-date-picker
      v-model="time"
      value-format="YYYY-MM-DD HH:mm:ss"
      type="datetime"
      placeholder="请选择设置过期时间"
    />
    <div class="dialogEnd">
      <el-button @click="closeTime" class="dialogCancelBtn">取消</el-button>
      <el-button @click="saveTime" class="dialogSaveBtn" :loading="props.loading">
        确定
      </el-button>
    </div>
  </div>
  <div class="mask"></div>
</template>

<script setup>
import closeFileViewImg from "@/assets/base/closeFileView.svg";
import { ref, defineEmits,defineProps } from "vue";
const emit = defineEmits(["saveTime", "closeTime"]);
const props = defineProps(['loading'])
const time = ref("");
const saveTime = () => {
  emit("saveTime", time.value);
};
const closeTime = () => {
  emit("closeTime");
};
</script>

<style lang="scss" scoped>
.dialog {
  position: fixed;
  width: 540px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 300;
  box-shadow: 0px 0px 15px 0px #dcdcdc;
}
.dialogTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
  margin-bottom: 24px;
}
:deep(.el-date-editor) {
  width: 100%;
  height: 48px;
  margin-bottom: 24px;
}
:deep(.el-input__wrapper) {
  border-radius: 16px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  height: 44px;
  font-size: 18px;
  font-family: "Regular";
  width: 100%;
}
.closeFileViewImg {
  width: 14px;
  cursor: pointer;
}
.dialogBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialogTip {
  font-size: 18px;
  font-family: "Medium";
  color: #3d3d3d;
  margin-bottom: 17px;
}
.dialogCancelBtn {
  height: 48px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  padding: 16px 20px;
  color: #f02d63;
  font-family: "Regular";
  font-size: 18px;
}
.dialogSaveBtn {
  height: 48px;
  border: 2px solid #3376ff;
  background-color: #3376ff;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  padding: 16px 20px;
  color: #fefefe;
  font-family: "Regular";
  font-size: 18px;
}
.dialogEnd {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
</style>

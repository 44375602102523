export default {
    CN: {
        title: 'AI智能体',
        all: '全部',
        search: '搜索智能体',
        searchTit: '搜索',
        helper: '智能助手',
        tool: '智能工具',
        code: '输入兑换码',
        addIntellect: '新建AI智能体',
        addContent: '新建一个AI智能助手；我们推荐您基于特定的部门、群组、项目或专题构建专属AI微模型。',
        confirm: '发布',
        cancel: '取消',
        placeName: '请输入智能助手名称',
        palceDesc: '输入微模型主题、内容或作用的简要描述',
        name: '名称',
        setting: '设定',
        skill: '技能',
        limits: "权限设置",
        highSetting: '高级设置',
        introduce: '介绍',
        placeIntroduce: '简短介绍您的智能体，例如：',
        openingRemarks: '开场白',
        aiSmall: 'AI微模型',
        imageProduction: '图像生成',
        IE:'联网检索',
        oneKey: "一键完善",
        tipName: "智能助手名称不能为空",
        move: '转移',
        group: '群组',
        role: '角色',
        operation: '操作',
        addMember: '添加成员',
        selectAdd: '检索用户/部门/群组/管理员角色',
        inspiration: '提问灵感',
        placeOpening: '智能体的开场白。例如：',
        placeInspiration: '展示在开场白的下方，例如：你是谁？',
        addition: '添加',
    },
    HK: {
        title: 'AI智慧體',
        all: '全部',
        search: '搜索智能體',
        searchTit: '搜索',
        helper: '智能助手',
        tool: '智能工具',
        code: '輸入兌換碼',
        addIntellect: '新建AI智能體',
        addContent: '新建一個AI智能助手；我哋推薦你基於特定嘅部門、群組、項目或專題構建專屬AI微模型。',
        confirm: '发布',
        cancel: '攞消',
        placeName: '请输入智能助手名称',
        palceDesc: '输入微模型主题、内容或作用嘅简要描述',
        name: '名稱',
        setting: '設定',
        skill: '技能',
        limits: "權限設置",
        highSetting: '高級設置',
        introduce: '介紹',
        placeIntroduce: '簡短介紹你嘅智能體，例如：',
        openingRemarks: '開場白',
        aiSmall: 'AI微模型',
        imageProduction: '圖像生成',
        oneKey: "一鍵完善",
        tipName: "智能助手名稱唔得為空",
        move: '轉移',
        group: '群組',
        role: '角色',
        operation: '操作',
        addMember: '添加成員',
        selectAdd: '檢索用戶/部門/群組/管理員角色',
        inspiration: '提問靈感',
        placeOpening: '智能體嘅開場白。例如：',
        placeInspiration: '展示喺開場白嘅下方，例如：你系邊個？',
        addition: '添加',
    },
    US: {
        title: 'AI agents',
        all: 'All',
        search: 'Search Intellect',
        searchTit: 'Search',
        helper: 'Intelligent Assistant',
        tool: 'Intelligent Tool',
        code: 'Enter Redemption Code',
        addIntellect: 'Create AI Agent',
        addContent: 'Create a new AI Intelligent Assistant; we recommend building a dedicated AI micro-model based on specific departments, groups, projects, or topics.',
        confirm: 'Release',
        cancel: 'Cancel',
        placeName: 'Please enter the name of the intelligent assistant',
        palceDesc: 'Enter a brief description of the micro model theme,content or function',
        name: 'Name',
        setting: 'Settings',
        skill: 'Skills',
        limits: "Permission settings",
        highSetting: 'Advanced settings',
        introduce: 'Introduction',
        placeIntroduce: 'Brief introduction to your agent, such as:',
        openingRemarks: 'Opening remarks',
        aiSmall: 'AI Micro Model',
        imageProduction: 'Image Generation',
        oneKey: "One-click Perfection",
        tipName: "One-click Perfection",
        move: 'Transfer',
        group: 'Group',
        role: 'Role',
        operation: 'Operation',
        addMember: 'Add Member',
        selectAdd: 'Search for Users/Departments/Groups/Administrator Roles',
        inspiration: 'Inspiration for Questions',
        placeOpening: 'The opening greeting of the smart assistant. For example:',
        placeInspiration: 'Displayed below the opening greeting, for example: Who are you?',
        addition: 'Addition',
    },
};

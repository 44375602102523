<script setup>
import { ref, defineProps, onMounted, watch } from "vue";
import { getRandomColor } from "@/utils/utils";
const emit = defineEmits(['handleCancel', 'handleAdd']);
const props = defineProps({
    showDetail: Boolean,
    detailInfo: Object
});
watch(() => props.detailInfo, (newVal, oldValue) => {
    if (newVal !== oldValue) {
        getUserDetail(newVal)
    }
})
onMounted(() => {
    getUserDetail(props.detailInfo)
})
const form = ref({})
// 用户详情
const getUserDetail = (data) => {
    form.value = data
}
const title = ref('用户详情');
// 拒绝
const handleCancel = () => {
    emit('handleCancel')
}
//同意
const handleConfirm = () => {
    emit('handleAdd')
};
</script>

<template>
    <div>
        <!-- 用户详情 -->
        <el-dialog v-model="props.showDetail" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>{{ title }}</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleCancel" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <div class="content">
                <el-divider />
                <div style="display: flex; justify-content: space-between;">
                    <div style="display: flex;">
                        <div class="avatar" :style="'background-color:' + getRandomColor()">
                            {{ form.userName.slice(0, 1) }}
                        </div>
                        <div class="name">{{ form.userName }}</div>
                        <!-- 启用 -->
                        <div class="tag" v-if="form.isActive == true">
                            <div class="tag-icon">
                                <img src="@/assets/manage/dui.svg" alt="">
                            </div>
                            <div class="tag-text">启用</div>
                        </div>
                        <!-- 禁用 -->
                        <div class="tag_cuo" v-else>
                            <div class="tag-icon">
                                <img src="@/assets/manage/cuo.svg" alt="">
                            </div>
                            <div class="tag-text">禁用</div>
                        </div>
                    </div>
                    <!-- <div class="reset">重置密码</div> -->
                </div>
                <el-form :model="form" class="formDetail">
                    <el-form-item prop="name">
                        <div class="form-name">
                            <p class="tit">用户名</p>
                            <p class="comtent">{{ form.userName }}</p>
                        </div>
                    </el-form-item>
                    <el-form-item prop="email">
                        <div class="form-name">
                            <p class="tit">邮箱</p>
                            <p class="comtent">{{ form.email }}</p>
                        </div>
                    </el-form-item>
                    <el-form-item prop="phone">
                        <div class="form-name">
                            <p class="tit">手机</p>
                            <p class="comtent" v-if="form.phone == ''">-</p>
                            <p class="comtent" v-else>{{ form.phone }}</p>
                        </div>
                    </el-form-item>
                    <el-form-item prop="address">
                        <div class="form-name">
                            <p class="tit">部门</p>
                            <div class="el-tag">
                                <div class="tag-img"><img src="@/assets/manage/qincheng.svg" alt=""></div>
                                <div>{{ form.departmentName }}</div>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item prop="address">
                        <div class="form-name">
                            <p class="tit">群组</p>
                            <div v-if="form.groups.length == 0">
                                <!-- <div class="tag-img"><img src="@/assets/manage/user_yellow.svg" alt=""></div> -->
                                <div>-</div>
                            </div>
                            <div class="el-tag" v-else v-for="(item, index) in form.groups " :key="index">
                                <div class="tag-img"><img src="@/assets/manage/user_yellow.svg" alt=""></div>
                                <div>{{ form.roleName }}暂无群组字段</div>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item prop="roleName">
                        <div class="form-name">
                            <p class="tit">角色</p>
                            <p class="comtent">{{ form.roleName }}</p>
                        </div>

                    </el-form-item>
                    <el-form-item prop="roleName">
                        <div class="form-name">
                            <p class="tit">性别</p>
                            <p class="comtent">{{ form.sex == null ? '暂无信息' : form.sex == 1 ? '男' : '女' }}</p>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <div @click="handleCancel" class="cancel">关闭详情</div>
                    <div @click="handleConfirm" class="confirm">编辑用户</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>



<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-dialog) {
    --el-dialog-width: 600px;
    border-radius: 30px;
    // height: 800px;
    padding: 30px;
}

:deep(.el-divider) {
    margin: 1px 0 10px !important;
    width: calc(100%) !important;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 24px;
    position: relative;
}

.close-icon {
    font-size: 20px;
}

.formDetail {
    margin-top: 20px;
}

.content {
    font-size: 16px;
    color: #8A8A8A;

    .name {
        color: #3d3d3d;
        font-size: 22px;
        margin: auto 8px auto 12px;
    }

    .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 6px;
        text-align: center;
        line-height: 50px;
        color: #ffffff;        
        font-size:34px;
        font-family: "Semibold";
    }

    .tag {
        display: flex;
        justify-content: space-between;
        background-color: #B8E6CD;
        color: #14AE5C;
        padding: 0px 4px;
        height: 26px;
        line-height: 26px;
        border-radius: 6px;
        margin: auto 0;

        .tag-icon {
            width: 16px;
            height: 16px;
            display: block;
            margin-top: 3px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .tag-text {
            margin-left: 4px;
        }
    }

    .tag_cuo {
        display: flex;
        justify-content: space-between;
        background-color: #FBD4DF;
        color: #F02D63;
        padding: 0px 4px;
        height: 26px;
        line-height: 26px;
        border-radius: 6px;
        margin: auto 0;

        .tag-icon {
            width: 16px;
            height: 16px;
            display: block;
            margin-top: 3px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .tag-text {
            margin-left: 4px;
        }
    }

    .reset {
        color: #0256FF;
        margin: auto 10px
    }
}

.form {
    margin-top: 10px;
}

.form-name {
    font-size: 18px;
    color: #3D3D3D;

    .el-tag {
        display: flex;
        padding: 2px 18px 2px 2px;
        height: 35px;
        line-height: 35px;
        border-radius: 30px;
        font-size: 16px;
        margin-top: 4px;
        margin-bottom: 8px;

        .tag-img {
            width: 30px;
            height: 30px;
            margin-right: 6px;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    }

    .tit {
        margin-bottom: 2px;
        font-weight: 600;
    }

    .comtent {
        font-size: 16px;
        margin-bottom: 8px;
    }
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #8A8A8A;
        padding: 6px 12px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 6px 12px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
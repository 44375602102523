<template>
  <iframe
    style="width: 100%; height: 100%"
    :src="offUrl + encodeURIComponent(props.wordUrl)"
    frameborder="0"
  ></iframe>
  <!-- <vue-office-docx :src="docx" @rendered="rendered" /> -->
</template>

<script setup>
import { defineProps, ref } from "vue";
//引入VueOfficeDocx组件
import VueOfficeDocx from "@vue-office/docx";
//引入相关样式
import "@vue-office/docx/lib/index.css";
const props = defineProps(["wordUrl"]);
const offUrl = ref("https://view.officeapps.live.com/op/embed.aspx?src=");
const docx = ref("http://static.shanhuxueyuan.com/test6.docx");
const rendered =()=>{

}
</script>

<style lang="scss" scoped>
:deep(.docx){
  width: 90% !important;
  padding: 96px 120px !important;
}
</style>

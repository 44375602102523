<script setup>
import { ref, onMounted, watch } from "vue"
import baseAdd from '../components/baseAdd.vue'
import baseDelete from '../components/baseDelete.vue'
import baseWarning from '../components/baseWarning.vue'
import AddResoureView from '../components/baseAddResoure.vue'
import baseBill from '../components/baseBill.vue'
import baseBill2 from '../components/baseBill2.vue'
import baseBill3 from '../components/baseBill3.vue'
import baseSaml from '../components/baseSaml.vue'
import { useStore } from "vuex";
import api from "@/api/index";
import { message } from "ant-design-vue";
const store = useStore();
const userId = ref(store.state.userId);
// 进度条
const redisNumber = ref(null);
const tipNumber = ref(null);
onMounted(() => {
  getMember()
  getRedis()
  getReminder()
})
// 资源组查询
const newMemberList = ref([])
const getMember = () => {
  api.manage
    .getBaseListApi({
      userId: userId.value
    })
    .then((res) => {
      if (res.returnCode == 200) {
        newMemberList.value = res.data
      }
    })
}
const defaultProps = ref({
  children: "children",
  label: "name",
  id: "id",
});
const departmentList = ref([])
const rightDialog = ref(false);
const newObject = ref({})
const departIds = ref({
  userId: userId.value,
  operationType: "change",
  departmentIds: [],
})
watch(
  () => departIds.value,
  (newVal) => {
    departIds.value = newVal
  }
)
// 关闭每一项 --修改部门组
const closeGroupItem = () => {
  rightDialog.value = false;
  api.manage
    .changeBaseApi(departIds.value)
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('修改成功')
      }
    })
}
const newGroupId = ref(null)
const newGroupName = ref('')
// 点击资源组每一项
const handleItem = (item) => {
  newGroupId.value = item.id
  newGroupName.value = item.name
  departIds.value.resourceGroupId = item.id
  getItemList()
  // 部门树占用
  api.manage
    .getBaseOccupyApi({
      userId: userId.value,
      resourceGroupId: item.id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        departmentList.value = res.data;
      }
    })
}
// 资源组刷新
const getItemList = () => {
  api.manage
    .getBaseDetailApi({
      userId: userId.value,
      resourceGroupId: newGroupId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        rightDialog.value = true;
        newObject.value = res.data
        departIds.value.departmentIds = res.data.groupDepartmentInfo.map((item) => item.departmentId)
      }
    })
}
// 新建资源组
const showEdit = ref(false);
const handleAdd = () => {
  showEdit.value = true;
};
const handleClose = () => {
  showEdit.value = false;
};
const handleSave = (data) => {
  showEdit.value = false;
  api.manage
    .addBaseApi(data)
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('创建成功');
        getMember()
      }
    })
}
const formType = ref('add');
// 添加资源配置弹窗
const showAddResource = ref(false);
const addResource = () => {
  showAddResource.value = true;
  formType.value = 'add';
  resoureInfo.value = {};
}
const resoureInfo = ref({});
// 单个资源配置点击
const editResoure = (item) => {
  baseItem(item);
  formType.value = 'edit';
  showAddResource.value = true;
}
// 设置默认
const setFomr = ref({
  userId: userId.value,
  functionTypeId: '',
  resourceType: '',
  configurationId: '',
  resourceGroupId: ''
})
const setDefault = (item) => {
  setFomr.value.functionTypeId = item.functionTypeId
  setFomr.value.resourceType = item.resourceType
  setFomr.value.configurationId = item.configurationId
  setFomr.value.resourceGroupId = item.resourceGroupId
  api.manage
    .setDefaultConfigApi(setFomr.value)
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('默认设置成功');
      }
    })
}
// 调用单个资源组
const baseItem = (data) => {
  api.manage
    .lookBaseDetailApi({
      resourceType: data.resourceType,
      functionTypeId: data.functionTypeId,
      configurationId: data.configurationId,
      userId: userId.value
    })
    .then((res) => {
      if (res.returnCode == 200) {
        resoureInfo.value = res.data;
      }
    })
};
// 标题上的修改资源组名称
const showName = ref(true)
const editName = () => {
  showName.value = false
}
const saveName = () => {
  api.manage
    .updateGroupNameApi({
      userId: userId.value,
      groupId: newGroupId.value,
      groupName: newGroupName.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('修改成功');
        getItemList()
      }
    })
  showName.value = true
}
// redis配置查询
const getRedis = () => {
  api.manage
    .getBaseRedisApi({
      userId: userId.value
    })
    .then((res) => {
      if (res.returnCode == 200) {
        redisNumber.value = res.data
      }
    })
}
const changeStatus = ref(true);
const changeBtn = () => {
  changeStatus.value = !changeStatus.value;
}
const changeRedis = () => {
  api.manage
    .changeBaseRedisApi({
      userId: userId.value,
      redisMemorySize: redisNumber.value
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('修改成功');
        changeStatus.value = true;
      }
    })
}
// 个人时长查询
const newDays = ref(null)
const timeStatus = ref(true);
const getReminder = () => {
  api.manage
    .getBaseTimeApi({
      userId: userId.value
    })
    .then((res) => {
      if (res.returnCode == 200) {
        newDays.value = res.data.days
        tipNumber.value = (30 - newDays.value) / 30 * 100
      }
    })
};
const timeBtn = () => {
  timeStatus.value = !timeStatus.value;
}
// 修改个人时长
const changeTime = () => {
  api.manage
    .changeBaseTimeApi({
      userId: userId.value,
      days: newDays.value
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('修改成功');
        tipNumber.value = (30 - newDays.value) / 30 * 100
        timeStatus.value = true;
      }
    })
}
// 删除资源组弹窗
const showDelete = ref(false);
const handleDelete = () => {
  showDelete.value = true;
}
// 放弃删除资源弹窗
const handleDeleteCancel = () => {
  showDelete.value = false;
}
// 确认删除资源弹窗
const handleSubmit = () => {
  showDelete.value = false;
  if (newObject.value.configurationInfo.length == 0) {
    // 调用删除资源组接口
    api.manage
      .deleteGroupItemApi({
        userId: userId.value,
        resourceGroupId: newGroupId.value
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success('删除成功');
          newObject.value = res.data;
          showDelete.value = false;
        }
      })
  } else {
    warningShow.value = true;
  }
}
// 无法删除资源弹窗
const warningShow = ref(false);
// 放弃删除资源组
const handleWarning = () => {
  warningShow.value = false;
}
// 前往清空资源组
const goClean = () => {
  warningShow.value = false;
  rightDialog.value = true;
}
// 关闭资源配置
const closeDialog = () => {
  rightDialog.value = false;
}


// 关闭添加资配置组弹窗
const handleCancel = () => {
  showAddResource.value = false;
}
// 确认添加资源配置弹窗
const handleAddResoure = (active, data) => {
  showAddResource.value = false;
  if (active == 0) {
    // Azure
    api.manage
      .addAzureBillApi(data)
      .then((res) => {
        if (res.returnCode == 200) {
          message.success('添加成功');
        }
      })
  } else if (active == 1) {
    // 阿里
    api.manage
      .addAliBillApi(data)
      .then((res) => {
        if (res.returnCode == 200) {
          message.success('添加成功');
        }
      })
  } else if (active == 2) {
    // baidu
    api.manage
      .addBaiduBillApi(data)
      .then((res) => {
        if (res.returnCode == 200) {
          message.success('添加成功');

        }
      })
  }
}
// 确认修改资源配置按钮
const saveResoure = (data) => {
  showAddResource.value = false;
  api.manage
    .updateBaseApi(data)
    .then((res) => {
      if (res.returnCode === 200) {
        message.success('修改成功');

      }
    })
}
const moreAzure = require('@/assets/manage/Azure.svg')
const moreAli = require('@/assets/manage/ali.svg')
const moreBaidu = require('@/assets/manage/baidu.svg')
// 资源账单
const billList = ref([
  {
    url: moreAzure,
  },
  {
    url: moreAli,
  },
  {
    url: moreBaidu,
  },
]);
// 资源平台账单配置弹窗
const showBill = ref(false);
const showBill2 = ref(false);
const showBill3 = ref(false);
const billBtn = (index) => {
  if (index === 0) {
    showBill.value = true;
  } else if (index === 1) {
    showBill2.value = true;
  } else if (index === 2) {
    showBill3.value = true;    
  }

};
// 资源平台/关闭
const handleBill = () => {
  showBill.value = false;
};
const handleBill2 = () => {
  showBill2.value = false;
};
const handleBill3 = () => {
  showBill3.value = false;
};
const handleBillAdd = (form) => {
  showBill.value = false;
  api.manage
    .changeBaseBillApi(form)
    .then((res) => {
      if (res.returnCode === 200) {
        message.success('修改成功');
      }
    })
};
const handleBillAdd2 = (form) => {
  showBill2.value = false;
  api.manage
    .changeBaseAliApi(form)
    .then((res) => {
      if (res.returnCode === 200) {
        message.success('修改成功');
      }
    })
};
const handleBillAdd3 = (form) => {
  showBill3.value = false;
  api.manage
    .changeBaseBaiduApi(form)
    .then((res) => {
      if (res.returnCode === 200) {
        message.success('修改成功');
      }
    })
};
// SAML认证
const showSaml = ref(false);
const samlBtn = () => {
  showSaml.value = true;
};
const handleSaml = () => {
  showSaml.value = false;
};
const handleSamlAdd = () => {
  // 完成SAML认证
  showSaml.value = false;
}

const customColor = ref('#11D7B2');
// 开关
const value1 = ref(true);
// 删除按钮
const toolDelete = () => {
  console.log('she');

};
</script>

<template>
  <div class="main">
    <div class="leftBox">
      <div class="info">
        <div>资源组</div>
        <div class="intro">资源组仅用于分配各项模型资源及用量统计，资源组之间的应用范围不可相互重叠</div>
      </div>
      <div class="groupBox">
        <div class="groupItem" @click="handleAdd">
          <div class="tit">
            <div class="tit_img"><img src="@/assets/manage/addGroup.svg" alt=""></div>
            <div class="tit_name" style="color: #0256FF;">新建资源组</div>
          </div>
          <div class="range">
            先设定资源组，再给资源组配置资源
          </div>
        </div>
        <div class="groupItem" v-for="item in newMemberList" :key="item" @click="handleItem(item)">
          <div class="tit">
            <div class="tit_img"><img src="@/assets/manage/groupItem.svg" alt=""></div>
            <div class="tit_name">{{ item.name }}</div>
          </div>
          <div class="range1">
            <div>
              应用范围:
            </div>
            <baseName :avatars=item?.departments :number="2"></baseName>
          </div>
          <div class="delete" @click="handleDelete">
            <img src="@/assets/manage/delete.svg" alt="">
          </div>
        </div>
      </div>
      <div class="info">
        <div>Redis 内存配置 <img @click="changeBtn" class="editImg" src="@/assets/manage/editImg.svg" alt=""></div>
        <div class="intro">为了保证您的使用体验，我们建议您开放2 GB以上的Redis内存</div>
        <div class="progress">
          <div class="left">
            <el-progress :percentage="redisNumber" :color="customColor">
              <span></span>
            </el-progress>
          </div>
          <div class="right">
            <el-input v-model="redisNumber" placeholder="Please input" type="number" :disabled="changeStatus"
              @blur="changeRedis">
              <template #append>GB</template>
            </el-input>
          </div>
        </div>
      </div>
      <div class="info">
        <div>个人话题归档提醒时长 <img @click="timeBtn" class="editImg" src="@/assets/manage/editImg.svg" alt=""></div>
        <div class="intro">系统仅留存自最新一次对话时间起30日内的个人话题数据（微模型话题除外），我们建议您开放至少3天提醒时长，提醒用户在话题归档前保留重要数据</div>
        <div class="progress">
          <div class="left">
            <el-progress :percentage="tipNumber" :color="customColor">
              <span></span>
            </el-progress>
          </div>
          <div class="right">
            <el-input v-model="newDays" type="number" max="29" min="1" :disabled="timeStatus" @blur="changeTime">
              <template #append>天</template>
            </el-input>
          </div>
        </div>
      </div>
      <div class="info">
        <div>资源平台账单配置</div>
        <div class="intro">仪表盘调取对应资源平台账单API所必须的参数，已部署的资源平台显示绿色圆点</div>
        <div class="more">
          <div class="item" v-for="(item, index) in billList" :key="index" @click="billBtn(index)">
            <img :src=item.url alt="">
          </div>
        </div>
      </div>
      <div class="info">
        <div>SSO账号登录 <el-switch v-model="value1" /></div>
        <div class="intro">跨平台账号认证方式，使用您习惯的企业通行证登录NextAI客户端，验证成功的认证方式显示绿色圆点</div>
      </div>
      <div class="info end" v-if="value1">
        <div class="more">
          <div class="item" @dblclick="samlBtn">
            <img src="@/assets/manage/SAML.svg" alt="">
          </div>
        </div>
      </div>
    </div>
    <div v-if="rightDialog" class="rightDialog">
      <div class="rightBox">
        <div class="name">
          <div style="display: flex;">
            <div class="name_img"><img src="@/assets/manage/groupItem.svg" alt=""></div>
            <div class="name_text" v-if="showName">{{ newObject.groupBasicInfo?.groupName }}</div>
            <el-input v-else v-model="newGroupName" placeholder="请输入名称" @blur="saveName"></el-input>
            <div class="edit_img" @click="editName"><img src="@/assets/manage/editImg.svg" alt=""></div>
            <div class="edit_img" @click="handleDelete"><img src="@/assets/manage/delete.svg" alt=""></div>
          </div>
          <div>
            <el-icon class="close" @click="closeDialog">
              <Close />
            </el-icon>
          </div>
        </div>
        <div class="rangeBox">
          <div class="item_box">
            <div class="tit">应用范围</div>
            <el-tree-select  v-model="departIds.departmentIds" multiple :data="departmentList" show-checkbox
              :render-after-expand="false" class="treeClass" placeholder="勾选本资源组需要应用的部门/子部门" :props="defaultProps"
              node-key="id">
              <template #default="{ data: { name } }">
                {{ name }}
              </template>
            </el-tree-select>
            <div class="tit">
              <div>资源配置</div>
              <div class="addBox" @click="addResource">添加资源配置</div>
            </div>
            <div v-for="item in newObject.configurationInfo" :key="item">
              <div class="tit_tip" v-if="item.functionType == '文本生成'">文本生成模型</div>
              <div class="tit_tip" v-else-if="item.functionType == '向量化'">向量化模型</div>
              <div class="tit_tip" v-else-if="item.functionType == '文生图'">文生图模型</div>
              <div class="tit_tip" v-else-if="item.functionType == '光学扫描'">光学扫描模型</div>
              <div class="tit_tip" v-else-if="item.functionType == '文本翻译'">文本翻译模型</div>
              <div class="tit_tip" v-else-if="item.functionType == '数据存储'">数据存储模型</div>
              <div class="tit_tip" v-else>其他模型</div>
              <div class="mo_item">
                <div class="top">
                  <!-- A图片 -->
                  <div class="icon1" v-if="item.resourceType == 0">
                    <img src="@/assets/manage/AzureAI.svg" alt="">
                  </div>
                  <!-- ali图片 -->
                  <div class="icon2" v-if="item.resourceType == 2">
                    <img src="@/assets/manage/aliAI.svg" alt="">
                  </div>
                  <!-- baidu图片 -->
                  <div class="icon1" v-if="item.resourceType == 1">
                    <img src="@/assets/manage/baiduAI.svg" alt="">
                  </div>
                  <div class="span">{{ item.modelType }}</div>
                </div>
                <div class="button" v-if="item.isDefault == 1">
                  {{ item.name }}
                  <div class="ren">默认</div>
                </div>
                <div class="tool_box">
                  <div v-if="item.isDefault == 0">
                    <div class="set" @click="setDefault(item)">
                      设为默认
                    </div>
                  </div>
                  <div class="tool" @click="editResoure(item)">
                    <img src="@/assets/manage/editImg.svg" alt="">
                  </div>
                  <div class="tool" @click="toolDelete">
                    <img src="@/assets/manage/delete.svg" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer" @click="closeGroupItem">确定修改</div>
      </div>
    </div>
  </div>
  <!-- 新建资源组 -->
  <baseAdd :show="showEdit" @handleClose="handleClose" @handleSave="handleSave"></baseAdd>
  <!-- 删除资源组弹窗 -->
  <baseDelete :showDelete="showDelete" @handleSubmit="handleSubmit" @handleDeleteCancel="handleDeleteCancel">
  </baseDelete>
  <!-- 无法删除资源弹窗 -->
  <baseWarning :warningShow="warningShow" @handleWarning="handleWarning" @goClean="goClean"></baseWarning>
  <!-- 添加资源组配置弹窗 -->
  <AddResoureView :showAddResource="showAddResource" :formType="formType" :resoureInfo="resoureInfo"
    @handleCancel="handleCancel" @handleAddResoure="handleAddResoure" @saveResoure="saveResoure">
  </AddResoureView>
  <!-- 资源平台账单配置弹窗 -->
  <baseBill :showBill="showBill" @handleBill="handleBill" @handleBillAdd="handleBillAdd">
  </baseBill>
  <baseBill2 :showBill2="showBill2" @handleBill2="handleBill2" @handleBillAdd2="handleBillAdd2">
  </baseBill2>
  <baseBill3 :showBill3="showBill3" @handleBill3="handleBill3" @handleBillAdd3="handleBillAdd3">
  </baseBill3>
  <!-- SAML认证 -->
  <baseSaml :showSaml="showSaml" @handleSaml="handleSaml" @handleSamlAdd="handleSamlAdd"></baseSaml>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-progress-bar__outer) {
  height: 10px !important;
}

:deep(.el-select__wrapper) {
  min-height: 40px;
  border-radius: 16px;
}

:deep(.el-select__tags-text) {
  color: #3D3D3D;
}

:deep(.el-tag) {
  background-color: #DBDFFF;
}

:deep(.el-select__input) {
  color: #3D3D3D;
}

.main {
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: #fff;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
}

.footer {
  width: 100%;
  height: 40px;
  border-radius: 16px;
  text-align: center;
  line-height: 40px;
  background-color: #767BFA;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.close {
  color: #8A8A8A;
  font-size: 22px;
  cursor: pointer;
}

.close:hover {
  color: #264095;
}

.leftBox {
  width: 710px;
  margin-right: 40px;

  .info {
    font-size: 16px;
    margin-bottom: 16px;
    color: #3D3D3D;

    .intro {
      font-size: 15px;
      color: #8A8A8A;
      font-weight: 400;
      margin-top: 4px;
    }

    .more {
      display: flex;
      justify-content: space-between;
      margin-top: 14px;

      .item {
        width: 225px;
        height: 56px;
        border-radius: 18px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 18px;
        }
      }
    }

    .progress {
      display: flex;
      width: 100%;
      height: 56px;
      border-radius: 20px;
      padding: 10px 18px;
      margin-top: 14px;
      background-color: #F7F8FF;
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);

      .left {
        width: 510px;
        margin: auto 0;
        margin-right: 20px;

      }
    }
  }
}

.end {
  padding-bottom: 30px !important;
}

.editImg {
  margin-bottom: -4px;
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.mo_item {
  position: relative;
  width: 100%;
  height: 70px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 8px 15px;

  .top {
    display: flex;
    margin-bottom: 8px;

    .icon1 {
      margin-top: 4px;
      margin-right: 5px;
      width: 16px;
      height: 16px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .icon2 {
      width: 16px;
      height: 11px;
      margin-right: 5px;
      margin-top: 4px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .span {
      color: #8A8A8A;
      font-size: 16px;
    }
  }

  .button {
    color: #3D3D3D;
    font-size: 16px;
    display: flex;

    .ren {
      color: #14AE5C;
      background-color: #B8E6CD;
      border-radius: 4px;
      padding: 1px 4px;
      margin-left: 8px;
      font-size: 15px;
    }
  }

  .tool_box {
    position: absolute;
    display: flex;
    justify-content: end;
    top: 25px;
    right: 15px;

    .tool {
      background-color: #F7F8FF;
      width: 26px;
      height: 26px;
      margin-left: 6px;
      padding: 4%;
      border-radius: 6px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .set {
      font-size: 14px;
      padding: 3px 9px;
      background-color: #F7F8FF;
      border-radius: 6px;
      color: #3376FF;
      cursor: pointer;
    }
  }
}

.rightDialog {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  z-index: 1000;
}

.rightBox {
  width: 460px;
  height: 720px;
  background-color: #F7F8FF;
  border-radius: 18px;
  padding: 30px;
  box-shadow: 4px 5px 10px 4px rgba(0, 0, 0, 0.1);

  .name {
    display: flex;
    justify-content: space-between;

    .name_img {
      width: 28px;
      height: 28px;
      border-radius: 8px;
      margin-right: 8px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }

    .name_text {
      color: #3D3D3D;
      font-size: 19px;
      margin-right: 8px;
    }

    .edit_img {
      width: 20px;
      height: 20px;
      margin-top: 3px;
      margin-right: 4px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;

      }
    }
  }

  .rangeBox {
    margin-top: 20px;
    height: 560px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 20px;

    .item_box {
      .tit {
        font-size: 18px;
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;

        .addBox {
          color: #3376FF;
          background-color: #ECEEFF;
          padding: 5px 12px;
          border-radius: 12px;
          cursor: pointer;
        }
      }

      .tit_tip {
        font-size: 16px;
        color: #8A8A8A;
        margin-bottom: 10px;
      }
    }

    .treeClass {
      margin-bottom: 12px;
    }
  }
}

.groupBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  .groupItem {
    width: 347px;
    height: 80px;
    border-radius: 16px;
    background-color: #F7F8FF;
    padding: 12px 20px;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);

    .tit {
      display: flex;
      color: #3D3D3D;

      .tit_img {
        width: 28px;
        height: 28px;
        border-radius: 8px;
        margin-right: 12px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }

      .tit_name {
        font-size: 18px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .range {
      margin-top: 6px;
      font-size: 17px;
      color: #8A8A8A;
    }

    .range1 {
      display: flex;
      margin-top: 6px;
      font-size: 17px;
      color: #8A8A8A;

      .range1_item {
        margin-left: 6px;
      }
    }

    .delete {
      position: absolute;
      top: 22px;
      right: 18px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: none;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
}

.groupItem:hover {
  background-color: #ECEEFF;

  .delete {
    display: block;
  }
}
</style>
<template>
  <div class="shareContent">
    <!-- 左侧 -->
    <div
      v-if="!lookDialog"
      :class="conflictType ? 'leftBigDivFlex leftBigDiv' : 'leftBigDiv'"
    >
      <div :class="conflictType ? 'leftConflict' : 'left'">
        <div class="leftqaTitles">
          <div class="leftqaBtn">原问答</div>
          <div class="exportImgDiv" v-if="conflictType" @click="changeYct">
            <img class="exportImg" :src="exportImg" alt="" />
          </div>
        </div>
        <div class="shareLeftDiv">
          <qaIndex
            v-if="leftList.conversationId"
            type="share"
            :item="leftList"
            class="qaIndex"
          ></qaIndex>
          <div
            class="fillCenter"
            v-if="leftList.answers && leftList.answers.length > 1"
          >
            <img
              :src="leftList.chatActive != 1 ? leftFill : leftNoFill"
              alt=""
              class="fillImg"
              @click="changeItemPage(false)"
            />
            <span
              :class="leftList.chatActive == 1 ? 'fillColor' : ''"
              class="fillText"
              >{{ leftList.chatActive }}</span
            >
            <span
              class="fillText"
              :class="
                leftList.chatActive == leftList.answers.length
                  ? 'fillColor'
                  : ''
              "
              >/{{ leftList.answers.length }}</span
            >
            <img
              :src="
                leftList.chatActive == leftList.answers.length
                  ? rightNoFill
                  : rightFill
              "
              alt=""
              class="fillImg"
              @click="changeItemPage(true)"
            />
          </div>
          <!--     :class="{
              qaIndexOp: index != 0,
            }" -->
        </div>
        <!-- 锚点列表 -->
        <el-popover
          placement="top"
          trigger="click"
          popper-class="fileImgPopover"
        >
          <template #reference>
            <div class="anchorImgDiv">
              <img class="anchorImg" :src="anchorImg" alt="" />
            </div>
          </template>
          <div class="anchorHover">
            <div
              v-for="(item, index) in yzItem.conversations"
              :key="item"
              @click="clickAnchor(item, index)"
              :class="leftNumber == index ? 'hrefAc' : 'hrefNoac'"
            >
              {{ item.question }}
            </div>
          </div>
        </el-popover>
      </div>
      <div v-if="conflictType" class="leftConflict">
        <div class="leftqaTitles">
          <div class="leftqaBtn">上次验证</div>
          <div class="exportImgDiv" @click="changeCt">
            <img class="exportImg" :src="exportImg" alt="" />
          </div>
        </div>
        <div class="shareLeftDiv" v-if="!lookDialog">
          <detailsQa
            :item="publishData"
            class="qaIndex"
            @openFileView="openLook"
          ></detailsQa>
        </div>
      </div>
    </div>
    <div :class="lookDialog ? 'shareRightDivLook' : 'shareRightDiv'">
      <!-- 右侧 -->
      <div>
        <!-- 第一步 -->
        <div class="step1Div">
          <div class="flexBetween stepMargin">
            <div class="flexCenter">
              <div class="stepBtn">第1步</div>
              <div class="stepTitle">调整回答来源</div>
            </div>
          </div>
          <!-- 第一步tip -->
          <div class="step1Tip">
            若根据原回答搜索到的参考来源得出的回答不正确/不完善，您可以按需调整回答来源
          </div>
        </div>
        <!-- 微模型 -->
        <div class="flexCenter">
          <img class="baseImg" :src="baseImg" alt="" />
          <div class="fileTitle">调整AI知识库来源</div>
          <div class="chDiv">
            <img class="chImg" :src="chImg" alt="" />
          </div>
        </div>
        <!-- 文件列表 -->
        <div class="pa" v-if="fileList.length != 0">
          <img
            :src="rightReson"
            alt=""
            class="leftResonIcon"
            @click="changeLeftReson(fileDiv)"
          />
          <div class="fileListDiv" ref="fileDiv">
            <fileIndex
              @openLook="openLook"
              class="resonMargin"
              v-for="(item, index) in fileList"
              :key="item.id"
              :item="item"
              @deleteFile="deleteFile(index)"
            ></fileIndex>
            <talkIndex
              :talkItem="item"
              class="resonMargin"
              v-for="item in sessionList"
              :key="item.id"
            ></talkIndex>
          </div>
          <img
            :src="rightReson"
            alt=""
            class="rightResonIcon"
            @click="changeRightReson(fileDiv)"
          />
        </div>
        <div v-else>
          <el-empty />
        </div>
        <!-- 上传新文件 -->
        <div class="selDiv">
          <el-select
            class="baseSel"
            placeholder="在当前微模型内选择您认为适合的来源文档"
            @change="changeFile"
          >
            <el-option
              v-for="item in baseFile"
              :key="item.id"
              :value="item.id"
              >{{ item.fileName || item.folderName }}</el-option
            >
          </el-select>

          <el-button class="addFileBtn" :loading="timmer != null">
            <upLoadView type="file" @beforeAvatarUpload="upFileFn">
              <img class="step2Img" :src="addFileImg" alt="" />
              <div>上传新文档</div>
            </upLoadView>
          </el-button>
        </div>
        <!-- 网页 -->
        <div class="flexCenter">
          <img class="baseImg" :src="netImg" alt="" />
          <div class="fileTitle">调整互联网来源</div>
          <div class="chDiv">
            <img class="chImg" :src="chImg" alt="" />
          </div>
        </div>
        <div class="pa" v-if="netWordList.length != 0">
          <img
            :src="rightReson"
            alt=""
            class="leftResonIcon"
            @click="changeLeftReson(newDiv)"
          />
          <div class="netListDiv" ref="newDiv">
            <htmlIndex
              class="resonMargin"
              v-for="item in netWordList"
              :key="item"
              :htmlItem="item"
            ></htmlIndex>
          </div>
          <img
            :src="rightReson"
            alt=""
            class="rightResonIcon"
            @click="changeRightReson(newDiv)"
          />
        </div>
        <div v-else>
          <el-empty />
        </div>
        <!-- 第二部 -->
        <div class="step1Div">
          <div class="flexBetween stepMargin">
            <div class="flexCenter">
              <div class="stepBtn">第2步</div>
              <div class="stepTitle">调整回答文本</div>
            </div>
          </div>
          <!-- 第二步tip -->
          <div class="step1Tip">
            完成回答来源调整后，你可以尝试“一键刷新”回答，对不满意的地方还可点击“编辑”自行调整新回答；点击“回到最初”则可消除编辑修改，恢复到未经编辑的回答版本
          </div>
        </div>
        <!-- 一键更新 编辑 -->
        <div class="step2BtnDiv">
          <div class="flexCenter">
            <el-button
              :disabled="editType"
              :class="editType ? 'yjgxBtnDisabled' : 'yjgxBtn'"
              @click="sendFn"
            >
              <img class="step2Img" :src="yjgxImg" alt="" />
              <div class="step2FnFont step2FontColorF">一键更新</div>
            </el-button>
            <el-button
              class="editBtn"
              v-if="!editType"
              @click="editType = !editType"
            >
              <img class="step2Img" :src="editImg" alt="" />
              <div class="step2FnFont step2FontColor8A">编辑</div>
            </el-button>
            <el-button class="yjgxBtn" v-else @click="editType = !editType">
              <img class="step2Img" :src="editAcImg" alt="" />
              <div class="step2FnFont step2FontColorF">存储</div>
            </el-button>
          </div>
          <el-button class="editBtn" @click="goMessage">
            <img class="step2Img" :src="hdzcImg" alt="" />
            <div class="step2FnFont step2FontColor8A">回到最初</div>
          </el-button>
        </div>
        <!-- 内容 -->
        <el-input
          v-model="messageText"
          :disabled="!editType"
          autosize
          type="textarea"
          :class="editType ? 'rightInner' : 'textareaBg'"
          class="rightInner"
          :placeholder="!updataLoading ? '请输入正确内容' : '正在更新中...'"
        />
        <!-- 第三步 -->
        <div class="step1Div">
          <div class="flexBetween stepMargin">
            <div class="flexCenter">
              <div class="stepBtn">第3步</div>
              <div class="stepTitle">设置时效</div>
            </div>
          </div>
          <!-- 第二步tip -->
          <div class="step1Tip">
            为了保证过期内容不再作为有效知识，你可以为你的验证内容设置时效，验证失效一周前，系统将向你确认是否更新验证或延长时效；默认验证时效为长期
          </div>
        </div>
        <div class="radioDiv">
          <el-radio-group v-model="setTime">
            <el-radio
              v-for="item in timeList"
              :key="item.value"
              :value="item.value"
              class="radioText"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
          <el-date-picker
            v-if="setTime == 6"
            class="timePicker"
            v-model="time"
            type="date"
            placeholder="请选择有效期"
          />
        </div>
        <!-- 下一步 -->
        <div class="nextDiv">
          <el-button class="nextQ" v-if="leftNumber != 0" @click="upQ">
            上个问答
          </el-button>
          <el-button
            class="nextQ"
            v-if="leftNumber != yzItem.conversations.length - 1"
            @click="nextQ"
          >
            下个问答
          </el-button>
          <el-button
            class="nextQ"
            v-if="yzItem.conversations.length - 1 == leftNumber"
            @click="goLook"
          >
            进入预览
          </el-button>
          <el-button
            v-if="yzItem.conversations.length == 1"
            :disabled="editType"
            @click="goRelease"
            :class="editType ? 'yjgxBtnDisabled' : 'yjgxBtn'"
          >
            <img class="step2Img" :src="fbyzImg" alt="" />
            <div class="step2FnFont step2FontColorF">发布验证</div>
          </el-button>
        </div>
      </div>
    </div>
    <fileView
      v-if="lookDialog"
      class="fileViewDiv"
      type="chat"
      :fileItem="fileItem"
      :chunkDialog="true"
      @closeFileView="closeFileView"
    ></fileView>
    <!-- 发布提醒 -->
    <tipView
      v-if="releaseDialog"
      title="确认发布验证"
      tip="感谢您的辛苦审阅，您的验证内容会立即推送至当前AI知识库的权威话题，并加入后续相关提问的参考来源，确认发布当前验证内容吗？"
      cancel="放弃"
      ok="发布"
      @cancel="releaseCancel"
      @ok="releaseOk"
    ></tipView>
    <!-- 冲突提醒 -->
    <tipView
      v-if="conflictDialog"
      title="发生冲突"
      tip="非常抱歉，当前申请已由他人审阅并发布验证，请核对与您的验证内容是否一致，并解决可能的冲突后再次发布。若选择放弃解决，系统将不会保存您的验证内容。"
      cancel="放弃解决"
      ok="解决冲突"
      @cancel="conflictCancel"
      @ok="conflictOk"
    ></tipView>
    <!-- 锚点提醒 -->
    <tipView
      v-if="anchorDialog"
      title="跳转问答确认"
      tip="您在当前问答页面尚有未存储的调整内容，是否存储当前内容并跳转到新问答页面？"
      cancel="放弃更改并跳转"
      ok="存储并跳转新问答"
      @cancel="anchorCancel"
      @ok="anchorOk"
    ></tipView>
    <!-- 否决 -->
    <div :class="lookDialog ? 'absNos' : 'absNo'" class="flexCenter">
      <el-button class="noQBtn" @click="closeShare">
        <img class="noQ" :src="noQImg" alt="" />
        <div>否决</div>
      </el-button>
      <div class="outQBtn" @click="goBack">
        <img class="outQ" :src="outQaImg" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import outQaImg from "@/assets/share/outQa.svg";
import noQImg from "@/assets/share/noQ.svg";
import baseImg from "@/assets/share/base.svg";
import chImg from "@/assets/share/ch.svg";
import netImg from "@/assets/share/net.svg";
import editImg from "@/assets/share/edit.svg";
import editAcImg from "@/assets/share/editAc.svg";
import hdzcImg from "@/assets/share/hdzc.svg";
import yjgxImg from "@/assets/share/yjgx.svg";
import addFileImg from "@/assets/share/addFile.svg";
import anchorImg from "@/assets/share/anchorImg.svg";
import qaIndex from "@/components/qa/index.vue";
import detailsQa from "@/components/qa/detailsQa.vue";
import fileIndex from "@/components/shareReson/file.vue";
import htmlIndex from "@/components/shareReson/html.vue";
import talkIndex from "@/components/shareReson/talk.vue";
import rightReson from "@/assets/chat/rightReson.svg";
import exportImg from "@/assets/share/exportImg.svg";
import fbyzImg from "@/assets/share/fbyz.svg";
import upLoadView from "@/components/upload/index.vue";
import fileView from "@/components/fileView/index.vue";
import tipView from "@/components/tipDialog/index.vue";
import leftFill from "@/assets/chat/leftFill.svg";
import rightFill from "@/assets/chat/rightFill.svg";
import leftNoFill from "@/assets/chat/leftNoFill.svg";
import rightNoFill from "@/assets/chat/rightNoFill.svg";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
import { ref, watch, onMounted } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import api from "@/api";
import { encryptApi, decryptApi } from "@/utils/crypto";
const router = useRouter();
const store = useStore();
const yzItem = ref(store.state.yzItem);
const userId = ref(store.state.userId);
const setTime = ref(5);
const time = ref();
const timeList = ref([
  {
    label: "一个月",
    value: 1,
  },
  {
    label: "一季度",
    value: 2,
  },
  {
    label: "半年",
    value: 3,
  },
  {
    label: "一年",
    value: 4,
  },
  {
    label: "长期",
    value: 5,
  },
  {
    label: "自选",
    value: 6,
  },
]);
const fileDiv = ref(null);
const newDiv = ref(null);
const editType = ref(false); //编辑状态
const lookDialog = ref(false);
const releaseDialog = ref(false); //发布提醒
const conflictDialog = ref(false); //冲突dialog
const conflictType = ref(false); //冲突展示
const anchorDialog = ref(false); //锚点跳转前提醒
const fileItem = ref({});
const messageText = ref("");
const leftNumber = ref(0);
const leftList = ref({}); //左侧列表
const model = ref(null); //一键更新
const strategy = ref(null);
const baseFile = ref([]); //文件列表
const fileList = ref([]); //文件列表
const sessionList = ref([]); //对话列表
const netWordList = ref([]); //网页列表
const rootId = ref(null); //rootID
const timmer = ref(null);
const firstMessage = ref(""); //最初的字段
const updataLoading = ref(false);
const publishData = ref({});
const oldData = ref({});
watch(
  () => store.state.yzItem,
  (newValue, oldValue) => {
    yzItem.value = store.state.yzItem;
  }
);
onBeforeRouteLeave((to, from, next) => {
  clearInterval(timmer.value);
  timmer.value = null;
  next();
});
// 生命周期
onMounted(() => {
  getDesc();
  getLeft();
  getBaseFile();
  pollingFn();
  task();
  getRight();
});
// 删除文件
const deleteFile = (index) => {
  fileList.value.splice(index, 1);
};
// 选择文件直接进行添加
const changeFile = (e) => {
  fileList.value.unshift(...baseFile.value.filter((item) => item.id == e));
};
// 获取微模型文件列表
const getBaseFile = () => {
  api.base
    .queryFileList(
      { userId: userId.value, folderId: null },
      yzItem.value.libraryId
    )
    .then((res) => {
      if (res.returnCode == 200) {
        rootId.value = res.data.currentFolderInfo.id;
        baseFile.value = [...res.data.folderInfo, ...res.data.filesInfo];
      }
    });
};
// 上个问答
const upQ = () => {
  leftNumber.value--;
  getLeft();
  getRight();
};
// 获取日期
const getTimeLast = (setTime) => {
  var today = new Date();
  const addMonths = (monthsToAdd) => {
    var month = today.getMonth();
    var year = today.getFullYear();
    var nextMonth = month + monthsToAdd;
    var nextYear = year;

    if (nextMonth > 11) {
      nextMonth -= 12;
      nextYear += 1;
    }

    return new Date(nextYear, nextMonth, today.getDate());
  };

  switch (setTime) {
    case 1:
      return addMonths(1).toISOString().split("T")[0];
    case 2:
      return addMonths(3).toISOString().split("T")[0];
    case 3:
      return addMonths(6).toISOString().split("T")[0];
    case 4:
      return new Date(
        today.getFullYear() + 1,
        today.getMonth(),
        today.getDate()
      )
        .toISOString()
        .split("T")[0];
    case 5:
      return null;
    case 6:
      return time; // 假设time是一个变量，需要在函数外部定义
    default:
      throw new Error("Invalid setTime value");
  }
};
// 下个问答
const nextQ = () => {
  let fileIds = [];
  let folderIds = [];
  fileList.value.forEach((item) => {
    if (item.fileName) {
      fileIds.push(item.id);
    } else if (item.folderName) {
      folderIds.push(item.id);
    }
  });
  let contentSessionId = sessionList.value.map((item) => item.sessionId);
  let internetSource = netWordList.value.map((item) => item.internetUrl);
  if (fileIds.length == 0) {
    message.warning("回答来源至少一个");
    return;
  }
  if (messageText.value == "") {
    message.warning("回答文本不能为空");
    return;
  }
  if (editType.value) {
    anchorDialog.value = true;
    return;
  }
  api.share
    .recordSave({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId,
      ConversationRequestVerifyId:
        yzItem.value.conversations[leftNumber.value]
          .ConversationRequestVerifyId,
      expireDate: getTimeLast(setTime.value),
      libraryId: yzItem.value.libraryId,
      conversationId:
        yzItem.value.conversations[leftNumber.value].conversationId,
      message: messageText.value,
      model: model.value,
      strategy: strategy.value,
      question: leftList.value.question,
      isOver: conflictType.value ? 1 : 0, //先0或不传，返回201后如果要继续则传
      internetSource: internetSource,
      sessionId: yzItem.value.sessionId, //当前所在的session
      contentSessionId: contentSessionId, //来源session
      fileIds: fileIds,
      folderIds: folderIds,
      timeChoice: setTime.value - 1,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        editType.value = false;
        anchorDialog.value = false;
        leftNumber.value++;
        getLeft();
        getRight();
      } else if (res.returnCode == 201) {
        getRight();
      }
    });
};
// 加减页数
const changeItemPage = (type) => {
  if (type && leftList.value.chatActive < leftList.value.answers.length) {
    leftList.value.chatActive++;
  }
  if (!type && leftList.value.chatActive > 1) {
    leftList.value.chatActive--;
  }
};
// 否决
const closeShare = () => {
  api.share
    .recordReject({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId,
      conversationId:
        yzItem.value.conversations[leftNumber.value].conversationId,
      ConversationRequestVerifyId:
        yzItem.value.conversations[leftNumber.value]
          .ConversationRequestVerifyId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success("否决成功");
        store.commit("SET_CHATTYPE", "chat");
        store.commit("SET_TOPIC", null);
        store.commit("SET_YZ", null);
        router.push("/share");
      }
    });
};
// 回到最初
const goMessage = () => {
  messageText.value = firstMessage.value;
};
// 获取左侧
const getLeft = () => {
  api.base
    .baseHistory({
      userId: userId.value,
      conversationId:
        yzItem.value.conversations[leftNumber.value].conversationId,
      libraryId: yzItem.value.libraryId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        res.data.chatActive = 1;
        res.data.resonType = false;
        res.data.resonList = [];
        leftList.value = res.data;
        console.log(res.data, "左侧数组");
      }
    });
};
// 获取右侧
const getRight = () => {
  api.share
    .recordAll({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId,
      ConversationRequestVerifyId:
        yzItem.value.conversations[leftNumber.value]
          .ConversationRequestVerifyId,
      libraryId: yzItem.value.libraryId,
      conversationId:
        yzItem.value.conversations[leftNumber.value].conversationId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        console.log(res.data, "右侧数据");
        // 有冲突
        if (Object.keys(res.data[0].publishData).length != 0) {
          conflictDialog.value = true;
          publishData.value = res.data[0].publishData;
          oldData.value = res.data[0].oldData;
        }
        firstMessage.value = res.data[0].oldData.message;
        if (Object.keys(res.data[0].mySaveData).length != 0) {
          fileList.value = res.data[0].mySaveData.fileResource;
          sessionList.value = res.data[0].mySaveData.sessionResource;
          netWordList.value = res.data[0].mySaveData.internets;
          messageText.value = res.data[0].mySaveData.message;
          model.value = res.data[0].mySaveData.model;
          setTime.value = res.data[0].mySaveData.timeChoice
            ? res.data[0].mySaveData.timeChoice + 1
            : 5;
        } else {
          fileList.value = res.data[0].oldData.fileResource;
          sessionList.value = res.data[0].oldData.sessionResource;
          netWordList.value = res.data[0].oldData.internets;
          messageText.value = res.data[0].oldData.message;
          model.value = res.data[0].oldData.model;
          setTime.value = res.data[0].oldData.timeChoice
            ? res.data[0].oldData.timeChoice + 1
            : 5;
        }
      }
    });
};
// 毛 获取详情
const getDesc = () => {
  api.share
    .getRecordDetails({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId,
      ConversationRequestVerifyId:
        yzItem.value.conversations[leftNumber.value]
          .ConversationRequestVerifyId,
      libraryId: yzItem.value.libraryId,
      conversationId:
        yzItem.value.conversations[leftNumber.value].conversationId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        // 获取model
        model.value = res.data.oldData.model;
        strategy.value = res.data.oldData.strategy;
      }
    });
};
// 回退
const goBack = () => {
  router.push("/share");
};
// 向左
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
// 打开文件预览
const openLook = (item) => {
  console.log(item);
  fileItem.value = item;
  lookDialog.value = true;
};
// 上传文件fuction
const upFileFn = (fileList) => {
  api.base
    .uploadFileCompanyApi(
      fileList,
      { folderId: rootId.value },
      yzItem.value.libraryId
    )
    .then((res) => {
      if (res.returnCode == 200) {
        pollingFn();
        task();
        if (res.failedFileList.length != 0) {
          const textFile = res.failedFileList.toString();
          message.warning(textFile + "文件名重复");
        }
      }
    });
};
// 轮询
const pollingFn = () => {
  clearInterval(timmer.value);
  timmer.value = null;
  timmer.value = setInterval(() => {
    setTimeout(() => {
      task();
    }, 0);
  }, 2000);
};
// 轮询
const task = () => {
  api.base
    .taskView({ userId: userId.value }, yzItem.value.libraryId)
    .then((res) => {
      if (res.returnCode == 200) {
        //刷新当前文件夹
        getBaseFile();
        if (res.data.length == 0) {
          clearInterval(timmer.value);
          timmer.value = null;
        }
      }
    });
};
// 关闭文件预览
const closeFileView = () => {
  lookDialog.value = false;
};
// 点击发布打开弹窗
const goRelease = () => {
  releaseDialog.value = true;
};
// 发布放弃
const releaseCancel = () => {
  releaseDialog.value = false;
};
// 发布
const releaseOk = () => {
  let fileIds = [];
  let folderIds = [];
  fileList.value.forEach((item) => {
    if (item.fileName) {
      fileIds.push(item.id);
    } else if (item.folderName) {
      folderIds.push(item.id);
    }
  });
  let contentSessionId = sessionList.value.map((item) => item.sessionId);
  let internetSource = netWordList.value.map((item) => item.internetUrl);
  if (fileIds.length == 0) {
    message.warning("回答来源至少一个");
    return;
  }
  if (messageText.value == "") {
    message.warning("回答文本不能为空");
    return;
  }
  api.share
    .recordSave({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId,
      ConversationRequestVerifyId:
        yzItem.value.conversations[leftNumber.value]
          .ConversationRequestVerifyId,
      expireDate: getTimeLast(setTime.value),
      libraryId: yzItem.value.libraryId,
      conversationId:
        yzItem.value.conversations[leftNumber.value].conversationId,
      message: messageText.value,
      model: model.value,
      strategy: strategy.value,
      question: leftList.value.question,
      isOver: conflictType.value ? 1 : 0, //先0或不传，返回201后如果要继续则传
      internetSource: internetSource,
      sessionId: yzItem.value.sessionId, //当前所在的session
      contentSessionId: contentSessionId, //来源session
      fileIds: fileIds,
      folderIds: folderIds,
      timeChoice: setTime.value - 1,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        api.share
          .verifyPublish({
            userId: userId.value,
            RequestVerifyId: yzItem.value.RequestVerifyId,
            libraryId: yzItem.value.libraryId,
            isOver: conflictType.value ? 1 : 0,
          })
          .then((res) => {
            if (res.returnCode == 200) {
              message.success("发布成功");
              releaseDialog.value = false;
              router.push("/share");
            } else if (res.returnCode == 201) {
              getRight();
            }
          });
      } else if (res.returnCode == 201) {
        getRight();
      }
    });
};
// 放弃冲突
const conflictCancel = () => {
  conflictDialog.value = false;
  router.push("/share");
};
// 原文打冲突
const changeYct = () => {
  fileList.value = oldData.value.fileResource;
  sessionList.value = oldData.value.sessionResource;
  netWordList.value = oldData.value.internets;
  messageText.value =
    leftList.value.answers[leftList.value.chatActive - 1].answerContent;
  model.value = oldData.value.model;
  setTime.value = oldData.value.timeChoice ? oldData.value.timeChoice + 1 : 5;
};
// 上次问答转移到右侧
const changeCt = () => {
  fileList.value = publishData.value.fileResource;
  sessionList.value = publishData.value.sessionResource;
  netWordList.value = publishData.value.internets;
  messageText.value = publishData.value.message;
  model.value = publishData.value.model;
  setTime.value = publishData.value.timeChoice
    ? publishData.value.timeChoice + 1
    : 5;
};
// 解决冲突
const conflictOk = () => {
  conflictDialog.value = false;
  conflictType.value = true;
};
// 锚点点击事件
const clickAnchor = (item, index) => {
  if (editType.value) {
    anchorDialog.value = true;
  }
  if (index > leftNumber.value) {
    // 下一步
    nextQ();
  } else {
    // 上一步
    upQ();
  }
};
// 锚点提醒确定
const anchorOk = () => {
  anchorDialog.value = false;
  editType.value = false;
  nextQ();
};
// 锚点提醒取消
const anchorCancel = () => {
  anchorDialog.value = false;
};
// 进入预览
const goLook = () => {
  let fileIds = [];
  let folderIds = [];
  fileList.value.forEach((item) => {
    if (item.fileName) {
      fileIds.push(item.id);
    } else if (item.folderName) {
      folderIds.push(item.id);
    }
  });
  let contentSessionId = sessionList.value.map((item) => item.sessionId);
  let internetSource = netWordList.value.map((item) => item.internetUrl);
  if (fileIds.length == 0) {
    message.warning("回答来源至少一个");
    return;
  }
  api.share
    .recordSave({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId,
      ConversationRequestVerifyId:
        yzItem.value.conversations[leftNumber.value]
          .ConversationRequestVerifyId,
      expireDate: getTimeLast(setTime.value),
      libraryId: yzItem.value.libraryId,
      conversationId:
        yzItem.value.conversations[leftNumber.value].conversationId,
      message: messageText.value,
      model: model.value,
      strategy: strategy.value,
      question: leftList.value.question,
      isOver: conflictType.value ? 1 : 0, //先0或不传，返回201后如果要继续则传
      internetSource: internetSource,
      sessionId: yzItem.value.sessionId, //当前所在的session
      contentSessionId: contentSessionId, //来源session
      fileIds: fileIds,
      folderIds: folderIds,
      timeChoice: setTime.value - 1,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        router.push({
          name: "shareView",
          query: {
            type: "edit",
          },
        });
      }
    });
};
// 一键更新
const sendFn = async () => {
  let fileIds = [];
  let folderIds = [];
  updataLoading.value = true;
  fileList.value.forEach((item) => {
    if (item.fileName) {
      fileIds.push(item.id);
    } else if (item.folderName) {
      folderIds.push(item.id);
    }
  });
  let obj = {
    userId: userId.value,
    model: model.value, //暂无
    message: leftList.value.question,
    libraryId: yzItem.value.libraryId,
    isInternetOn: false,
    fileIds: fileIds,
    folderIds: folderIds,
    sessionId: yzItem.value.sessionId,
    strategy: strategy.value, //暂无
    regenerateFlag: 0,
    internetSource: null,
    conversationId: yzItem.value.conversations[leftNumber.value].conversationId,
    inValid: true,
  };
  messageText.value = "";
  let time = Math.round(new Date() / 1000);
  let postObj = {
    time: time,
    idempotentEncryData: encryptApi(JSON.stringify({ ...obj, time: time })),
  };
  let lang = store.state.lang;
  if (lang === "CN") {
    lang = "zh-CN";
  } else if (lang === "HK") {
    lang = "zh-HK";
  } else if (lang === "US") {
    lang = "EN";
  }
  try {
    let controller = new AbortController();
    const response = await fetch("/openai/chat/v4/knowledge/chat", {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + store.state.token,
        "Accept-Language": lang,
      },
      body: JSON.stringify(postObj),
      signal: controller.signal,
    });
    if (response.status === 500) {
      updataLoading.value = false;
      message.warning("服务器连接失败");
    }
    if (response.status === 504) {
      updataLoading.value = false;
      message.warning("服务器连接超时");
    }
    const reader = response.body.getReader();
    let flag = true;
    let data = "";
    while (flag) {
      const { done, value } = await reader.read();
      const rawData = new TextDecoder().decode(value);
      const regex = /<start>(.*?)<end>/g;
      let matches;
      const extractedData = [];
      while ((matches = regex.exec(rawData)) !== null) {
        extractedData.push(matches[1]);
      }
      const decryptedData = extractedData.map((data) => decryptApi(data));
      data += decryptedData.map((item) => item).join("");
      messageText.value = data;
      updataLoading.value = false;
      if (done) {
        if (
          !data.includes("returnCode") &&
          !data.includes("!(end of answer)")
        ) {
        } else if (data.includes("!(end of answer)")) {
          message.warning("当前对话记录超过限制,请结束对话或新建对话。");
          messageText.value = "当前对话记录超过限制,请结束对话或新建对话。";
        } else if (data.includes("returnCode")) {
          let newData = JSON.parse(data);
          message.warning(newData.returnMessage);
          if (newData.returnCode == 423 || newData.returnCode == 412) {
            router.push("/login");
          } else {
            messageText.value = newData.returnMessage;
          }
        }
        break;
      }
    }
  } catch (err) {
    updataLoading.value = false;
    console.log(err);
  }
};
</script>

<style lang="scss" scoped>
.shareContent {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}
.leftBigDiv {
  width: calc(50% - 10px);
  height: 100%;
}
.left {
  position: relative;
  padding: 30px;
  margin-right: 10px;
  background-color: #fefefe;
  border-radius: 30px;
  height: calc(100% - 60px);
}
.leftBigDivFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.leftConflict {
  position: relative;
  padding: 30px;
  margin-right: 10px;
  background-color: #fefefe;
  border-radius: 30px;
  height: calc(50% - 70px);
}
.shareLeftDiv {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.shareRightDiv {
  width: calc(50% - 70px);
  margin-left: 10px;
  background-color: #fefefe;
  border-radius: 30px;
  height: calc(100% - 60px);
  padding: 30px;
  overflow: auto;
  position: relative;
}
.shareRightDivLook {
  width: 447px;
  margin-right: 20px;
  background-color: #fefefe;
  border-radius: 30px;
  height: calc(100% - 60px);
  padding: 30px;
  overflow: auto;
  position: relative;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.stepMargin {
  margin-bottom: 6px;
}
.stepBtn {
  background-color: #767bfa;
  height: 31px;
  text-align: center;
  line-height: 31px;
  border-radius: 30px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Semibold";
  padding: 0px 15px;
}
.stepTitle {
  font-size: 20px;
  font-family: "Semibold";
  color: #3d3d3d;
  margin-left: 12px;
}
.outQ {
  width: 20px;
}
.noQ {
  width: 14px;
  margin-right: 6px;
}
.noQBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #edeef6;
  border-radius: 12px;
  padding: 5px 12px;
  color: #f02d63;
  font-family: "Regular";
  font-size: 18px;
  cursor: pointer;
}
.outQBtn {
  width: 36px;
  height: 36px;
  background-color: #edeef6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 8px;
}
.step1Tip {
  font-size: 16px;
  color: #8a8a8a;
  font-family: "Medium";
}
.step1Div {
  margin-bottom: 24px;
}
.fileTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Semibold";
  margin: 0px 12px;
}
.baseImg {
  width: 25px;
}
.chDiv {
  width: 21px;
  height: 21px;
  background-color: #edeef6;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chImg {
  width: 13px;
}
.fileListDiv {
  margin: 17px 15px 12px 11px;
  height: 78px;
  padding: 4px;
  display: flex;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.netListDiv {
  margin: 17px 15px 30px 11px;
  height: 114px;
  padding: 4px;
  display: flex;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.resonMargin {
  margin-right: 12px;
}
.absNo {
  position: absolute;
  right: 30px;
  top: 17px;
  z-index: 100;
  background-color: #ffffff;
  box-shadow: -20px 20px 20px 0px #ffffff;
}
.absNos {
  position: absolute;
  left: 335px;
  top: 17px;
  z-index: 100;
  background-color: #ffffff;
  box-shadow: -20px 20px 20px 0px #ffffff;
}
.pa {
  position: relative;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
}
.step2Img {
  width: 25px;
  margin-right: 3px;
  height: 25px;
}
.step2FnFont {
  font-size: 18px;
  font-family: "Regular";
}
.step2FontColorF {
  color: #fefefe;
}
.step2FontColor8A {
  color: #8a8a8a;
}
.yjgxBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  background-color: #3376ff;
  border-radius: 12px;
  padding: 6px 12px;
  margin-right: 8px;
}
.editBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #edeef6;
  border-radius: 12px;
  height: 36px;
  padding: 6px 12px;
}
.step2BtnDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 27px;
  margin: 24px 0px 12px 0px;
}
.textareaBg {
  background-color: #eceeff;
}
:deep(.el-textarea.is-disabled .el-textarea__inner) {
  background-color: #eceeff;
  color: #3d3d3d;
  cursor: text;
  box-shadow: 0px 0px 0px 0px #ffffff;
}
:deep(.el-textarea__inner) {
  border: 0px;
  resize: none;
  outline: none;
  width: 100%;
  overflow: auto;
  height: 100%;
  font-size: 24px;
  font-family: "Regular";
  box-shadow: 0px 0px 0px 0px #ffffff;
}
.rightInner {
  border: 2px solid #dbdfff;
  padding: 15px 20px;
  border-radius: 16px;
  font-size: 20px;
  font-family: "Regular";
  line-height: 34px;
  margin: 0px 15px 30px 15px;
  width: calc(100% - 70px) !important;
}
.radioText {
  font-size: 18px;
  font-family: "Regular";
  color: #3d3d3d;
  :deep(.el-radio__input.is-checked .el-radio__inner) {
    background-color: transparent;
    border: 1px solid #3376ff;
  }
  :deep(.el-radio__input.is-checked .el-radio__inner::after) {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #3376ff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    content: "";
  }
  :deep(.el-radio__inner::after) {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #8a8a8a;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    content: "";
  }
  :deep(.el-radio__inner) {
    border: 1px solid #8a8a8a;
    width: 18px;
    height: 18px;
  }
  :deep(el-radio__label) {
    font-size: 18px;
    font-family: "Regular";
    color: #3d3d3d;
  }
}
.radioDiv {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
}
.nextQ {
  border-radius: 16px;
  background-color: #3376ff;
  color: #ffffff;
  font-size: 20px;
  font-family: "Regular";
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  height: auto;
}
.nextDiv {
  display: flex;
  justify-content: flex-end;
}
:deep(.timePicker) {
  height: 36px !important;
  width: 160px !important;
  :deep(.el-input__wrapper) {
    box-shadow: 0px 0px 0px 1px #8a8a8a;
  }
  :deep(.el-input__inner) {
    color: #3d3d3d;
    font-size: 18px;
    font-family: "Regular";
  }
}
.yjgxBtnDisabled {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  background-color: #d9d9d9;
  border-radius: 12px;
  padding: 6px 12px;
  margin-right: 8px;
}
.yjgxBtnDisabled:hover {
  background-color: #d9d9d9 !important;
}
.addFileBtn {
  border: 2px solid #edeef6;
  height: 48px;
  font-size: 18px;
  color: #0256ff;
  font-family: "Regular";
  border-radius: 16px;
  margin-left: 12px;
}
.baseSel {
  :deep(.el-select__wrapper) {
    height: 48px;
    border: 2px solid #edeef6;
    box-shadow: 0px 0px 0px 0px #ffffff;
    border-radius: 16px;
    font-size: 18px;
    color: #d9d9d9;
    font-family: "Regular";
  }
}
.selDiv {
  display: flex;
  align-items: center;
  padding: 0px 15px;
  margin-bottom: 24px;
}
.fileViewDiv {
  width: calc(100% - 547px);
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
}
.leftqaTitle {
  position: absolute;
  background-color: #767bfa;
  color: #ffffff;
  padding: 3px 11px;
  border-radius: 30px;
  font-size: 18px;
  font-family: "Semibold";
  top: 30px;
  left: 30px;
  box-shadow: 20px 20px 20px 0px #ffffff;
  z-index: 199;
}
.leftqaBtn {
  background-color: #767bfa;
  color: #ffffff;
  padding: 3px 11px;
  border-radius: 30px;
  font-size: 18px;
  font-family: "Semibold";
  margin-right: 12px;
}
.leftqaTitles {
  position: absolute;
  top: 30px;
  left: 30px;
  box-shadow: 20px 20px 20px 0px #ffffff;
  display: flex;
  align-items: center;
  z-index: 199;
}
.exportImg {
  width: 17px;
}
.exportImgDiv {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  background-color: #edeef6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgb(255 255 255 / 80%);
}
.qaIndex {
  margin-bottom: 43px;
}
.qaIndexOp {
  opacity: 0.5;
}
.anchorImg {
  width: 18px;
}
.anchorImgDiv {
  position: absolute;
  width: 36px;
  height: 36px;
  background-color: #edeef6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  right: 30px;
  bottom: 30px;
  box-shadow: -20px 0px 24px 0px #fefefe;
}
.anchorHover {
  width: 100%;
  padding-left: 10px;
  border-left: 3px solid #8a8a8a;
}
.fillCenter {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.fillColor {
  color: #d9d9d9;
}
.fillImg {
  width: 24px;
  cursor: pointer;
}
.fillText {
  font-size: 18px;
  font-family: "Semibold";
  color: #8a8a8a;
}
.hrefNoac {
  color: #8a8a8a;
  font-family: "Regular";
  font-size: 20px;
  margin-bottom: 14px;
  cursor: pointer;
  position: relative;
}
.hrefNoac::after {
  content: "";
  width: 3px;
  height: 28px;
  background-color: #8a8a8a;
  position: absolute;
  left: -13px;
}
.hrefAc {
  color: #0256ff;
  font-family: "Regular";
  font-size: 20px;
  margin-bottom: 14px;
  cursor: pointer;
  position: relative;
}
.hrefAc::after {
  content: "";
  width: 3px;
  height: 28px;
  background-color: #0256ff;
  position: absolute;
  left: -13px;
}
</style>

<script setup>
import { ref, defineProps, onMounted, watch, defineEmits } from 'vue';
import { useI18n } from "vue-i18n";
import api from "@/api/index";
import { useStore } from "vuex";
import { message } from 'ant-design-vue';
import { ElMessageBox } from 'element-plus'
const { t } = useI18n();
const emit = defineEmits(['changeSize', 'changePage']);
const store = useStore();
const userId = ref(store.state.userId);
// 你可以在这里添加更多的点击处理逻辑  
const props = defineProps({
    tableId: Number,
    tableName: String,
});
onMounted(() => {
    getUserTable()
})
watch(
    () => props.tableId,
    (newVal) => {
        listTable.value.departmentId = newVal;
        getUserTable()
    }
)
// 资源下拉
const status = ref('全部')
const statusList = ref([
    {
        value: -1,
        label: '全部',

    },
    {
        value: 2,
        label: '阿里'
    },
    {
        value: 1,
        label: 'baidu'
    },
    {
        value: 0,
        label: 'Azure'
    }
])
const statusBtn = (item) => {
    if (item == -1) {
        status.value = '全部'
    }
    changeType(item)
    listTable.value.resourceType = item
    getUserTable()
}
// 分页
const currentPage = ref(1);
const pageSize = ref(20);
const updatePageSize = (newSize) => {
    listTable.value.perPage = newSize;
    getUserTable()
};
const updateCurrentPage = (newPage) => {
    listTable.value.pageId = newPage;
    getUserTable()
};
// 请求表格数据
const totalItems = ref(0);
const listTable = ref({
    userId: userId.value,
    departmentId: props.tableId,
    resourceType: null,
    ifLimit: true,
    pageId: currentPage.value,
    perPage: pageSize.value,
})
const tableMember = ref([])
const getUserTable = () => {
    api.manage
        .getResourceListApi(listTable.value)
        .then((res) => {
            if (res.returnCode == 200) {
                // totalItems.value = res.totalDataNum
                tableMember.value = res.data             
            }
        })
}
// 多选
const ids = ref([])
const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
    ids.value = val.map(item => item.resourceId)
    multipleSelection.value = val
};
// 批量禁用
const allDisabled = () => {
    if (multipleSelection.value.length == 0) {
        message.error('请选择要禁用的资源');
    } else {
        ElMessageBox.confirm('确定要禁用这些资源吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        })
            .then(() => {
                api.manage
                    .resourceDisableApi({
                        departmentId: props.tableId,
                        resourceList: ids.value,
                    })
                    .then((res) => {
                        if (res.returnCode == 200) {
                            message.success('禁用成功');
                            getUserTable()
                        } else {
                            message.error('禁用失败');
                        }
                    });
            })
    }
}
// 禁用按钮
const handleClick = (row) => {
    api.manage
        .resourceDisableApi({
            departmentId: props.tableId,
            resourceList: [{
                resourceType: row.resourceType,
                configurationId: row.configurationId,
                functionTypeId: row.functionTypeId,
            }]
        })
        .then((res) => {
            if (res.returnCode == 200) {
                message.success('禁用成功')
                getUserTable()
            }
        })

};
// 启用按钮
const handleEnable = (row) => {
    api.manage
        .resourceEnableApi({
            departmentId: props.tableId,
            resourceList: [{
                resourceType: row.resourceType,
                configurationId: row.configurationId,
                functionTypeId: row.functionTypeId,
                BlockId: row.BlockId,
            }]
        })
        .then((res) => {
            if (res.returnCode == 200) {
                message.success('启用成功')
                getUserTable()
            }
        })
};
// 配额-单选
const editNum = ref(true);
// 编辑配额
const editNumBtn = () => {
    editNum.value = false;
};
const limit = ref({
})
const newObj = ref({})
const changeInput = (e) => {
    limit.value[e.limit.limitType] = e.limit.value;
    Object.assign(newObj.value, limit.value)
};
// 保存配额编辑
const submitNumBtn = () => {
    console.log(newObj.value, '完成编辑')
    editNum.value = true;
    api.manage
        .quotaEditApi({
            userId: userId.value,
            limits: newObj.value,
            departmentId: props.tableId,
        })
        .then((res) => {
            if (res.returnCode === 200) {
                message.success('配额编辑成功')
                getUserTable()
                newObj.value = {}
            }
        })
};
// 禁用当前资源平台
const disabledType = () => {
    api.manage
        .enableApi({
            userId: userId.value,
            departmentId: props.tableId,
            resourceType: listTable.value.resourceType,
            isForbidden: 1
        })
        .then((res) => {
            if (res.returnCode === 200) {
                message.success('该资源平台禁用成功')
                getUserTable()
            }
        })
};
const btnType = ref(true);
const changeType = (item) => {
    console.log({
        userId: userId.value,
        departmentId: props.tableId,
        resourceType: item,
    });
    api.manage
        .resourceStatusApi({
            userId: userId.value,
            departmentId: props.tableId,
            resourceType: item,
        })
        .then((res) => {
            if (res.returnCode === 200) {
                console.log(res.data);
                if (res.data.isForbidden === 0) {
                    btnType.value = true
                } else {
                    btnType.value = false
                }
            }
        })
};
// 启用当前资源平台
const enableType = () => {
    api.manage
        .enableApi({
            userId: userId.value,
            departmentId: props.tableId,
            resourceType: listTable.value.resourceType,
            isForbidden: 0
        })
        .then((res) => {
            if (res.returnCode === 200) {
                message.success('该资源平台启用成功')
                getUserTable()
            }
        })
};
</script>
<template>
    <div class="mian">
        <div class="dashboard">
            <div class="companyName">{{ tableName }}</div>
        </div>
        <div class="search">
            <div class="searchLeft">
                <!-- 资源平台 -->
                <div class="searchItem">
                    <el-select v-model="status" clearable @change="statusBtn">
                        <template #label="{ label, value }">
                            <div style="display: flex; justify-content: space-between;">
                                <div>资源平台： </div>
                                <div>{{ label }}</div>
                            </div>
                        </template>
                        <el-option v-for="item in statusList" :key="item.value" :label="item.label"
                            :value="item.value" />
                    </el-select>
                </div>
            </div>
            <!-- 功能区 -->
            <div style="display: flex;">
                <div class="toolBox" style="color: #F02D63;" @click="allDisabled">
                    {{ $t('manage.resoureForm.bulkDisable') }}
                </div>
                <div v-if="status !== '全部'">
                    <div class="toolBox" style="color: #F02D63;" @click="disabledType" v-if="btnType == true">
                        {{ $t('manage.resoureForm.disableCurrent') }}
                    </div>
                    <div class="toolBox" style="color: #11D7B2;" @click="enableType" v-if="btnType == false">
                        启用当前资源平台
                    </div>
                </div>
                <div class="toolBox addUser" style="background-color: #767bfa;" @click="submitNumBtn" v-if="!editNum">
                    完成编辑
                </div>
                <div class="toolBox addUser" @click="editNumBtn" v-if="editNum">
                    {{ $t('manage.resoureForm.editQuota') }}
                </div>
            </div>
        </div>
        <el-table :data="tableMember" @selection-change="handleSelectionChange" class="tableHeight">
            <el-table-column type="selection" />
            <el-table-column prop="name" :label="$t('manage.resoureForm.resourceName')" />
            <el-table-column prop="resourceType" label="资源平台">
                <template #default="scope">
                    <div class="flex gap-2">
                        <el-tag v-if="scope.row.resourceType === 0">Azure</el-tag>
                        <el-tag type="success" v-else-if="scope.row.resourceType === 1">baidu</el-tag>
                        <el-tag type="warning" v-else-if="scope.row.resourceType === 2">阿里</el-tag>
                        <el-tag type="danger" v-else>其他</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="functionType" :label="$t('manage.resoureForm.resourceType')" />
            <el-table-column prop="isBlocked" label="状态">
                <template #default="scope">
                    <div class="flex gap-2">
                        <el-tag v-if="scope.row.isBlocked === 0">启用</el-tag>
                        <el-tag v-else type="info">禁用</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="parent_department_limit" :label="$t('manage.resoureForm.inheritQuota')" />
            <el-table-column prop="limit" label="用户日配额">
                <template #default="scope">
                    <el-input v-model="scope.row.limit.value" :disabled="editNum" @blur="changeInput(scope.row)">
                        <template #append v-if="scope.row.limit.limitType === 'limitAliImageTimes'"> 张</template>
                        <template #append v-if="scope.row.limit.limitType === 'limitBaiduImageTimes'"> 张</template>
                        <template #append v-if="scope.row.limit.limitType === 'limitDocumentChatNum'"> 个</template>
                        <template #append v-if="scope.row.limit.limitType === 'limitDocumentNum'"> 个</template>
                        <template #append v-if="scope.row.limit.limitType === 'limitImageTimes'"> 张</template>
                        <template #append v-else>令牌</template>
                    </el-input>
                </template>
            </el-table-column>
            <el-table-column :label="$t('manage.resoureForm.operation')" class="operation">
                <template #default="scope">
                    <div style="display: flex;">
                        <div class="remove" @click="handleClick(scope.row)">
                            {{ $t('manage.resoureForm.disable') }}
                        </div>
                        <div class="remove1" :disabled="scope.row.BlockId === -1" @click="handleEnable(scope.row)">
                            启用
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <Pagination :background="true" :currentPage="currentPage" :pageSize="pageSize" :total="totalItems"
            @size-change="updatePageSize" @current-change="updateCurrentPage" />
    </div>
</template>

<style scoped lang="scss">
.search {
    :deep(.el-select__wrapper) {
        border-radius: 16px !important;
        height: 48px;
        width: 160px !important;
        font-size: 16px !important;
    }
}

:deep(.el-input__wrapper) {
    border-radius: 2px !important;
}

:deep(.el-input-group__append) {
    padding: 0 6px !important;
    width: 100px !important;
    color: #8A8A8A !important;
}

:deep(.el-checkbox) {
    height: 30px;
}

.main {
    width: 100%;
    height: 100%;

    .operation {
        width: 200px;
    }
}

.tableHeight {
    height: 510px;

    .remove {
        width: 50px;
        height: 30px;
        border-radius: 8px;
        line-height: 30px;
        color: #F02D63;
        text-align: center;
        background-color: #F7F8FF;
        cursor: pointer;
        margin-right: 10px;
    }

    .remove1 {
        width: 50px;
        height: 30px;
        border-radius: 8px;
        line-height: 30px;
        color: #0256FF;
        text-align: center;
        background-color: #F7F8FF;
        cursor: pointer;
    }
}

.dialog {
    width: 200px;
}

.changeMore {
    margin-left: 5px;

    .moreIcon {
        width: 24px;
        height: 24px;

        img {
            display: block;
            width: 16px;
            height: 16px;
            margin: 4px auto;
        }
    }
}


.dashboard {
    margin-top: 20px;
    margin-bottom: 8px;
    display: flex;
    position: relative;

    .companyName {
        font-size: 22px;
        color: #3D3D3D;
        margin-right: 32px;
        font-weight: 600;
    }

    .companyNum {
        font-size: 18px;
        color: #8A8A8A;
        margin-right: 16px;
        margin-top: 4px;

        .num {
            color: #3D3D3D;
        }
    }
}

.search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;

    .searchLeft {
        display: flex;

        .date {
            height: 44px;
            line-height: 44px;
            border: #edeef6 solid 2px;
            border-radius: 14px;
            font-size: 18px;
            display: flex;
            font-size: 18px;
            text-align: center;
            padding: 0 18px;
            cursor: pointer;

            div:first-child {
                margin-right: 10px;
                color: #8a8a8a;
            }
        }
    }
}

.ellipsis {
    font-size: 16px;
    min-width: 44px;
}

.searchDiv {
    border: #edeef6 solid 2px;
    border-radius: 16px;
    font-size: 16px;
    padding: 0 10px;
    margin-left: 10px;
    cursor: pointer;
}

.toolBox {
    border: #edeef6 solid 2px;
    border-radius: 16px;
    font-size: 16px;
    padding: 0 10px;
    height: 42px;
    line-height: 40px;
    color: #8A8A8A;
    margin-right: 10px;
    cursor: pointer;
}

.searchSpan {
    color: #3D3D3D;
    font-size: 16px;
    width: 100%;
    display: flex;
    margin-top: 13px;
}

.searchIcon {
    margin-left: 10px;
}

.searchDiv2 {
    border: #edeef6 solid 2px;
    border-radius: 16px;
    font-size: 16px;
    padding: 6px;
    margin-left: 10px;
    cursor: pointer;

    .toolImg {
        width: 32px;
        height: 32px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.searchInput {
    margin-right: 10px;
    height: 44px;
}

.addUser {
    background-color: #0256FF;
    color: #fff;
    margin-right: 0;
}
</style>
<script setup>
import { ref, defineProps, watch, onMounted, defineEmits } from "vue";
import { useStore } from "vuex";
import api from "@/api/index";
import { message } from "ant-design-vue"
const store = useStore();
const userId = ref(store.state.userId);
const emit = defineEmits(['handleClose', 'editSave','handleSave']);
const props = defineProps({
    show: Boolean,
    groupInfo: Object,
    formType: String,
});
onMounted(() => {

})
const users = ref([]);
watch(
    () => users.value,
    (newVal) => {
        users.value = newVal
    }
)
watch(
    () => props.groupInfo,
    (newVal) => {
        props.groupInfo = newVal
        users.value = newVal.users
    }
)
watch(
    () => props.formType,
    (newVal) => {
        props.formType = newVal
    }
)
const formRef = ref(null);
const rules = ref({
    groupName: [
        { required: true, message: '请输入群组名称', trigger: 'blur' },
    ],
    groupDescription: [
        { required: true, message: '请输入群组描述', trigger: 'blur' },
    ],
    userIdList: [
        { required: true, message: '请选择群组成员', trigger: 'blur' },
    ]
})
const showAddDialog = ref(false)
const handleAddUser = () => {
    showAddDialog.value = true
}
const ids = ref([])
// 确定保存
const saveUser = (arr) => {
    users.value = [...arr, ...users.value]
    showAddDialog.value = false
}
// 关闭编辑
const handleClose = () => {
    emit('handleClose')
}
const newForm = ref({})
//保存新增
const handleSave = async (formRef) => {
    newForm.value.userIdList = ids.value
    newForm.value.userId = userId.value
    newForm.value.groupName = props.groupInfo.groupName
    await formRef.validate((valid) => {
        if (valid) {
            emit('handleSave', newForm.value)            
        }
    })
};
// 确定修改
const editSave = async (formRef) => {
    ids.value = users.value.map((item) => item.userId)
    newForm.value.userIdList = ids.value
    newForm.value.userId = userId.value
    newForm.value.groupName = props.groupInfo.groupName
    await formRef.validate((valid) => {
        if (valid) {
            api.manage
                .editGroupApi(newForm.value, props.groupInfo.groupId)
                .then((res) => {
                    if (res.returnCode == 200) {
                        message.success('修改成功')
                        emit('handleClose')
                    } else {
                        message.error('修改失败')
                        emit('handleClose')
                    }
                })
        }
    })
};
</script>

<template>
    <div>
        <el-dialog v-model="props.show" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>{{ props.formType == 'add' ? '群组新增' : '群组编辑' }}</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleClose" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <!-- 添加用户弹窗 -->
            <addUser :show="showAddDialog" @closeUser="showAddDialog = false" @saveUser="saveUser" :userList="users">
            </addUser>
            <div class="content">
                <el-form :model="groupInfo" class="form" :rules="rules" ref="formRef" label-position="top">
                    <el-form-item prop="groupName" label="群组名称">
                        <el-input v-model="groupInfo.groupName" placeholder="群组名称"></el-input>
                    </el-form-item>
                    <el-form-item prop="groupDescription" label="群组描述" class="userAdd">
                        <el-input class="comtent" v-model="groupInfo.groupDescription" placeholder="群组描述"></el-input>
                    </el-form-item>
                    <el-form-item prop="users" label="群组成员" class="userAdd" @click="handleAddUser">
                        <div class="btnTag" v-if="!showAddDialog">添加成员</div>
                        <el-table :data="users" class="table">
                            <el-table-column prop="userName" label="用户名" />
                            <el-table-column prop="email" label="邮箱" />
                            <el-table-column prop="departmentName" label="部门" />
                        </el-table>
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <div class="dialog-footer" v-if="props.formType === 'add'">
                    <div @click="handleClose" class="cancel">放弃</div>
                    <div @click="handleSave(formRef)" class="confirm">添加</div>
                </div>
                <div class="dialog-footer" v-if="props.formType === 'edit'">
                    <div @click="handleClose" class="cancel">取消</div>
                    <div @click="editSave(formRef)" class="confirm">确定</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>



<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-dialog) {
    --el-dialog-width: 600px;
    border-radius: 30px;
    padding: 30px;
}

:deep(.el-input__wrapper) {
    height: 38px !important;
    width: 520px !important;
    margin-bottom: 10px !important;
}

:deep(.el-select__wrapper) {
    height: 42px !important;
    border-radius: 16px !important;
    width: 540px !important;
    margin-bottom: 10px !important;
}

:deep(.el-form-item__label) {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #3D3D3D !important;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 24px;
    position: relative;
}

.userAdd {
    position: relative;
    margin-top: 14px;

    .btnTag {
        position: absolute;
        right: 10px;
        top: -36px;
        z-index: 777;
        color: #fff;
        border-radius: 8px;
        padding: 0 8px;
        background-color: #0D6EFD;
        cursor: pointer;
    }
}

.close-icon {
    font-size: 20px;
}

.content {
    font-size: 18px;
    color: #8A8A8A;

    .name {
        color: #3D3D3D;
        font-size: 24px;
        margin: auto 6px auto 18px;
        font-weight: 500;
    }
}

.form {
    margin-top: 10px;
}

.form-name {
    font-size: 18px;
    color: #3D3D3D;
    display: flex;
    justify-content: space-between;

    .tit {
        margin-bottom: 10px;
        font-weight: 600;
    }
}

.isActive {
    margin-right: 6px;
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #F02D63;
        padding: 6px 12px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 6px 12px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
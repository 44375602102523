<template>
  <div class="addContent">
    <div class="titleFlex">
      <div class="addTitle">
        {{ props.addType == "add" ? "新建AI知识库" : "编辑AI知识库" }}
      </div>
      <img :src="addClose" alt="" class="addClose" @click="closeAdd" />
    </div>
    <div class="addBody">
      <!-- 选择模式 -->
      <div class="flexBetWeen marginBtomm20px">
        <div
          v-for="(item, index) in navList"
          :key="item"
          :class="form.type == index ? 'itemDivNavActive' : 'itemDivNav'"
          @click="changeNav(index)"
        >
          <img :src="item.url" alt="" class="navImg" />
          <div :class="form.type == index ? 'itemTextActive' : 'itemText'">
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- 表单 -->
      <div>
        <span class="addLabel nameLabel">名称</span>
      </div>
      <el-input
        class="itemInput marginBtomm20px"
        maxlength="100"
        show-word-limit
        placeholder="微模型名称不能为空"
        v-model="form.name"
      ></el-input>
      <div class="addLabel">描述</div>
      <el-input
        v-model="form.desc"
        class="itemInput marginBtomm20px"
        maxlength="2000"
        show-word-limit
        placeholder="输入微模型主题、内容或作用的简要描述"
      ></el-input>
      <div class="addLabel marginBtomm20px">知识来源</div>
      <div class="flexBetWeen marginBtomm20px">
        <div
          v-for="(item, index) in upTypeList"
          :key="index"
          @click="changeFile(index)"
          :class="fileActive == index ? 'bottomFileActive' : 'bottomFile'"
        >
          <div class="flexCenter">
            <img class="fileImg" :src="item.icon" alt="" />
            <div :class="fileActive == index ? 'itemTextActive' : 'itemText'">
              {{ item.name }}
            </div>
          </div>
          <div :class="fileActive == index ? 'fileTipActive' : 'fileTip'">
            {{ item.tip }}
          </div>
        </div>
      </div>
      <div class="marginBtomm20px flexBetWeen">
        <span class="addLabel nameLabel">资源平台</span>
        <span class="ptTip"
          >您可以选择一个或多个平台的资源分析处理您上传的文档</span
        >
      </div>
      <div class="marginBtomm20px flexBetWeen">
        <div
          v-for="item in ptList"
          :key="item.id"
          :class="getPt(item.id) ? 'ptDivItemActive' : 'ptDivItem'"
          @click="clickPt(item.id)"
        >
          <img :src="item.icon" alt="" class="ptImg" />
        </div>
      </div>
      <div class="marginBtomm20px addLabel">图标</div>
      <div class="flexCenter marginBtomm20px">
        <div class="step2Img">
          <img :src="bImg" alt="" class="stepImgIcon" />
        </div>
        <div class="step2RightImg">
          <div class="stepImgAi">
            <div class="aiImgIcon">
              <img :src="imgAi" alt="" class="wenshengtuImg" />
            </div>
            <div class="aiImgTitle">AI图像生成</div>
          </div>
        </div>
      </div>
      <div class="marginBtomm20px">
        <span class="addLabel"
          >默认智能助手<el-icon class="tipIcon"><Warning /></el-icon
        ></span>
      </div>
      <el-select
        class="marginBtomm20px elSelete"
        placeholder="请选默认智能助手"
        v-model="form.promptId"
      >
        <el-option
          v-for="item in botList"
          :key="item.templateId"
          :label="item.templateName"
          :value="item.templateId"
        />
      </el-select>
      <div class="marginBtomm20px flexBetweenAdd">
        <span class="addLabel">成员 </span>
        <el-button class="addUserBtn" @click="addUser">添加成员</el-button>
      </div>
      <el-row class="rightTable">
        <el-col :span="10">用户/部门/群组</el-col>
        <el-col :span="8"
          >权限<el-icon class="tipIcon"><Warning /></el-icon
        ></el-col>
        <el-col :span="6">操作</el-col>
      </el-row>
      <el-row
        class="tableItem marginBtomm20px"
        v-for="(item, index) in form.shareUserIds"
        :key="item.id"
      >
        <el-col :span="10">
          <img :src="admin1" alt="" class="tableIcon" />
          <span class="tableTitleNmae">{{ item.name }}</span>
        </el-col>
        <el-col :span="8">
          <div class="tableRoleType" v-if="item.type == 1">
            <div class="tableLogo">主</div>
            <div class="tableRole">拥有者</div>
          </div>
          <div class="tableRoleType1" v-if="item.type == 2">
            <div class="tableLogo1">创</div>
            <div class="tableRole1">创作者</div>
          </div>
          <div class="tableRole" v-if="item.type == 0">普通成员</div>
        </el-col>
        <el-col :span="6">
          <el-button class="deleUserBtn" @click="deleteUser(item, index)"
            >移除</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="addBottomBtn">
      <el-button class="closeBtn" @click="closeAdd">取消</el-button>
      <el-button
        class="nextBtn"
        v-if="props.addType == 'add'"
        @click="save"
        :loading="props.addLoading"
      >
        创建
      </el-button>
      <el-button
        class="nextBtn"
        v-else
        @click="save"
        :loading="props.addLoading"
      >
        保存
      </el-button>
    </div>
  </div>
  <div class="mask"></div>
  <userView
    v-if="addDialog"
    :userList="userList"
    @closeUser="addDialog = false"
    @saveUser="saveUser"
  ></userView>
</template>

<script setup>
import item1 from "@/assets/base/item1.svg";
import excelImg from "@/assets/base/excel.svg";
import zhImg from "@/assets/base/zh.svg";
import upFile from "@/assets/base/upFile.svg";
import upServe from "@/assets/base/upServe.svg";
import bImg from "@/assets/base/b.svg";
import imgAi from "@/assets/base/img.svg";
import admin from "@/assets/base/admin.svg";
import admin1 from "@/assets/base/admin1.svg";
import addClose from "@/assets/base/addClose.svg";
import azure from "@/assets/base/azure.svg";
import aliyun from "@/assets/base/aliyun.svg";
import baidu from "@/assets/base/baidu.svg";
import { ref, defineEmits, onMounted, defineProps } from "vue";
import { useStore } from "vuex";
import api from "@/api";
import { message } from "ant-design-vue";
import userView from "./user.vue";
const props = defineProps(["addType", "addLoading", "editObj"]);
const emit = defineEmits(["closeAdd", "saveAdd", "saveEdit"]);
const store = useStore();
const fileActive = ref(0);
const userId = ref(store.state.userId);
const navList = ref([
  {
    name: "文本模式",
    url: item1,
  },
  {
    name: "表格模式",
    url: excelImg,
  },
  {
    name: "综合模式",
    url: zhImg,
  },
]);
const upTypeList = ref([
  {
    name: "本地文档",
    icon: upFile,
    tip: "上传PDF, TXT, MD, DOC, DOCX, XLS, XLSX",
  },
  // {
  //   name: "文件服务器",
  //   icon: upServe,
  //   tip: "导入本地文件服务器的文档目录",
  // },
]);
const ptList = ref([
  {
    id: "Azure",
    icon: azure,
  },
  {
    id: "Ali",
    icon: aliyun,
  },
  {
    id: "Baidu",
    icon: baidu,
  },
]);
const form = ref({
  userId: store.state.userId,
  name: "", //微模型名称
  desc: "", //微模型描述
  type: 0, //微模型类型
  promptId: null, //提示词ID
  shareUserIds: [], //知识库成员
  resourcePlatform: [], //资源平台
});
const botPageId = ref(1); //智能体页数
const botPerPage = ref(10); //智能体分页
const templatePattern = ref(""); //智能体模糊查询
const botList = ref([]);
const addDialog = ref(false);
const userList = ref({
  departmentList: [],
  userList: [],
  gropuList: [],
}); //回显
onMounted(() => {
  getBotList();
  // 新增
  if (props.addType == "add") {
    // 新增设置本人为主创
    form.value.shareUserIds.push({
      userId: userId.value,
      id: userId.value,
      type: 1, //1创建者 2创作者 0普通成员
      userName: store.state.userName,
      name: store.state.userName,
      ownerType: 0, //0个人 1部门 2群组
    });
  } else {
    form.value.name = props.editObj.knowledge_name;
    form.value.desc = props.editObj.knowledge_desc;
    form.value.type = props.editObj.knowledge_type;
    form.value.promptId = props.editObj.prompt_value.promptId;
    if (props.editObj.userInfo.length != 0) {
      props.editObj.userInfo.forEach((item) => {
        form.value.shareUserIds.push({
          userId: item.ownerId,
          id: Number(item.ownerId),
          type: item.type, //1创建者 2创作者 0普通成员
          userName: item.ownerName,
          name: item.ownerName,
          ownerType: item.ownerType, //0个人 1部门 2群组
        });
      });
    }
    if (props.editObj.aliEmbeddingOn == 1) {
      form.value.resourcePlatform.push("Ali");
    }
    if (props.editObj.azureEmbeddingOn == 1) {
      form.value.resourcePlatform.push("Azure");
    }
    if (props.editObj.baiduEmbeddingOn == 1) {
      form.value.resourcePlatform.push("Baidu");
    }
  }
});
// 确定保存
const saveUser = (arr) => {
  form.value.shareUserIds = form.value.shareUserIds.concat(arr);
  // 去重
  const uniqueMap = new Map(
    form.value.shareUserIds.map((item) => [item.id, item])
  );
  form.value.shareUserIds = Array.from(uniqueMap.values());
  addDialog.value = false;
};
//添加成员
const addUser = () => {
  // 判断部门 群组  人
  // 使用reduce方法将ownerType为0，1，2的对象分别筛选出来
  const result = form.value.shareUserIds.reduce((acc, item) => {
    const { ownerType } = item;
    if (!acc[ownerType]) {
      acc[ownerType] = [];
    }
    acc[ownerType].push(item);
    return acc;
  }, {});

  // 将结果转换为数组
  userList.value.userList = result[0] || [];
  userList.value.departmentList = result[1] || [];
  userList.value.gropuList = result[2] || [];
  addDialog.value = true;
};
// 移除成员
const deleteUser = (item, index) => {
  if (props.addType == "add") {
    form.value.shareUserIds.splice(index, 1);
  } else {
    console.log({
      userId: userId.value,
      libraryId: props.editObj.knowledge_library_id,
      changeOwnerId: item.id,
      changeOwnerType: item.ownerType,
    });
    api.base
      .userDelete({
        userId: userId.value,
        libraryId: props.editObj.knowledge_library_id,
        changeOwnerId: item.id,
        changeOwnerType: item.ownerType,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success("移除成功");
          form.value.shareUserIds.splice(index, 1);
        }
      });
  }
};
// 获取智能体列表
const getBotList = () => {
  api.base
    .getBot({
      userId: userId.value,
      pageId: botPageId.value,
      perPage: botPerPage.value,
      templatePattern: templatePattern.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        botList.value = res.data;
        if (props.addType == "edit") {
          const hasTemplateId2086 = botList.value.some(
            (template) =>
              template.templateId === props.editObj.prompt_value.promptId
          );
          if (!hasTemplateId2086) {
            botList.value.push({
              templateDesc: "",
              templateId: props.editObj.prompt_value.promptId,
              templateName: props.editObj.prompt_value.promptName,
              userResults: 0,
            });
          }
        }
      }
    });
};
// 判断有没有这个id 有就返回true  class判断
const getPt = (id) => {
  // 查找数组中是否存在该id
  const index = form.value.resourcePlatform.findIndex((item) => item === id);
  if (index !== -1) {
    return true;
  } else {
    return false;
  }
};
// 选择的平台
const clickPt = (id) => {
  if (props.addType == "add") {
    // 查找数组中是否存在该id
    const index = form.value.resourcePlatform.findIndex((item) => item === id);
    if (index !== -1) {
      // 如果存在，删除该id
      form.value.resourcePlatform.splice(index, 1);
    } else {
      // 如果不存在，添加该id到数组中
      form.value.resourcePlatform.push(id);
    }
  }
};
// 取消
const closeAdd = () => {
  emit("closeAdd");
};
// 切换选择
const changeNav = (index) => {
  form.value.type = index;
};
const changeFile = (index) => {
  fileActive.value = index;
};
// 保存
const save = () => {
  if (!form.value.name) {
    message.warning("微模型名称不能为空");
    return;
  }
  if (!form.value.desc) {
    message.warning("输入微模型主题、内容或作用的简要描述");
    return;
  }
  if (form.value.resourcePlatform.length == 0) {
    message.warning("您至少选择一个资源平台");
    return;
  }
  let newForm = JSON.parse(JSON.stringify(form.value));
  newForm.shareUserIds = newForm.shareUserIds.map((item) => {
    return {
      id: item.id,
      name: item.name,
      type: item.type,
      ownerType: item.ownerType,
    };
  });
  if (props.addType == "add") {
    emit("saveAdd", newForm);
  } else {
    emit("saveEdit", newForm);
  }
};
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 10;
  opacity: 0.2;
}
.addContent {
  position: absolute;
  width: 540px;
  height: 700px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 11;
  padding: 30px;
  border-radius: 30px;
}
.addTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}
.addBottomBtn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
}
.closeBtn {
  height: 44px;
  border: 2px solid #edeef6;
  border-radius: 16px;
  font-size: 18px;
  color: #f02d63;
  font-family: "Regular";
  padding: 12px 20px;
}
.nextBtn {
  height: 48px;
  border-radius: 16px;
  color: #fefefe;
  background-color: #3376ff;
  border: 0px;
  font-size: 18px;
  font-family: "Regular";
  padding: 12px 20px;
}
.addBody {
  height: calc(100% - 120px);
  overflow: auto;
  padding: 5px 5px;
}
.navImg {
  width: 28px;
  height: 28px;
}
.itemText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
}
.itemTextActive {
  font-size: 18px;
  color: #0256ff;
  font-family: "Medium";
}
.itemDivNav {
  width: 163px;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 18px;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  cursor: pointer;
}
.itemDivNavActive {
  width: 163px;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 18px;
  border: 2px solid #eceeff;
  background-color: #eceeff;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  cursor: pointer;
}

.flexBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.marginBtomm20px {
  margin-bottom: 12px;
}
.addLabel {
  font-size: 18px;
  font-family: "Regular";
  color: #3d3d3d;
  position: relative;
}
.nameLabel::before {
  position: absolute;
  content: "*";
  color: #f02d63;
  right: -15px;
}
.itemInput {
  height: 48px;
  margin-top: 10px;
  font-size: 18px;
  font-family: "Regular";
}
:deep(.el-input .el-input__count) {
  color: #8a8a8a;
  font-size: 18px;
}
.fileImg {
  width: 23px;
  margin-right: 6px;
}
.fileTip {
  font-size: 16px;
  color: #8a8a8a;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileTipActive {
  font-size: 16px;
  color: #a3acec;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.bottomFileActive {
  width: calc(49.5% - 44px);
  height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #eceeff;
  padding: 14px 20px;
  border-radius: 18px;
  border: 2px solid #dbdfff;
  cursor: pointer;
}
.bottomFile {
  width: calc(49.5% - 44px);
  height: 79px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px;
  border-radius: 18px;
  border: 2px solid #d9d9d9;
  cursor: pointer;
}
.step2Img {
  width: 80px;
  height: 80px;
  border-radius: 18px;
  border: 2px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #edeef6;
}
.stepImgIcon {
  width: 60px;
}
.step2RightImg {
  background-color: #f7f8ff;
  height: 60px;
  border-radius: 18px;
  margin-left: 10px;
  width: calc(100% - 130px);
  padding: 12px 18px;
  display: flex;
  align-items: center;
}
.stepImgAi {
  border-radius: 12px;
  height: 28px;
  background-color: #eceeff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
}
.aiImgIcon {
  width: 36px;
  height: 36px;
  background-color: #dbdfff;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.aiImgTitle {
  font-size: 18px;
  color: #0256ff;
  font-family: "Medium";
}
.tipIcon {
  margin-left: 10px;
  font-size: 16px;
}
.elSelete {
  :deep(.el-select__wrapper) {
    font-size: 18px;
    height: 44px;
    border-radius: 18px;
    border: 2px solid #d9d9d9;
    color: #3d3d3d;
    box-shadow: 0px 0px 0px;
  }
}
.rightTable {
  height: 60px;
  background-color: #f7f8ff;
  line-height: 60px;
  border-top: 2px solid #edeef6;
  border-bottom: 2px solid #edeef6;
  font-size: 18px;
  color: #8a8a8a;
  :deep(.el-col-8) {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
}
.tableItem {
  height: 60px;
  line-height: 60px;
  border-bottom: 2px solid #edeef6;
  font-size: 18px;
  color: #8a8a8a;
  :deep(.el-col-8) {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
}
.tableIcon {
  margin-right: 15px;
  width: 24px;
}
.tableTitleNmae {
  color: #3d3d3d;
  font-size: 18px;
  width: calc(100% - 46px);
  display: inline-block;
}
.tableLogo {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  background-color: #0256ff;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  font-family: "Regular";
}
.tableRole {
  color: #0256ff;
  font-size: 18px;
  font-family: "Regular";
  margin-left: 10px;
}
.tableRole2 {
  color: #3d3d3d;
  font-size: 18px;
  font-family: "Regular";
  margin-left: 10px;
}
.tableLogo1 {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  background-color: #ffc300;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  font-family: "Regular";
}
.tableRole1 {
  color: #ffc300;
  font-size: 18px;
  font-family: "Regular";
  margin-left: 10px;
}
.tableRoleType {
  display: flex;
  width: 115px;
  height: 40px;
  align-items: center;
  background-color: #b2ccfe;
  border-radius: 8px;
  justify-content: center;
}
.tableRoleType1 {
  display: flex;
  width: 115px;
  height: 40px;
  align-items: center;
  background-color: #feecb2;
  border-radius: 8px;
  justify-content: center;
}
.wenshengtuImg {
  width: 21px;
}
.titleFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 20px;
}
.addClose {
  width: 14px;
  cursor: pointer;
}
.addUserBtn {
  height: 36px;
  border-radius: 12px;
  background-color: #3376ff;
  color: #ffffff;
  font-size: 18px;
  font-family: "Regular";
  border: 0px;
  padding: 6px 20px;
}
.flexBetweenAdd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ptTip {
  color: #8a8a8a;
  font-size: 16px;
  font-family: "Medium";
}
.ptImg {
  height: 25px;
}
.ptDivItem {
  width: 167px;
  height: 56px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ptDivItemActive {
  width: 167px;
  height: 56px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #dbdfff;
}
.deleUserBtn {
  height: 31px;
  border-radius: 8px;
  background-color: #f7f8ff;
  border: 0px;
  font-size: 18px;
  color: #f02d63;
  font-family: "Regular";
}
</style>

<script setup>
import { ref, defineProps, defineEmits, onMounted, watch, reactive } from 'vue'
import { useI18n } from "vue-i18n"
import api from "@/api/index";
import { useStore } from "vuex";
import { formatTime } from "@/utils/qincheng";
const store = useStore();
const userId = ref(store.state.userId);
const { t } = useI18n();
const props = defineProps({
    showExport: Boolean,
});
onMounted(() => {
    getDepartment()
    getRole()
    getIps()
    allOperationBtn()
    allModuleBtn()
})
const emits = defineEmits(['exportClose', 'exportSave']);
const handleClose = () => {    
    emits('exportClose')
};
const handleSave = () => {    
    emits('exportSave',form.value)
};
// 导出表单
const form = ref({
    userId: userId.value,
    namePattern: '',
    RoleId: '',
    ips: '',
    departmentId: '',
    operationType: '',
    operationModule: '',
    operationResult: '',
    operationStartDate: '',
    operationEndDate: '',
});
// ips
const ipsItem = ref('')
const ips = ref([]);
const getIps = () => {
    api.log.getIps({ userId: userId.value }).then((res) => {
        if (res.returnCode == 200) {
            ips.value = res.data;
        }
    });
};
// 获取角色
const roleList = ref([]);
const getRole = () => {
    api.log
        .queryUserRole({ userId: userId.value, pageId: 0 })
        .then((res) => {
            if (res.returnCode === 200) {
                // 不能有超级管理员
                roleList.value = res.data;
            }
        });
};
// 获取部门
const defaultProps = ref({
    children: "children",
    label: "departmentName",
    id: "departmentId",
});
const departmentList = ref([{
    parentId: null,
    departmentName: '',
    departmentId: null,
    children: []
}]);
const getDepartment = () => {
    api.log
        .getDepartmentName({ userId: userId.value, pageId: 0 })
        .then((res) => {
            if (res.returnCode === 200) {
                departmentList.value = res.data;
            }
        });
};
// 操作模块
const allModuleList = ref([])
const allModuleBtn = () => {
    api.log
        .getLogger({
            userId: userId.value,
        })
        .then((res) => {
            allModuleList.value = res.data
        })
};
// 操作类型
const allOperationList = ref([]);
const allOperationBtn = () => {
    api.log
        .getType({
            userId: userId.value,
        })
        .then((res) => {
            allOperationList.value = res.data;
        })
};
// 操作时间
const timeDate = ref([]);
const handleTime = (value) => {
    if (value != null) {
        form.value.operationStartDate = formatTime(value[0])
        form.value.operationEndDate = formatTime(value[1])
    }
};
</script>
<template>
    <div class="logExport">
        <el-dialog v-model="props.showExport" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>导出</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleClose" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <el-form :model="form" class="flexDiv">
                <!-- 名称 -->
                <el-form-item prop="namePattern">
                    <div> 用户名/邮箱</div>
                    <el-input v-model="form.namePattern" placeholder="请输入用户名/邮箱" />
                </el-form-item>
                <el-form-item prop="RoleId">
                    <div>
                        <div> 角色</div>
                        <el-select v-model="form.RoleId" clearable>
                            <el-option v-for="(items, index) in roleList" :key="index" :label="items.description"
                                :value="items.id" />
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item prop="ips">
                    <div>
                        <div> IP</div>
                        <el-select v-model="ipsItem" clearable>
                            <el-option v-for="(items, index) in ips" :key="index" :label="items" :value="items" />
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item prop="departmentId">
                    <div>
                        <div> 部门</div>
                        <el-tree-select :data="departmentList" v-model="form.departmentId" check-strictly
                            :render-after-expand="false" :props="defaultProps" node-key="departmentId">
                            <template #default="{ data }">
                                <div>
                                    {{ data.departmentName }}
                                </div>
                            </template>
                        </el-tree-select>
                    </div>
                </el-form-item>
                <el-form-item prop="operationType">
                    <div>
                        <div> 操作类型</div>
                        <el-select v-model="form.operationType" clearable>
                            <el-option v-for="(items, index) in allOperationList" :key="index" :label="items"
                                :value="items" />
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item prop="operationModule">
                    <div>
                        <div> 操作模块</div>
                        <el-select v-model="form.operationModule" clearable>
                            <el-option v-for="(items, index) in allModuleList" :key="index" :label="items"
                                :value="items" />
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item prop="operationResult">
                    <div>
                        <div> 操作结果</div>
                        <el-select v-model="form.operationResult" clearable>
                            <el-option v-for="(items, index) in roleList" :key="index" :label="items.description"
                                :value="items.id" />
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item prop="namePattern">
                    <div> 操作时间</div>
                    <el-date-picker v-model="timeDate" type="daterange" unlink-panels range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" :clearable="false" :shortcuts="shortcuts"
                        @change="handleTime" />
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <div @click="handleClose" class="cancel">取消</div>
                    <div @click="handleSave" class="confirm">确定</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:deep(.el-dialog) {
    --el-dialog-width: 600px;
    border-radius: 30px;
    padding: 20px;
}

.logExport {
    :deep(.el-select) {
        width: 230px !important;
        height: 40px !important;
        margin-right: 24px;
        margin-bottom: 10px;
    }

    :deep(.el-select__wrapper) {
        width: 560px !important;
        height: 40px !important;
        border-radius: 30px;
    }

    :deep(.el-input__wrapper) {
        height: 42px !important;
        width: 500px !important;
        border-radius: 12px;
    }
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 18px;
    position: relative;
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #8A8A8A;
        padding: 8px 20px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 8px 20px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
<template>
  <div
    :class="
      (props.historyDialog && props.rightType) || lookDialog
        ? 'bottomIcon'
        : 'flexCenter'
    "
  >
    <div class="center">
      <div class="fillCenter" v-if="props.item.answers.length > 1">
        <img
          :src="item.chatActive != 1 ? leftFill : leftNoFill"
          alt=""
          class="fillImg"
          @click="changeItemPage(false)"
        />
        <span
          :class="item.chatActive == 1 ? 'fillColor' : ''"
          class="fillText"
          >{{ item.chatActive }}</span
        >
        <span
          class="fillText"
          :class="
            item.chatActive == props.item.answers.length ? 'fillColor' : ''
          "
          >/{{ props.item.answers.length }}</span
        >
        <img
          :src="
            item.chatActive == props.item.answers.length
              ? rightNoFill
              : rightFill
          "
          alt=""
          class="fillImg"
          @click="changeItemPage(true)"
        />
      </div>
      <div class="detailsBtn color1 btnImg">
        <img :src="setSearch" alt="" class="btnImg wid17" />
        <div>详情</div>
      </div>
      <div class="detailsBtn color2 btnImg">
        <img :src="setSearch" alt="" class="btnImg wid17" />
        <div>深度</div>
      </div>
      <div
        class="detailsBtn color3 marginRight"
        @click="openRequestverify"
        v-if="chatType == 'base'"
      >
        <img :src="setCap" alt="" class="btnImg wid21" />
        <div>请求验证</div>
      </div>
    </div>
    <div
      :class="
        props.historyDialog && props.rightType
          ? 'marginBottom center'
          : 'center'
      "
    >
      <div class="iconMargin" @click="copyFn">
        <img :src="copy" alt="" class="wid36" />
      </div>
      <div class="iconMargin" @click="releteTalk">
        <img :src="relete" alt="" class="wid36" />
      </div>
      <div class="iconMargin" @click="itemQa">
        <img :src="cToa" alt="" class="wid36" />
      </div>
      <div class="iconMargin" @click="itemLike" v-if="item.isCollected == 0">
        <img :src="setSq" alt="" class="wid36" />
      </div>
      <div class="iconMargin" @click="itemLike" v-else>
        <img :src="qxsc" alt="" class="wid36" />
      </div>
      <!--         :class="props.historyDialog ? 'iconMarginBg' : 'iconMargin'"
 -->
      <div class="iconMargin" @click="openHistory">
        <img :src="history" alt="" class="wid36" />
      </div>
      <el-tooltip placement="top">
        <template #content>
          LLM model:{{ descObj.model }}<br />
          response mode: 暂无<br />
          response token/s: {{ descObj.answerTokens }}<br />
          query token/s:{{ descObj.questionTokens }}<br />
          prompt token/s: {{ descObj.realQuestionTokens }}<br />
          internet search event/s: {{ descObj.internetCount }}<br />
          time: {{ getTime(descObj.createdTime) }}<br />
        </template>
        <div class="iconMargin" @mouseover="getDesc">
          <img :src="gantan" alt="" class="wid36" />
        </div>
      </el-tooltip>
      <div class="iconMargin" @click="deleteItem(item)">
        <img :src="cd" alt="" class="wid36" />
      </div>
      <div class="lineIcon">
        <img :src="line" alt="" class="widLine" />
      </div>
      <div class="iconMargin" @click="itemUp" v-if="item.isVoteUp == 0">
        <img :src="dz" alt="" class="wid36" />
      </div>
      <div class="iconMargin" @click="itemUp" v-else>
        <img :src="ydz" alt="" class="wid36" />
      </div>
      <div class="iconMargin" @click="itemDown" v-if="item.isVoteDown == 0">
        <img :src="qxdz" alt="" class="wid36" />
      </div>
      <div class="iconMargin" @click="itemDown" v-else>
        <img :src="ydc" alt="" class="wid36" />
      </div>
    </div>
    <!-- 验证 -->
    <div v-if="requestverify" class="capDialogClass">
      <div class="capTitle">
        <div>请求验证话题</div>
        <img class="crossSvug" :src="crossSvug" alt="" @click="closeCap" />
      </div>
      <div class="labelItem">
        <span class="capLabel">邀请验证人</span>
      </div>
      <div>
        <el-select v-model="peopleSel" multiple placeholder="请选择邀请验证人">
          <el-option
            v-for="item in seleteUserInfo"
            :key="item.ownerId"
            :label="item.ownerName"
            :value="item.ownerId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="labelItem">验证留言</div>
      <el-input
        placeholder="(选填) 向验证人简短描述你的验证需求"
        show-word-limit
        class="catText"
        type="textarea"
        v-model="leftNotes"
      />
      <div class="capBtnDiv">
        <el-button class="capCancel" @click="closeCap">取消</el-button>
        <el-button
          class="capSave"
          :loading="requestverifyLoading"
          @click="saveCap"
          >提交</el-button
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import setSearch from "@/assets/chat/setSearch.svg";
import setCap from "@/assets/chat/setCap.svg";
import copy from "@/assets/chat/copy.svg";
import relete from "@/assets/chat/relete.svg";
import cToa from "@/assets/chat/cToa.svg";
import setSq from "@/assets/chat/setSq.svg";
import history from "@/assets/chat/history.svg";
import gantan from "@/assets/chat/gantan.png";
import cd from "@/assets/chat/cd.svg";
import line from "@/assets/chat/line.svg";
import dz from "@/assets/chat/dz.svg";
import qxdz from "@/assets/chat/qxdz.svg";
import ydz from "@/assets/chat/ydz.svg";
import ydc from "@/assets/chat/ydc.svg";
import qxsc from "@/assets/chat/qxsc.svg";
import leftFill from "@/assets/chat/leftFill.svg";
import rightFill from "@/assets/chat/rightFill.svg";
import leftNoFill from "@/assets/chat/leftNoFill.svg";
import rightNoFill from "@/assets/chat/rightNoFill.svg";
import crossSvug from "@/assets/chat/cross.svg";
import { getTime, throttle } from "@/utils/utils";
import { defineEmits, defineProps, ref, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { encryptApi, decryptApi } from "@/utils/crypto";
import api from "@/api";
const router = useRouter();
const store = useStore();
const chatType = ref(store.state.chatType);
const userId = ref(store.state.userId);
const topicItem = ref(store.state.topicItem);
const requestverify = ref(false); //请求重新验证
const peopleSel = ref([]);
const seleteUserInfo = ref([]);
const requestverifyLoading = ref(false);
const leftNotes = ref("");
const emit = defineEmits(["openHistory", "scrollBottom", "getTalk"]);
const props = defineProps([
  "rightType",
  "historyDialog",
  "lookDialog",
  "item",
  "model",
]);
const descObj = ref({
  answerTokens: null,
  createdTime: "",
  internetCount: null,
  model: "",
  questionTokens: null,
  realQuestionTokens: null,
});
watch(
  () => store.state.chatType,
  (newValue, oldValue) => {
    chatType.value = newValue;
  }
);
watch(
  () => store.state.topicItem,
  (newValue, oldValue) => {
    topicItem.value = newValue;
  }
);
// 删除会话
const deleteItem = (item) => {
  api.chat[
    chatType.value == "base"
      ? "baseConversationDelete"
      : "chatConversationDelete"
  ]({
    conversationId: item.conversationId,
    userId: userId.value,
    operation_type: 0,
  }).then((res) => {
    if (res.returnCode == 200) {
      message.success("成功");
      emit("getTalk");
    }
  });
  console.log(item);
};
// 保存
const saveCap = () => {
  requestverifyLoading.value = true;
  api.share
    .postRequestverify({
      userId: userId.value,
      conversationIds: [props.item.conversationId],
      userIds: peopleSel.value,
      sessionName: topicItem.value.sessionName,
      sessionId: topicItem.value.sessionId,
      leftNotes: leftNotes.value,
    })
    .then((res) => {
      requestverifyLoading.value = false;
      if (res.returnCode == 200) {
        message.success("请求验证成功");
        requestverify.value = false;
      }
    });
};
// 关闭严重
const closeCap = () => {
  requestverify.value = false;
};
// 打开验证
const openRequestverify = () => {
  peopleSel.value = [];
  leftNotes.value = "";
  api.base
    .customLibraryDesc({
      userId: userId.value,
      libraryId: topicItem.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        seleteUserInfo.value = res.data.userInfo;
        requestverify.value = true;
      }
    });
};
// 获取详情
const getDesc = throttle(() => {
  api.chat[chatType.value == "chat" ? "chatDesc" : "baseDesc"]({
    answerId: props.item.answers[props.item.chatActive - 1].answerId,
    conversationId: props.item.conversationId,
    userId: userId.value,
  }).then((res) => {
    if (res.returnCode == 200) {
      descObj.value = res.data;
    }
  });
}, 1000);
// 重新生成
const releteTalk = () => {
  props.item.answers.push({
    answerContent: "",
    answerId: "",
  });
  props.item.chatActive = props.item.answers.length;
  let time = Math.round(new Date() / 1000);
  let obj = {};
  if (chatType.value == "chat") {
    obj = {
      userId: userId.value,
      model: props.model,
      topicId: topicItem.value.sessionId,
      conversationId: props.item.conversationId,
    };
  } else {
    obj = {
      userId: userId.value,
      model: props.model,
      libraryId: topicItem.value.knowledge_library_id,
      conversationId: props.item.conversationId,
      sessionId: topicItem.value.sessionId,
    };
  }
  let postObj = {
    time: time,
    idempotentEncryData: encryptApi(JSON.stringify({ ...obj, time: time })),
  };
  let lang = store.state.lang;
  if (lang === "CN") {
    lang = "zh-CN";
  } else if (lang === "HK") {
    lang = "zh-HK";
  } else if (lang === "US") {
    lang = "EN";
  }
  if (chatType.value == "chat") {
    regenerateFn(postObj, lang, "/openai/chat/v4/normal/regenerate");
  } else {
    regenerateFn(postObj, lang, "/openai/chat/v4/knowledge/regenerate");
  }
};
// 重新生成chat
const regenerateFn = async (postObj, lang, url) => {
  try {
    let controller = new AbortController();
    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + store.state.token,
        "Accept-Language": lang,
      },
      body: JSON.stringify(postObj),
      signal: controller.signal,
    });
    if (response.status === 500) {
      message.warning("服务器连接失败");
      props.item.answers[props.item.answers.length - 1].answerContent =
        "服务器连接失败";
      return;
    }
    if (response.status === 504) {
      message.warning("服务器连接超时");
      props.item.answers[props.item.answers.length - 1].answerContent =
        "服务器连接超时";
      return;
    }
    const reader = response.body.getReader();
    let flag = true;
    let data = "";
    while (flag) {
      const { done, value } = await reader.read();
      const rawData = new TextDecoder().decode(value);
      const regex = /<start>(.*?)<end>/g;
      let matches;
      const extractedData = [];
      while ((matches = regex.exec(rawData)) !== null) {
        extractedData.push(matches[1]);
      }
      const decryptedData = extractedData.map((data) => decryptApi(data));
      data += decryptedData.map((item) => item).join("");
      props.item.answers[props.item.answers.length - 1].answerContent = data;
      nextTick(() => {
        emit("scrollBottom");
      });
      if (done) {
        if (
          !data.includes("returnCode") &&
          !data.includes("!(end of answer)")
        ) {
          nextTick(() => {
            store.commit("SET_TALK", {});
          });
        } else if (data.includes("!(end of answer)")) {
          message.warning("当前对话记录超过限制,请结束对话或新建对话。");
          props.item.answers[props.item.answers.length - 1].answerContent =
            "当前对话记录超过限制,请结束对话或新建对话。";
        } else if (data.includes("returnCode")) {
          let newData = JSON.parse(data);
          message.warning(newData.returnMessage);
          if (newData.returnCode == 423 || newData.returnCode == 412) {
            router.push("/login");
          } else {
            props.item.answers[props.item.answers.length - 1].answerContent =
              newData.returnMessage;
          }
        }
        break;
      }
    }
  } catch (err) {
    console.log(err);
  }
};
// 加减页数
const changeItemPage = (type) => {
  if (type && props.item.chatActive < props.item.answers.length) {
    props.item.chatActive++;
  }
  if (!type && props.item.chatActive > 1) {
    props.item.chatActive--;
  }
};
// 打开历史记录
const openHistory = () => {
  if (chatType.value == "chat") {
    api.base
      .chatHistory({
        userId: userId.value,
        conversationId: props.item.conversationId,
        topicId: topicItem.value.sessionId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          if (res.data.length == 0) {
            message.warning("暂无历史记录");
            return;
          }
          res.data.chatActive = 1;
          emit("openHistory", res.data);
        }
      });
  } else {
    api.base
      .baseHistory({
        userId: userId.value,
        conversationId: props.item.conversationId,
        libraryId: topicItem.value.knowledge_library_id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          if (res.data.length == 0) {
            message.warning("暂无历史记录");
            return;
          }
          res.data.chatActive = 1;
          emit("openHistory", res.data);
        }
      });
  }
};
// 复制
const copyFn = async () => {
  if (!navigator.clipboard) {
    message.warning("浏览器不支持复制到剪贴板");
    return;
  }
  try {
    await navigator.clipboard.writeText(
      props.item.answers[props.item.chatActive - 1].answerContent
    );
    message.success("复制成功");
  } catch (err) {
    message.warning("复制失败");
  }
};
// 翻译
const itemQa = () => {
  api.chat[chatType.value == "chat" ? "chatTranslate" : "baseTranslate"]({
    userId: userId.value,
    conversationId: props.item.conversationId,
    answerId: props.item.answers[props.item.chatActive - 1].answerId,
  }).then((res) => {
    if (res.returnCode == 200) {
      message.success("翻译成功");
      props.item.answers[props.item.chatActive - 1].answerContent = res.data;
    }
  });
};
// 收藏
const itemLike = () => {
  api.chat[chatType.value == "chat" ? "chatLike" : "baseLike"]({
    userId: userId.value,
    conversationId: props.item.conversationId,
    operationType: props.item.isCollected == 1 ? 1 : 0,
  }).then((res) => {
    if (res.returnCode == 200) {
      message.success(
        props.item.isCollected == 1 ? "取消收藏成功" : "收藏成功"
      );
      props.item.isCollected = props.item.isCollected == 1 ? 0 : 1;
    }
  });
};
// 点赞
const itemUp = () => {
  api.chat[chatType.value == "chat" ? "chatUp" : "baseUp"]({
    userId: userId.value,
    conversationId: props.item.conversationId,
    operationType: props.item.isVoteUp == 1 ? 1 : 0,
  }).then((res) => {
    if (res.returnCode == 200) {
      message.success(props.item.isVoteUp == 1 ? "取消点赞成功" : "点赞成功");
      props.item.isVoteUp = props.item.isVoteUp == 1 ? 0 : 1;
    }
  });
};
// 点踩
const itemDown = () => {
  api.chat[chatType.value == "chat" ? "chatDown" : "baseDown"]({
    userId: userId.value,
    conversationId: props.item.conversationId,
    operationType: props.item.isVoteDown == 1 ? 1 : 0,
  }).then((res) => {
    if (res.returnCode == 200) {
      message.success(props.item.isVoteDown == 1 ? "取消点踩成功" : "点踩成功");
      props.item.isVoteDown = props.item.isVoteDown == 1 ? 0 : 1;
    }
  });
};
</script>

<style lang="scss" scoped>
.detailsBtn {
  display: flex;
  border-radius: 30px;
  font-size: 18px;
  color: #fefefe;
  font-family: "Regular";
  padding: 5px 12px;
  cursor: pointer;
}
.color1 {
  background-color: #38aee5;
}
.color2 {
  background-color: #767bfa;
}
.color3 {
  background-color: #3376ff;
}
.btnImg {
  margin-right: 8px;
}
.flexCenter {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.iconMargin {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
}
.iconMarginBg {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}
.lineIcon {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconMargin:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}
.marginRight {
  margin-right: 20px;
}
.center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bottomIcon {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin-bottom: 30px;
}
.marginBottom {
  margin-bottom: 20px;
}
.wid17 {
  width: 17px;
}
.wid21 {
  width: 21px;
}
.wid36 {
  width: 36px;
}
.widLine {
  width: 5px;
  height: 25px;
}
.fillImg {
  width: 24px;
  cursor: pointer;
}
.fillText {
  font-size: 18px;
  font-family: "Semibold";
  color: #8a8a8a;
}
.fillCenter {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.fillColor {
  color: #d9d9d9;
}
.capDialogClass {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 566px;
  height: 370px;
  border-radius: 16px;
  background-color: #f7f8ff;
  z-index: 10;
  padding: 17px;
  cursor: default;
}

.capTitle {
  font-size: 22px;
  color: #3d3d3d;
  font-family: "Medium";
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  height: 30px;
}

.crossSvug {
  width: 24px;
}

.labelItem {
  font-size: 18px;
  font-family: "Medium";
  color: #3d3d3d;
  margin-bottom: 12px;
  margin-top: 24px;
}

.capLabel {
  position: relative;
  margin-right: 20px;
}

.capLabel::after {
  content: "*";
  position: absolute;
  color: #f02d63;
  right: -15px;
}

.catText {
  margin-bottom: 24px;

  :deep(.el-textarea__inner) {
    height: 110px;
    min-height: 110px;
    max-height: 110px;
    border-radius: 16px;
    border: 2px solid #edeef6;
    padding: 12px 20px;
    resize: none;
    outline: none;
    font-family: "Regular";
    font-size: 18px;
  }
}

.capBtnDiv {
  text-align: right;
}

.capCancel {
  height: 48px;
  color: #f02d63;
  border: 2px solid #edeef6;
  border-radius: 16px;
  font-size: 20px;
  font-family: "Regular";
  padding: 10px 20px;
}

.capSave {
  background-color: #3376ff;
  border: 0px;
  border-radius: 16px;
  height: 48px;
  font-size: 20px;
  font-family: "Regular";
  color: #fefefe;
  padding: 10px 20px;
}
</style>

<template>
  <div class="baseInner">
    <!-- 左侧 -->
    <div :class="navActive == 0 ? 'leftInner' : 'leftInner1'">
      <div ref="leftDiv" class="leftDiv">
        <!-- tab切换 -->
        <div ref="topNav">
          <div class="dialogBetWeen marginBottom15">
            <div class="flexCenter">
              <div
                v-for="(item, index) in navList"
                :key="index"
                :class="{
                  navItem: navActive != index,
                  navItemActive: navActive == index,
                }"
                @click="changeNav(index)"
              >
                {{ item }}
              </div>
            </div>
            <div class="flexCenter" @click="openEdit">
              <img :src="szImg" alt="" class="szImg" />
              <div class="szText" v-if="navActive == 0">设置</div>
            </div>
          </div>
          <!-- 搜索 -->
          <el-select
            class="searchInput"
            v-if="navActive == 0"
            filterable
            remote
            reserve-keyword
            placeholder="在 欢迎使用NextAI 中搜索"
            :remote-method="selectSearch"
            :loading="searchLoading"
            :teleported="false"
            @change="changeSearch"
          >
            <el-option
              v-for="(item, index) in searchFileList"
              :key="item.id"
              :label="item.fileName"
              :value="index"
            />
          </el-select>
          <!-- 上传 新增文件夹 历史 -->
          <div class="upDiv" v-if="navActive == 0">
            <el-dropdown @command="upFile">
              <div class="upFileBtn">
                <img :src="upFiles" alt="" class="upFiles" />
                <div>上传</div>
                <img :src="fileIcon" alt="" class="wid11" />
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <uploadIndex
                    type="base"
                    @beforeAvatarUpload="beforeAvatarUpload"
                  >
                    <el-dropdown-item :command="1">上传文件</el-dropdown-item>
                  </uploadIndex>
                  <upFloderIndex @upFloder="upFloder">
                    <el-dropdown-item :command="2">上传文件夹</el-dropdown-item>
                  </upFloderIndex>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <div class="addFiles" @click="openCreateFloader">
              <img :src="addFiles" alt="" class="width30" />
              <div>新建</div>
            </div>
            <div class="addFiles" @click="openHistory">
              <img :src="upLog" alt="" class="width30" />
              <div>历史</div>
            </div>
          </div>
          <!-- 返回 -->
          <div class="pageHeader">
            <el-page-header @back="goBack" v-if="urlList.length != 0">
              <template #content>
                {{ urlList[urlList.length - 1].folderName }}
              </template>
            </el-page-header>
          </div>
        </div>
        <!-- 文件列表 -->
        <div
          :style="'height:' + fileListHeight + 'px'"
          class="fileListDiv"
          v-if="navActive == 0 && fileList.length != 0"
          v-loading="leftFileLoading"
        >
          <VueDraggable
            v-model="fileList"
            :animation="150"
            :group="{ name: 'fileList', pull: 'clone', put: false }"
            :sort="false"
            class="VueDraggable"
          >
            <div
              v-for="(item, index) in fileList"
              :key="index"
              :class="fileActive == index ? 'fileItemDivActive' : 'fileItemDiv'"
              @dblclick="handleNodeClick(item, index)"
            >
              <div class="fileBetween">
                <div class="flexCenter treeDiv">
                  <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
                  <div v-if="item.fileName" class="fileTitle">
                    {{ item.fileName }}
                  </div>
                  <div v-if="item.folderName" class="fileTitle">
                    {{ item.folderName }}
                  </div>
                  <img
                    :src="fileYear"
                    alt=""
                    class="fileYear"
                    v-if="item.expireFlag == 0"
                  />
                </div>
                <el-dropdown @command="changeFileMore($event, item)">
                  <div class="fileMoreImgDiv">
                    <img :src="fileMore" alt="" class="fileMoreImg" />
                  </div>

                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item :command="1" v-if="item.fileName"
                        >下载文件</el-dropdown-item
                      >
                      <el-dropdown-item
                        :command="2"
                        v-if="item.fileName && item.expireDate == null"
                        >设为过期</el-dropdown-item
                      >
                      <el-dropdown-item
                        :command="6"
                        v-if="item.fileName && item.expireDate != null"
                        >取消设置</el-dropdown-item
                      >
                      <el-dropdown-item
                        :command="3"
                        v-if="item.fileName && item.importantFlag == 1"
                        >取消验证</el-dropdown-item
                      >
                      <el-dropdown-item
                        :command="7"
                        v-if="
                          item.fileName &&
                          (item.importantFlag == 0 ||
                            item.importantFlag == null)
                        "
                        >验证</el-dropdown-item
                      >
                      <el-dropdown-item :command="4" v-if="item.fileName"
                        >删除文件</el-dropdown-item
                      >
                      <el-dropdown-item :command="5" v-if="item.folderName"
                        >删除文件夹</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </div>
          </VueDraggable>
        </div>
        <!-- 无数据 -->
        <div
          :style="'height:' + fileListHeight + 'px'"
          class="fileListDiv nodataCenter"
          v-if="navActive == 0 && fileList.length == 0"
        >
          <el-empty />
        </div>
        <!-- 话题广场列表 -->
        <div
          class="talkDiv"
          v-if="navActive == 1"
          :style="'height:' + fileListHeight + 'px'"
        >
          <div
            v-for="(item, index) in topicList"
            :key="index"
            :class="{
              topMenuActive: topicActive == index,
            }"
            class="topMenu"
            @click="changeTopic(item, index)"
          >
            <img :src="item.url" alt="" class="wid24 marginRight13" />
            <div>{{ item.name }}</div>
          </div>
        </div>
        <!-- 针对微模型提问 -->
        <div ref="topBtn" class="drapBottomDiv">
          <VueDraggable
            v-model="bottomFileList"
            :animation="150"
            group="fileList"
            :sort="false"
          >
            <div class="drapFlex" v-if="bottomFileList.length == 0">
              <img :src="drapBottom" alt="" class="drapBottom" />
              <div class="drapBottomText">
                将您需要引用的文件/文件夹/话题拖放到此处，知识库可以针对引用回答得更加精准
              </div>
            </div>
            <div v-else class="bottomMax">
              <div
                v-for="(item, index) in bottomFileList"
                :key="item.id"
                class="bottomFileItem"
              >
                <div class="fileBetween">
                  <div class="flexCenter bottomTreeDiv">
                    <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
                    <div v-if="item.fileName" class="fileTitle">
                      {{ item.fileName }}
                    </div>
                    <div v-if="item.folderName" class="fileTitle">
                      {{ item.folderName }}
                    </div>
                    <div v-if="item.sessionName" class="fileTitle">
                      {{ item.sessionName }}
                    </div>
                    <img
                      :src="fileYear"
                      v-if="item.expireFlag == 0"
                      alt=""
                      class="fileYear"
                    />
                  </div>
                  <img
                    :src="closeFileBottom"
                    alt=""
                    class="closeFileBottom"
                    @click="deleteBottomFile(index)"
                  />
                </div>
              </div>
            </div>
          </VueDraggable>
          <el-button class="qaBtn" @click="goChat">针对微模型提问</el-button>
        </div>
      </div>
    </div>
    <!-- 话题广场 -->
    <div class="rightTopic" v-if="navActive == 1">
      <!-- 搜索 -->
      <div class="topicSearch">
        <el-input
          placeholder="在 欢迎使用NextAI 中搜索"
          prefix-icon="Search"
          class="topicInput"
        />
        <div class="roleDiv">
          <div class="roleTitle">时间</div>
          <el-dropdown>
            <span class="el-dropdown-link">
              全部
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>Action 1</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div class="roleDiv">
          <div class="roleTitle">提问人</div>
          <el-dropdown>
            <span class="el-dropdown-link">
              全部
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>Action 1</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div :class="pxBtnType ? 'pxDivActive' : 'pxDiv'" @click="changePx">
          <img :src="pxSvg" alt="" class="px" />
        </div>
      </div>
      <!-- 话题 -->
      <div class="baseItemDiv" v-loading="topicLoading">
        <!-- 换指定的数据即可 -->
        <VueDraggable
          v-model="rightList"
          :animation="150"
          group="fileList"
          :sort="false"
          :disabled="ifTopic"
          class="VueDraggable"
        >
          <lookView
            type="base"
            :list="rightList"
            :deleteType="false"
            v-if="rightList.length != 0"
            @goLookView="goLookView"
          ></lookView>
          <div class="noDataDiv" v-else>
            <img :src="noData" class="noData" alt="" />
          </div>
        </VueDraggable>
      </div>
    </div>
    <!-- 预览文件 -->
    <fileView
      class="rightInner"
      :fileItem="fileItem"
      :chunkDialog="chunkDialog"
      type="base"
      @closeFileView="closeFileView"
      @downFile="downFile"
      @deleteFile="deleteFile"
      v-if="navActive == 0 && fileItem.id"
    >
    </fileView>
    <!-- 无文件展示 -->
    <div
      class="noDataFile"
      v-if="navActive == 0 && Object.keys(fileItem).length == 0"
    >
      <img :src="noFileImg" alt="" class="noFileImg" />
    </div>
    <!-- 新建文件夹 -->
    <div v-if="floderDialog" class="dialog">
      <div class="dialogBetWeen">
        <div class="dialogTitle">新建文件夹</div>
        <img
          @click="closeAddFolder"
          :src="closeFileViewImg"
          alt=""
          class="closeFileViewImg"
        />
      </div>
      <div class="dialogTip">
        文件夹名称 <span style="color: #f02d63">*</span>
      </div>
      <el-input
        maxlength="100"
        placeholder="文件夹名称不能为空"
        v-model="floderName"
        show-word-limit
        class="dialogInput"
      ></el-input>
      <div class="dialogEnd">
        <el-button @click="closeAddFolder" class="dialogCancelBtn"
          >取消</el-button
        >
        <el-button
          @click="saveFloader"
          class="dialogSaveBtn"
          :loading="folderLoading"
        >
          创建
        </el-button>
      </div>
    </div>
    <div class="mask" v-if="floderDialog"></div>
    <!-- 上传文件 -->
    <div v-if="upFileDialog" class="upFileDialog">
      <div class="upFileTitleDiv dialogBetWeen">
        <div class="upFileTitle">
          {{ upTypeActive == 1 ? "上传文件" : "上传文件夹" }}
        </div>
        <img
          :src="closeFileViewImg"
          alt=""
          class="closeFileViewImg"
          @click="closeUpDialog"
        />
      </div>
      <div class="upFileBody">
        <div class="upFileTip">
          {{
            upTypeActive == 1
              ? "您选择的下列文件等待上传："
              : "您选择的下列文件夹等待上传："
          }}
        </div>
        <div class="upFileItemDiv" v-if="upFloderName">
          <div class="upFileLeftDiv">
            <img
              :src="zkr"
              alt=""
              v-if="collType"
              class="zk"
              @click="collType = !collType"
            />
            <img
              :src="zkb"
              alt=""
              v-if="!collType"
              class="zk"
              @click="collType = !collType"
            />
            <img :src="fileFolder" alt="" class="fileIcon" />
            <div class="upListItemTitle">{{ upFloderName }}</div>
          </div>
          <div class="upFileItemTip">待上传</div>
        </div>
        <el-collapse-transition>
          <div v-show="(upFloderName && !collType) || !upFloderName">
            <div v-for="(item, index) in upFileList.slice(0, 10)" :key="index">
              <div class="upFileItemDiv">
                <div class="upFileLeftDiv">
                  <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
                  <div class="upListItemTitle">{{ item.name }}</div>
                </div>
                <div class="upFileItemTip">待上传</div>
              </div>
            </div>
            <div v-if="isExpanded">
              <div v-for="(item, index) in upFileList.slice(10)" :key="index">
                <div class="upFileItemDiv">
                  <div class="upFileLeftDiv">
                    <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
                    <div class="upListItemTitle">{{ item.name }}</div>
                  </div>
                  <div class="upFileItemTip">待上传</div>
                </div>
              </div>
            </div>
            <div
              class="zkText"
              @click="isExpanded = !isExpanded"
              v-if="upFileList.length > 10"
            >
              {{ isExpanded ? "收起" : "展开所有" }}
            </div>
          </div>
        </el-collapse-transition>
        <div class="upFileTip" v-if="errorFileList.length != 0">
          下列文件不符合系统上传规则：
        </div>
        <div v-for="(item, index) in errorFileList" :key="index">
          <div class="upFileItemDiv">
            <div class="upFileLeftDiv">
              <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
              <div class="upListItemTitle">{{ item.name }}</div>
            </div>
            <div class="upFileItemTip">{{ item.type }}</div>
          </div>
        </div>
      </div>

      <div class="dialogEnd">
        <el-button class="dialogCancelBtn" @click="closeUpDialog"
          >取消</el-button
        >
        <el-button class="dialogSaveBtn" @click="saveDialog">上传</el-button>
      </div>
    </div>
    <!-- 历史记录 -->
    <div v-if="historyDialog" class="historyDialog">
      <div class="dialogBetWeen">
        <div class="upFileTitle">上传历史</div>
        <img
          :src="closeFileViewImg"
          alt=""
          class="closeFileViewImg"
          @click="historyDialog = false"
        />
      </div>
      <div class="historyDiv" ref="historyBody">
        <el-table
          :data="historyTableData"
          border
          :height="tableHistory"
          v-loading="historyLoading"
        >
          <el-table-column
            prop="fileName"
            label="所有类型"
            show-overflow-tooltip
          >
            <template #default="{ row }">
              <div class="tableFileNameDiv">
                <img :src="getBaseFileIcon(row)" alt="" class="fileIcon" />
                <div class="tableText tableMore">{{ row.fileName }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="fileSize" label="文档大小" align="center">
            <template #default="{ row }">
              <div class="tableText">
                {{ formatFileSize(row.fileSize) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="finishTime" label="最近上传"
            ><template #default="{ row }">
              <div class="tableText">
                {{ getTime(row.finishTime) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="finishStatus" label="不限状态" align="center">
            <template #default="{ row }">
              <div :class="row.finishStatus == 1 ? 'hisSuccess' : 'hisError'">
                {{ row.finishStatus == 1 ? "上传成功" : "上传失败" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" align="center">
            <template #default="{ row }">
              <el-button
                class="tableClearBtn"
                @click="clearItemHistory(row.taskId)"
                >清除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="dialogEnd">
        <el-button class="dialogSaveBtn" @click="clearItemHistory('all')"
          >清楚全部记录</el-button
        >
      </div>
    </div>
    <div class="maskFile" v-if="upFileDialog || historyDialog"></div>
    <!-- 上传进度条 -->
    <div class="processDialog" v-if="processDialog">
      <div class="processBetWeen">
        <div class="processTop">
          <img :src="ok" alt="" class="img36" v-if="processList.length == 0" />
          <div class="processTopTitle">
            {{
              processList.length == 0
                ? "已完成上传"
                : "正在上传 " + processList.length
            }}
          </div>
        </div>
        <div>
          <img
            :src="down"
            alt=""
            class="img36"
            v-if="!processType"
            @click="processType = !processType"
          />
          <img
            :src="up"
            alt=""
            class="img36"
            v-else
            @click="processType = !processType"
          />
          <img
            v-if="processList.length == 0"
            :src="closeSvg"
            alt=""
            class="img36"
            @click="processDialog = false"
          />
        </div>
      </div>
      <el-collapse-transition>
        <div v-if="!processType">
          <!-- 总进度条 -->
          <!-- 成功 -->
          <!-- <div class="processItemDiv">
            已完成上传2个文件夹，共12个文件，失败1个文件
          </div> -->
          <!-- 进度中 -->
          <div class="processItemDiv" v-if="processList.length != 0">
            <div :style="'width:' + getContSum + '%'" class="processItem"></div>
            <div class="zindex">已上传：{{ getContSum }}%</div>
            <div class="zindex">
              <el-button
                type="text"
                class="cancelBtn"
                :disabled="cancelType"
                @click="postFile({}, 'allDelete')"
                >全部取消</el-button
              >
              <el-button
                type="text"
                class="stopBtn"
                :disabled="pauseType"
                v-if="!startType"
                @click="postFile({}, 'allPause')"
                >全部暂停</el-button
              >
              <el-button
                type="text"
                class="stopBtn"
                :disabled="startType"
                v-if="startType && !pauseType"
                @click="postFile({}, 'allStart')"
                >全部开始</el-button
              >
            </div>
          </div>
          <!-- 文件列表 -->
          <!-- <div class="upFileItemDiv">
            <div class="upFileLeftDiv">
              <img
                :src="zkr"
                alt=""
                v-if="processCollType"
                class="zk"
                @click="processCollType = !processCollType"
              />
              <img
                :src="zkb"
                alt=""
                v-if="!processCollType"
                class="zk"
                @click="processCollType = !processCollType"
              />
              <img :src="fileFolder" alt="" class="fileIcon" />
              <div class="upListItemTitle">法律文书</div>
            </div>
            <div class="upFileItemTip">
              <el-progress :percentage="50" />
            </div>
          </div> -->
          <el-collapse-transition>
            <div v-show="!processCollType" class="fileListItemUp">
              <div v-for="(item, index) in processList" :key="index">
                <div class="upFileItemDiv">
                  <div class="upFileLeftDiv">
                    <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
                    <div class="upListItemTitle">{{ item.fileName }}</div>
                  </div>
                  <div class="upFileItemTip">
                    <el-progress :percentage="Math.floor(item.process * 100)" />
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </el-collapse-transition>
    </div>
    <!-- 过期 -->
    <timeView
      v-if="timeDialog"
      :loading="timeLoading"
      @closeTime="closeTime"
      @saveTime="saveTime"
    ></timeView>
    <!-- 编辑 -->
    <addView
      v-if="addDialog"
      :addType="addType"
      :addLoading="addLoading"
      :editObj="editObj"
      @closeAdd="closeAdd"
      @saveEdit="saveEdit"
    ></addView>
    <!-- 针对话题弹窗 -->
    <newChat
      v-if="chatDialog"
      type="base"
      @closeChat="closeChat"
      :bottomFileList="bottomFileList"
    ></newChat>
  </div>
</template>

<script setup>
import upFiles from "@/assets/base/upFiles.svg";
import drapBottom from "@/assets/base/drapBottom.svg";
import addFiles from "@/assets/base/addFiles.svg";
import pxSvg from "@/assets/base/px.svg";
import upLog from "@/assets/base/upLog.svg";
import fileIcon from "@/assets/base/fileIcon.svg";
import fileWord from "@/assets/base/fileWord.svg";
import fileFolder from "@/assets/base/fileFolder.svg";
import fileYear from "@/assets/base/fileYear.svg";
import fileMore from "@/assets/base/fileMore.svg";
import szImg from "@/assets/base/sz.svg";
import topic1 from "@/assets/base/topic1.svg";
import topic2 from "@/assets/base/topic2.svg";
import topic3 from "@/assets/base/topic3.svg";
import topic4 from "@/assets/base/topic4.svg";
import topic5 from "@/assets/base/topic5.svg";
import down from "@/assets/upload/down.svg";
import up from "@/assets/upload/up.svg";
import ok from "@/assets/upload/ok.svg";
import loadingSvg from "@/assets/upload/loading.svg";
import closeSvg from "@/assets/upload/close.svg";
import closeFileBottom from "@/assets/base/closeFileBottom.svg";
import closeFileViewImg from "@/assets/base/closeFileView.svg";
import noFileImg from "@/assets/base/noFile.svg";
import uploadIndex from "@/components/upload/index.vue";
import upFloderIndex from "@/components/upFloder/index.vue";
import fileView from "@/components/fileView/index.vue";
import lookView from "@/components/look/index.vue";
import zkb from "@/assets/base/zkb.svg";
import zkr from "@/assets/base/zkr.svg";
import api from "@/api/index";
import { getBaseFileIcon, formatFileSize } from "@/utils/file";
import { ref, onMounted, watch, nextTick, computed } from "vue";
import { VueDraggable } from "vue-draggable-plus";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
import { getTime } from "@/utils/utils";
import timeView from "./time.vue";
import addView from "./add.vue";
import noData from "@/assets/nodata.svg";
import newChat from "@/components/newChat/index.vue";
const store = useStore();
const router = useRouter();
const baseItem = ref(store.state.baseItem);
const fileList = ref([]);
const fileItem = ref({});
const navList = ref(["文档仓库", "话题广场"]);
const topicActive = ref(0); //话题广场选择
const rootId = ref(null); //root文件夹目录
const topicList = ref([
  {
    name: "权威话题",
    url: topic1,
  },
  {
    name: "热门话题",
    url: topic2,
  },
  {
    name: "收藏话题",
    url: topic3,
  },
  {
    name: "所有话题",
    url: topic5,
  },
]);
const navActive = ref(0); //nav菜单选项
const userId = ref(store.state.userId); //userId
const chunkDialog = ref(false); //文件预览是否展示
const floderDialog = ref(false); //新建文件夹
const floderName = ref(""); //新建文件夹名称
const fileActive = ref(null); //选择的那个文件
const topNav = ref(null); //ref计算使用
const topBtn = ref(null); //ref计算使用
const leftDiv = ref(null);
const fileListHeight = ref(null);
const bottomFileList = ref([]); //底部拖动
const pxBtnType = ref(false); //排序
const upFileList = ref([]); //要上传的文件列表
const upFileDialog = ref(false); //上传框状态
const upTypeActive = ref(null); //判断上传文件还是文件夹
const errorFileList = ref([]); //不符合条件的文件
const upFloderName = ref(""); //上传文件夹的名称
const collType = ref(false); //文件夹展开 上传
const isExpanded = ref(false); //默认不展开
const timmer = ref(null); //轮询使用
const historyDialog = ref(false); //历史上传dialog
const historyBody = ref(null); //获取histable高度
const tableHistory = ref(null);
const historyTableData = ref([]); //历史数据
const historyLoading = ref(false); //历史记录loading
const processCollType = ref(false); //上传文件夹展开与关闭
const processDialog = ref(false); //上传进度
const processType = ref(false); //上传进度展开收起
const searchLoading = ref(false); //搜索loading
const searchFileList = ref([]); //搜索数组
const urlList = ref([]); //顶部路径
const leftFileLoading = ref(false); //左侧列表loading
const folderLoading = ref(false); //创建文件夹loading
const processList = ref([]); //进度
const pauseType = ref(false); //暂停
const startType = ref(false); //开始
const cancelType = ref(false); //取消
const timeDialog = ref(false); //过期弹窗
const timeLoading = ref(false); //过期loading
const timeType = ref(null); //判断权威 还是设置过期
const timeObj = ref({});
const rightList = ref([]);
const topicLoading = ref(false);
const addType = ref(""); //打开编辑
const editObj = ref({}); //编辑信息存储
const addDialog = ref(false); //编辑弹窗
const addLoading = ref(false); //编辑保存
const chatDialog = ref(false); //针对微模型对话
watch(
  () => bottomFileList.value,
  (newValue, oldValue) => {
    setBottomFileList();
    setTimeout(() => {
      getFileHeight();
    }, 100);
  }
);
const getContSum = computed(() => {
  // 获取所有值 / 个数 *100
  let totalProcess =
    processList.value.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.process;
    }, 0) / processList.value.length;
  totalProcess = totalProcess >= 1 ? 100 : totalProcess * 100;
  return Math.floor(totalProcess);
});
const ifTopic = computed(() => {
  let topicList = bottomFileList.value
    .filter(
      (item) => item.sessionName !== undefined && item.sessionName !== null
    ) // 确保sessionName存在且不为null
    .map((item) => item.sessionName);
  if (topicList.length == 0) {
    return false;
  } else {
    return true;
  }
});
onMounted(() => {
  pollingFn();
  task();
  getFileList();
  window.onresize = () => {
    if (historyDialog.value) {
      tableHistory.value = historyBody.value.offsetHeight;
    }
    getFileHeight();
  };
  getFileHeight();
});
onBeforeRouteLeave((to, from, next) => {
  clearInterval(timmer.value);
  timmer.value = null;
  window.onresize = null;
  next();
});
// 去对话历史记录
const goLookView = (item) => {
  store.commit("SET_CHATTYPE", "base");
  store.commit("SET_TOPIC", item);
  router.push("/chat");
};
// 编辑保存
const saveEdit = (form) => {
  addLoading.value = true;
  let newForm = {
    userId: form.userId,
    libraryName: form.name,
    desc: form.desc,
    promptId: form.promptId,
    libraryId: editObj.value.knowledge_library_id,
  };
  api.base
    .baseUpdata(newForm)
    .then((res) => {
      addLoading.value = false;
      if (res.returnCode == 200) {
        message.success("保存成功");
        addDialog.value = false;
        updataEdit();
      }
    })
    .catch((err) => {
      addLoading.value = true;
    });
};
// 详情更新
const updataEdit = () => {
  api.base
    .customLibraryDesc({
      userId: userId.value,
      libraryId: editObj.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        store.commit("SET_BASEITEM", res.data);
      }
    });
};
// 打开编辑
const openEdit = () => {
  // 编辑
  api.base
    .customLibraryDesc({
      userId: userId.value,
      libraryId: baseItem.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        addType.value = "edit";
        editObj.value = res.data;
        addDialog.value = true;
      }
    });
};
// 关闭编辑
const closeAdd = () => {
  addType.value = "";
  editObj.value = {};
  addDialog.value = false;
};
// 清除所有历史
const clearItemHistory = (id) => {
  historyLoading.value = true;
  api.base
    .clearHistory(
      { userId: userId.value, taskId: id },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        openHistory();
        message.success("清除成功");
      }
    });
};
// 打开历史
const openHistory = () => {
  historyLoading.value = true;
  api.base
    .getHistoryFiles(
      {
        endTime: null,
        filenamePattern: "",
        pageId: 1,
        perPage: 10000,
        startTime: null,
        userId: userId.value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        historyLoading.value = false;
        historyDialog.value = true;
        historyTableData.value = res.data;
        nextTick(() => {
          tableHistory.value = historyBody.value.offsetHeight;
        });
      }
    });
};
// 删除底部
const deleteBottomFile = (index) => {
  bottomFileList.value.splice(index, 1);
  getFileHeight();
};
// 下载文件
const downFile = (item) => {
  api.base
    .wordView(
      {
        fileId: item.id,
        userId: userId.value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      const imageUrl = res.data;
      const link = document.createElement("a");
      link.href = imageUrl;
      link.setAttribute("download", "文件");
      link.click();
    });
};
// 删除文件
const deleteFile = () => {
  deleteFileFn(fileItem.value);
  fileItem.value = {};
  fileActive.value = null;
};
// 去重底部
const setBottomFileList = () => {
  bottomFileList.value = newSetFn(bottomFileList.value);
};
// 去重
const newSetFn = (arr) => {
  let len = arr.length;
  for (let i = 0; i < len; i++) {
    for (let j = i + 1; j < len; j++) {
      if (arr[i].id === arr[j].id) {
        arr.splice(j, 1);
        len--; // 减少循环次数提高性能
        j--; // 保证j的值自加后不变
      }
    }
  }
  return arr;
};
// 动态计算高度
const getFileHeight = () => {
  nextTick(() => {
    fileListHeight.value =
      leftDiv.value.offsetHeight -
      (topNav.value.offsetHeight + topBtn.value.offsetHeight);
  });
};
// 跳转chat
const goChat = () => {
  let fileList = [];
  let folderList = [];
  let topicList = [];
  bottomFileList.value.forEach((item) => {
    if (item.fileName) {
      fileList.push(item.id);
    } else if (item.folderName) {
      folderList.push(item.id);
    } else if (item.sessionName) {
      topicList.push(item.sessionId);
    }
  });
  store.commit("SET_BASEDRAP", { fileList, folderList, topicList });
  store.commit("SET_CHATTYPE", "base");
  chatDialog.value = true;
};
const closeChat = () => {
  chatDialog.value = false;
};
// 创建文件夹
const openCreateFloader = () => {
  floderName.value = "";
  floderDialog.value = true;
};
// 切换话题
const changeTopic = (item, index) => {
  topicActive.value = index;
  getTopic();
};
// 获取文件
const getFileList = (id) => {
  leftFileLoading.value = true;
  api.base
    .queryFileList(
      { userId: userId.value, folderId: id ? id : null },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      leftFileLoading.value = false;
      if (res.returnCode == 200) {
        fileList.value = [];
        rootId.value = res.data.currentFolderInfo.id;
        fileList.value = [...res.data.folderInfo, ...res.data.filesInfo];
      }
    })
    .catch((err) => {
      leftFileLoading.value = false;
    });
};
// 远程搜索
const selectSearch = (value) => {
  searchLoading.value = true;
  api.base
    .queryFolderFileCompanyApi(
      {
        userId: userId.value,
        folderNamePattern: value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      searchLoading.value = false;
      if (res.returnCode == 200) {
        if (res.data.filesData.length != 0) {
          searchFileList.value = res.data.filesData;
        }
      }
    })
    .catch((err) => {
      searchLoading.value = false;
    });
};
// 点击搜索项
const changeSearch = (index) => {
  //获取父级文件夹
  api.base
    .parentTraceCompanyApi(
      {
        userId: userId.value,
        folderId: searchFileList.value[index].folder,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        urlList.value = res.data;
        leftFileLoading.value = true;
        api.base
          .accuracySearch({
            userId: userId.value,
            fileId: searchFileList.value[index].id,
          })
          .then((res) => {
            fileList.value = [];
            fileList.value = [res.data];
            handleNodeClick(res.data, 0);
            leftFileLoading.value = false;
          })
          .catch((err) => {
            leftFileLoading.value = false;
          });
      }
    });
};
// 关闭新增文件夹
const closeAddFolder = () => {
  floderDialog.value = false;
  floderName.value = "";
};
// 打开预览
const handleNodeClick = (data, index) => {
  // 进入文件夹
  if (data.folderName) {
    urlList.value.push(data);
    getFileList(data.id);
    fileActive.value = null;
  } else {
    fileItem.value = data;
    fileActive.value = index;
    chunkDialog.value = true;
  }
};
// 返回上一级
const goBack = () => {
  // 特殊情况 获取root
  if (urlList.value.length == 1) {
    getFileList(null);
    urlList.value = [];
  } else {
    // 获取最后的前面的
    getFileList(urlList.value[urlList.value.length - 2].id);
    urlList.value.splice(urlList.value.length - 1, 1);
  }
};
// 确定设置过期时间
const saveTime = (time) => {
  timeLoading.value = true;
  api.base[timeType.value == 1 ? "fileExpire" : "importantSet"]({
    userId: userId.value,
    fileId: timeObj.value.id,
    expireTime: time,
    libraryId: baseItem.value.knowledge_library_id,
    operateType: "add",
  })
    .then((res) => {
      timeLoading.value = false;
      if (res.returnCode == 200) {
        timeDialog.value = false;
        message.success("设置成功");
        getFileList(
          urlList.value.length == 0
            ? null
            : urlList.value[urlList.value.length - 1].id
        );
      }
    })
    .catch((err) => {
      timeLoading.value = false;
    });
};
const closeTime = () => {
  timeDialog.value = false;
};
// 操作文件
const changeFileMore = (e, item) => {
  // 下载
  if (e == 1) {
    downFile(item);
  }
  // 设为过期
  if (e == 2) {
    timeObj.value = item;
    timeDialog.value = true;
    timeType.value = 1;
  }
  // 取消验证
  if (e == 3) {
    api.base
      .importantSet({
        userId: userId.value,
        fileId: item.id,
        expireTime: null,
        libraryId: baseItem.value.knowledge_library_id,
        operateType: "delete",
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success("取消成功");
          getFileList(
            urlList.value.length == 0
              ? null
              : urlList.value[urlList.value.length - 1].id
          );
        }
      });
  }
  // 删除文件夹
  if (e == 5) {
    deleteFolderFn(item);
  }
  // 删除文件
  if (e == 4) {
    deleteFileFn(item);
  }
  // 取消过期
  if (e == 6) {
    api.base
      .fileExpire({
        userId: userId.value,
        fileId: item.id,
        expireTime: null,
        libraryId: baseItem.value.knowledge_library_id,
        operateType: "delete",
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success("取消成功");
          getFileList(
            urlList.value.length == 0
              ? null
              : urlList.value[urlList.value.length - 1].id
          );
        }
      });
  }
  // 验证
  if (e == 7) {
    timeObj.value = item;
    timeDialog.value = true;
    timeType.value = 2;
  }
};
// 删除文件
const deleteFileFn = (item) => {
  api.base
    .deleteFile(
      {
        idArray: [item.id],
        userId: userId.value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        message.success("删除成功");
        getFileList(
          urlList.value.length == 0
            ? null
            : urlList.value[urlList.value.length - 1].id
        );
      }
    });
};
// 删除文件夹
const deleteFolderFn = (item) => {
  api.base
    .deleteFolder(
      {
        folderId: item.id,
        userId: userId.value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        message.success("删除成功");
        getFileList(
          urlList.value.length == 0
            ? null
            : urlList.value[urlList.value.length - 1].id
        );
      }
    });
};
// 排序
const changePx = () => {
  pxBtnType.value = !pxBtnType.value;
};
// nav
const changeNav = (index) => {
  navActive.value = index;
  if (index == 1) {
    chunkDialog.value = false;
    getTopic();
  } else {
    fileActive.value = null;
    fileItem.value = {};
    getFileList(
      urlList.value.length == 0
        ? null
        : urlList.value[urlList.value.length - 1].id
    );
  }
  getFileHeight();
};
// 获取话题广场
const getTopic = () => {
  let arr = ["verifyQuery", "hotQuery", "likeQuery", "allQuery"];
  topicLoading.value = true;
  api.base[arr[topicActive.value]](
    {
      userId: userId.value,
      libraryId: baseItem.value.knowledge_library_id,
    },
    baseItem.value.knowledge_library_id
  )
    .then((res) => {
      topicLoading.value = false;
      if (res.returnCode == 200) {
        rightList.value = res.data;
      }
    })
    .catch((err) => {
      topicLoading.value = false;
    });
};
// 上传选择
const upFile = (index) => {
  upTypeActive.value = index;
};
// 关闭上传文件选项
const closeUpDialog = () => {
  upFileList.value = [];
  errorFileList.value = [];
  isExpanded.value = false;
  collType.value = false;
  upFloderName.value = "";
  upFileDialog.value = false;
};
// 保存上传
const saveDialog = () => {
  if (upTypeActive.value == 1) {
    let id =
      urlList.value.length == 0
        ? rootId.value
        : urlList.value[urlList.value.length - 1].id;
    upFileFn(upFileList.value, id);
  } else {
    // 新建文件夹
    api.base
      .createFolderCompanyApi(
        {
          userId: userId.value,
          folderName: upFloderName.value,
          parentId:
            urlList.value.length == 0
              ? rootId.value
              : urlList.value[urlList.value.length - 1].id,
        },
        baseItem.value.knowledge_library_id
      )
      .then((res) => {
        if (res.returnCode == 200) {
          handleNodeClick({ folderName: upFloderName.value, id: res.data });
          upFileFn(upFileList.value, res.data);
        }
      });
  }
};
// 上传文件
const beforeAvatarUpload = (fileList, err) => {
  upFileList.value = fileList;
  errorFileList.value = err;
  upFileDialog.value = true;
};
// 上传文件fuction
const upFileFn = (fileList, id) => {
  upFileDialog.value = false;
  api.base
    .uploadFileCompanyApi(
      fileList,
      { folderId: id },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        pollingFn();
        task();
        if (res.failedFileList.length != 0) {
          const textFile = res.failedFileList.toString();
          message.warning(textFile + "文件名重复");
        }
      }
    });
};
// 轮询
const pollingFn = () => {
  clearInterval(timmer.value);
  timmer.value = null;
  timmer.value = setInterval(() => {
    setTimeout(() => {
      task();
    }, 0);
  }, 2000);
};
// 轮询
const task = () => {
  api.base
    .taskView({ userId: userId.value }, baseItem.value.knowledge_library_id)
    .then((res) => {
      if (res.returnCode == 200) {
        //刷新当前文件夹
        getFileList(
          urlList.value.length == 0
            ? rootId.value
            : urlList.value[urlList.value.length - 1].id
        );
        if (res.data.length == 0) {
          clearInterval(timmer.value);
          timmer.value = null;
          processList.value = [];
        } else {
          processDialog.value = true;
          processList.value = res.data;
          // 判断是否有可暂停的
          let pauseList = processList.value.filter(
            (item) => item.waitableFlag == "1"
          );
          // 有暂停的
          if (pauseList.length != 0) {
            pauseType.value = false;
          } else {
            // 没有 不可点击
            pauseType.value = true;
          }
          // 判断是否有开始的
          let startList = processList.value.filter(
            (item) => item.processStatusFlag == "suspend"
          );
          if (startList.length != 0) {
            startType.value = false;
          } else {
            startType.value = true;
          }
          // 判断是否有取消的
          let cancelList = processList.value.filter(
            (item) =>
              item.processStatusFlag == "ready" ||
              item.processStatusFlag == "suspend"
          );
          if (cancelList.length != 0) {
            cancelType.value = false;
          } else {
            cancelType.value = true;
          }
        }
      }
    });
};
// 判断取消 暂停 开始
const postFile = (item, type) => {
  if (processList.value.length == 0) {
    message.warning("当前暂无上传中任务...");
    return;
  }
  if (type == "pause") {
    fileTypeChange(item, "uploadSuspend");
  } else if (type == "start") {
    fileTypeChange(item, "uploadContinue");
  } else if (type == "delete") {
    fileTypeChange(item, "uploadDelete");
  } else if (type == "allPause") {
    fileTypeChange({}, "uploadAllSuspend");
  } else if (type == "allStart") {
    fileTypeChange({}, "uploadAllStart");
  } else if (type == "allDelete") {
    fileTypeChange({}, "uploadAllDelete");
  }
};
// 公共类
const fileTypeChange = (item, name) => {
  api.base[name](
    { userId: userId.value, taskId: item ? item.taskId : "" },
    baseItem.value.knowledge_library_id
  ).then((res) => {
    if (res.returnCode == 200) {
      clearInterval(timmer.value);
      timmer.value = null;
      pollingFn();
      task();
    }
  });
};
// 保存文件夹名称
const saveFloader = () => {
  if (floderName.value == "") {
    message.warning("文件夹名称不能为空");
    return;
  }
  folderLoading.value = true;
  api.base
    .createFolderCompanyApi(
      {
        userId: userId.value,
        folderName: floderName.value,
        parentId:
          urlList.value.length == 0
            ? rootId.value
            : urlList.value[urlList.value.length - 1].id,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      folderLoading.value = false;
      if (res.returnCode == 200) {
        message.success("新建文件夹成功");
        floderDialog.value = false;
        getFileList(
          urlList.value.length == 0
            ? null
            : urlList.value[urlList.value.length - 1].id
        );
      }
    })
    .catch((err) => {
      folderLoading.value = false;
    });
};
// 上传文件夹
const upFloder = (fileList, name, errorList) => {
  upFileList.value = fileList;
  errorFileList.value = errorList;
  upFloderName.value = name;
  upFileDialog.value = true;
};
// 关闭文件预览
const closeFileView = () => {
  fileItem.value = {};
  fileActive.value = null;
};
</script>

<style lang="scss" scoped>
.baseInner {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}
.leftInner {
  width: 402px;
  height: calc(100% - 74px);
  margin-right: 21px;
  background-color: #fefefe;
  border-radius: 30px;
  padding: 37px 43px;
}
.leftInner1 {
  width: 270px;
  height: calc(100% - 74px);
  margin-right: 21px;
  background-color: #fefefe;
  border-radius: 30px;
  padding: 37px 30px;
}
.rightTopic {
  width: calc(100% - 351px);
  height: calc(100% - 60px);
  border-radius: 30px;
  background-color: #ffffff;
  padding: 30px 59px;
}
.noDataFile {
  width: calc(100% - 518px);
  height: 100%;
  background-color: #fefefe;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.navItem {
  font-size: 20px;
  color: #8a8a8a;
  font-family: "Regular";
  width: 92px;
  text-align: center;
  margin-right: 22px;
  height: 35px;
  cursor: pointer;
}
.navItemActive {
  font-size: 20px;
  color: #0256ff;
  font-family: "Regular";
  width: 92px;
  text-align: center;
  margin-right: 22px;
  height: 31px;
  border-bottom: 4px solid #0256ff;
  cursor: pointer;
}
.searchInput {
  :deep(.el-select__wrapper) {
    height: 48px;
    border: 1px solid #edeef6;
    margin-bottom: 15px;
    font-size: 18px;
    color: #8a8a8a;
    font-family: "Regular";
    border-radius: 16px;
  }
  :deep(.el-select__popper) {
    width: 402px;
    z-index: 9999;
  }
}
.topicInput {
  width: 390px;
  :deep(.el-input__wrapper) {
    height: 44px;
    border: 1px solid #edeef6;
    font-size: 18px;
    color: #8a8a8a;
    font-family: "Regular";
    border-radius: 16px;
  }
}
.topicSearch {
  display: flex;
  align-items: center;
  height: 60px;
  margin-bottom: 17px;
}
.upFileBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 12px 13px;
  font-size: 18px;
  font-family: "Regular";
  border: 2px solid #edeef6;
  color: #0256ff;
  cursor: pointer;
}
.addFiles {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-size: 18px;
  font-family: "Regular";
  color: #8a8a8a;
  padding: 12px 20px;
  border: 2px solid #edeef6;
  cursor: pointer;
}
.upDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 49px;
  margin-bottom: 15px;
}
.width30 {
  margin-right: 6px;
  width: 24px;
}
.upFiles {
  width: 18px;
  margin-right: 9px;
}
.wid11 {
  width: 24px;
  margin-left: 6px;
}
.qaBtn {
  width: 100%;
  height: 49px;
  border-radius: 16px;
  font-size: 22px;
  color: #fefefe;
  font-family: "Regular";
  background-color: #0256ff;
  border: 0px;
}
.fileListDiv {
  overflow: auto;
}
.talkDiv {
}
.fileBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24px);
  height: 100%;
}
.fileIcon {
  width: 21px;
  height: 21px;
  margin-right: 7px;
}
.fileTitle {
  color: #3d3d3d;
  font-size: 22px;
  font-family: "Regular";
  max-width: calc(100% - 22px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileYear {
  width: 22px;
  margin-left: 10px;
}
:deep(.el-tree-node) {
  height: 64px;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
}
:deep(.el-tree-node__content) {
  width: 100%;
  height: 100%;
}
.fileMoreImg {
  width: 4px;
}
.treeDiv {
  width: calc(100% - 52px);
}
.bottomTreeDiv {
  width: calc(100% - 16px);
}
.wid24 {
  width: 24px;
}
.rightInner {
  width: calc(100% - 518px);
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
}
.topMenu {
  height: 32px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: "Medium";
  cursor: pointer;
  padding: 17px 88px 15px 49px;
}
.topMenuActive {
  background-color: #f7f8ff;
  color: #0256ff;
}
.marginRight13 {
  margin-right: 13px;
}
.roleDiv {
  border-radius: 16px;
  border: 2px solid #edeef6;
  display: flex;
  align-items: center;
  width: 180px;
  height: 24px;
  padding: 10px 20px;
  justify-content: space-between;
  margin-left: 14px;
  :deep(.el-dropdown) {
    max-width: calc(100% - 70px);
    font-size: 18px;
    color: #3d3d3d;
    font-family: "Regular";
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.roleTitle {
  color: #8a8a8a;
  font-size: 18px;
  font-family: "Regular";
  width: 70px;
}
.baseItemDiv {
  height: calc(100% - 77px);
  overflow: auto;
}
.nodataCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fileItemDiv {
  height: 26px;
  padding: 13px 0px 13px 30px;
  cursor: pointer;
}
.fileItemDivActive {
  height: 26px;
  padding: 13px 0px 13px 30px;
  cursor: pointer;
  border-radius: 20px;
  background-color: #eceeff;
}
.fileItemDiv:hover {
  border-radius: 20px;
  background-color: #eceeff;
}
.bottomFileItem {
  height: 26px;
  padding: 13px 0px 13px 30px;
  cursor: pointer;
  border-radius: 20px;
  background-color: #eceeff;
  margin-bottom: 8px;
}
.fileMoreImgDiv {
  width: 36px;
  height: 36px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fileMoreImgDiv:hover {
  background-color: #dbdfff;
}
.noFileImg {
  width: 360px;
}
.leftDiv {
  width: 100%;
  height: 100%;
}
.drapBottom {
  width: 20px;
  height: 20px;
  margin-right: 11px;
}
.drapBottomText {
  font-size: 16px;
  color: #8a8a8a;
  font-family: "Medium";
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.drapFlex {
  display: flex;
  margin-bottom: 15px;
}
.drapBottomDiv {
  background-color: #f7f8ff;
  border-radius: 20px;
  padding: 12px;
}
.VueDraggable {
  width: 100%;
  height: 100%;
}
.closeFileBottom {
  width: 16px;
}
.bottomMax {
  max-height: 232px;
  overflow: auto;
}
.dialog {
  position: fixed;
  width: 540px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 300;
  box-shadow: 0px 0px 15px 0px #dcdcdc;
}
.dialogTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
  margin-bottom: 24px;
}
.closeFileViewImg {
  width: 14px;
  cursor: pointer;
}
.dialogBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialogTip {
  font-size: 18px;
  font-family: "Medium";
  color: #3d3d3d;
  margin-bottom: 17px;
}
.dialogInput {
  margin-bottom: 24px;
  :deep(.el-input__wrapper) {
    border-radius: 16px;
    border: 2px solid #edeef6;
    box-shadow: 0px 0px 0px;
    height: 48px;
    font-size: 18px;
    font-family: "Regular";
  }
}
.dialogCancelBtn {
  height: 48px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  padding: 16px 20px;
  color: #f02d63;
  font-family: "Regular";
  font-size: 18px;
}
.dialogSaveBtn {
  height: 48px;
  border: 2px solid #3376ff;
  background-color: #3376ff;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  padding: 16px 20px;
  color: #fefefe;
  font-family: "Regular";
  font-size: 18px;
}
.dialogEnd {
  display: flex;
  justify-content: flex-end;
}
.szText {
  color: #0256ff;
  font-family: "Regular";
  font-size: 18px;
  cursor: pointer;
}
.szImg {
  width: 25px;
  margin-right: 3px;
  cursor: pointer;
}
.marginBottom15 {
  margin-bottom: 15px;
}
.px {
  width: 13px;
}
.pxDiv {
  width: 34px;
  height: 34px;
  border: 1px solid #edeef6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14px;
  cursor: pointer;
}
.pxDivActive {
  width: 34px;
  height: 34px;
  border: 1px solid #edeef6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14px;
  cursor: pointer;
  background-color: #edeef6;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 200;
  opacity: 0;
}
.maskFile {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 200;
  opacity: 0.5;
}
.upFileDialog {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  padding: 30px;
  width: 600px;
  max-height: 782px;
  background-color: #fefefe;
  border-radius: 30px;
}
.upFileTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}
.upFileTitleDiv {
  height: 36px;
  padding-bottom: 17px;
  border-bottom: 2px solid #edeef6;
  margin-bottom: 17px;
}
.upFileTip {
  color: #8a8a8a;
  font-family: "Regular";
  font-size: 18px;
}
.upListItemTitle {
  color: #3d3d3d;
  font-size: 18px;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 43px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.upFileItemTip {
  color: #3376ff;
  font-size: 18px;
  font-family: "Regular";
  text-align: right;
  width: 40%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.upFileItemDiv {
  display: flex;
  align-items: center;
  padding: 15px 20px;
}
.upFileLeftDiv {
  width: 60%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.upFileBody {
  max-height: calc(100% - 120px);
  overflow: auto;
}
.zk {
  width: 10px;
  margin-right: 5px;
  cursor: pointer;
  transition: 0.5s ease;
}
.zkText {
  color: #8a8a8a;
  font-family: "Regular";
  font-size: 18px;
  margin: 10px 0px;
  text-align: center;
  cursor: pointer;
}
.historyDialog {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  padding: 30px;
  width: 820px;
  height: 782px;
  background-color: #fefefe;
  border-radius: 30px;
}
.historyDiv {
  height: calc(100% - 106px);
  margin: 20px 0px;
}
.hisSuccess {
  color: #14ae5c;
  font-family: "Semibold";
  font-size: 18px;
}
.hisError {
  color: #f02d63;
  font-family: "Semibold";
  font-size: 18px;
}
.tableClearBtn {
  height: 31px;
  background-color: #f7f8ff;
  border: 0px;
  border-radius: 8px;
  font-size: 18px;
  color: #f02d63;
  font-family: "Regular";
}
.tableText {
  color: #3d3d3d;
  font-family: "Regular";
  font-size: 18px;
}
.tableMore {
  width: calc(100% - 28px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
:deep(.el-table--border th.el-table__cell) {
  background-color: #f7f8ff;
  font-size: 18px;
  color: #8a8a8a;
}
.tableFileNameDiv {
  width: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.processDialog {
  position: absolute;
  right: 20px;
  bottom: 10px;
  background-color: #fefefe;
  border-radius: 16px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  width: 660px;
  z-index: 100;
  max-height: 424px;
}
.img36 {
  width: 36px;
  cursor: pointer;
}
.processTop {
  display: flex;
  align-items: center;
  width: calc(100% - 72px);
}
.processTopTitle {
  margin-left: 12px;
  color: #3d3d3d;
  font-size: 26px;
  font-family: "Meduim";
  max-width: calc(100% - 48px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.processBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.processItemDiv {
  height: 60px;
  background-color: #f7f8ff;
  line-height: 60px;
  padding: 0px 30px;
  font-size: 18px;
  color: #000000;
  font-family: "Regular";
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.processItem {
  position: absolute;
  left: 0px;
  height: 100%;
  background-color: #dbdfff;
}
.cancelBtn {
  font-size: 18px;
  color: #f02d63;
  font-family: "Regular";
  margin-right: 12px;
  cursor: pointer;
}
.stopBtn {
  font-size: 18px;
  color: #3376ff;
  font-family: "Regular";
  cursor: pointer;
}
.zindex {
  z-index: 20;
}
.fileListItemUp {
  max-height: 264px;
  overflow: auto;
}
.pageHeader {
  height: 25px;
  :deep(.el-page-header__title) {
    font-size: 16px;
    font-family: "Regular";
    color: #8a8a8a;
  }
  :deep(.el-page-header__content) {
    font-size: 16px;
    font-family: "Regular";
    color: #3d3d3d;
  }
}
.noData {
  width: 300px;
}
.noDataDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

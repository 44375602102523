<script setup>
import { ref, defineProps, watch, onMounted, defineEmits } from "vue";
import { useStore } from "vuex";
import api from "@/api/index";
const store = useStore();
const userId = ref(store.state.userId);
const emit = defineEmits(['handleClose', 'handleSave']);
const props = defineProps({
    show: Boolean,
    departmentInfo: Object,
    formType: String
});
onMounted(() => {
    getRole()
    getDepart()
    getUserList()
})
watch(
    () => props.departmentInfo,
    (newVal) => {
        props.departmentInfo = newVal
    })
watch(
    () => props.formType,
    (newVal) => {
        if (newVal == 'add') {
            props.departmentInfo = {}
        }
        props.formType = newVal
    }
);
const defaultProps = ref({
    children: "children",
    label: "departmentName",
    id: "departmentId",
});
// 部门树形列表
const departmentList = ref([]);
const getDepart = () => {
    api.manage
        .getDepartmentList({ userId: userId.value, pageId: 0 })
        .then((res) => {
            if (res.returnCode == 200) {
                departmentList.value = res.data;
            }
        })
}
// 角色树形列表
const roleList = ref([]);
const getRole = () => {
    api.manage
        .queryUserRole({ userId: userId.value, pageId: 0 })
        .then((res) => {
            if (res.returnCode == 200) {
                roleList.value = res.data;
            }
        })
}

// 取消
const handleClose = () => {
    emit('handleClose')
}
const rules = ref({
    departmentName: [
        {
            required: true,
            message: '请输入部门名称',
            trigger: "blur",
        },
    ],
    parentId: [
        { required: true, message: '请选择上级部门', trigger: 'blur' },
    ],

})
const formRef = ref(null)
//保存新增
const handleSave = async (formRef) => {
    props.departmentInfo.userId = userId.value,
        await formRef.validate((valid) => {
            if (valid) {
                emit('handleSave', props.departmentInfo)
            }
        })

};
// 关闭编辑/保存
const editSave = async (formRef) => {
    await formRef.validate((valid) => {
        if (valid) {
            emit('editSave', props.departmentInfo)
        }
    })
};
// 部门负责人下拉
const userForm = ref({
    userId: userId.value,
    pageId: 1,
    departmentId: props.departmentInfo.departmentId,
    activate: 1,
    ifDirectly: false,
});
const userList = ref([])
const getUserList = () => {
    api.manage
        .getUserList(userForm)
        .then((res) => {
            if (res.returnCode == 200) {
                userList.value = res.data
            }
        })
};
const form = ref({
    departmentName: '',
    parentId: props.departmentInfo.parentId,
    userId: userId.value,
})
const formRef2 = ref(null);
const rules2 = ref({
    departmentName: [
        {
            required: true,
            message: '请输入子部门名称',
            trigger: "blur",
        },
    ],
});
const addSave = async (formRef2) => {
    await formRef2.validate((valid) => {
        if (valid) {
            emit('addSave', form.value)
        }
    })
};
</script>

<template>
    <div>
        <el-dialog v-model="props.show" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>{{ props.formType == 'add' ? '部门新增' : props.formType == 'edit' ? '部门编辑' : '新增子部门' }}</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleClose" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <div class="content">
                <el-form :model="form" ref="formRef2" :rules="rules2" label-position="top"
                    v-if="props.formType == 'addSmall'">
                    <el-form-item prop="departmentName" label="子部门名称">
                        <el-input class="comtent" v-model="form.departmentName" placeholder="请输入子部门名称"></el-input>
                    </el-form-item>
                </el-form>
                <el-form :model="departmentInfo" class="form" :rules="rules" ref="formRef" label-position="top" v-else>
                    <el-form-item prop="departmentName" label="部门名称">
                        <el-input class="comtent" v-model="departmentInfo.departmentName"
                            placeholder="请输入部门名称"></el-input>
                    </el-form-item>
                    <el-form-item prop="parentId" label="上级部门"
                        v-if="props.formType === 'add' || props.formType === 'edit'">
                        <el-tree-select :data="departmentList" v-model="departmentInfo.parentId" check-strictly
                            :render-after-expand="false" :props="defaultProps" node-key="departmentId">
                            <template #default="{ data }">
                                <div>
                                    {{ data.departmentName }}
                                </div>
                            </template>
                        </el-tree-select>
                    </el-form-item>
                    <el-form-item prop="manager" v-if="props.formType == 'edit'" label="部门负责人">
                        <el-select v-model="departmentInfo.manager" clearable>
                            <el-option v-for="(items, index) in userList" :key="index" :label="items.departmentName"
                                :value="items.departmentId" />
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <div class="dialog-footer" v-if="props.formType == 'add'">
                    <div @click="handleClose" class="cancel">放弃</div>
                    <div @click="handleSave(formRef)" class="confirm">提交</div>
                </div>
                <div class="dialog-footer" v-if="props.formType == 'edit'">
                    <div @click="handleClose" class="cancel">取消</div>
                    <div @click="editSave(formRef)" class="confirm">确定</div>
                </div>
                <div class="dialog-footer" v-if="props.formType == 'addSmall'">
                    <div @click="handleClose" class="cancel">取消</div>
                    <div @click="addSave(formRef2)" class="confirm">添加</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>



<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-dialog) {
    --el-dialog-width: 600px;
    border-radius: 30px;
    padding: 30px;
}

:deep(.el-input__wrapper) {
    height: 38px !important;
    width: 520px !important;
    margin-bottom: 10px !important;
}

:deep(.el-select__wrapper) {
    height: 42px !important;
    border-radius: 16px !important;
    width: 540px !important;
    margin-bottom: 10px !important;
}

:deep(.el-form-item__label) {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #3D3D3D !important;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 24px;
    position: relative;
}

.close-icon {
    font-size: 20px;
}

.content {
    font-size: 18px;
    color: #8A8A8A;

    .name {
        color: #3D3D3D;
        font-size: 24px;
        margin: auto 6px auto 18px;
        font-weight: 500;
    }

    .avatar {
        height: 50px;
        width: 50px;
    }

    .tag {
        display: flex;
        justify-content: space-between;
        background-color: #B8E6CD;
        color: #14AE5C;
        padding: 0px 6px;
        height: 30px;
        line-height: 30px;
        border-radius: 6px;
        margin: auto 0;

        .tag-icon {
            width: 18px;
            height: 18px;
            display: block;
            margin-top: 3px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .tag-text {
            margin-left: 4px;
        }
    }

    .tag_cuo {
        display: flex;
        justify-content: space-between;
        background-color: #FBD4DF;
        color: #F02D63;
        padding: 0px 6px;
        height: 30px;
        line-height: 30px;
        border-radius: 6px;
        margin: auto 0;

        .tag-icon {
            width: 18px;
            height: 18px;
            display: block;
            margin-top: 3px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .tag-text {
            margin-left: 4px;
        }
    }

    .reset {
        color: #0256FF;
        margin: auto 10px
    }
}

.form {
    margin-top: 20px;
}

.form-name {
    font-size: 18px;
    color: #3D3D3D;

    .el-tag {
        display: flex;
        padding: 2px 33px 2px 2px;
        height: 35px;
        line-height: 35px;
        border-radius: 30px;
        font-size: 18px;
        margin-top: 12px;

        .tag-img {
            width: 30px;
            height: 30px;
            margin-right: 6px;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    }

    .tit {
        margin-bottom: 10px;
        font-weight: 600;
    }

    .comtent {
        margin-bottom: 12px;
    }
}

.isActive {
    margin-right: 6px;
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #F02D63;
        padding: 6px 12px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 6px 12px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
<script setup>
import { ref, defineProps, onMounted, watch, nextTick } from "vue";
import { Search } from "@element-plus/icons-vue";
const emit = defineEmits(['handleClose', 'handleAdd']);
import { useStore } from "vuex";
import api from "@/api/index";
const store = useStore();
const userId = ref(store.state.userId);
const props = defineProps({
    showEdit: Boolean,
    roleInfo: Object,
    formType: String,
});
watch(
    () => props.roleInfo,
    (newVal) => { 
        props.roleInfo = newVal
    }
)
watch(
    () => props.formType,
    (newVal) => {        
        props.formType = newVal
    }
)

onMounted(() => {
    if (props.formType == 'add') {
        resetForm()
    }
    getTableList();
    getDepartment();
    getQxList();
})

// 左侧部门菜单
const defaultProps = ref({
    children: "children",
    label: "departmentName",
    id: "departmentId",
});
const listForm = ref({
    userId: userId.value,
    departmentName: "",
});

const tableId = ref(1); //部门ID
watch(
    () => tableId.value,
    (newVal) => {
        userTable.value.departmentId = newVal;
        getUserTable()
    }
)
const data = ref([])
const getTableList = () => {
    api.manage
        .getGroupList(listForm.value)
        .then((res) => {
            if (res.returnCode == 200) {
                data.value = res.data;
                getUserTable()
                tableId.value = res.data[0]?.departmentId;//默认选中第一个部门ID
            }
        })
};
// 部门树形列表点击
const multipleTableRef = ref(null);
const multipleSelection = ref([]);
const newArrList = ref([]) //用户选中数据
const handleNodeClick = (data) => {    
    tableId.value = data.departmentId
};
const user = ref(null)
watch(
    () => user.value,
    (newVal) => {
        userTable.value.userNamePattern = newVal
        getUserTable()
    }
)
// 请求表格数据
const userTable = ref({
    userId: userId.value,
    userNamePattern: '',
    departmentId: tableId.value,
    activate: 1,
    ifDirectly: true,
})
const tableMember = ref([])
const getUserTable = () => {
    api.manage
        .getUserList(userTable.value)
        .then((res) => {
            if (res.returnCode == 200) {
                tableMember.value = res.data
                toggleSelection();
            }
        })
};

watch(
    () => multipleSelection.value,
    (newVal) => {
        multipleSelection.value = newVal
    }
)
const handleSelectionChange = (val) => {
    multipleSelection.value = val;
    multipleSelection.value.map((item) => {
        newArrList.value.push(item)
    })
};
const toggleSelection = () => {
    nextTick(() => {
        newArrList.value.forEach((row) => {
            tableMember.value.forEach((item) => {
                if (item.userId === row.userId) {
                    multipleTableRef.value.toggleRowSelection(item, true);
                }
            });
        });
    });
};
// 部门下拉
const departmentList = ref([{
    parentId: null,
    departmentName: '',
    departmentId: null,
    children: []
}]);
const getDepartment = () => {
    api.log
        .getDepartmentName({ userId: userId.value, pageId: 0 })
        .then((res) => {
            if (res.returnCode === 200) {
                departmentList.value = res.data;
            }
        });
};
// 作用域
const roseScopes = ref();
const roseScopesList = ref([]);
// 选中
const changeRoseScopes = (data, check) => {
    const index = roseScopesList.value.findIndex(item => item.departmentId === data.departmentId);
    if (check) {
        roseScopesList.value.push(data);
    } else {
        if (index !== -1) {
            roseScopesList.value.splice(index, 1);
        }
    }
}
// 移除
const removeRoseScopes = (val) => {
    const index = roseScopesList.value.findIndex(item => item.departmentId === (typeof val === 'object' ? val.departmentId : val));
    if (index !== -1) {
        roseScopesList.value.splice(index, 1); // 移除选中的项
    }
}
watch(
    () => roseScopesList.value,
    (newVal) => {
        roseScopesList.value = newVal
    }
)
// 权限管理树形列表
const tdList = ref([]);
const zsList = ref([]);
const bsList = ref([]);

const defaultProps2 = {
    children: 'children',
    label: 'all_name',
}
const getQxList = () => {
    api.manage
        .getRoleTreeApi({
            userId: userId.value,
        })
        .then((res) => {            
            if (res.returnCode == 200) {   
                console.log(res.data,'权限管理树形列表');             
                res.data[0].children.forEach((item) => {
                    console.log(item);
                    if (item.name == 'TDGL') {
                        tdList.value = [item]
                    } else if (item.name == 'ZSGC') {
                        zsList.value = [item]
                    } else if (item.name == 'BSGL') {
                        bsList.value = [item]
                    }
                })
            }
        })
};
// 权限列表
const checkTdList = ref([]);
const tdBtn = (data, check) => {
    const index = checkTdList.value.findIndex(item => item.id == data.id);
    if (check) {
        if (index == -1) {
            checkTdList.value.push(data);
        }
    } else {
        if (index != -1) {
            checkTdList.value.splice(index, 1);
        }
    }
};
const zsBtn = (data, check) => {
    const index = checkTdList.value.findIndex(item => item.id == data.id);
    if (check) {
        if (index == -1) {
            checkTdList.value.push(data);
        }
    } else {
        if (index != -1) {
            checkTdList.value.splice(index, 1);
        }
    }
};
const bsBtn = (data, check) => {
    const index = checkTdList.value.findIndex(item => item.id == data.id);
    if (check) {
        if (index == -1) {
            checkTdList.value.push(data);
        }
    } else {
        if (index != -1) {
            checkTdList.value.splice(index, 1);
        }
    }
};
const rules = ref({
    roleName: [{
        required: true,
        message: '角色名称不能为空',
        trigger: "blur",
    }],
    roles: [{
        required: true,
        message: '请选择权限范围',
        trigger: "blur",
    }],
    userIdList: [{
        required: true,
        message: '选择该角色下的用户',
        trigger: "blur",
    }],
    description: [{
        required: true,
        message: '角色描述不能为空',
        trigger: "blur",
    }],
    roseScopes: [{
        required: true,
        message: '请配置作用域',
        trigger: "blur",
    }]
});
// 拒绝
const handleClose = () => {
    emit('handleClose')
}
const formRef = ref(null)
//同意
const handleConfirm = async(formRef) => {    
    const checkTdListId = checkTdList.value.map(item => item.id);
    const userIdLis = newArrList.value.map(item => item.userId);
    const roseScopesId = roseScopesList.value.map(item => item.departmentId);
    props.roleInfo.userId = userId.value;
    props.roleInfo.roles = checkTdListId;
    props.roleInfo.roseScopes = roseScopesId;
    props.roleInfo.userIdList = userIdLis;
    await formRef.validate((valid) => {
        if (valid) {
            emit('handleSubmit', props.roleInfo)
        }
    });
    
};
// 重置
const resetForm = () => {
    checkTdList.value = []
    newArrList.value = []
    roseScopesList.value = []
};
// 新增用户
const addSave = async (formRef) => {
    const checkTdListId = checkTdList.value.map(item => item.id);
    const userIdLis = newArrList.value.map(item => item.userId);
    const roseScopesId = roseScopesList.value.map(item => item.departmentId);
    props.roleInfo.userId = userId.value;
    props.roleInfo.roles = checkTdListId;
    props.roleInfo.roseScopes = roseScopesId;
    props.roleInfo.userIdList = userIdLis;
    await formRef.validate((valid) => {
        if (valid) {
            emit('addSubmit', props.roleInfo)
        }
    });
};
</script>

<template>
    <div>
        <!-- 角色编辑 -->
        <el-dialog v-model="props.showEdit" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>{{ formType == 'add' ? '新增角色' : '编辑角色' }}</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleClose" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <div class="flexDiv">
                <el-form ref="formRef" :model="roleInfo" :rules="rules" label-position="top">
                    <el-form-item prop="description" label="角色名称">
                        <el-input class="comtent" v-model="roleInfo.description" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item prop="roleName" label="角色简介">
                        <el-input class="comtent" v-model="roleInfo.roleName" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item prop="userIdList" label="编辑成员">
                        <el-container>
                            <div class="container">
                                <!-- 用户 -->
                                <el-input v-model="user" size="large" class="searchInput" placeholder="用户名/手机号/邮箱">
                                    <template #prefix>
                                        <el-icon class="el-input__icon">
                                            <search />
                                        </el-icon>
                                    </template>
                                </el-input>
                                <el-aside class="side">
                                    <el-tree ref="treeRef" :data="data" node-key="departmentId" :props="defaultProps"
                                        :default-expand-all="true" :expand-on-click-node="false"
                                        @node-click="handleNodeClick" highlight-current :current-node-key="tableId">
                                        <template #="{ data }">
                                            <span class="custom-tree-node">
                                                <span>{{ data.departmentName }}</span>
                                            </span>
                                        </template>
                                    </el-tree>
                                </el-aside>
                            </div>
                            <el-main class="userMain">
                                <el-table :data="tableMember" @selection-change="handleSelectionChange"
                                    class="tableHeight" ref="multipleTableRef">
                                    <el-table-column type="selection" />
                                    <el-table-column prop="userName" :label="$t('manage.userForm.userName')" />
                                    <el-table-column prop="departmentName" show-overflow-tooltip
                                        :label="$t('manage.userForm.Department')" />
                                </el-table>
                            </el-main>
                        </el-container>
                    </el-form-item>
                    <el-form-item prop="roseScopes" label="配置作用域">
                        <el-tree-select multiple show-checkbox :data="departmentList" v-model="roseScopes"
                            :render-after-expand="false" :placeholder="$t('bot.selectAdd')" :props="defaultProps"
                            node-key="departmentId" @check-change="changeRoseScopes" @remove-tag="removeRoseScopes">
                            <template #default="{ data }">
                                <div>
                                    {{ data.departmentName }}
                                </div>
                            </template>
                        </el-tree-select>
                    </el-form-item>
                    <el-form-item prop="roles" label="配置权限范围">
                        <div class="treeList">
                            <div class="aside_one">
                                <el-tree :data="tdList" show-checkbox node-key="id"
                                    :default-expanded-keys="[10001, 10002]" :props="defaultProps2"
                                    @check-change="tdBtn" />
                            </div>
                            <div>
                                <div class="aside_two button">
                                    <el-tree :data="zsList" show-checkbox node-key="id" :default-expanded-keys="[10034]"
                                        :props="defaultProps2" @check-change="zsBtn" />
                                </div>
                                <div class="aside_two">
                                    <el-tree :data="bsList" show-checkbox node-key="id" :default-expanded-keys="[10047]"
                                        :props="defaultProps2" @check-change="bsBtn" />
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <div class="dialog-footer" v-if="props.formType == 'add'">
                    <div @click="handleClose" class="cancel">取消</div>
                    <div @click="addSave(formRef)" class="confirm">新增</div>
                </div>
                <div class="dialog-footer" v-if="props.formType == 'edit'">
                    <div @click="handleClose" class="cancel">放弃</div>
                    <div @click="handleConfirm(formRef)" class="confirm">提交</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>



<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-dialog) {
    --el-dialog-width: 756px;
    border-radius: 30px;
    padding: 30px;
    max-height: 872px;
}

:deep(.el-tree) {
    background-color: #f7f8ff;
    color: #3D3D3D;
}

:deep(.el-tree-node__content) {
    height: 34px;
    border-radius: 16px;
    font-size: 15px;
    color: #3d3d3d;
    font-family: "Regular";
}

:deep(.el-tree-node__content:hover) {
    background-color: #eceeff;
    color: #0256ff;
}

:deep(.el-tree-node:focus > .el-tree-node__content) {
    background-color: #eceeff;
    color: #0256ff;
}

:deep(.el-input__wrapper) {
    border-radius: 16px;
    width: 130px !important;
    margin-bottom: 10px;
    margin-right: 10px;
}

:deep(.el-select__wrapper) {
    min-height: 42px !important;
    border-radius: 16px !important;
    width: 670px !important;
}

:deep(.custom-tree-node) {
    font-size: 16px;

}

:deep(.el-tag.is-closable) {
    height: 35px !important;
    line-height: 35px !important;
    border-radius: 8px !important;
    font-size: 16px !important;
    background-color: #eceeff !important;
    color: #3D3D3D !important;
}

:deep(.el-tag .el-tag__close:hover) {
    background-color: #3D3D3D !important;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 24px;
    position: relative;
}

.close-icon {
    font-size: 20px;
}

.flexDiv {
    height: 660px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px 10px;
}

.form-name {
    font-size: 18px;
    color: #3D3D3D;

    .el-tag {
        display: flex;
        padding: 2px 33px 2px 2px;
        height: 35px;
        line-height: 35px;
        border-radius: 30px;
        font-size: 18px;
        margin-top: 12px;

        .tag-img {
            width: 30px;
            height: 30px;
            margin-right: 6px;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    }

    .tit {
        margin-bottom: 10px;
        font-weight: 600;
    }

    .comtent {
        margin-bottom: 12px;
    }

    .select {
        margin-bottom: 12px;
    }
}

.container {
    position: relative;

    .side {
        max-width: 200px;
        height: 240px;
        overflow: auto;
        border-radius: 16px;
        background-color: #f7f8ff;
        padding: 0px 2px;
        position: relative;
        margin-right: 20px;
    }
}

.tableHeight {
    height: 300px;
}

.comtent {

    .aside {
        width: 200px;
        height: 200px;
        background-color: #F7F8FF;
        margin-right: 6px;
        border-radius: 16px;
        padding: 8px;

        .baseInput {
            width: 180px;
            height: 36px;
            height: 36px;
            font-size: 14px;
            color: #8a8a8a;
            font-family: "Regular";
        }

        .tree {
            margin-top: 10px;
            height: 314px;
            overflow-y: auto;
            overflow-x: hidden;


        }
    }

    .rightMain {
        width: 470px;
        height: 200px;
        border-radius: 0 0 16px 16px;

        .table {
            height: 200px;
        }


    }
}

// 配置权限范围
.treeList {
    display: flex;

    .aside_one {
        width: 325px;
        height: 380px;
        background-color: #F7F8FF;
        border-radius: 16px;
        padding: 8px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-right: 20px;
    }

    .aside_two {
        width: 325px;
        height: 180px;
        background-color: #F7F8FF;
        border-radius: 16px;
        padding: 8px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .button {
        margin-bottom: 20px;
    }
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #F02D63;
        padding: 6px 12px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 6px 12px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
<template>
    <div class="imgageContent">
        <div class="btnRight">
            <div class="left" @click="back">
                <el-icon class="icon">
                    <Back />
                </el-icon>
                返回
            </div>
            <Button type="link" @click="getHistory">历史记录</Button>
        </div>
        <!-- 上传 -->
        <div v-if="type == 'upFile'">
            <UploadDragger class="upFile" name="file" :multiple="false" :showUploadList="false"
                :customRequest="customRequest" action="#" :before-upload="beforeUpload">
                <div class="iconCenter">
                    <img src="@/assets/upload/word.svg" alt="" class="imageIcon">
                </div>
                <div class="imageFileText">点击上传/拖拽图片到这里</div>
            </UploadDragger>
        </div>
        <div v-if="type == 'loading'" class="upFile">
            <loading></loading>
            <div class="loadingNow">正在解析图片中...</div>
        </div>
        <!-- 成功 -->
        <div v-if="type == 'success'" class="upFile">
            <div class="imgRow">
                <div class="imgLeft">
                    <div class="imgTopFlex">
                        <div class="dr"></div>
                        <div class="imgTitle">图片</div>
                    </div>
                    <div class="leftCenter">
                        <img :src="detail.image_url" class="leftImg" alt="" />
                    </div>
                    <div class="leftBottom">
                        <div class="flexBetween">
                            <div class="imgTopFlex">
                                <div class="dr"></div>
                                <div class="imgTitle">图片识别</div>
                            </div>
                            <div class="flexCenterRight">
                                <!-- <Icon type="file-word" class="rightIcon" theme="filled" /> -->
                                <div class="exportText" @click="exportText">导出为文档</div>
                            </div>
                        </div>
                        <div class="leftBottomText">
                            <markdownChat :message="detail.word"></markdownChat>
                        </div>
                    </div>
                </div>
                <div class="imgRight">
                    <div class="flexBetween">
                        <div class="imgTopFlex">
                            <div class="dr"></div>
                            <div class="imgTitle">AI润色预览</div>
                        </div>
                        <div class="flexCenterRight">
                            <Icon type="file-word" class="rightIcon" theme="filled" />
                            <div class="exportText" @click="exportText">导出为文档</div>
                        </div>
                    </div>
                    <div class="leftBottomText">
                        <markdownChat :message="detail.describe"></markdownChat>
                    </div>
                </div>
            </div>
        </div>
        <!-- 历史 -->
        <Drawer v-if="historyType" title="历史记录" placement="right" :closable="false" :width="300" :open="historyType"
            @close="historyType = false">
            <div>
                <div v-for="item in historyData" :key="item.id" class="historyDiv">
                    <img :src="item.imageUrl" alt="" style="width: 100%; cursor: pointer" @click="openDetails(item)" />
                </div>
            </div>
        </Drawer>
    </div>
</template>

<script setup>
import api from "@/api/index";
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { Button, UploadDragger, Icon,Drawer } from "ant-design-vue";
const { t } = useI18n();
const router = useRouter();
const store = useStore();
const type = ref("upFile");
const historyType = ref(false);
const userId = ref(store.state.userId);
const fileList = ref([]);
const detail = ref({});
const historyData = ref([]);

const exportText = () => {
    const imageUrl = detail.value.file_url;
    const a = document.createElement("a");
    a.href = imageUrl;
    a.download = "downloaded-image.jpg"; // 设置保存的文件名
    a.style.display = "none";
    // 将链接添加到DOM中
    document.body.appendChild(a);
    // 模拟用户点击链接以触发下载
    a.click();
    // 从DOM中移除链接
    document.body.removeChild(a);
};

const getHistory = () => {
    api.bot
        .imageHistory({ userId: userId.value }).then((res) => {
            if (res.returnCode == 200) {
                historyType.value = true;
                historyData.value = res.data;
            }
        });
};
const beforeUpload = (file) => {    
    fileList.value = [file];
    upFile();
};
const upFile = () => {
    type.value = "loading"
    api.bot
        .imgToTxt(fileList.value, {}).then((res) => {
            console.log(res);
            type.value = "success";
            if (res.returnCode == 200) {
                detail.value = res.data;
            }
        });
};
const openDetails = (item) => {
    detail.value = item;
    detail.value.image_url = item.imageUrl;
    detail.value.word = item.contentMessage;
    detail.value.describe = item.describeMessage;
    detail.value.file_url = item.fileUrl;
    type.value = "success";
    historyType.value = false;
}
const customRequest = () => {};
// 返回上一页
const back = () => {
    router.go(-1);
};
</script>

<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.imgageContent {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 30px;
    min-width: 1430px;
    min-height: 711px;
    width: 100%;
    height: 100%;
}

.btnRight {
    display: flex;
    justify-content: space-between;
    font-family: "Regular";

    .left {
        font-size: 16px;
        color: #7d7d7d;
        padding-top: 4px;
        cursor: pointer;
    }
}

.upFile {
    width: 100%;
    height: calc(100% - 30px);
}

:deep(.ant-upload.ant-upload-drag) {
    background-color: #edf2ff;
}

:deep(.ant-upload-wrapper .ant-upload-drag) {
    width: 1460px !important;
    height: 690px !important;
}

.iconCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 180px;
}

.imageIcon {
    display: block;
    width: 70px;
    margin-bottom: 20px;
}

.imageFileText {
    color: #000000;
    font-family: "Regular";
    font-size: 18px;
}

.imgRow {
    height: 100%;
    width: 100%;
    display: flex;
}

.imgLeft {
    height: 100%;
    padding: 20px;
    background-color: #edf2ff;
    width: 49%;
    margin-right: 1%;
}

.imgRight {
    height: 100%;
    padding: 20px;
    background-color: #edf2ff;
    width: 49%;
}

.dr {
    width: 2px;
    height: 22px;
    background-color: #264095;
    margin-right: 5px;
}

.imgTitle {
    font-size: 18px;
    color: #000000;
    font-family: "Bold";
}

.imgTopFlex {
    display: flex;
    align-items: center;
    height: 30px;
}

.leftBottom {
    height: 30%;
}

.leftCenter {
    height: calc(70% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexCenterRight {
    display: flex;
    align-items: center;
    height: 30px;
}

.flexBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
}

.leftBottomText {
    height: calc(100% - 30px);
    overflow: auto;
    padding: 0px 20px;
}

.rightIcon {
    color: #264095;
    margin-right: 5px;
}

.exportText {
    color: #264095;
    font-family: "Regular";
    font-size: 18px;
    cursor: pointer;
}

.leftImg {
    max-height: 80%;
    max-width: 80%;
}

.historyDiv {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
}

.historyIcon {
    font-size: 24px;
    margin-right: 10px;
    width: 25px;
}

.down {
    position: absolute;
    right: 0;
    font-size: 24px;
    width: 25px;
    margin-left: 10px;
}

.historyName {
    width: calc(100% - 70px);
}

.question_span {
    font-size: 18px;
    color: #000000;
    font-family: "Regular";
}

.imgLoading {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loadingNow {
    margin-top: 50px;
    font-family: "Regular";
    font-size: 18px;
    color: #000000;
}
</style>
<template>
  <div class="baseContent">
    <!-- 搜索 -->
    <div class="flexCenter searchDiv">
      <el-input
        class="baseInput"
        placeholder="搜索微模型"
        v-model="listFomr.namePattern"
        @input="searchInput"
        prefix-icon="Search"
      />
      <!-- 角色 -->
      <div class="roleDiv">
        <div class="roleTitle">角色</div>
        <el-dropdown>
          <span class="el-dropdown-link">
            全部
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>Action 1</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="roleDiv">
        <div class="roleTitle">模式</div>
        <el-dropdown>
          <span class="el-dropdown-link">
            全部
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>Action 1</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div :class="pxBtnType ? 'pxDivActive' : 'pxDiv'" @click="changePx">
        <img :src="pxSvg" alt="" class="px" />
      </div>
    </div>
    <!-- 内容 -->
    <div class="flexDiv" v-loading="listLoading">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="itemWidth" @click="openAdd">
            <div class="flexCenter itemTile">
              <div class="addDiv">
                <img :src="addImg" alt="" class="addImg" />
              </div>
              <div class="baseItemTitle">新建知识库</div>
            </div>
            <div class="addTip">
              新建一个空白的AI微模型容器；我们推荐您基于特定的部门、群组、项目或专题构建专属AI微模型。
            </div>
          </div>
        </el-col>
        <el-col :span="6" v-for="item in list" :key="item.knowledge_library_id">
          <div class="itemWidth" @dblclick="goBaseDetails(item)">
            <div class="flexCenter itemTile">
              <div class="flexCenter titleMax">
                <div class="logoDiv">
                  <img :src="baseLogo" alt="" class="baseIconLogo" />
                </div>
                <div class="forBaseItemTitle">{{ item.name }}</div>
              </div>
              <div
                class="pinDiv"
                @click="changeMore(1, item)"
                v-if="item.isPin == 0"
              >
                <img :src="pinImg" alt="" class="pinImg" />
              </div>
              <div
                class="pinYesDiv"
                @click="changeMore(2, item)"
                v-if="item.isPin == 1"
              >
                <img :src="pinYesImg" alt="" class="pinImg" />
              </div>
            </div>
            <div class="addTip">
              {{ item.desc }}
            </div>
            <div class="bottomFlexBetween">
              <div class="flexCenter">
                <img :src="fileImg" alt="" class="baseIcon" />
                <div class="bottomNumberItem">{{ item.documentNum }}</div>
                <img :src="talkImg" alt="" class="baseIcon" />
                <div class="bottomNumberItem">{{ item.conversationNum }}</div>
                <img :src="memberImg" alt="" class="baseIcon" />
                <div class="bottomNumberItem">{{ item.userNum }}</div>
              </div>
              <el-dropdown @command="changeMore($event, item)">
                <div class="moreDiv">
                  <img :src="moreImg" alt="" class="moreIcon" />
                </div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item :command="5"> 编辑 </el-dropdown-item>
                    <el-dropdown-item :command="1" v-if="item.isPin != 1">
                      置顶
                    </el-dropdown-item>
                    <el-dropdown-item :command="2" v-if="item.isPin === 1">
                      取消置顶
                    </el-dropdown-item>
                    <el-dropdown-item
                      :command="3"
                      v-if="item.is_owner == true && !item.isDefault"
                    >
                      删除
                    </el-dropdown-item>
                    <el-dropdown-item
                      :command="4"
                      v-if="
                        item.is_owner == false &&
                        !(item.owner_id == userId && item.isDefault == true)
                      "
                    >
                      退出
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 新增 -->
    <addView
      v-if="addDialog"
      :addType="addType"
      :addLoading="addLoading"
      :editObj="editObj"
      @closeAdd="closeAdd"
      @saveAdd="saveAdd"
      @saveEdit="saveEdit"
    ></addView>
    <dialogView v-if="tipDialog" @closeTipDialog="closeTipDialog"></dialogView>
  </div>
</template>

<script setup>
import addImg from "@/assets/base/add.svg";
import fileImg from "@/assets/base/file.svg";
import memberImg from "@/assets/base/member.svg";
import talkImg from "@/assets/base/talk.svg";
import moreImg from "@/assets/base/more.svg";
import pinImg from "@/assets/base/pin.svg";
import pinYesImg from "@/assets/base/pinYes.svg";
import pxSvg from "@/assets/base/px.svg";
import addView from "./add.vue";
import baseLogo from "@/assets/base/baselogo.svg";
import dialogView from "./dialog.vue";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import api from "@/api/index";
const store = useStore();
const router = useRouter();
onMounted(() => {
  store.commit("SET_BASEITEM", null);
  getList();
});
const addType = ref("add"); //默认新增
const pxBtnType = ref(false);
const userId = ref(store.state.userId);
const addLoading = ref(false);
const editObj = ref({}); //编辑接口
const listLoading = ref(false);
// 列表参数
const listFomr = ref({
  userId: store.state.userId,
  namePattern: "",
  pageId: 1,
  perPage: 10,
});
const tipDialog = ref(false);
// 关闭提示弹窗
const closeTipDialog = () => {
  tipDialog.value = false;
};
// 排序
const changePx = () => {
  pxBtnType.value = !pxBtnType.value;
};
const list = ref([]);
const searchInput = () => {
  listFomr.value.pageId = 1;
  getList();
};
// 获取微模型列表
const getList = () => {
  listLoading.value = true;
  api.base
    .customLibraryList(listFomr.value)
    .then((res) => {
      listLoading.value = false;
      if (res.returnCode == 200) {
        list.value = res.data;
      }
    })
    .catch((err) => {
      listLoading.value = false;
    });
};
// 更多操作
const changeMore = (key, item) => {
  if (key == 1) {
    // 置顶
    api.base
      .basePin({
        userId: userId.value,
        libraryId: item.id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success("置顶成功");
          getList();
        }
      });
  } else if (key == 2) {
    // 取消置顶
    api.base
      .basePinCancel({
        userId: userId.value,
        libraryId: item.id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success("取消成功");
          getList();
        }
      });
  } else if (key == 3) {
    // 删除微模型
    api.base
      .libraryDelete(
        {
          userId: userId.value,
        },
        item.id
      )
      .then((res) => {
        message.success("删除成功");
        getList();
      });
  } else if (key == 4) {
    // 退出微模型
    api.base
      .libraryExit(
        {
          userId: userId.value,
        },
        item.id
      )
      .then((res) => {
        message.success("退出成功");
        getList();
      });
  } else if (key == 5) {
    // 编辑
    api.base
      .customLibraryDesc({
        userId: userId.value,
        libraryId: item.id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          addType.value = "edit";
          editObj.value = res.data;
          addDialog.value = true;
        }
      });
  }
};
// 双击进入详情
const goBaseDetails = (item) => {
  api.base
    .customLibraryDesc({
      userId: userId.value,
      libraryId: item.id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        store.commit("SET_BASEITEM", res.data);
        store.commit("SET_TOPIC", res.data);
        store.commit("SET_CHATTYPE", "base");
        router.push("/base/details");
      }
    });
};
const addDialog = ref(false);
// 关闭新增
const closeAdd = () => {
  addDialog.value = false;
};
// 保存新增
const saveAdd = (form) => {
  addLoading.value = true;
  api.base
    .customLibraryAdd(form)
    .then((res) => {
      addLoading.value = false;
      if (res.returnCode == 200) {
        message.success("创建成功");
        getList();
        addDialog.value = false;
      }
    })
    .catch((err) => {
      addLoading.value = true;
    });
};
// 编辑
const saveEdit = (form) => {
  addLoading.value = true;
  let newForm = {
    userId: form.userId,
    libraryName: form.name,
    desc: form.desc,
    promptId: form.promptId,
    libraryId: editObj.value.knowledge_library_id,
  };
  api.base
    .baseUpdata(newForm)
    .then((res) => {
      addLoading.value = false;
      if (res.returnCode == 200) {
        message.success("保存成功");
        getList();
        addDialog.value = false;
      }
    })
    .catch((err) => {
      addLoading.value = true;
    });
};
const openAdd = () => {
  addType.value = "add";
  editObj.value = {};
  addDialog.value = true;
};
</script>

<style lang="less" scoped>
.baseContent {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 30px;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
}
.roleDiv {
  border-radius: 16px;
  border: 2px solid #edeef6;
  display: flex;
  align-items: center;
  width: 180px;
  height: 24px;
  padding: 10px 20px;
  justify-content: space-between;
  margin-left: 14px;
}
.roleTitle {
  color: #8a8a8a;
  font-size: 18px;
  font-family: "Regular";
  width: 45px;
}
:deep(.el-dropdown) {
  max-width: calc(100% - 45px);
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
:deep(.el-input__wrapper) {
  height: 44px;
  border-radius: 16px !important;
  border: 1px solid #edeef6;
  box-shadow: 0px 0px 0px #ffffff;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.baseInput {
  width: 236px;
  height: 52px;
  height: 52px;
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
}
.baseItemTitle {
  font-size: 20px;
  color: #0256ff;
  font-family: "Bold";
}
.forBaseItemTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Bold";
  width: calc(100% - 80px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.addDiv {
  background-color: #dbdfff;
  border-radius: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.logoDiv {
  border-radius: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.addImg {
  width: 15px;
  height: 15px;
}
.baseIconLogo {
  width: 28px;
}
.searchDiv {
  margin-bottom: 22px;
  position: relative;
}
.itemWidth {
  border-radius: 20px;
  background-color: #f7f8ff;
  margin-bottom: 20px;
  padding: 24px;
  cursor: pointer;
  height: 162px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
}
.itemWidth:hover {
  background-color: #eceeff;
  box-shadow: 0px 0px 8px 0px #eceeff;
  .pinDiv {
    width: 36px;
    height: 36px;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: #f7f8ff;
  }
  .pinYesDiv {
    background-color: #f7f8ff;
    border-radius: 12px;
  }
  .moreDiv {
    width: 36px;
    height: 36px;
    border-radius: 12px;
    background-color: #f7f8ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.pinDiv {
  display: none;
}
.pinYesDiv {
  width: 36px;
  height: 36px;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.moreDiv {
  display: none;
}
.itemMargin {
  margin-left: 25px;
  margin-right: 25px;
}
.addTip {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  height: 50px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 18px;
}
.flexDiv {
  height: calc(100% - 84px);
  padding: 5px 0px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  :deep(.el-row) {
    margin: 0px !important;
    width: 100% !important;
  }
}
.itemTile {
  height: 28px;
  margin-bottom: 24px;
  position: relative;
}
.bottomFlexBetween {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottomNumberItem {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  margin-left: 3px;
  margin-right: 12px;
}
.pinImg {
  width: 16px;
  height: 16px;
}
.titleMax {
  width: 100%;
}
.baseIcon {
  width: 25px;
}
.moreIcon {
  width: 18px;
}
.px {
  width: 13px;
}
.pxDiv {
  width: 34px;
  height: 34px;
  border: 1px solid #edeef6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14px;
  cursor: pointer;
}
.pxDivActive {
  width: 34px;
  height: 34px;
  border: 1px solid #edeef6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14px;
  cursor: pointer;
  background-color: #edeef6;
}
</style>

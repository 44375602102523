<template>
  <div class="fileItem">
    <div class="fontTitle">{{ props.htmlItem.internetUrl }}</div>
    <div class="flexBetween">
      <div class="fontName">{{ getUrl() }}</div>
      <!-- <div class="zwf">1</div> -->
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps(["htmlItem"]);
const getUrl = () => {
  var url = new URL(props.htmlItem.internetUrl);
  return url.origin;
};
</script>

<style lang="scss" scoped>
.fileItem {
  background-color: #fefefe;
  border-radius: 20px;
  padding: 12px 43px 12px 23px;
  height: 90px;
  width: 284px;
}
.zwf {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #d9d9d9;
  font-size: 13px;
  color: #ffffff;
  font-family: "Semibold";
  text-align: center;
  line-height: 18px;
}
.fontName {
  color: #8a8a8a;
  font-size: 16px;
  font-family: "Regular";
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Medium";
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 12px;
}
</style>

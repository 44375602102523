<script setup>
import tableUser from '../components/resoureTable.vue'
import resoureDetail from '../components/resoureDetail.vue'
import resoureEdit from '../components/resoureEdit.vue'
import { ref, onMounted, reactive, watch } from "vue";
import { useI18n } from "vue-i18n"
import { useStore } from "vuex";
import api from "@/api/index";
import { message } from "ant-design-vue";
import { ElMessageBox } from 'element-plus'
const { t } = useI18n()
onMounted(() => {
  getTableList()
  getTableData()
});

const store = useStore();
const userId = ref(store.state.userId);
// 导航条切换
const activeTab = ref("dashboard");
const tabs = [
  { name: 'dashboard', label: '部门', contentKey: 'User' },
  { name: 'unit', label: '白名单', contentKey: 'Department' },
];
function setActiveTab(name) {
  activeTab.value = name;
}
// 部门组收缩
const isAsideVisible = ref(true)
const toggleAside = () => {
  isAsideVisible.value = !isAsideVisible.value
};
// 左侧部门菜单
const defaultProps = ref({
  children: 'children',
  label: 'label',
});
const listForm = ref({
  userId: userId,
  departmentName: "",
});
// 部门组列表
const tableName = ref(''); //部门名称
const tableId = ref(1); //部门ID
const data = ref([])
watch(
  () => listForm.value.departmentName,
  (val) => {
    listForm.value.departmentName = val
    getTableList()
  }
)
const getTableList = () => {
  api.manage
    .getGroupList(listForm.value)
    .then((res) => {
      if (res.returnCode == 200) {
        data.value = res.data;                
        tableId.value = res.data[0]?.departmentId;//默认选中第一个部门ID
        tableName.value = res.data[0]?.departmentName;//默认选中第一个部门名称
      }
    })
}
// 部门树点击
const currentNodeId = ref(0);
const handleNodeClick = (data) => {
  currentNodeId.value = data.departmentId;
  tableId.value = data.departmentId;
  tableName.value = data.departmentName;
};
// 资源下拉
const status = ref('全部')
const statusList = ref([
  {
    value: -1,
    label: '全部',

  },
  {
    value: 2,
    label: '阿里'
  },
  {
    value: 1,
    label: 'baidu'
  },
  {
    value: 0,
    label: 'Azure'
  }
])
// 筛选条件
const statusBtn = (item) => {
  listTable.value.resourceType = item
  getTableData()
};
// 分页
const currentPage = ref(1);
const pageSize = ref(20);
const tableList = ref({
  pageId: currentPage.value,
  perPage: pageSize.value,
  userId: userId.value,
})
const tableDepartment = ref([])
const getTableData = () => {
  api.manage
    .getWhiteListApi(tableList.value)
    .then((res) => {
      if (res.returnCode == 200) {
        tableDepartment.value = res.data
      }
    })

}
const openShow = ref(false)
const resoureInfo = ref({})
const rowRuleId = ref(null)
// 操作
const handleDetail = (row) => {
  rowRuleId.value = row.ruleId
  api.manage
    .getSingleWhiteListApi(row.ruleId)
    .then((res) => {
      if (res.returnCode == 200) {
        openShow.value = true
        resoureInfo.value = res.data
      }
    })
};
// 关闭详情
const handleCancel = () => {
  openShow.value = false
};
const formType = ref('add')
const showEdit = ref(false);
// 打开编辑
const handleAdd = () => {
  openShow.value = false
  showEdit.value = true
  formType.value = 'edit'
  resoureInfo.value = resoureInfo.value
}
// 编辑关闭
const closeEdit = () => {
  showEdit.value = false
}
// 编辑确定
const submitEdit = (form) => {
  showEdit.value = false
  api.manage
    .editWhiteListApi(form, rowRuleId.value)
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('编辑成功')
        getTableData()
      }
    })
}
// 新增白名单
const addWhiteBtn = () => {
  openShow.value = false
  showEdit.value = true
  formType.value = 'add'
  resoureInfo.value = {
    groups: [],
    resourceInfo: []
  }
}
// 新增确定
const submitAdd = (form) => {
  showEdit.value = false
  api.manage
    .addWhiteListApi(form)
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('新增成功')
        getTableData()
      }
    })
};
const userIdList = ref([]);
const roleId = ref(null)
const ids2 = ref([]);
const showAddRole = ref(false);
const addDepartment = (row) => {
  roleId.value = row.ruleId;
  showAddRole.value = true;
}
// 确定添加成员
const saveUser = (arr) => {
  console.log(arr);

  ids2.value = arr.map((item) => item.userId);
  showAddRole.value = false;
  // api.manage
  //   .roleAddUserApi({
  //     userId: userId.value,
  //     userIdList: ids2.value,
  //     roleId: roleId.value,
  //   })
  //   .then((res) => {
  //     if (res.returnCode == 200) {
  //       message.success('用户添加成功');
  //       getList()
  //     }
  //   })
};
// 更多操作
const changeMore = (key, item) => {
  if (key === 1) {
    showEdit.value = true
    formType.value = 'edit'
    resoureInfo.value = item
  } else if (key === 2) {
    api.manage
      .delWhiteListApi({
        ruleIds: [item.ruleId]
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success('删除成功')
          getTableData()
        }
      })
  }
};
// 白名单列表--多选
const ids = ref([]);
const handleSelectionChange = (val) => {
  ids.value = val.map(item => item.ruleId)
};
// 批量禁用
const allDisabled = () => {
  if (ids.value.length == 0) {
    message.warning('请选择要禁用的数据')
    return
  }
  ElMessageBox.confirm('确定要禁用这些白名单吗？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(() => {
      api.manage
        .allWhiteListApi({
          ruleIds: ids.value,
          isActive: 0,
          userId: userId.value
        })
        .then((res) => {
          if (res.returnCode == 200) {
            message.success('禁用成功')
            getTableData()
          } else {
            message.error('禁用失败');
          }
        })
    })

};
// 批量删除
const allDelete = () => {
  if (ids.value.length == 0) {
    message.warning('请选择要删除的数据')
    return
  }
  ElMessageBox.confirm('确定要删除这些白名单吗？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(() => {
      api.manage
        .delWhiteListApi({
          ruleIds: ids.value,
          userId: userId.value
        })
        .then((res) => {
          if (res.returnCode == 200) {
            message.success('删除成功')
            getTableData()
          } else {
            message.error('删除失败');
          }
        })
    })
};
</script>

<template>
  <div class="main">
    <div style="position: relative;display: flex;justify-content: space-between;">
      <div class="tabs">
        <div class="tab" v-for="(tab, index) in tabs" :key="index" :class="{ active: activeTab === tab.name }"
          @click="setActiveTab(tab.name)">
          {{ tab.label }}
        </div>
      </div>
      <div class="tip">
        <div class="tipSpan">*</div>
        <div>资源配额优先级规则：白名单配额 > 部门配额</div>
      </div>
    </div>
    <div v-if="activeTab === 'dashboard'">
      <el-container>
        <div class="container">
          <el-aside class="side" v-if="isAsideVisible">
            <el-tree ref="treeRef" :data="data" node-key="departmentId" :props="defaultProps" default-expand-all
              @node-click="handleNodeClick" highlight-current :current-node-key="tableId">
              <template #="{ data }">
                <span class="custom-tree-node">
                  <div class="admin-name">
                    <img src="@/assets/manage/admin.svg" alt="" class="admin">
                    <div class="admin-text">{{ data.departmentName }}</div>
                  </div>
                  <span>
                    <el-dropdown @command="changeMoreTree($event, data)" trigger="click">
                      <div class="moreIcon">
                        <img src="@/assets/base/more.svg" alt="" />
                      </div>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item :command="1">修改名称</el-dropdown-item>
                          <el-dropdown-item :command="2">删除部门</el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </span>
                </span>
              </template>
            </el-tree>
          </el-aside>
          <div @click="toggleAside" class="toggleAside">
            <el-icon class="tagIcon">
              <component :is="isAsideVisible ? 'DArrowLeft' : 'DArrowRight'" />
            </el-icon>
          </div>
        </div>
        <el-main class="userMain">
          <tableUser :tableId="tableId" :tableName="tableName" />
        </el-main>
      </el-container>
    </div>
    <div v-if="activeTab === 'unit'">
      <div class="search">
        <div class="searchLeft">
          <!-- 资源平台 -->
          <div class="searchItem">
            <el-select v-model="status" clearable @change="statusBtn">
              <template #label="{ label, value }">
                <div style="display: flex; justify-content: space-between;">
                  <div>资源平台： </div>
                  <div>{{ label }}</div>
                </div>
              </template>
              <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div>
        </div>
        <!-- 功能区 -->
        <div style="display: flex;">
          <div class="toolBox" style="color: #F02D63;" @click="allDisabled">
            {{ $t('manage.resoureForm.bulkDisable') }}
          </div>
          <div class="toolBox" style="color: #F02D63;" @click="allDelete">
            {{ $t('manage.resoureForm.bulkDelete') }}
          </div>
          <div class="toolBox addUser" @click="addWhiteBtn">
            {{ $t('manage.resoureForm.addWhitelist') }}
          </div>
        </div>
      </div>
      <el-table :data="tableDepartment" @selection-change="handleSelectionChange" class="tableHeight">
        <el-table-column type="selection" />
        <el-table-column fixed prop="name" :label="$t('manage.resoureForm.whitelistName')" />
        <el-table-column fixed prop="groups" :label="$t('manage.resoureForm.member')">
          <template #default="scope">
            <avatarGroupName :avatars=scope.row.groups :number="5"></avatarGroupName>
          </template>
        </el-table-column>
        <el-table-column fixed prop="resourceInfo" :label="$t('manage.resoureForm.peiResource')">
          <template #default="scope">
            <baseName :avatars=scope.row?.resourceInfo :number="2"></baseName>
          </template>
        </el-table-column>
        <el-table-column :label="$t('manage.resoureForm.operation')">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="handleDetail(scope.row)">
              {{ $t('manage.resoureForm.Details') }}
            </el-button>
            <el-button link type="primary" size="small" @click="addDepartment(scope.row)">
              {{ $t('manage.resoureForm.addMember') }}
            </el-button>
            <el-dropdown @command="changeMore($event, scope.row)" trigger="click" class="changeMore">
              <div class="moreIcon">
                <img src="@/assets/base/more.svg" alt="" />
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :command="1">编辑</el-dropdown-item>
                  <el-dropdown-item :command="2">删除</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
  <!-- 白名单详情 -->
  <resoureDetail :openShow="openShow" :resoureInfo="resoureInfo" @handleCancel="handleCancel" @handleAdd="handleAdd">
  </resoureDetail>
  <!-- 白名单编辑 -->
  <resoureEdit :showEdit="showEdit" :formType="formType" :resoureInfo="resoureInfo" @closeEdit="closeEdit"
    @submitEdit="submitEdit" @submitAdd=submitAdd></resoureEdit>
  <!-- 给白名单增加组 -->

  <resoureGroup :show="showAddRole" @closeUser="showAddRole = false" @saveUser="saveUser" :userList="userIdList">

  </resoureGroup>
</template>

<style lang="scss" scoped>
@import './resoure.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.search {
  :deep(.el-select__wrapper) {
    border-radius: 16px !important;
    height: 48px;
    width: 160px !important;
    font-size: 16px !important;
  }
}


:deep(.el-tree) {
  font-size: 18px;
  background-color: #f7f8ff;
  color: #3D3D3D;
}


:deep(.el-table th.el-table__cell) {
  background-color: #f7f8ff;
}

:deep(.el-container) {
  height: calc(100% - 60px) !important;
}

.container {
  height: 696px !important;
}

.side {
  :deep(.el-tree) {
    background-color: #f7f8ff;
    color: #3D3D3D;
  }

  :deep(.el-tree-node__content) {
    height: 50px;
    border-radius: 16px !important;

    &:hover {
      background-color: #ECEEFF;
    }
  }

  :deep(.custom-tree-node) {
    font-size: 16px;
  }
}
</style>

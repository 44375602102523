import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import util from './modules/util';
export default createStore({
  state: {
    menuList: [
      {
        name: 'chat',
        key: '/chat',
        icon: require('@/assets/menu/chat.svg'),
      },
      {
        name: 'index',
        key: '/index',
        icon: require('@/assets/menu/index.svg'),
      },
      {
        name: 'base',
        key: '/base',
        icon: require('@/assets/menu/base.svg'),
        type: 1,
        active: require('@/assets/header/baseAc.svg'),
        tip: '利用人工智能深度学习的团队知识库',
      },
      {
        name: 'share',
        key: '/share',
        icon: require('@/assets/menu/share.svg'),
        type: 1,
        active: require('@/assets/header/sac.svg'),
        tip: '探索新知识，验证新话题',
      },
      {
        name: 'bot',
        key: '/bot',
        icon: require('@/assets/menu/bot.svg'),
        type: 1,
        active: require('@/assets/header/botAc.svg'),
        tip: '提示词模板，可以执行多重能力工作流',
      },
      {
        name: 'statistics',
        key: '/statistics',
        icon: require('@/assets/menu/statistics.svg'),
        type: 1,
        active: require('@/assets/header/tokenAc.svg'),
        tip: '',
      },
      {
        name: 'manage',
        key: '/manage',
        icon: require('@/assets/menu/manage.svg'),
        type: 1,
        active: require('@/assets/header/mangeAc.svg'),
        menuName: '用户与部门',//进行传参即可
      },
    ],
    menuActive: '/index',
    lang: 'CN',
    speedType: false,
    token: '',
    userId: null,
    avatar: '',
    departmentId: null,
    departmentName: null,
    email: '',
    roleId: null,
    sex: null,
    userName: "",
    menuType: true,//菜单类型
    topicItem: null,//对话详情
    baseItem: null,//知识库详情
    menuItem: {},//菜单列表信息
    chatType: '',//判断是chat还是知识库
    yzItem: null,//验证
    modelList: [],//模型列表
    baseDrapList: {},//拖动列表
    postObj: {},//首页 ctrl+k
    fileList: [],//首页文件列表
    topicActive:null,//选择的最近话题
  },
  getters: {
  },
  mutations: {
    SET_MENUACTIVE(state: any, data: any) {
      state.menuItem = data
      state.menuActive = data.key
    },
    SET_LANG(state: any, data: any) {
      state.lang = data
    },
    SET_SPEEDTYPE(state: any, data: any) {
      state.speedType = data
    },
    SET_USERINFO(state: any, data: any) {
      state.userId = data.userInfo.userId
      state.token = data.token
      state.avatar = data.userInfo.avatar
      state.departmentId = data.userInfo.departmentId
      state.email = data.userInfo.email
      state.roleId = data.userInfo.roleId
      state.sex = data.userInfo.sex
      state.userName = data.userInfo.userName
      state.departmentName = data.userInfo.departmentName
      state.roles = data.rolesList  
      console.log(state.roles,'登陆给我的列表');
      
      // state.roles = data.roles
      state.menuActive = '/index'
    },
    CLEAR_TOKEN(state: any, data: any) {
      state.token = ''
    },
    SET_MENUTYPE(state: any, data: any) {
      state.menuType = data
    },
    SET_AVATAR(state: any, data: any) {
      state.avatar = data
    },
    SET_TOPIC(state: any, data: any) {
      state.topicItem = data
    },
    SET_BASEITEM(state: any, data: any) {
      state.baseItem = data
    },
    SET_MANGER(state: any, data: any) {
      state.menuItem.menuName = data
    },
    SET_CHATTYPE(state: any, data: any) {
      if (data != 'base') {
        state.baseDrapList = {}
      }
      state.chatType = data
    },
    SET_YZ(state: any, data: any) {
      state.yzItem = data
    },
    SET_INDEXGOSHARE(state: any) {
      state.menuItem = {
        name: 'share',
        key: '/share',
        icon: require('@/assets/menu/share.svg'),
        type: 1,
        active: require('@/assets/header/sac.svg'),
        tip: '探索新知识，验证新话题',
      }
      state.menuActive = '/share'
    },
    SET_INDEXGOBOT(state: any) {
      state.menuItem = {
        name: 'bot',
        key: '/bot',
        icon: require('@/assets/menu/bot.svg'),
        type: 1,
        active: require('@/assets/header/botAc.svg'),
        tip: '提示词模板，可以执行多重能力工作流',
      }
      state.menuActive = '/bot'
    },
    SET_MODEL(state: any, data: any) {
      state.modelList = data
    },
    SET_BASEDRAP(state: any, data: any) {
      state.baseDrapList = data
    },
    // 普通对话
    SET_TALK(state: any, data: any) {
      state.postObj = data
    },
    SET_FILELIST(state: any, data: any) {
      state.fileList = data
    },
    SET_TOPICITEM(state:any,data:any){
      state.topicActive = data
    }
  },
  actions: {
  },
  modules: {
    util
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
})

<script setup>
import tableUser from '../components/userTable.vue'
import addView from '../components/userAdd.vue'
import departmentView from '../components/departmentDetail.vue'
import departmentAdd from '../components/departmentAdd.vue'
import userMember from '../components/userMember.vue'
import { ref, onMounted, reactive, watch } from "vue";
import { useI18n } from "vue-i18n"
import { message } from "ant-design-vue"
const { t } = useI18n()
import { useStore } from "vuex";
import api from "@/api/index";
onMounted(() => {
    getTableList()
});
const store = useStore();
const userId = ref(store.state.userId);
const loading = ref(true)
const loading2 = ref(true)
// 导航条切换
const activeTab = ref("dashboard");
const tabs = [
    { name: 'dashboard', label:'用户', contentKey: 'User' },
    { name: 'unit', label:'部门', contentKey: 'Department' },
];
function setActiveTab(name) {
    activeTab.value = name;
}

const userTable = ref(''); // 用户input
const user = ref(''); // 部门input
watch(
    () => userTable.value,
    (val) => {
        listForm.departmentName = val
        getTableList()
    }
)
// 部门组收缩
const isAsideVisible = ref(true)
const toggleAside = () => {
    isAsideVisible.value = !isAsideVisible.value
};
// 部门树形列表点击
const handleNodeClick = (data) => {
    tableId.value = data.departmentId
    tableName.value = data.departmentName
};
// 打开部门名称修改
const showName = ref(false)
const memberInfo = ref({})
const closeMember = () => {
    showName.value = false
}
const submitMember = (data) => {
    showName.value = false
    api.manage
        .updateDepartmentName({
            userId: userId.value,
            departmentId: data.departmentId,
            departmentName: data.departmentName,
        })
        .then((res) => {
            if (res.returnCode == 200) {
                message.success(t('部门名称修改成功'))
                getTableList()
                user.value = ''
            }
        })
}
// 更多操作
const changeMore = (key, data) => {
    if (key == 1) {
        showName.value = true
        memberInfo.value = data
    } else if (key == 2) {
        deleteBtn(data)
    }
};
// 删除部门
const deleteBtn = (data) => {    
    api.manage
        .deleteDepartment({
            userId: userId.value,
            departmentIds: [data.departmentId],
        })
        .then((res) => {
            if (res.returnCode == 200) {
                message.success('部门删除成功');
                getTableList()
            }
        })
}
// 左侧部门菜单
const defaultProps = ref({
    children: 'children',
    label: 'label',
});
const listForm = ref({
    userId: userId,
    departmentName: "",
});
// 部门组列表
const tableName = ref(''); //部门名称
const tableId = ref(1); //部门ID
const data = ref([])
watch(
    () => listForm.value.departmentName,
    (val) => {
        listForm.value.departmentName = val
        getTableList()
    }
)
const getTableList = () => {
    api.manage
        .getGroupList(listForm.value)
        .then((res) => {
            if (res.returnCode == 200) {
                data.value = res.data;                
                tableId.value = res.data[0]?.departmentId;//默认选中第一个部门ID
                tableName.value = res.data[0]?.departmentName;//默认选中第一个部门名称
                loading.value = false;
                loading2.value = false;                
            }
        })
}
// 部门详情打开
const dataDetail = ref({})
const showDepartment = ref(false)
const handleDetail = (data) => {
    dataDetail.value = data
    showDepartment.value = true
};
// 关闭部门详情
const handleCancel = () => {
    showDepartment.value = false
}
// 打开修改部门
const formType = ref('add')
const uploadDepartment = ref(false)
const handleAdd = () => {
    formType.value = 'edit'
    showDepartment.value = false
    uploadDepartment.value = true
}
// 关闭修改部门
const handleClose = () => {
    uploadDepartment.value = false
}
// 提交修改部门
const editSave = (data) => {
    uploadDepartment.value = false
    api.manage
        .editDepartment(data)
        .then((res) => {
            if (res.returnCode == 200) {
                message.success('部门修改成功')
                getTableList()
                
            }
        })
}
const exportDepartment = () => {
    const imageUrl = "/openai/user-management/department/export";
    const a = document.createElement("a");
    a.href = imageUrl;
    a.download = "部门导出.excel";
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
// 新增部门
const openDepartment = () => {
    formType.value = 'add'
    uploadDepartment.value = true
}
const handleSave = (data) => {
    uploadDepartment.value = false
    api.manage
        .addDepartment(data)
        .then((res) => {
            if (res.returnCode == 200) {
                message.success('部门新增成功')
                getTableList()
            }
        })
}
// 新增子部门
const addDepartment = (row) => {    
    formType.value = 'addSmall'
    departmentInfo.value = row
    uploadDepartment.value = true
}
const addSave = (data) => {
    uploadDepartment.value = false
    api.manage
        .addDepartment(data)
        .then((res) => {
            if (res.returnCode == 200) {
                message.success('部门子新增成功')
                getTableList()
            }
        })
}
// 更多操作
const departmentInfo = ref({})
const changeMoreTree = (key, item) => {
    if (key === 1) {
        formType.value = 'edit'
        departmentInfo.value = item
        uploadDepartment.value = true
    } else if (key === 2) {
        deleteBtn(item)
    }
};
//新建群组
const showAdd = ref(false);
const handleCloseUser = () => {
    showAdd.value = false
};
//提交
const handleAddUser = () => {
    showAdd.value = false
};
</script>

<template>
    <div class="main">
        <div style="position: relative;">
            <div class="tabs">
                <div class="tab" v-for="(tab, index) in tabs" :key="index" :class="{ active: activeTab === tab.name }"
                    @click="setActiveTab(tab.name)">
                    {{tab.label}}
                </div>
                <div v-if="activeTab === 'dashboard'" class="dashboard">
                    <!-- 用户 -->
                    <el-input v-model="user" size="large" class="searchInput"
                        :placeholder="$t('manage.userForm.PleaseEnterUsername')">
                        <template #prefix>
                            <el-icon class="el-input__icon">
                                <search />
                            </el-icon>
                        </template>
                    </el-input>
                </div>
                <div v-if="activeTab === 'unit'" class="unit">
                    <!-- 部门 -->
                    <div class="search">
                        <div>
                            <el-input v-model="listForm.departmentName" size="large" class="searchInput"
                                placeholder="搜索部门名">
                                <template #prefix>
                                    <el-icon class="el-input__icon">
                                        <search />
                                    </el-icon>
                                </template>
                            </el-input>
                        </div>
                        <div style="display: flex;">
                            <div class="toolBox" @click="exportDepartment">
                                部门导出
                            </div>
                            <div class="toolBox addUser" @click="openDepartment">
                                {{ $t('manage.userForm.CreateNewDepar') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="activeTab === 'dashboard'">
            <el-container v-loading="loading">
                <div class="container" >
                    <el-aside class="side" v-if="isAsideVisible">
                        <el-tree ref="treeRef" :data="data" node-key="departmentId" :props="defaultProps"
                            default-expand-all @node-click="handleNodeClick" highlight-current
                            :current-node-key="tableId">
                            <template #="{ data }">
                                <span class="custom-tree-node">
                                    <div class="admin-name">
                                        <img src="@/assets/manage/admin.svg" alt="" class="admin">
                                        <div class="admin-text">{{ data.departmentName }}</div>
                                    </div>
                                    <span>
                                        <el-dropdown @command="changeMore($event, data)" trigger="click">
                                            <div class="moreIcon">
                                                <img src="@/assets/base/more.svg" alt="" />
                                            </div>
                                            <template #dropdown>
                                                <el-dropdown-menu>
                                                    <el-dropdown-item :command="1">修改名称</el-dropdown-item>
                                                    <el-dropdown-item :command="2">删除部门</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                    </span>
                                </span>
                            </template>
                        </el-tree>
                    </el-aside>
                    <div @click="toggleAside" class="toggleAside">
                        <el-icon class="tagIcon">
                            <component :is="isAsideVisible ? 'DArrowLeft' : 'DArrowRight'" />
                        </el-icon>
                    </div>
                </div>
                <el-main class="userMain">
                    <tableUser :tableId="tableId" :tableName="tableName" :user="user">
                    </tableUser>
                </el-main>
            </el-container>
        </div>
        <div v-if="activeTab === 'unit'" v-loading="loading2">
            <el-table :data="data" row-key="departmentId" default-expand-all class="tableHeight">
                <el-table-column fixed prop="departmentName" :label="$t('manage.userForm.departmentName')" />
                <el-table-column fixed prop="userNum" :label="$t('manage.userForm.NumberOfUsers')" />
                <el-table-column fixed prop="userNames" :label="$t('manage.userForm.Directusers')">
                    <template #default="scope">
                        <AvatarGroup :avatars=scope.row.userNames :number="5"></AvatarGroup>
                    </template>
                </el-table-column>
                <el-table-column fixed prop="manager" :label="$t('manage.userForm.DepartmentHead')">
                    <template #default="{ row }">
                        <div style="display: flex;">
                            <div>{{ row.manager }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('manage.userForm.operation')">
                    <template #default="scope">
                        <el-button link type="primary" size="small" @click="handleDetail(scope.row)">
                            {{ $t('manage.userForm.Details') }}
                        </el-button>
                        <el-button link type="primary" size="small" @click="addDepartment(scope.row)">
                            {{ $t('manage.userForm.AddSubDepartment') }}
                        </el-button>
                        <el-dropdown @command="changeMoreTree($event, scope.row)" trigger="click" class="changeMore">
                            <div class="moreIcon">
                                <img src="@/assets/base/more.svg" alt="" />
                            </div>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item :command="1">编辑</el-dropdown-item>
                                    <el-dropdown-item :command="2">删除</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
    <!-- 部门详情 -->
    <departmentView :show="showDepartment" :dataDetail="dataDetail" @handleCancel="handleCancel" @handleAdd="handleAdd">
    </departmentView>
    <!-- 部门编辑 -->
    <departmentAdd :formType="formType" :show="uploadDepartment" :departmentInfo="departmentInfo"
        @handleSave="handleSave" @handleClose="handleClose" @addSave="addSave" @editSave="editSave"></departmentAdd>
    <!-- 新建用户 -->
    <addView :show="showAdd" @handleCloseUser="handleCloseUser" @handleAddUser="handleAddUser"></addView>
    <!-- 修改部门名称 -->
    <userMember :showName="showName" :memberInfo="memberInfo" @closeMember="closeMember" @submitMember="submitMember">
    </userMember>
</template>

<style lang="scss" scoped>
@import './user.scss';

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-tree) {
    font-size: 18px;
    background-color: #f7f8ff;
    color: #3D3D3D;
}

:deep(.el-tree-node__content) {
    height: 50px;
}

:deep(.el-table th.el-table__cell) {
    background-color: #f7f8ff;
}

:deep(.el-input__wrapper) {
    border-radius: 16px;
}

:deep(.el-container) {
    height: calc(100% - 60px) !important;
}
.container{
    height: 696px !important;
}
.side {
    :deep(.el-tree) {
        background-color: #f7f8ff;
        color: #3D3D3D;
    }

    :deep(.el-tree-node__content) {
        height: 50px;
        border-radius: 16px !important;

        &:hover {
            background-color: #ECEEFF;
        }
    }

    :deep(.custom-tree-node) {
        font-size: 16px;
    }
}
</style>

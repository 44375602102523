<template>
    <div class="audioContent">
        <div class="btnRight">
            <div class="left" @click="back">
                <el-icon class="icon">
                    <Back />
                </el-icon>
                返回
            </div>
            <Button type="link" @click="openHistory">历史记录</Button>
        </div>
        <!-- 上传 -->
        <div v-if="type == 'upFile'">
            <UploadDragger class="upFile" name="file" :multiple="false" :showUploadList="false"
                :customRequest="customRequest" action="#" :before-upload="beforeUpload">
                <div>
                    <!-- <Icon class="upFileIcon" type="inbox" /> -->
                </div>
                <div class="upFileTip">点击上传/拖拽本地音频文件到这里</div>
                <div class="auditTip">· 大小：音频≤50M，时长0.5小时内</div>
                <dl class="auditTip">· 格式：.WAV,.MP3,.M4A,.OPUS/OGG,FLAC,WMA,AAC,ALAW in WAV container,MULAW in WAV
                    container,AMR,WebM,SPEEX</dl>
            </UploadDragger>
        </div>
        <!-- 提取中 -->
        <div v-if="type == 'loading'" class="upFile bgF">
            <div class="loadingAbs">
                <loading></loading>
                <div class="loadingText">正在提取文字...</div>
            </div>
        </div>
        <!-- 成功 -->
        <div v-if="type == 'finish'" class="finsh">
            <el-row class="rowItem">
                <el-col :span="24" class="col12 bfFa">
                    <div class="finfishFlex">
                        <div class="titleIcon"></div>
                        <div class="finishTitle">音频内容</div>
                    </div>
                    <div class="leftCenterText">
                        {{ leftText }}
                    </div>
                    <div class="audioDiv">
                        <audioComponentsVue :fileurl="voiceUrl"></audioComponentsVue>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 历史 -->
        <Drawer v-if="historyType" title="历史记录" placement="right" :closable="false" :width="500" :open="historyType"
            @close="historyType = false">
            <div>
                <div v-for="item in voicesList" :key="item.id" class="historyDiv">
                    <!-- <Icon class="historyIcon" type="file" /> -->
                    <div class="historyName">{{ item.fileName }}</div>
                    <!-- <Icon type="download" class="down" @click="downloadFile(item)" /> -->
                </div>
            </div>
        </Drawer>
    </div>
</template>

<script setup>
import api from "@/api/index";
import { ref, reactive, onMounted } from "vue";
import { message, Button, UploadDragger, Icon, Select, SelectOption, Modal, Drawer } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import audioComponentsVue from "./audioComponents.vue";
onMounted(() => {

})
const { t } = useI18n();
const router = useRouter();
const store = useStore();
const userId = ref(store.state.userId);
const type = ref("upFile");
const historyType = ref(false);
const audioUrl = ref("");
const leftText = ref("");
const fileId = ref(null)
const voicesList = ref([]);
const voiceUrl = ref("");
const timmer = ref(null);

const downloadFile = (item) => {
    const imageUrl = item.contentUrl;
    // 创建一个下载链接
    const a = document.createElement("a");
    a.href = imageUrl;
    a.download = "downloaded-image.jpg"; // 设置保存的文件名
    a.style.display = "none";
    // 将链接添加到DOM中
    document.body.appendChild(a);
    // 模拟用户点击链接以触发下载
    a.click();
    // 从DOM中移除链接
    document.body.removeChild(a);
}
// 打开历史记录
const openHistory = () => {
    getVoicesListAsync()
}
// 返回上一页
const back = () => {
    router.go(-1);
};
// 上传前校验
const beforeUpload = (file) => {
    // 文件类型
    file.fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
    let validFileTypes = [
        "wav",
        "WAV",
        "mp3",
        "MP3",
        "M4A",
        "m4a",
        "opus",
        "OPUS",
        "OGG",
        "ogg",
        "FLAC",
        "flac",
        "WMA",
        "wma",
        "AAC",
        "aac",
        "ALAW in WAV container",
        "MULAW in WAV container",
        "AMR",
        "amr",
        "WebM",
        "SPEEX",
        "speex",
    ];
    if (!validFileTypes.includes(file.fileType)) {
        message.warning("不支持该文件类型上传。");
        return false;
    }
    type.value = "loading";
    api.bot.upVoice([file],{}).then((res) => {
        if (res.data.length != 0) {
            poill(res.data);
        }
    });
}
// 轮询
const poill = (data) => {
    api.bot
        .voiceStatus({
            userId: userId.value,
            targetUrl: data,
        })
        .then((res) => {
            if (res.returnCode == 200) {
                if (res.data == "Succeeded") {
                    api.bot
                        .voiceResult({
                            userId: userId.value,
                            targetUrl: data,
                        })
                        .then((res) => {
                            audioUrl.value = data;
                            leftText.value = res.data;
                            voiceUrl.value = res.voiceUrl;
                            type.value = "finish";
                        });
                } else {
                    timmer.value = setTimeout(() => {
                        poill(data);
                    }, 1000);
                }
            }
        });
};
const customRequest = () => { };
const getVoicesListAsync = () => {
    api.bot.voiceListApi({ userId: userId.value }).then((res) => {
        if (res.returnCode === 200) {
            voicesList.value = res.data;
            historyType.value = true;
        }
    });
};
</script>

<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.audioContent {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 30px;
    min-width: 1430px;
    min-height: 711px;
    width: 100%;
    height: 100%;
}

.btnRight {
    display: flex;
    justify-content: space-between;
    font-family: "Regular";

    .left {
        font-size: 16px;
        color: #7d7d7d;
        padding-top: 4px;
        cursor: pointer;
    }
}

.historyDiv {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 20px;
}

.historyIcon {
    font-size: 24px;
    margin-right: 10px;
    width: 25px;
}

.down {
    position: absolute;
    right: 0;
    font-size: 24px;
    width: 25px;
    margin-left: 10px;
}

.historyName {
    width: calc(100% - 70px);
}

.upFile {
    width: 100%;
    height: calc(100% - 30px);
}

.finsh {
    position: absolute;
    width: 90%;
    height: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

:deep(.ant-upload-wrapper .ant-upload-drag) {
    width: 1460px !important;
    height: 690px !important;
}

:deep(.ant-upload.ant-upload-drag) {
    background-color: #fafafa;
}

.upFileIcon {
    font-size: 80px;
    color: #264095;
}

.upFileTip {
    color: #000000;
    font-family: "Bold";
    font-size: 24px;
    margin: 20px 0px;
}

.auditTip {
    font-size: 14px;
    color: #000000;
    font-family: "Regular";
    margin-bottom: 10px;
}

.bgF {
    background-color: #ffffff;
}

.loadingText {
    font-size: 24px;
    color: #000000;
    margin-top: 50px;
    font-family: "Regular";
}

.loadingAbs {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rowItem {
    height: 100%;
}

.col12 {
    padding: 20px;
    height: 100%;
    position: relative;
}

.titleIcon {
    width: 2px;
    background-color: #264095;
    height: 20px;
    margin-right: 10px;
}

.finishTitle {
    font-size: 18px;
    color: #000000;
    font-family: "Bold";
}

.finfishFlex {
    display: flex;
    align-items: center;
    height: 30px;
}

.playAudio {
    height: 80px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bfFa {
    background-color: #fafafa;
}

.leftCenterText {
    height: calc(100% - 140px);
    overflow: auto;
    font-size: 16px;
    font-family: "Regular";
    padding: 20px 0px;
    color: #000000;
}

.flexCenterRight {
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: flex-end;
}

.rightIcon {
    color: #264095;
    margin-right: 5px;
}

.exportText {
    color: #264095;
    font-family: "Regular";
    font-size: 18px;
}

.rightText {
    height: calc(100% - 60px);
    overflow: auto;
    font-size: 16px;
    font-family: "Regular";
    padding: 20px 0px;
    color: #000000;
}

.audioDiv {
    height: 50px;
}
</style>
<script setup>
import { ref, defineProps, defaultEmits, onMounted, watch } from 'vue'
import { useStore } from "vuex";
import api from "@/api/index";
const store = useStore();
const userId = ref(store.state.userId);
const props = defineProps({
    show: Boolean,
    userList: Array,
})

onMounted(() => {
    getTableList()
})
const emit = defineEmits(['closeUser', 'saveUser']);

// 左侧部门菜单
const defaultProps = ref({
    label: 'label',
});
const listData = ref({
    userId: userId.value,
    search: ""
})
// 请求表格数据
const listForm = ref({
    userId: userId.value,
    groupId: '',
})
watch(
    () => listData.value.search,
    (newVal) => {
        listData.value.search = newVal;
        getTableList()
    }
)
const groupList = ref([])
const getTableList = () => {
    api.manage
        .getGroupListApi(listData.value)
        .then((res) => {
            if (res.returnCode == 200) {
                groupList.value = res.data;
                listForm.value.groupId = res.data[0]?.groupId;//默认选中第一个部门ID
                getUserTable()
            }
        })
};
const tableMember = ref([])
const getUserTable = () => {
    api.manage
    .getGroupTableApi(listForm.value)
    .then((res) => {
      if (res.returnCode == 200) {
        tableMember.value = res.data.users
      }
    })
};
// 部门组收缩
const isAsideVisible = ref(true)
const toggleAside = () => {
    isAsideVisible.value = !isAsideVisible.value
};
const currentNodeId = ref(0);
// 部门树形列表点击
const handleNodeClick = (data) => {
    currentNodeId.value = data.groupId;
};
watch(
    () => currentNodeId.value,
    (newVal) => {
        listForm.value.groupId = newVal;
        getUserTable()
    }
)
// 多选
const newArrList = ref([])
const multipleSelection = ref([]);
watch(
    () => multipleSelection.value,
    (newVal) => {
        multipleSelection.value = newVal
    }
)
const handleSelectionChange = (val) => {
    multipleSelection.value = val
    multipleSelection.value.map((item) => {
        newArrList.value.push(item)
    })
};
const handleClose = () => {
    emit('closeUser')
}
const handleSave = () => {
    emit('saveUser', newArrList.value)
};
</script>
<template>
    <div>
        <el-dialog v-model="props.show" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>添加成员</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleClose" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <div>
                <el-container>
                    <div class="container">
                        <!-- 用户 -->
                        <el-input v-model="listData.search" size="large" class="searchInput"
                            :placeholder="$t('manage.groupForm.SearchGroup')" v-if="isAsideVisible">
                            <template #prefix>
                                <el-icon class="el-input__icon">
                                    <search />
                                </el-icon>
                            </template>
                        </el-input>
                        <el-aside class="side" v-if="isAsideVisible">
                            <el-tree ref="treeRef" :data="groupList" node-key="currentNodeId" :props="defaultProps"
                                default-expand-all @node-click="handleNodeClick" highlight-current
                                :current-node-key="currentNodeId">
                                <template #="{ data }">
                                    <span class="custom-tree-node">
                                        <span>{{ data.groupName }}</span>
                                    </span>
                                </template>
                            </el-tree>
                        </el-aside>
                        <div @click="toggleAside" class="toggleAside">
                            <el-icon class="tagIcon">
                                <component :is="isAsideVisible ? 'DArrowLeft' : 'DArrowRight'" />
                            </el-icon>
                        </div>
                    </div>
                    <el-main class="userMain">
                        <el-table :data="tableMember" @selection-change="handleSelectionChange" class="tableHeight">
                            <el-table-column type="selection" />
                            <el-table-column prop="userName" :label="$t('manage.userForm.userName')" />
                            <el-table-column prop="departmentName" show-overflow-tooltip
                                :label="$t('manage.userForm.Department')" />
                        </el-table>
                    </el-main>
                </el-container>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <div @click="handleClose" class="cancel">放弃</div>
                    <div @click="handleSave" class="confirm">提交</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-dialog) {
    --el-dialog-width: 800px;
    border-radius: 30px;
    padding: 30px;
}

:deep(.el-tree-node__content) {
    height: 34px;
    border-radius: 16px;
    font-size: 15px;
    color: #3d3d3d;
    font-family: "Regular";
}

:deep(.el-tree-node__content:hover) {
    background-color: #eceeff;
    color: #0256ff;
}

:deep(.el-tree-node:focus > .el-tree-node__content) {
    background-color: #eceeff;
    color: #0256ff;
}

:deep(.el-input__wrapper) {
    border-radius: 16px;
    width: 130px !important;
    margin-bottom: 10px;
    margin-right: 10px;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 24px;
    position: relative;
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #F02D63;
        padding: 6px 12px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 6px 12px;
        border-radius: 16px;
        cursor: pointer;
    }
}

.container {
    position: relative;

    .side {
        max-width: 200px;
        height: 460px;
        overflow: auto;
        border-radius: 16px;
        background-color: #f7f8ff;
        padding: 0px 2px;
        position: relative;
        margin-right: 20px;
    }

    .toggleAside {
        width: 20px;
        height: 40px;
        background-color: #f7f8ff;
        margin-top: 20px;
        z-index: 999;
        position: absolute;
        right: -10px;
        top: 40%;
        cursor: pointer;
        color: #8A8A8A;
        border-radius: 0 12px 12px 0;

        .tagIcon {
            font-size: 20px;
            margin-top: 8px;
        }
    }
}

.tableHeight {
    height: 510px;
}
</style>
<script setup>
import { ref } from 'vue'
import { useI18n } from "vue-i18n"
const { t } = useI18n()
// 引入右侧展示内容
import Accredit from './system/accredit.vue'
import Base from './system/base.vue'
import Bot from './system/bot.vue'
import User from './system/user.vue'
import Group from './system/group.vue'
import Log from './system/log.vue'
import Micromodel from './system/micromodel.vue'
import Role from './system/role.vue'
import Resoure from './system/resoure.vue'
import Tipic from './system/tipic.vue'
import { useStore } from "vuex";
const store = useStore();
const activeIndex = ref('3')
const handleSelect = (key, keyPath) => {
  activeIndex.value = key
}
const handleItem = (data) => {
  store.commit("SET_MANGER", data.title)
}
// 左侧菜单栏
const menuGroups = ref([
  {
    title: t('manage.team'),
    items: [
      { index: '1', title: t('manage.department'), id: '10002' },
      { index: '2', title: t('manage.group'), id: '10012' },
      { index: '3', title: t('manage.role'), id: '10017' },
      { index: '4', title: t('manage.resoure'), id: '10023' }, // 注意：这里可能是个拼写错误，应该是 'manage.resource'  
    ],
  },
  {
    title: t('manage.knowledge'),
    items: [
      { index: '5', title: t('manage.tipic'), id: '10035' }, // 注意：这里可能是个拼写错误，应该是 'manage.topic'  
      { index: '6', title: t('manage.micromodel'), id: '10039' }, // 注意：这里可能是个特定术语，但确保它是正确的  
      { index: '7', title: t('manage.bot'), id: '10043' },
    ],
  },
  {
    title: t('manage.deploy'),
    items: [
      { index: '8', title: t('manage.accredit'), id: '10048' },
      { index: '9', title: t('manage.base'), id: '10051' },
      { index: '10', title: t('manage.log'), id: '10062' },
    ],
  }
]);
</script>
<template>
  <div class="container">
    <!-- 侧边导航 -->
    <el-container>
      <el-aside class="aside">
        <!-- <el-menu :default-active="activeIndex" @select="handleSelect" text-color="#3D3D3D" active-text-color="#0256FF"
          :collapse="false">
          <el-menu-item-group v-for="group in menuGroups" :key="group.title" :title="group.title">
            <el-menu-item v-for="item in group.items"  :key="item.index"
              :index="item.index" @click="handleItem(item)">
              <span class="ellipsis" >{{ item.title }}</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-menu> -->
        <el-menu :default-active="activeIndex" @select="handleSelect" text-color="#3D3D3D" active-text-color="#0256FF"
        :collapse="false">
        <el-menu-item-group :title="t('manage.team')">
          <el-menu-item index="1" v-if="$hasPermission('10061')">
            <span class="ellipsis" >{{ t('manage.department') }}</span>
          </el-menu-item>
          <el-menu-item index="2" v-if="$hasPermission('10061')">
            <span class="ellipsis" >{{ t('manage.group') }}</span>
          </el-menu-item>
          <el-menu-item index="3" v-if="$hasPermission('10061')">
            <span class="ellipsis" >{{ t('manage.role') }}</span>
          </el-menu-item>
          <el-menu-item index="4" v-if="$hasPermission('10061')">
            <span class="ellipsis" >{{ t('manage.resoure') }}</span>
          </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group :title="t('manage.knowledge')">
          <el-menu-item index="5" v-if="$hasPermission('10061')">
            <span class="ellipsis" >{{ t('manage.tipic') }}</span>
          </el-menu-item>
          <el-menu-item index="6" v-if="$hasPermission('10061')">
            <span class="ellipsis" >{{ t('manage.micromodel') }}</span>
          </el-menu-item>
          <el-menu-item index="7" v-if="$hasPermission('10061')">
            <span class="ellipsis" >{{ t('manage.bot') }}</span>
          </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group :title="t('manage.deploy')">
          <el-menu-item index="8" v-if="$hasPermission('10061')">
            <span class="ellipsis" >{{ t('manage.accredit') }}</span>
          </el-menu-item>
          <el-menu-item index="9" v-if="$hasPermission('10061')">
            <span class="ellipsis" >{{ t('manage.base') }}</span>
          </el-menu-item>
          <el-menu-item index="10" v-if="$hasPermission('10061')">
            <span class="ellipsis" >{{ t('manage.log') }}</span>
          </el-menu-item>
        </el-menu-item-group>
        </el-menu>

      </el-aside>
      <el-main class="main">
        <!-- 团队 -->
        <User v-if="activeIndex === '1'"></User>
        <Group v-if="activeIndex === '2'"></Group>
        <Role v-if="activeIndex === '3'"></Role>
        <Resoure v-if="activeIndex === '4'"></Resoure>
        <!-- 知识 -->
        <Tipic v-if="activeIndex === '5'"></Tipic>
        <Micromodel v-if="activeIndex === '6'"></Micromodel>
        <Bot v-if="activeIndex === '7'"></Bot>
        <!-- 部署 -->
        <Accredit v-if="activeIndex === '8'"></Accredit>
        <Base v-if="activeIndex === '9'">
        </Base>
        <Log v-if="activeIndex === '10'"></Log>
      </el-main>
    </el-container>
  </div>
</template>



<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-menu-item-group__title) {
  padding: 10px 0 10px 8px !important;
  line-height: normal;
  font-size: 22px;
  color: #3D3D3D;
  font-weight: 600;
}

:deep(.el-menu-item) {
  display: flex;
  align-items: center;
  height: 46px;
  font-size: 18px;
  padding-left: 40px !important;

}

:deep(.el-menu) {
  border-right: none;
}

:deep(.el-menu-item.is-active) {
  background-color: #F7F8FF;
  border-radius: 10px;
  color: #0256FF;
}

:deep(.el-main) {
  padding: 0 !important;
  height: 100% !important;
}

:deep(.el-menu-item:hover) {
  background-color: #F7F8FF;
  border-radius: 10px;
}

:deep(.el-container) {
  height: 100%;
}

:deep(.el-menu-item-group) {
  height: 100% !important;
}

.container {
  background-color: #f7f8ff;
  border-radius: 16px;
  width: 100%;
  height: 100%;
}

.aside {
  width: 228px;
  height: 100%;
  border-radius: 16px;
  margin-right: 16px;
  padding: 18px 18px 12px;
  background-color: #fff;

}

.main {
  flex: 1;
  height: 100%;
  border-radius: 16px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
</style>

<script setup>
import { ref, defineProps, reactive, watch, defineEmits, onMounted } from 'vue';
import { useI18n } from "vue-i18n";
import detailView from './userDetail.vue';
import userReset from './userReset.vue';
import addView from './userAdd.vue';
import api from "@/api/index";
import { useStore } from "vuex";
import { message } from 'ant-design-vue';
import { ElMessageBox } from 'element-plus'
const store = useStore();
const userId = ref(store.state.userId);
const { t } = useI18n();
// 你可以在这里添加更多的点击处理逻辑  
const props = defineProps({
    tableId: Number,
    tableName: String,
    user: String,
});
onMounted(() => {
    getUserTable()
    getUserTotal()
})
watch(
    () => props.tableId,
    (newVal) => {
        props.tableId = newVal;
        getUserTotal()
        getUserTable()
    }
)
watch(
    () => props.user,
    (newVal) => {
        userTable.value.userNamePattern = newVal
        getUserTable()
    }
)

// 分页
const currentPage = ref(1);
const pageSize = ref(20);
const updatePageSize = (newSize) => {
    userTable.value.perPage = newSize;
    getUserTable()
};
const updateCurrentPage = (newPage) => {
    userTable.value.pageId = newPage;
    getUserTable()
};
// 请求表格数据
const totalItems = ref(0);
const userTable = ref({
    userId: userId.value,
    userNamePattern: '',
    departmentId: props.tableId,
    activate: null,
    ifDirectly: true,
    pageId: currentPage.value,
    perPage: pageSize.value,
})
const tableMember = ref([])
const getUserTable = () => {
    api.manage
        .getUserList(userTable.value)
        .then((res) => {
            if (res.returnCode == 200) {
                totalItems.value = res.totalDataNum
                tableMember.value = res.data
            }
        })
}
// 用户数量
const userTotal = ref(null)
const activeTotal = ref(null)
const totalForm = ref({
    userId: userId.value,
    departmentId: props.tableId,
    ifDirectly: userTable.value.ifDirectly
})
const getUserTotal = () => {
    api.manage
        .getUserTotal(totalForm.value)
        .then((res) => {
            if (res.returnCode == 200) {
                userTotal.value = res.data.alluserCount
                activeTotal.value = res.data.activeUserCount
            }
        })
}
// 状态下拉
const status = ref('全部')
const statusList = ref([
    {
        value: 3,
        label: '全部',

    },
    {
        value: 1,
        label: '启用'
    },
    {
        value: 0,
        label: '禁用'
    }
])
const statusBtn = (item) => {
    userTable.value.activate = item
    getUserTable()
}
// 用户数量下拉
const allUser = ref('展示全部用户')
const allUserList = ref([
    {
        value: true,
        label: '展示全部用户'
    },
    {
        value: false,
        label: '仅展示部门直属'
    }
])
const allUserBtn = (item) => {
    userTable.value.ifDirectly = item
    getUserTable()
    getUserTotal()
}
// 控制表头显示
const tabelType = ref({
    userName: true,
    isActive: true,
    email: true,
    departmentName: true,
    operation: true,
    address: false,
    phone: false,
    roleName: false,
    isManager: false,
    sex: false,
    supervisorName: false,
});
const showDialog = ref(false);
const tabelTypeBtn = () => {
    showDialog.value = true
};
const handleChange = (e, key) => {
    tabelType.value[key] = e;
};
const handleConfirm = () => {
    tabelType.value = tabelType.value
    showDialog.value = false
}
// 多选
const ids = ref([]);
const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
    ids.value = val.map((item) => item.userId);
    multipleSelection.value = val.map((item) => item.userId);
};
// 批量禁用
const deleteAll = () => {
    if (multipleSelection.value.length == 0) {
        message.error('请选择要禁用的用户');
    } else {
        ElMessageBox.confirm('确定要禁用这些用户吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        })
            .then(() => {
                api.manage
                    .batchDisable({
                        userId: userId.value,
                        deleteUserIds: ids.value,
                    })
                    .then((res) => {
                        if (res.returnCode == 200) {
                            message.success('禁用成功');
                            getUserTable()
                            getUserTotal()
                        } else {
                            message.error('禁用失败');
                        }
                    });
            })
    }

}
const showReset = ref(false)
const resetInfo = ref({})
// 重置密码
const handleReset = (row) => {
    showReset.value = true
    resetInfo.value = row
};
const closeReset = () => {
    showReset.value = false
}
const submitReset = (data) => {
    showReset.value = false
    api.manage
        .resetPassword(data)
        .then((res) => {
            if (res.returnCode === 200) {
                message.success('密码重置成功')
            }
        })
}
// 详情
const detailInfo = ref({})
const showDetail = ref(false)
const handleDetail = (data) => {
    detailInfo.value = data
    showDetail.value = true
};
const handleCancel = () => {
    showDetail.value = false
}
const handleAdd = () => {
    showDetail.value = false;
    uploadInfo.value = detailInfo.value;
    showAdd.value = true
}
// 用户新增与修改
const showAdd = ref(false)
const formType = ref('add')
const addUser = () => {
    showAdd.value = true
    formType.value = 'add'
}
const uploadInfo = ref({})
const uploadUser = (data) => {
    formType.value = 'edit'
    uploadInfo.value = data
    showAdd.value = true
}
// 关闭编辑
const handleCloseUser = () => {
    showAdd.value = false
    uploadInfo.value = {}
}
// 保存编辑
const handleAddUser = (form) => {    
    form.userId = userId.value
    showAdd.value = false
    api.manage
        .editUser(form).then(res => {
            if (res.returnCode === 200) {
                message.success('修改成功')
            }
        })
}
// 新增用户确定
const addUserBtn = () => {
    showAdd.value = false
    api.manage
        .addUser(uploadInfo.value).then(res => {
            if (res.returnCode === 200) {
                message.success('新增成功')
            }
        })
}
// 更多操作
const changeMore = (key, item) => {
    if (key === 1) {
        uploadUser(item)
    } else if (key === 2) {
        multipleSelection.value = [item.userId]
        api.manage
            .batchDisable({
                userId: userId.value,
                deleteUserIds: [item.userId],
            })
            .then((res) => {
                if (res.returnCode == 200) {
                    message.success('禁用成功');
                    getUserTable()
                    getUserTotal()
                } else {
                    message.error('禁用失败');
                }
            });
    } else if (key === 3) {
        api.manage
            .enable({
                userId: userId.value,
                reuseUserIds: [item.userId],
            })
            .then((res) => {
                if (res.returnCode == 200) {
                    message.success('启用成功');
                    getUserTable()
                    getUserTotal()
                } else {
                    message.error('启用失败');
                }
            })
    }
};
// 批量导入导出
const showExport = ref(false);
const showExportBtn = () => {
    showExport.value = true
};
// 关闭批量导入导出
const handleCloneExport = () => {
    showExport.value = false
};
// 确定/批量导入导出
const handleExport = () => {
    showExport.value = false
    // 调用导出接口
};   
</script>
<template>
    <div class="userTable">
        <div class="dashboard">
            <div class="companyName">{{ tableName }}</div>
            <div class="companyNum">{{ $t('manage.userForm.totalUsers') }} <span class="num">{{ userTotal }}</span>
            </div>
            <div class="companyNum">{{ $t('manage.userForm.enabledUsers') }}<span class="num">{{ activeTotal }}</span>
            </div>
        </div>
        <div class="search">
            <div class="searchLeft">
                <!-- 状态 -->
                <div class="searchItem">
                    <el-select v-model="status" clearable @change="statusBtn">
                        <template #label="{ label, value }">
                            <div style="display: flex; justify-content: space-between;">
                                <div>{{ $t('manage.userForm.isActive') }} : </div>
                                <div>{{ label }}</div>
                            </div>
                        </template>
                        <el-option v-for="item in statusList" :key="item.value" :label="item.label"
                            :value="item.value" />
                    </el-select>
                </div>
                <!-- 展示全部用户 -->
                <div class="searchItem">
                    <el-select v-model="allUser" clearable @change="allUserBtn">
                        <el-option v-for="(item, index) in allUserList" :key="index" :label="item.label"
                            :value="item.value" />
                    </el-select>
                </div>
                <div class="searchDiv2" @click="tabelTypeBtn">
                    <div class="toolImg">
                        <img src="@/assets/manage/table.svg" alt="">
                    </div>
                </div>
            </div>
            <!-- 功能区 -->
            <div style="display: flex;">
                <div class="toolBox" style="color: #F02D63;" @click="deleteAll">
                    {{ $t('manage.userForm.batchDisable') }}
                </div>
                <div class="toolBox" @click="showExportBtn">
                    批量新增
                </div>
                <div class="toolBox addUser" @click="addUser">
                    {{ $t('manage.userForm.addUser') }}
                </div>
            </div>
        </div>
        <el-table :data="tableMember" @selection-change="handleSelectionChange" class="tableHeight">
            <el-table-column type="selection" />
            <el-table-column v-if="tabelType.userName" prop="userName" :label="$t('manage.userForm.userName')" />
            <el-table-column v-if="tabelType.isActive" prop="isActive" :label="$t('manage.userForm.accountStatus')" />
            <el-table-column v-if="tabelType.email" prop="email" show-overflow-tooltip
                :label="$t('manage.userForm.email')" />
            <el-table-column v-if="tabelType.departmentName" prop="departmentName" show-overflow-tooltip
                :label="$t('manage.userForm.Department')" />
            <el-table-column v-if="tabelType.address" prop="address" show-overflow-tooltip
                :label="$t('manage.userForm.address')" />
            <el-table-column v-if="tabelType.phone" prop="phone" show-overflow-tooltip
                :label="$t('manage.userForm.phone')" />
            <el-table-column v-if="tabelType.roleName" prop="roleName" :label="$t('manage.userForm.roleName')" />
            <el-table-column v-if="tabelType.isManager" prop="isManager" :label="$t('manage.userForm.isManager')" />
            <el-table-column v-if="tabelType.sex" prop="sex" :label="$t('manage.userForm.sex')" />
            <el-table-column v-if="tabelType.supervisorName" prop="supervisorName"
                :label="$t('manage.userForm.supervisorName')" />
            <el-table-column v-if="tabelType.operation" :label="$t('manage.userForm.operation')" class="operation"
                fixed="right">
                <template #default="scope">
                    <el-button link type="primary" size="small" @click="handleDetail(scope.row)">
                        {{ $t('manage.userForm.Details') }}
                    </el-button>
                    <el-button link type="primary" size="small" @click="handleReset(scope.row)">
                        {{ $t('manage.userForm.resetPassword') }}
                    </el-button>
                    <el-dropdown @command="changeMore($event, scope.row)" trigger="click" class="changeMore">
                        <div class="moreIcon">
                            <img src="@/assets/base/more.svg" alt="" />
                        </div>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item :command="1">编辑用户</el-dropdown-item>
                                <el-dropdown-item :command="2" style="color: #F02D63;">禁用用户</el-dropdown-item>
                                <el-dropdown-item :command="3" style="color: #F02D63;">启用用户</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
        <Pagination :background="true" :currentPage="currentPage" :pageSize="pageSize" :total="totalItems"
            @size-change="updatePageSize" @current-change="updateCurrentPage" class="Pagination" />
    </div>
    <div>
        <el-dialog v-model="showDialog" :show-close="false" class="dialog" align-center>
            <template #header="">
                <div class="my-header">
                    <div>表格显示</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="showDialog = false"
                        style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <div v-for="(item, key, index) in tabelType" :key="index">
                <el-checkbox :value="item" :checked="item" @change="handleChange($event, key)">
                    {{ $t('manage.userForm.' + key) }}
                </el-checkbox>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="showDialog = false">
                        {{ $t('manage.userForm.Cancel') }}
                    </el-button>
                    <el-button type="primary" @click="handleConfirm">
                        {{ $t('manage.userForm.Confirm') }}
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
    <!-- 用户详情 -->
    <detailView :showDetail='showDetail' @handleCancel="handleCancel" @handleAdd="handleAdd" :detailInfo="detailInfo">
    </detailView>
    <!-- 用户新增与修改 -->
    <addView :show="showAdd" :formType="formType" @handleCloseUser="handleCloseUser" @handleAddUser="handleAddUser"
        :uploadInfo="uploadInfo" @addUserBtn="addUserBtn">
    </addView>
    <!-- 批量导入导出 -->
    <Export :showExport="showExport" @handleCloneExport='handleCloneExport'></Export>
    <!-- 密码重置 -->
    <userReset :showReset="showReset" :resetInfo="resetInfo" @closeReset="closeReset" @submitReset="submitReset">
    </userReset>
</template>

<style scoped lang="scss">
.search {
    :deep(.el-select__wrapper) {
        border-radius: 16px !important;
        height: 48px;
        width: 160px !important;
        font-size: 16px !important;
    }
}


:deep(.el-checkbox) {
    height: 30px !important;
}

:deep(.el-dialog) {
    --el-dialog-width: 600px;
    border-radius: 30px;
    padding: 30px;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 24px;
    position: relative;
}

.searchItem {
    margin-right: 10px;
}

.operation {
    width: 200px;
}

.tableHeight {
    height: 510px;
}

.dialog {
    width: 200px;
}

.changeMore {
    margin-left: 5px;

    .moreIcon {
        width: 24px;
        height: 24px;

        img {
            display: block;
            width: 16px;
            height: 16px;
            margin: 4px auto;
        }
    }
}

.Pagination {
    margin-top: 20px;
}

.dashboard {
    margin-top: 20px;
    margin-bottom: 8px;
    display: flex;
    position: relative;

    .companyName {
        font-size: 22px;
        color: #3D3D3D;
        margin-right: 32px;
        font-weight: 600;
    }

    .companyNum {
        font-size: 18px;
        color: #8A8A8A;
        margin-right: 16px;
        margin-top: 4px;

        .num {
            color: #3D3D3D;
        }
    }
}

.search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;

    .searchLeft {
        display: flex;

        .date {
            height: 44px;
            line-height: 44px;
            border: #edeef6 solid 2px;
            border-radius: 14px;
            font-size: 18px;
            display: flex;
            font-size: 18px;
            text-align: center;
            padding: 0 18px;
            cursor: pointer;

            div:first-child {
                margin-right: 10px;
                color: #8a8a8a;
            }
        }
    }
}

.ellipsis {
    font-size: 16px;
    min-width: 44px;
}

.tableWidth {
    width: 40px;
}

.tableWidth1 {
    width: 10px;
}

.searchDiv {
    border: #edeef6 solid 2px;
    border-radius: 16px;
    font-size: 16px;
    padding: 0 10px;
    margin-left: 10px;
    cursor: pointer;
}

.toolBox {
    border: #edeef6 solid 2px;
    border-radius: 16px;
    font-size: 16px;
    padding: 0 10px;
    height: 42px;
    line-height: 40px;
    color: #8A8A8A;
    margin-right: 10px;
    cursor: pointer;
}

.searchSpan {
    color: #3D3D3D;
    font-size: 16px;
    width: 100%;
    display: flex;
    margin-top: 13px;
}

.searchIcon {
    margin-left: 10px;
}

.searchDiv2 {
    border: #edeef6 solid 2px;
    border-radius: 16px;
    font-size: 16px;
    padding: 6px;
    cursor: pointer;

    .toolImg {
        width: 32px;
        height: 32px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.searchInput {
    margin-right: 10px;
    height: 44px;
}

.addUser {
    background-color: #0256FF;
    color: #fff;
    margin-right: 0;
}
</style>
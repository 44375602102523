import { App } from 'vue';  
  
const formatFileSize = (bytes: number | null): string => {  
  if (bytes == 0 || bytes == null) return "0 B";  
  const k = 1024;  
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];  
  const i = Math.floor(Math.log(bytes!) / Math.log(k));  
  return (bytes! / Math.pow(k, i)).toFixed(2) + " " + sizes[i];  
};  
  
const fileSizePlugin = {  
  install(app: App) {  
    app.config.globalProperties.$formatFileSize = formatFileSize;  
  }  
};  
  
export default fileSizePlugin;
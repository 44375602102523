<template>
    <div>
        <audio controls @timeupdate="updateProgress" @loadedmetadata="handleLoadedMetadata" ref="audioRef"
            style="display: none">
            <source :src="fileurl" />
        </audio>
        <div class="audio-right">
            <!-- <Icon :type="audioStatus !== 'pause' ? 'play-circle' : 'pause-circle'" @click="playAudio"
                class="dialogAudioPlay"></Icon> -->
            <div class="progress-bar-bg" id="progressBarBg" v-dragto="setAudioIcon">
                <div class="progress-bar" id="progressBar"></div>
            </div>
            <div class="audio-time" style="min-height: 10px">
                <span class="audio-length-current" id="audioCurTime">{{
                    audioStart
                    }}</span>/
                <span class="audio-length-total">{{ duration }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import api from "@/api/index";
import { ref, reactive, onMounted, defineProps ,computed} from "vue";
import { Icon } from "ant-design-vue";
const props = defineProps({
    fileurl: String,
})
onMounted(() => {
    audioIcon()
    const myVid = audioRef.value;
    myVid.loop = false;
    myVid.addEventListener('ended', () => {
        audioStatus.value = 'play';
        document.getElementById('progressBar').style.width = '0%';
    }, false);

    // 监听音频元数据加载完毕  
    myVid.addEventListener('loadedmetadata', () => {
        if (!isNaN(myVid.duration)) {
            duration.value = transTime(myVid.duration);
        }
    });

    // 更新进度条和当前播放时间  
    myVid.addEventListener('timeupdate', (e) => {
        const value = e.target.currentTime / e.target.duration;
        if (document.getElementById('progressBar')) {
            document.getElementById('progressBar').style.width = value * 100 + '%';
            if (e.target.currentTime === e.target.duration) {
                audioStatus.value = 'pause';
            }
            audioStart.value = transTime(e.target.currentTime);
        } else {
            audioStatus.value = 'pause';
        }
    });
})
const audioStatus = ref("play");
const audioStart = ref("0:00");
const duration = ref("0:00");
const audioVolume = ref(0.5);
const audioHuds = ref(false);

const audioIcon = computed(() => {
    return audioHuds.value ? (audioVolume.value < 0.01 ? 'checked icon-jingyin' : 'checked icon-shengyin') : 'icon-shengyin';
});
// 指令  
const directives = {
    dragto: {
        inserted(el, binding, vnode) {
            el.addEventListener('click', (e) => {
                const wdiv = document.getElementById('progressBarBg').clientWidth;
                const ratemin = e.offsetX / wdiv;
                const rate = ratemin * 100;
                document.getElementById('progressBar').style.width = rate + '%';
                audioRef.value.currentTime = audioRef.value.duration * ratemin;
                audioRef.value.play();
                binding.value();
            }, false);
        },
    },
    adjuster: {
        inserted(el, binding, vnode) {
            el.addEventListener('click', (e) => {
                const hdiv = document.getElementById('volumeBarBg').clientHeight;
                const ratemin = e.offsetY / hdiv;
                const rate = ratemin * 100;
                document.getElementById('volumeBar').style.height = rate + '%';
                audioRef.value.volume = ratemin;
                binding.value(rate / 100);
            }, false);
        },
    },
};
// 方法  
const playAudio = () => {
    const recordAudio = audioRef.value;
    if (recordAudio.paused) {
        recordAudio.play();
        audioStatus.value = 'pause';
    } else {
        recordAudio.pause();
        audioStatus.value = 'play';
    }
};

const transTime = (time) => {
    const duration = parseInt(time, 10);
    const minute = parseInt(duration / 60, 10);
    let sec = (duration % 60).toString();
    const isM0 = ':';
    if (minute === 0) {
        minute = '00';
    } else if (minute < 10) {
        minute = '0' + minute;
    }
    if (sec.length === 1) {
        sec = '0' + sec;
    }
    return minute + isM0 + sec;
}; 
</script>

<style lang="scss" scoped>
.volume {
    position: relative;

    .volume-progress {
        position: absolute;
        top: -150px;
        width: 32px;
        height: 140px;
        background: #f6f6f6;
        border-radius: 4px;
        padding-top: 10px;
    }

    .volume-bar-bg {
        margin: 0 auto;
        width: 6px;
        height: 120px;
        background: #dcdcdc;
        border-radius: 100px;
        flex: 1;
        position: relative;
        transform: rotate(180deg);
        cursor: pointer;

        .volume-bar {
            width: 6px;
            height: 50%;
            background: #56bf8b;
            border-radius: 100px;
        }
    }

    .checked {
        color: #56bf8b;
    }
}

.audio-right {
    width: 100%;
    height: 49px;
    line-height: 49px;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    padding: 0 15px;

    .dialogAudioPlay {
        cursor: pointer;
        color: #264095;
        font-size: 36px;
        display: flex;
        align-items: center;
    }

    .progress-bar-bg {
        border-radius: 5px;
        background-color: #cccccc;
        flex: 1;
        position: relative;
        height: 10px;
        top: 50%;
        transform: translateY(-50%);
        margin-top: -1px;
        cursor: pointer;
        margin: 0 10px;
    }

    .progress-bar {
        background-color: #264095;
        width: 0%;
        height: 10px;
        border-radius: 5px;
    }

    .audio-time {
        overflow: hidden;
        font-size: 14px;

        .audio-length-total {
            float: right;
        }

        .audio-length-current {
            float: left;
        }
    }
}
</style>
<script setup>
import { ref, watch, nextTick, defineProps, defineEmits } from 'vue'
import caseScreen from './caseScreen.vue'
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import api from "@/api/index";
const { t } = useI18n();
import helper from "@/assets/bot/helper.svg";
import brain from "@/assets/bot/brain.svg";
import vector from "@/assets/bot/Vector.svg";
import IE from "@/assets/bot/IE.svg";
import { message } from 'ant-design-vue';
const store = useStore();
const userId = ref(store.state.userId);
const props = defineProps({
    showDialog: Boolean,
    botInfo: Object,
    formType: String,
});
const formRef = ref(null)
const emit = defineEmits(['closeBtn', 'submitBtn', 'editBtn'])
const navActive = ref(0)
const skillActive = ref(0)
const navList = ref([
    {
        name: t('bot.helper'),
        url: helper
    },
    // {
    //     name: t('bot.tool'),
    //     url: tool
    // },
])
// 切换选择
const changeNav = (index) => {
    navActive.value = index;
};
const skillList = ref([
    {
        name: t('bot.aiSmall'),
        url: brain
    },
    {
        name: t('bot.imageProduction'),
        url: vector
    },
    {
        name: t('bot.IE'),
        url: IE
    },
])
const skillNav = (index) => {
    skillActive.value = index;
}
// 一键完善
const oneKeyPerfect = () => {
    console.log('一键完善');
}

// 校验规则
const rules = ref({
    templateName: [
        { required: true, message: '模板名称不能为空', trigger: 'blur' }
    ],
    templateValue: [
        { required: true, message: '模板描述不能为空', trigger: 'blur' }
    ],
    shareUserIds: [
        { required: true, message: '权限成员不能为空', trigger: 'blur' }
    ],
})
// 提问灵感
const inputValue = ref('')
const dynamicTags = ref([])
const inputVisible = ref(false)
const InputRef = ref(null)

const handleClose1 = (tag) => {
    dynamicTags.value.splice(dynamicTags.value.indexOf(tag), 1)
}

const showInput = () => {
    inputVisible.value = true
    // nextTick(() => {
    //     // InputRef.value!.input!.focus()
    // })
}

const handleInputConfirm = () => {
    if (inputValue.value) {
        dynamicTags.value.push(inputValue.value)
    }
    inputVisible.value = false
    inputValue.value = ''
};
// 增加成员弹窗
const showTool = ref(false);
const userList = ref({
    departmentList: [],
    userList: [],
    gropuList: [],
}); //回显
const addMember = () => {
    const result = props.botInfo.shareUserIds.reduce((acc, item) => {
        const { idType } = item;
        if (!acc[idType]) {
            acc[idType] = [];
        }
        acc[idType].push(item);
        return acc;
    }, {});
    // 将结果转换为数组
    userList.value.userList = result[0] || [];
    userList.value.departmentList = result[1] || [];
    userList.value.gropuList = result[2] || [];
    showTool.value = true
}
// 确定保存
const saveUser = (arr) => {
    props.botInfo.shareUserIds = props.botInfo.shareUserIds.concat(arr);
    // 去重
    const uniqueMap = new Map(
        props.botInfo.shareUserIds.map((item) => [item.id, item])
    );
    props.botInfo.shareUserIds = Array.from(uniqueMap.values());
    showTool.value = false;
};
const closeDialog = () => {
    emit('closeBtn')
}
const submitBtn = async (formRef) => {
    await formRef.validate((valid) => {
        if (valid) {
            emit('submitBtn', props.botInfo);
        }
    })
};
// 修改确定
const editBtn = async (formRef) => {
    await formRef.validate((valid) => {
        if (valid) {
            emit('editBtn', props.botInfo);
        }
    })

};
// 用户移除
const newType = ref(null)
const removeMember = (item) => {
    if (item.name == 'userId') {
        newType.value = 0
    } else if (item.name == 'GroupId') {
        newType.value = 2
    } else {
        newType.value = 1
    }
    api.bot
        .templateUserDel({
            userId: userId.value,
            templateId: props.botInfo.templateId,
            changeOwnerId: item.data,
            changeOwnerType: newType.value
        })
        .then((res => {
            if (res.returnCode == 200) {
                props.botInfo.shareUserIds = props.botInfo.shareUserIds.filter((items) => items.userName !== item.userName)
            }
        }))
};
</script>

<template>
    <div>
        <el-dialog v-model="props.showDialog" :close-on-click-modal="false" align-center :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>{{ props.formType == 'add' ? '新建智能体' : '修改智能体' }}</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="closeDialog" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <div class="flexDiv">
                <el-form ref="formRef" :model="props.botInfo" :rules="rules" label-position="top">
                    <el-form-item label="智能体类型">
                        <div class="flexBetWeenOne">
                            <div v-for="(item, index) in navList" :key="index"
                                :class="navActive == index ? 'itemDivNavActive' : 'itemDivNav'"
                                @click="changeNav(index)">
                                <img :src="item.url" alt="" class="navImg" />
                                <div :class="navActive == index ? 'itemTextActive' : 'itemText'">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                    <!-- 名称 -->
                    <el-form-item prop="templateName" :label="$t('bot.name')">
                        <el-input v-model="props.botInfo.templateName" :placeholder="$t('bot.placeName')" />
                    </el-form-item>
                    <el-form-item prop="templateValue">
                        <div class="settingBox">
                            <div>{{ $t('bot.setting') }}</div>
                            <div class="iconBox" @click="oneKeyPerfect">
                                <el-icon class="iconSet">
                                    <EditPen />
                                </el-icon>
                                {{ $t('bot.oneKey') }}
                            </div>
                        </div>
                        <el-input v-model="props.botInfo.templateValue" maxlength="2000"
                            :placeholder="$t('bot.palceDesc')" show-word-limit style="color: #a8abb2;" />
                    </el-form-item>
                    <!-- 技能 -->
                    <el-form-item :label="$t('bot.skill')">
                        <div class="flexBetWeen">
                            <div v-for="(item, index) in skillList" :key="index" class="itemSkillNav"
                                @click="skillNav(index)">
                                <img :src="item.url" alt="" class="skillImg" />
                                <div class="itemSkillText">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                    <!-- 权限设置 -->
                    <el-form-item prop="shareUserIds" :label="$t('bot.limits')">
                        <el-table :data="props.botInfo.shareUserIds" class="tableSet">
                            <el-table-column prop="userName" label="名称" class="column1" />
                            <el-table-column prop="type" label="身份" class="column1">
                                <template #default="scope">
                                    <div v-if="scope.row.type == 1">普通用户</div>
                                    <div v-else>创建者</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" class="column2">
                                <template #default="scope">
                                    <el-button link type="danger" size="small"
                                        @click="removeMember(scope.row)">移除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="divTop">
                            <!-- 添加新成员 -->
                            <div class="infoBtn" @click="addMember">
                                {{ $t('bot.addMember') }}
                            </div>
                        </div>
                    </el-form-item>
                    <!-- 介绍 -->
                    <el-form-item prop="templateDesc" :label="$t('bot.introduce')">
                        <el-input v-model="props.botInfo.templateDesc" maxlength="2000"
                            :placeholder="$t('bot.placeIntroduce')" show-word-limit />
                    </el-form-item>
                    <!-- 开场白 -->
                    <el-form-item :label="$t('bot.openingRemarks')" prop="templateCall">
                        <el-input v-model="props.botInfo.templateCall" maxlength="2000"
                            :placeholder="$t('bot.placeOpening')" show-word-limit />
                    </el-form-item>
                    <!-- 提问灵感 -->
                    <el-form-item prop="suggest">
                        <div style="width: 100%;">
                            <!-- 头部 -->
                            <div class="inspirationBox">
                                <div> {{ $t('bot.inspiration') }}</div>
                                <div>
                                    <el-input v-if="inputVisible" ref="InputRef" v-model="props.botInfo.suggest"
                                        size="small" @keyup.enter="handleInputConfirm" @blur="handleInputConfirm" />
                                    <div v-else class="button-new-tag" @click="showInput">
                                        <div>
                                            <el-icon class="formIcon">
                                                <Plus />
                                            </el-icon>
                                        </div>
                                        <div>{{ $t('bot.addition') }}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- 输入框 -->
                            <div class="flex gap-2">
                                <el-tag v-for="tag in dynamicTags" :key="tag" closable :disable-transitions="false"
                                    @close="handleClose1(tag)" size="large" class="tagRight">
                                    {{ tag }}
                                </el-tag>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <div class="dialog-footer" v-if="props.formType == 'add'">
                    <div @click="closeDialog" class="cancel">取消</div>
                    <div @click="submitBtn(formRef)" class="confirm">确定</div>
                </div>
                <div class="dialog-footer" v-if="props.formType == 'edit'">
                    <div @click="closeDialog" class="cancel">取消</div>
                    <div @click="editBtn(formRef)" class="confirm">确定</div>
                </div>
            </template>
        </el-dialog>
    </div>
    <!-- 添加成员 -->
    <caseScreen v-if="showTool" @closeUser="showTool = false" @saveUser="saveUser">
    </caseScreen>
</template>



<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:deep(.el-input__wrapper) {
    height: 44px;
    border-radius: 18px;
    border: 2px solid #d9d9d9;
    box-shadow: 0 0 0;

}

:deep(.el-select) {
    width: calc(100% - 10px) !important;
}

:deep(.el-select__wrapper) {
    min-height: 40px !important;
    width: calc(100% - 4px) !important;
}


:deep(.el-collapse-item__header) {
    background-color: #F7F8FF;
}

:deep(.el-button.is-round) {
    border-radius: 16px;
}

:deep(.el-table th.el-table__cell) {
    background-color: #f7f8ff;
}

:deep(.el-dialog) {
    --el-dialog-width: 610px;
    border-radius: 30px;
    padding: 30px;
}

:deep(.el-overlay) {
    z-index: 60 !important;
}

.flexDiv {
    height: 590px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px 10px;
}

.close-icon {
    font-size: 20px;
}

.tagRight {
    margin-right: 6px;
}

.column1 {
    width: 320px;
}

.column2 {
    width: 160px;
}

.tableSet {
    height: 180px;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 24px;
    position: relative;
}

.formIcon {
    margin-right: 4px;
    font-size: 18px;
    margin-top: 8px;
}

.infoBtn {
    height: 42px;
    width: 100%;
    color: #fff;
    background-color: #3376ff;
    border-radius: 16px;
    line-height: 42px;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
}

.divTop {
    width: 100%;
    margin-top: 10px;
    display: flex;
}

.btnSpan {
    margin-left: 10px
}

// 添加成员
.button-new-tag {
    display: flex;
    justify-content: center;
    color: #0256ff;
    font-size: 15px;
    margin-left: 12px;
    width: 82px;
    background-color: #f7f8ff;
    text-align: center;
    padding: 0px 8px;
}

.inspirationBox {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.collapseBox {
    background-color: #F7F8FF;
}

.title-Icon {
    font-size: 16px;
    color: #8A8A8A;
    display: flex;
    height: 46px;
    line-height: 46px;

    .header-icon {
        color: #3D3D3D;
        width: 21px;
        height: 21px;
        margin: 12px;
    }
}

// 一键完善
.settingBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-size: 15px;

    .iconBox {
        height: 30px;
        background-color: #f7f8ff;
        border-radius: 4px;
        padding: 4px 6px;
        color: #0256ff;
        font-weight: 600;

        .iconSet {
            width: 26px;
            height: 26px;
            border-radius: 6px;
            background-color: #dbdfff;
            margin-right: 8px;

        }
    }

}

// 选择模式
.flexBetWeen {
    display: flex;
    align-items: center;

    .imageBox {
        width: 80px;
        height: 80px;
        border-radius: 18px;
        margin-right: 10px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 18px;
        }
    }

    .aiBox {
        width: 433px;
        height: 80px;
        padding: 10px 18px 10px 2px;
        display: flex;
        justify-content: space-between;
        background-color: #f2f3fb;
        border-radius: 8px;

        .leftBtn {
            width: 32px;
            height: 42px;
            margin-top: 7px;
            margin-right: 6px;
            z-index: 100;
            cursor: pointer;
            background-color: #fff;
            // 渐变
            box-shadow: 0 0 10px 10px #fff;

            .icon {
                font-size: 24px;
                margin-top: 12px;
            }

            .icon:hover {
                background-color: #f2f3fb;
                border-radius: 50%
            }
        }

        .rightBtn {
            margin-top: 6px;
            cursor: pointer;
            border-radius: 12px;
            background-color: #eceeff;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            padding: 6px 10px;

            .aiImgIcon {
                width: 32px;
                height: 32px;
                border-radius: 8px;
                background-color: #dbdfff;
                margin-right: 8px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .aiImgTitle {
                font-size: 18px;
                color: #0256ff;
                font-family: "Medium";
            }
        }

    }
}

.flexBetWeenOne {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

// 选中样式
.itemSkillActive {
    padding: 0 12px 0 0;
    margin-right: 6px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 18px;
    border: 2px solid #dbdfff;
    background-color: #eceeff;
    cursor: pointer;
}

.itemSkillNav {
    padding: 0 12px 0 0;
    margin-right: 6px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 18px;
    border: 2px solid #d9d9d9;
    cursor: pointer;
}

.skillImg {
    width: 26px;
    height: 26px;
    display: block;
    margin: 6px 12px 6px 12px;
}

.itemDivNavActive {
    width: 49%;
    height: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 18px;
    box-shadow: #DBDFFF 0px 0px 2px 2px;
    background-color: #eceeff;
    cursor: pointer;
    margin-right: 30px
}

.itemDivNav {
    width: 49%;
    height: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 18px;
    box-shadow: #DBDFFF 0px 0px 2px 2px;
    cursor: pointer;
    margin-right: 30px;
}

.itemTextActive {
    font-size: 20px;
    color: #0256ff;
    font-family: "Regular";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.itemSkillTextActive {
    font-size: 18px;
    color: #0256ff;
    font-family: "Regular";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.itemText {
    font-size: 20px;
    color: #3d3d3d;
    font-family: "Regular";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.itemSkillText {
    font-size: 18px;
    color: #3d3d3d;
    font-family: "Regular";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}



.navImg {
    width: 38px;
    height: 38px;
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #8A8A8A;
        padding: 6px 12px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 6px 12px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
<script setup>
import { ref, defineProps, onMounted, watch } from "vue";
import { message } from 'ant-design-vue';
import { useStore } from "vuex";
import api from "@/api/index";
const store = useStore();
const userId = ref(store.state.userId);
const emit = defineEmits(['handleCancel', 'handleAdd']);
const props = defineProps({
    show: Boolean,
    roleInfo: Object
});
watch(
    () => props.roleInfo,
    (newVal) => {
        props.roleInfo = newVal
    })
onMounted(() => {
})
const title = ref('角色详情');

// 拒绝
const handleCancel = () => {
    emit('handleCancel')
}
//同意
const handleConfirm = () => {
    emit('handleAdd')
};
// 移除角色
// const removeTag = (row) => {     
//     api.manage
//         .roleRemoveUserApi({
//             roleId: props.roleInfo.id,
//             userId: userId.value,
//             userIdList:[row.userId]
//         })
//         .then((res) => {            
//             if (res.returnCode == 200) {
//                 // message.success('移除成功');
//                 props.roleInfo.userInfoList = props.roleInfo.userInfoList
//             }
//         })
// };
</script>

<template>
    <div>
        <!-- 角色详情 -->
        <el-dialog v-model="props.show" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>{{ title }}</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleCancel" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <el-divider />
            <div class="content">
                <div class="name">{{ roleInfo.roleName }}</div>
            </div>
            <div class="introduce">{{ roleInfo.description }}</div>
            <div class="addUser">
                <div class="left">成员</div>
            </div>
            <el-table :data="roleInfo.userInfoList" class="table">
                <el-table-column prop="userName" label="用户名" />
                <el-table-column prop="departmentName" label="部门" />
                <el-table-column prop="address" label="操作">
                    <template #default="scope">
                        <div class="move_tag" >移除</div>
                        <!-- <div class="move_tag" @click=removeTag(scope.row) >移除</div> -->
                    </template>
                </el-table-column>
            </el-table>
            <div class="form-name">
                <p class="tit">作用域</p>
                <div class="el-tag">
                    <div class="tag-img"><img src="@/assets/manage/qincheng.svg" alt=""></div>
                    <div v-for="item in roleInfo.roleScopes" :key="item" >{{ item.departmentName }}</div>
                </div>
            </div>
            <div class="form-name">
                <p class="tit">权限范围</p>
                <AvatarName :avatars=roleInfo.rolesNames :number="8"></AvatarName>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <div @click="handleCancel" class="cancel">关闭详情</div>
                    <div @click="handleConfirm" class="confirm">编辑用户</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>



<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-divider) {
    margin: 1px 0 10px !important;
    width: calc(100%) !important;
}

:deep(.el-dialog) {
    --el-dialog-width: 756px;
    border-radius: 30px;
    padding: 30px;
    max-height: 872px;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 24px;
    position: relative;
}
.move_tag{
    width: 48px;
    padding: 4px 8px;
    background-color: #F7F8FF;
    border-radius: 8px;
    color: #F02D63;    
}
.close-icon {
    font-size: 20px;
}

.content {
    display: flex;
    color: #3D3D3D;

    .name {
        font-size: 24px;
        font-weight: 600;
        margin-right: 6px;
    }

    .tag {
        display: flex;
        justify-content: space-between;
        background-color: #DBDFFF;
        color: #0256FF;
        padding: 0px 6px;
        height: 28px;
        line-height: 28px;
        border-radius: 6px;
        font-size: 16px;
        margin: auto 0;
    }
}

.introduce {
    margin-top: 12px;
    font-size: 18px;
    height: 25px;
    line-height: 25px;
}

.addUser {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;

    .left {
        font-size: 18px;
        color: #3D3D3D;
        font-weight: 600;
        height: 36px;
        line-height: 36px;
    }

    .add {
        width: 112px;
        height: 36px;
        font-size: 18px;
        color: #fff;
        background-color: #3376FF;
        line-height: 36px;
        text-align: center;
        border-radius: 12px;
    }
}

.table {
    margin-top: 12px;
    width: 100%;
    height: 300px;
}

.form-name {
    margin-top: 12px;
    font-size: 18px;
    color: #3D3D3D;

    .el-tag {
        display: flex;
        padding: 2px 33px 2px 2px;
        width: 144px;
        height: 35px;
        line-height: 35px;
        border-radius: 30px;
        font-size: 18px;
        margin-top: 12px;

        .tag-img {
            width: 30px;
            height: 30px;
            margin-right: 6px;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    }

    .tit {
        margin-bottom: 10px;
        font-weight: 600;
    }

    .comtent {
        margin-bottom: 12px;
    }
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #8A8A8A;
        padding: 6px 12px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 6px 12px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
<script setup>
import detailView from '../components/groupDetail.vue'
import addView from '../components/groupAdd.vue'
import { ref, onMounted, reactive, watch } from "vue";
import { useI18n } from "vue-i18n"
import { useStore } from "vuex";
import api from "@/api/index";
import { message } from "ant-design-vue";
import { ElMessageBox } from 'element-plus'
const { t } = useI18n()
const store = useStore();
const userId = ref(store.state.userId);
onMounted(() => {
  getGroupList()

});
// 分页 --table
const pageSize = ref(10);
const currentPage = ref(1);
const totalItems = ref(0);
const tableMember = ref([]);
const listForm = ref({
  pageId: currentPage.value,
  perPage: pageSize.value,
  userId: userId.value,
})
// 部门组列表
const defaultProps = ref({
  label: 'label',
});
const currentNodeId = ref(0);
// 部门组收缩
const isAsideVisible = ref(true)
const toggleAside = () => {
  isAsideVisible.value = !isAsideVisible.value
};

// 群组查询
const listData = ref({
  userId: userId.value,
  search: ""
})
watch(
  () => listData.value.search,
  (newVal) => {
    listData.value.search = newVal;
    getGroupList()
  }
)
const groupList = ref([])
const number = ref(0)
const getGroupList = () => {
  api.manage
    .getGroupListApi(listData.value)
    .then((res) => {
      if (res.returnCode == 200) {
        groupList.value = res.data;
        groupNameNew.value = res.data[0]?.groupName
        listForm.value.groupId = res.data[0]?.groupId
        getGroupData()
      }
    })
}
const getGroupData = () => {
  api.manage
    .getGroupTableApi(listForm.value)
    .then((res) => {
      if (res.returnCode == 200) {
        totalItems.value = res.totalUserNum
        tableMember.value = res.data.users
        number.value = res.data.users.length
      }
    })
}
// 左侧点击
const groupNameNew = ref('')
const handleNodeClick = (data) => {
  currentNodeId.value = data.groupId;
  groupNameNew.value = data.groupName
};
// 每页显示条数
const updatePageSize = (newSize) => {
  pageSize.value = newSize;
}
// 更新当前页码
const updateCurrentPage = (newPage) => {
  currentPage.value = newPage;
}

// 单个移除
const handleClick = (data) => {
  api.manage
    .deleteGroupApi({
      userIdList: [data.userId]
    }, listForm.value.groupId)
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('移除成功');
        getGroupData()
      }
    })
};
// 多选 -- 批量移除
const ids = ref([])
const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  multipleSelection.value = val
  ids.value = val.map((item) => item.userId)
};
const removeGroup = () => {
  if (multipleSelection.value.length == 0) {
    message.error('请选择要移除的群组用户');
  } else {
    console.log(ids.value);
    ElMessageBox.confirm('确定要移除这些群组用户吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
      .then(() => {
        api.manage
          .deleteGroupApi({
            userIdList: ids.value,
          }, listForm.value.groupId)
          .then((res) => {
            if (res.returnCode == 200) {
              message.success('移除成功');
              getGroupData()
            }
          })
      })
      .catch(() => {
        message.info('已取消移除');
      })
  }
};
// 打开群组详情
const showDetail = ref(false);
const groupInfo = ref({});
const groupDetail = ref({});
const changeMore = (key, item) => {
  if (key === 1) {
    api.manage
      .getGroupInfoApi({
        groupId: item.groupId,
        userId: userId.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          groupDetail.value = res.data;
        }
      })
    showDetail.value = true;
  } else if (key === 2) {
    api.manage
      .getGroupTableApi({
        groupId: item.groupId,
        userId: userId.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          groupInfo.value = res.data;
        }
      })
    showEdit.value = true;
    formType.value = 'edit';
  } else if (key === 3) {
    // 删除群组
    ElMessageBox.confirm('确定要解散该群组吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
      .then(() => {
        api.manage
          .deleteGroupAllApi({
            userId: userId.value,
          },item.groupId)
          .then((res) => {
            if (res.returnCode == 200) {
              message.success('解散成功');
              getGroupList();
            }
          })
      })
  }
};
// 群组详情
const formType = ref('add');
const showEdit = ref(false);
const handleCancel = () => {
  showDetail.value = false;
};
const handleUpload = () => {
  showDetail.value = false;
  showEdit.value = true;
  formType.value = 'edit';
  groupInfo.value = groupDetail.value;
};
// 编辑群组接口
const handleClose = () => {
  showEdit.value = false;
  getGroupList()

};
const addGroup = () => {
  showEdit.value = true;
  formType.value = 'add';
};
// 增加组用户
const userIdList = ref([]);
const ids2 = ref([]);
const showAddDialog = ref(false);
const addUserNum = () => {
  showAddDialog.value = true;
};
// 确定修改用户
const saveUser = (arr) => {
  ids2.value = arr.map((item) => item.userId);
  showAddDialog.value = false;
  api.manage
    .addGroupUserApi({
      userIdList: ids2.value,
    }, listForm.value.groupId)
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('添加成功');
        getGroupData()
      }
    })
};
// 确认新增
const handleSave = (data) => {
  api.manage
    .addGroupApi(data)
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('新增成功')
        showEdit.value = false;
        getGroupList()
      } else {
        message.error('新增失败')
        showEdit.value = false;
      }
    })
};
</script>

<template>
  <div class="main1">
    <el-container>
      <div class="container">
        <!-- 群组搜索 -->
        <el-input v-model="listData.search" size="large" class="searchInput"
          :placeholder="$t('manage.groupForm.SearchGroup')" clearable v-if="isAsideVisible">
          <template #prefix>
            <el-icon class="el-input__icon">
              <search />
            </el-icon>
          </template>
        </el-input>
        <el-aside class="side" v-if="isAsideVisible">
          <el-tree ref="treeRef" :data="groupList" node-key="currentNodeId" :props="defaultProps" default-expand-all
            @node-click="handleNodeClick" highlight-current :current-node-key="currentNodeId">
            <template #="{ data }">
              <span class="custom-tree-node">
                <div class="admin-name">
                  <img src="@/assets/manage/hover2.svg" alt="" class="admin">
                  <div class="admin-text">{{ data.groupName }}</div>
                </div>
                <span>
                  <el-dropdown @command="changeMore($event, data)" trigger="click">
                    <div class="moreIcon">
                      <img src="@/assets/base/more.svg" alt="" />
                    </div>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item :command="1">群组详情</el-dropdown-item>
                        <el-dropdown-item :command="2">编辑群组</el-dropdown-item>
                        <el-dropdown-item :command="3">解散群组</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </span>
              </span>
            </template>
          </el-tree>
          <!-- 新建群组按钮-->
          <div class="addGroup" @click="addGroup">
            <el-icon class="plus">
              <Plus />
            </el-icon>
            <div>{{ $t('manage.groupForm.addGroup') }}</div>
          </div>
        </el-aside>
        <div @click="toggleAside" class="toggleAside">
          <el-icon class="tagIcon">
            <component :is="isAsideVisible ? 'DArrowLeft' : 'DArrowRight'" />
          </el-icon>
        </div>
      </div>
      <el-main class="userMain">
        <div class="mian">
          <div class="dashboard">
            <div class="companyName">{{ groupNameNew }}</div>
            <div class="companyNum">{{ $t('manage.groupForm.NumberMembers') }} <span class="num">{{ number }}</span>
            </div>
          </div>
          <div class="search">
            <div></div>
            <!-- 功能区 -->
            <div style="display: flex;">
              <div class="toolBox" style="color: #F02D63;" @click="removeGroup">
                {{ $t('manage.groupForm.groupRemove') }}
              </div>
              <div class="toolBox addUser" @click="addUserNum">
                {{ $t('manage.groupForm.addMember') }}
              </div>
            </div>
          </div>
          <el-table :data="tableMember" @selection-change="handleSelectionChange" class="tableHeight">
            <el-table-column type="selection" />
            <el-table-column prop="userName" label="用户名" />
            <el-table-column prop="email" label="邮箱" />
            <el-table-column prop="departmentName" label="部门名" />
            <el-table-column label="操作" class="operation">
              <template #default="scope">
                <el-button link type="primary" class="remove" @click="handleClick(scope.row)">
                  {{ $t('manage.groupForm.Remove') }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination :background="true" :currentPage="currentPage" :pageSize="pageSize" :total="totalItems"
            @size-change="updatePageSize" @current-change="updateCurrentPage" />
        </div>
        <!-- 群组详情 -->
        <detailView :show='showDetail' :groupDetail="groupDetail" @handleCancel="handleCancel"
          @handleUpload="handleUpload">
        </detailView>
        <!-- 群组编辑 -->
        <addView :show="showEdit" :formType="formType" :groupInfo="groupInfo" @handleClose="handleClose"
          @handleSave="handleSave"></addView>
      </el-main>
    </el-container>

  </div>
  <!-- 增加组用户 -->
  <addUser :show="showAddDialog" @closeUser="showAddDialog = false" @saveUser="saveUser" :userList="userIdList">
  </addUser>
</template>

<style lang="scss" scoped>
@import './group.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-tree) {
  font-size: 18px;
  background-color: #f7f8ff;
  color: #3D3D3D;
}

:deep(.el-tree-node__content) {
  height: 50px;
  border-radius: 16px !important;

  &:hover {
    background-color: #ECEEFF;
  }
}

:deep(.el-table th.el-table__cell) {
  background-color: #f7f8ff;
}

:deep(.el-input__wrapper) {
  border-radius: 16px;
  margin-left: 6px;
  margin-right: 6px;
}

:deep(.el-container) {
  height: calc(100% - 60px) !important;
}

:deep(.custom-tree-node) {
  font-size: 16px;
}

:deep(.el-checkbox) {
  height: 30px;
}

.searchItem {
  :deep(.el-select__wrapper) {
    border-radius: 16px !important;
    height: 44px;
    width: 160px !important;
    font-size: 16px !important;
  }
}
</style>

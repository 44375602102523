<template>
  <div class="topRightDiv">
    <div class="rightDivItem">
      <el-row class="topRow">
        <el-col :span="12" class="topLeftCol">
          <div class="topLeftTitle">今日剩余资源</div>
          <div class="chatTip">当前统计模型：</div>
          <el-select class="topSelect" v-model="model" @change="getToken">
            <el-option
              v-for="item in modelList"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-col>
        <el-col :span="12" class="echartsItem">
          <el-progress
            type="circle"
            :percentage="tokenSum"
            color="#8A8A8A"
            :stroke-width="10"
          >
            <template #default="{ percentage }">
              <div class="tipLeft" style="color: #8a8a8a">消耗</div>
              <div class="tipLeft1" style="color: #8a8a8a">
                {{ percentage }}
              </div>
              <div class="tipLeft" style="color: #8a8a8a">令牌</div>
            </template>
          </el-progress>
          <el-popover placement="bottom" popper-class="formDIv">
            <template #reference>
              <div class="formDiv">
                <span>来源</span>
                <img :src="eImg" alt="" class="resonImg" />
                <img :src="bImg" alt="" class="resonImgActive" />
              </div>
            </template>
            <el-table :data="tokenData">
              <el-table-column label="来源">
                <template #default="scope">
                  <div class="tableCenter">
                    <div
                      v-if="scope.row.eName"
                      class="messageTx"
                      :style="'background-color:' + getRandomColor()"
                    >
                      {{ scope.row.eName.slice(0, 1) }}
                    </div>
                    <div class="tableText">{{ scope.row.name }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column property="number" label="用户日配额">
                <template #default="scope">
                  <span class="tableText">最多{{ scope.row.number }}令牌</span>
                </template>
              </el-table-column>
            </el-table>
          </el-popover>
        </el-col>
      </el-row>
    </div>
    <div class="rightDivItem">
      <!-- 左侧 -->
      <div class="echartsItem">
        <el-progress
          type="circle"
          :percentage="imgSum"
          color="#14AE5C"
          :stroke-width="10"
        >
          <template #default="{ percentage }">
            <div class="tipLeft" style="color: #14ae5c">生成</div>
            <div class="tipLeft1" style="color: #14ae5c">
              {{ percentage }}
            </div>
            <div class="tipLeft" style="color: #14ae5c">幅图像</div>
          </template>
        </el-progress>
        <el-popover placement="top" popper-class="formDIv">
          <template #reference>
            <div class="formDiv">
              <span>来源</span>
              <img :src="eImg" alt="" class="resonImg" />
              <img :src="bImg" alt="" class="resonImgActive" />
            </div>
          </template>
          <el-table :data="imgData">
            <el-table-column label="来源">
              <template #default="scope">
                <div class="tableCenter">
                  <div
                    class="messageTx"
                    v-if="scope.row.eName"
                    :style="'background-color:' + getRandomColor()"
                  >
                    {{ scope.row.eName.slice(0, 1) }}
                  </div>
                  <div class="tableText">{{ scope.row.name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column property="number" label="用户日配额">
              <template #default="scope">
                <span class="tableText">最多{{ scope.row.number }}令牌</span>
              </template>
            </el-table-column>
          </el-table>
        </el-popover>
      </div>
      <!-- 右侧 -->
      <div class="echartsItem">
        <el-progress
          type="circle"
          :percentage="fileSum"
          color="#FFC300"
          :stroke-width="10"
        >
          <template #default="{ percentage }">
            <div class="tipLeft" style="color: #ffc300">上传</div>
            <div class="tipLeft1" style="color: #ffc300">
              {{ percentage }}
            </div>
            <div class="tipLeft" style="color: #ffc300">个文件</div>
          </template>
        </el-progress>
        <el-popover placement="top" popper-class="formDIv">
          <template #reference>
            <div class="formDiv">
              <span>来源</span>
              <img :src="eImg" alt="" class="resonImg" />
              <img :src="bImg" alt="" class="resonImgActive" />
            </div>
          </template>
          <el-table :data="fileData">
            <el-table-column label="来源">
              <template #default="scope">
                <div class="tableCenter">
                  <div
                    class="messageTx"
                    v-if="scope.row.eName"
                    :style="'background-color:' + getRandomColor()"
                  >
                    {{ scope.row.eName.slice(0, 1) }}
                  </div>
                  <div class="tableText">{{ scope.row.name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column property="number" label="用户日配额">
              <template #default="scope">
                <span class="tableText">最多{{ scope.row.number }}令牌</span>
              </template>
            </el-table-column>
          </el-table>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script setup>
import eImg from "@/assets/index/e.svg";
import bImg from "@/assets/index/b.svg";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import api from "@/api";
import { getRandomColor } from "@/utils/utils";
const store = useStore();
const model = ref(null);
const modelList = ref(store.state.modelList); //模型列表
const userId = ref(store.state.userId);
const imgSum = ref(0); // 图片
const fileSum = ref(0); //文件
const tokenSum = ref(0); //token
const tokenData = ref([]); //悬浮来源数据
const fileData = ref([]); //悬浮来源数据
const imgData = ref([]); //悬浮来源数据
onMounted(() => {
  // getDefauleModel();
});
// 获取token
const getToken = () => {
  api.home
    .getIndexTokens({ userId: userId.value, model: model.value })
    .then((res) => {
      if (res.returnCode == 200) {
        imgSum.value = res.data.used_image_sum / res.data.imageSum[0];
        fileSum.value = res.data.used_file_sum / res.data.fileSum[0];
        tokenSum.value = res.data.used_tokens_sum / res.data.sum;
        imgSum.value = imgSum.value ? imgSum.value : 0;
        fileSum.value = fileSum.value ? fileSum.value : 0;
        tokenSum.value = tokenSum.value ? tokenSum.value : 0;
        imgData.value = [
          {
            name: res.data.imageSum[2],
            number: res.data.imageSum[0],
            eName: res.data.imageSum[1],
          },
        ];
        fileData.value = [
          {
            name: res.data.fileSum[2],
            number: res.data.fileSum[0],
            eName: res.data.fileSum[1],
          },
        ];
        tokenData.value = [
          {
            name: res.data.fileChatSum[2],
            number: res.data.fileChatSum[0],
            eName: res.data.fileChatSum[1],
          },
        ];
      }
    });
};
// 获取默认model
const getDefauleModel = () => {
  api.chat.modelGet({ userId: userId.value }).then((res) => {
    if (res.returnCode == 200) {
      model.value = res.data;
      getToken();
    }
  });
};
</script>

<style lang="less" scoped>
.topRightDiv {
  min-width: 475px;
  width: 30%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.rightDivItem {
  width: calc(100% - 40px);
  background-color: #fefefe;
  height: calc(50% - 45px);
  border-radius: 30px;
  padding: 20px;
  display: flex;
  box-shadow: 0px 0px 16px 0 #dbdfff82;
}
.topRow {
  width: 100%;
}
.topSelect {
  width: 155px;
}
.topLeftCol {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.echartsItem {
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tipLeft {
  font-size: 16px;
  font-family: "Regular";
  margin-bottom: 5px;
}
.tipLeft1 {
  font-size: 32px;
  font-family: "Bold";
  margin-bottom: 5px;
}
.formDiv {
  position: absolute;
  right: 5px;
  top: 0px;
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  padding: 2px 8px;
  cursor: pointer;
  .resonImg {
    display: inline-block;
    margin-left: 5px;
    width: 11px;
  }
  .resonImgActive {
    display: none;
    margin-left: 5px;
    width: 11px;
  }
}
.formDiv:hover {
  border-radius: 12px;
  background-color: #dbdfff;
  color: #0256ff;
  .resonImg {
    display: none;
    margin-left: 5px;
    width: 11px;
  }
  .resonImgActive {
    display: inline-block;
    margin-left: 5px;
    width: 11px;
  }
}
.topLeftTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Bold";
}
:deep(.el-select__wrapper) {
  height: 46px;
  color: #3d3d3d;
  font-size: 18px;
  font-family: "Regular";
  border-radius: 16px;
  border: 2px solid #edeef6;
}
:deep(.el-select__caret) {
  font-size: 20px;
  color: #8a8a8a;
}
.chatTip {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
}
:deep(.el-progress-circle) {
  width: 140px !important;
  height: 140px !important;
}
.messageTx {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  margin-right: 6px;
  text-align: center;
  line-height: 31px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Semibold";
}
.tableText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
}
.tableCenter {
  display: flex;
  align-items: center;
}
</style>

<script setup>
import { ref, defineProps, defaultEmits, onMounted, watch } from 'vue'
import { useStore } from "vuex";
import api from "@/api/index";
const store = useStore();
const userId = ref(store.state.userId);
const props = defineProps({
    show: Boolean,
    userList: Array,
})

onMounted(() => {
    getTableList()
})
const emit = defineEmits(['closeUser', 'saveUser']);
const listData = ref({
    userId: userId.value,
    search: ""
})
watch(
    () => listData.value.search,
    (newVal) => {
        listData.value.search = newVal;
        getTableList()
    }
)

const value1 = ref();
const groupList = ref([])
const getTableList = () => {
    api.manage
        .getGroupListApi(listData.value)
        .then((res) => {
            if (res.returnCode == 200) {
                groupList.value = res.data;
            }
        })
};
const newArr = ref([])
const changeBtn = (data, check)=>{
    if(check){
        newArr
    } else {
        
    }
}
const handleClose = () => {
    emit('closeUser')
}
const handleSave = () => {
    emit('saveUser')
};
</script>
<template>
    <div>
        <el-dialog v-model="props.show" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>添加成员</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleClose" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <div>
                <div class="container">
                    <!-- 用户 -->
                    <el-input v-model="listData.search" size="large" class="searchInput"
                        :placeholder="$t('manage.groupForm.SearchGroup')" v-if="isAsideVisible">
                        <template #prefix>
                            <el-icon class="el-input__icon">
                                <search />
                            </el-icon>
                        </template>
                    </el-input>
                    <el-select v-model="value1" multiple placeholder="Select" @change="changeBtn">
                        <el-option v-for="item in groupList" :key="item.value" :label="item.groupName"
                            :value="item.groupId" />
                    </el-select>
                </div>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <div @click="handleClose" class="cancel">放弃</div>
                    <div @click="handleSave" class="confirm">提交</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-dialog) {
    --el-dialog-width: 800px;
    border-radius: 30px;
    padding: 30px;
}

:deep(.el-tree-node__content) {
    height: 34px;
    border-radius: 16px;
    font-size: 15px;
    color: #3d3d3d;
    font-family: "Regular";
}

:deep(.el-tree-node__content:hover) {
    background-color: #eceeff;
    color: #0256ff;
}

:deep(.el-tree-node:focus > .el-tree-node__content) {
    background-color: #eceeff;
    color: #0256ff;
}

:deep(.el-input__wrapper) {
    border-radius: 16px;
    width: 130px !important;
    margin-bottom: 10px;
    margin-right: 10px;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 24px;
    position: relative;
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #F02D63;
        padding: 6px 12px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 6px 12px;
        border-radius: 16px;
        cursor: pointer;
    }
}

.container {
    position: relative;

    .side {
        max-width: 200px;
        height: 460px;
        overflow: auto;
        border-radius: 16px;
        background-color: #f7f8ff;
        padding: 0px 2px;
        position: relative;
        margin-right: 20px;
    }
}

.tableHeight {
    height: 510px;
}
</style>
<template>
  <div class="chatContent">
    <div :class="lookDialog ? 'fileIndex' : 'chatIndex'">
      <!-- gpt切换 -->
      <el-select class="chatSelete" v-model="model">
        <el-option
          v-for="item in modelList"
          :key="item.value"
          :label="item.label"
          :value="item.label"
        />
      </el-select>
      <!-- 聊天 -->
      <div
        :class="{
          width10101:
            (rightType && !historyDialog && !lookDialog) ||
            (!rightType && historyDialog && !lookDialog), //只展示右侧一个清空
          width1010: !rightType && !lookDialog, //没有展示
          width10102: rightType && historyDialog && !lookDialog, //俩同时
          width10103: !rightType && !historyDialog && lookDialog,
        }"
        ref="leftDiv"
      >
        <div
          class="talkDiv"
          :style="'height:' + qaHeight + 'px'"
          ref="containerRef"
        >
          <!-- 默认 -->
          <messageView
            message="我是您的聊天助手,现在可以和我聊天了!"
            class="messageView"
          ></messageView>
          <div v-for="(item, index) in chatList" :key="index">
            <!-- qa -->
            <qaIndex
              type="chat"
              :item="item"
              :id="'talk' + item.conversationId"
              @openFileView="openFileView"
            >
              <template v-slot:question>
                <div class="flexCenterEnd">
                  <div class="lineIcon" @click="copyFn(item)">
                    <img :src="copySvg" alt="" class="wid36" />
                  </div>
                  <div class="lineIcon">
                    <img :src="editSvg" alt="" class="wid36" />
                  </div>
                </div>
              </template>
            </qaIndex>
            <!-- 操作类 -->
            <setVue
              v-if="item.conversationId"
              @openHistory="openHistory"
              @scrollBottom="scrollBottom"
              :rightType="rightType"
              :item="item"
              :model="model"
              :lookDialog="lookDialog"
              :historyDialog="historyDialog"
              @getTalk="getTalk"
            ></setVue>
            <!-- 提示语句 -->
            <div
              class="tipStart"
              v-if="
                index == chatList.length - 1 &&
                chatList[chatList.length - 1].conversationId
              "
            >
              <tipView
                class="tipDiv"
                v-for="item in messageList"
                :key="item"
                @click="saveMessage(item)"
                >{{ item }}</tipView
              >
            </div>
          </div>
        </div>
        <div ref="inputBottomDiv">
          <inputIndex
            ref="inputRef"
            :rightType="rightType"
            :lookDialog="lookDialog"
            :historyDialog="historyDialog"
            @sendMessage="sendMessage"
            @getDivHeight="getDivHeight"
            @openFileView="openFileView"
            @openBaseFile="openBaseFile"
          ></inputIndex>
        </div>
      </div>
      <!-- 历史记录 -->
      <transition name="expand-transition">
        <div
          v-if="historyDialog"
          :class="{
            historyDialog: rightType && historyDialog, //俩都展示
            hisOne: !rightType && historyDialog, //志站是一个
          }"
        >
          <div class="historyTopDiv">
            <div>历史记录</div>
            <div @click="historyDialog = false" class="closeDiv">
              <img :src="closeHistory" class="historyCloseIcon" alt="" />
            </div>
          </div>
          <div class="hisToryItem">
            <!-- 提问 -->
            <div class="hisBetween">
              <div class="hisCenter">
                <div
                  class="txImg"
                  :style="'background-color:' + getRandomColor()"
                >
                  {{ userName.slice(0, 1) }}
                </div>
                <div class="userNmae">{{ userName }} 提问</div>
              </div>
              <div class="historyTime">缺少时间</div>
            </div>
            <!-- 问题 -->
            <div class="hisQ hisCenter">
              {{ historyList.question }}
            </div>
            <!-- 回大人 -->
            <div class="hisBetween">
              <div class="hisCenter">
                <div
                  class="txImg"
                  :style="'background-color:' + getRandomColor()"
                >
                  N
                </div>
                <div class="userNmae">NextAI 回答</div>
              </div>
              <div class="historyTime">缺少时间</div>
            </div>
            <!-- 回答 -->
            <div class="hisQ">
              <div class="fillCenter" v-if="historyList.answers.length > 1">
                <img
                  :src="historyList.chatActive != 1 ? leftFill : leftNoFill"
                  alt=""
                  class="fillImg"
                  @click="changeItemPage(false)"
                />
                <span
                  :class="historyList.chatActive == 1 ? 'fillColor' : ''"
                  class="fillText"
                  >{{ historyList.chatActive }}</span
                >
                <span
                  class="fillText"
                  :class="
                    historyList.chatActive == historyList.answers.length
                      ? 'fillColor'
                      : ''
                  "
                  >/{{ historyList.answers.length }}</span
                >
                <img
                  :src="
                    historyList.chatActive == historyList.answers.length
                      ? rightNoFill
                      : rightFill
                  "
                  alt=""
                  class="fillImg"
                  @click="changeItemPage(true)"
                />
              </div>
              <div
                :class="
                  historyList.answers.length > 1
                    ? 'text92 historyItemText'
                    : 'historyItemText'
                "
              >
                {{
                  historyList.answers[historyList.chatActive - 1].answerContent
                }}
              </div>
            </div>
            <!-- Ai微模型来源 -->
            <div
              class="userNmae marginHis10"
              v-if="
                historyList.answers[historyList.chatActive - 1].answerResource
                  .fileResource.length != 0 &&
                historyList.answers[historyList.chatActive - 1].answerResource
                  .sessionResource.length != 0
              "
            >
              AI知识库来源
            </div>
            <div
              v-for="(items, index) in historyList.answers[
                historyList.chatActive - 1
              ].answerResource.fileResource"
              :key="index"
              class="hisItemText"
            >
              <div class="dcflex">
                <span class="marginRightHistory">[{{ index + 1 }}]</span>
                <span class="marginRightHistory">{{ items.fileName }}</span>
                <!-- <div class="marginRightHistory">Word</div>
                  <div class="marginRightHistory">3.2MB</div> -->
              </div>
            </div>
            <div
              v-for="(items, index) in historyList.answers[
                historyList.chatActive - 1
              ].answerResource.sessionResource"
              :key="index"
              class="hisItemText"
            >
              <div class="dcflex">
                <span class="marginRightHistory">[{{ index + 1 }}]</span>
                <span class="marginRightHistory">{{ items.sessionName }}</span>
                <!-- <div class="marginRightHistory">Word</div>
                  <div class="marginRightHistory">3.2MB</div> -->
              </div>
            </div>
            <div
              class="userNmae marginHis10"
              v-if="
                historyList.answers[historyList.chatActive - 1].answerResource
                  .internetResource.length != 0
              "
            >
              互联网来源
            </div>
            <div
              v-for="(items, index) in historyList.answers[
                historyList.chatActive - 1
              ].answerResource.internetResource"
              :key="index"
              class="hisItemText"
            >
              <div class="dcflex">
                <span class="marginRightHistory">[{{ index + 1 }}]</span>
                <span class="marginRightHistory">{{ items.internetUrl }}</span>
                <!-- <div class="marginRightHistory">Word</div>
                  <div class="marginRightHistory">3.2MB</div> -->
              </div>
            </div>
          </div>
        </div>
      </transition>
      <!-- 右侧展开 -->
      <!-- 默认关闭展示 -->
      <div
        v-if="!rightType && !historyDialog && !lookDialog"
        @click="rightType = !rightType"
        class="rightIcon"
      >
        <img :src="sqLeftSvg" alt="" class="wid18" />
      </div>
      <!-- 展开div -->
      <transition name="expand-transition">
        <div v-if="rightType" class="rightDivAbs">
          <div class="flexRightCenter">
            <el-button
              class="sc"
              v-if="chatType == 'chat'"
              :loading="deleteLoading"
              @click="deleteSession"
            >
              <img :src="rightGd" alt="" class="marginRight8 rightGd" />
              <div class="gdText">归档话题</div>
            </el-button>
            <div
              class="sc"
              v-if="chatType == 'base' && topicItem.isCollected == 0"
              @click="likeTalk(0)"
            >
              <img :src="scSvg" alt="" class="marginRight8 wid14" />
              <div>收藏话题</div>
            </div>
            <div
              class="color3 sc"
              v-if="chatType == 'base' && topicItem.isCollected == 1"
              @click="likeTalk(1)"
            >
              <img :src="scNoSvg" alt="" class="marginRight8 wid14" />
              <div>取消收藏</div>
            </div>
            <div class="color2 sc" @click="openCap" v-if="chatType == 'base'">
              <img :src="setCap" alt="" class="marginRight8 wid21" />
              <div>{{ catType ? "请求重新验证" : "请求验证话题" }}</div>
            </div>
            <!-- 撤回验证请求 -->
            <!-- <div class="color3 sc" @click="openCap" v-if="chatType == 'base'">
              <img :src="setNoCap" alt="" class="marginRight8 wid21" />
              <div>撤回验证请求</div>
            </div> -->
            <el-dropdown @command="deleteSession" v-if="chatType == 'base'">
              <div class="iconRightCenter">
                <img :src="moreSvg" alt="" class="wid18" />
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :command="1">归档话题</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <div class="iconRightCenter" @click="rightType = !rightType">
              <img :src="sqRightSvg" alt="" class="wid20" />
            </div>
            <!-- 弹窗验证 -->
            <div v-if="capDialog" class="capDialogClass">
              <div class="capTitle">
                <div>请求验证话题</div>
                <img
                  class="crossSvug"
                  :src="crossSvug"
                  alt=""
                  @click="closeCap"
                />
              </div>
              <div class="labelItem">
                <span class="capLabel">选择验证范围</span>
                <span class="labelTip">（一个请求至多验证六组问答）</span>
              </div>
              <div>
                <el-select
                  v-model="treeData"
                  multiple
                  placeholder="请选择验证范围"
                >
                  <el-option
                    v-for="item in chatList"
                    :key="item.conversationId"
                    :label="item.question"
                    :value="item.conversationId"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="labelItem">
                <span class="capLabel">邀请验证人</span>
              </div>
              <div>
                <el-select
                  v-model="peopleSel"
                  multiple
                  placeholder="请选择邀请验证人"
                >
                  <el-option
                    v-for="item in seleteUserInfo"
                    :key="item.ownerId"
                    :label="item.ownerName"
                    :value="item.ownerId"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="labelItem">验证留言</div>
              <el-input
                placeholder="(选填) 向验证人简短描述你的验证需求"
                show-word-limit
                class="catText"
                type="textarea"
                v-model="leftNotes"
              />
              <div class="capBtnDiv">
                <el-button class="capCancel" @click="closeCap">取消</el-button>
                <el-button
                  class="capSave"
                  :loading="requestverifyLoading"
                  @click="saveCap"
                  >提交</el-button
                >
              </div>
            </div>
          </div>
          <div class="hrefDiv">
            <el-anchor
              :container="containerRef"
              direction="vertical"
              type="underline"
              :offset="30"
              @click="handleClick"
            >
              <el-anchor-link
                :href="'#talk' + item.conversationId"
                :title="item.question"
                v-for="(item, index) in chatList"
                :key="index"
              />
            </el-anchor>
          </div>
        </div>
      </transition>
    </div>
    <!-- 文件预览 -->
    <fileView
      v-if="lookDialog"
      type="chat"
      class="fileViewDiv"
      :fileItem="fileItem"
      :chunkDialog="true"
      @closeFileView="closeFileView"
    ></fileView>
    <!-- 微模型选择文件 -->
    <baseFileView
      :baseFile="baseFile"
      :saveList="saveList"
      v-if="baseFileDialog"
      @cancel="baseFileDialog = false"
      @ok="saveBase"
    ></baseFileView>
  </div>
</template>

<script setup>
import copySvg from "@/assets/chat/copy.svg";
import editSvg from "@/assets/chat/edit.svg";
import inputIndex from "./input.vue";
import setVue from "./set.vue";
import tipView from "@/components/tip/index.vue";
import scSvg from "@/assets/chat/sc.svg";
import rightGd from "@/assets/chat/rightGd.svg";
import setCap from "@/assets/chat/setCap.svg";
import setNoCap from "@/assets/chat/setNoCap.svg";
import scNoSvg from "@/assets/chat/noSc.svg";
import moreSvg from "@/assets/chat/more.svg";
import leftFill from "@/assets/chat/leftFill.svg";
import rightFill from "@/assets/chat/rightFill.svg";
import leftNoFill from "@/assets/chat/leftNoFill.svg";
import rightNoFill from "@/assets/chat/rightNoFill.svg";
import sqLeftSvg from "@/assets/chat/sqLeft.svg";
import sqRightSvg from "@/assets/chat/sqRight.svg";
import closeHistory from "@/assets/chat/closeHistory.svg";
import crossSvug from "@/assets/chat/cross.svg";
import qaIndex from "@/components/qa/index.vue";
import fileView from "@/components/fileView/index.vue";
import messageView from "@/components/message/index.vue";
import { encryptApi, decryptApi } from "@/utils/crypto";
import baseFileView from "@/components/chat/baseFile.vue";
import { ref, watch, onMounted, onUpdated, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { getRandomColor } from "@/utils/utils";
import api from "@/api";
const route = useRoute();
const router = useRouter();
const store = useStore();
const model = ref("GPT-4o"); //gpt值
const modelList = ref(store.state.modelList); //模型列表
const userId = ref(store.state.userId);
const userName = ref(store.state.userName);
const capDialog = ref(false); //验证弹窗状态
const chatType = ref(store.state.chatType);
const treeData = ref([]); //要验证的话题
const peopleSel = ref(); //要验证的人
const lookDialog = ref(false); //预览状态
const fileItem = ref({}); //文件预览item
const historyDialog = ref(false); //历史弹窗
const historyList = ref({}); //历史记录
// 右侧
const containerRef = ref(null); //ref值计算用
const chatList = ref([]); //历史聊天记录内容
const rightType = ref(false); //右侧展示值 如果大于1条就展开右侧 没有就关闭
const catType = ref(false); //是否验证过
const topicItem = ref(store.state.topicItem); //点击详情很重要
const leftDiv = ref(null);
const inputBottomDiv = ref(null);
const qaHeight = ref(null);
const seleteUserInfo = ref([]); //验证人
const leftNotes = ref("");
const requestverifyLoading = ref(false); //loading验证
const deleteLoading = ref(false); //loading归档
const messageList = ref([]);
const inputRef = ref(null);
const baseFileDialog = ref(false);
const baseFile = ref([]);
const saveList = ref([]);
onMounted(() => {
  if (Object.keys(store.state.postObj).length == 0) {
    getTalk();
  }
  window.onresize = () => {
    getDivHeight();
  };
  getMessage();
  getDivHeight();
  // getDefauleModel();
});
onUpdated(() => {
  scrollBottom();
});
watch(
  () => inputBottomDiv.value,
  (newValue, oldValue) => {
    getDivHeight();
  }
);
// 监听切换 然后调用接口
watch(
  () => store.state.topicItem,
  (newValue, oldValue) => {
    topicItem.value = newValue;
    lookDialog.value = false;
    if (Object.keys(store.state.postObj).length == 0) {
      getTalk();
    }
  }
);
watch(
  () => store.state.chatType,
  (newValue, oldValue) => {
    chatType.value = newValue;
  }
);
watch(
  () => historyDialog.value,
  (newValue, oldValue) => {
    if (rightType.value) {
      getDivHeight();
    }
  }
);
watch(
  () => lookDialog.value,
  (newValue, oldValue) => {
    setTimeout(() => {
      getDivHeight();
    }, 100);
  }
);
const saveMessage = (item) => {
  inputRef.value.saveMessage(item);
};
const getMessage = () => {
  api.chat.randomMessage().then((res) => {
    if (res.returnCode == 200) {
      messageList.value = res.data;
    }
  });
};
const deleteSession = () => {
  deleteLoading.value = true;
  api.chat
    .sessionDelete({
      userId: userId.value,
      sessionInfo: [
        {
          sessionId: topicItem.value.sessionId,
          typeId: chatType.value == "chat" ? 1 : 0,
        },
      ],
      operationType: 0,
    })
    .then((res) => {
      deleteLoading.value = false;
      if (res.returnCode == 200) {
        message.success("归档成功");
        router.push({
          name: "lookAll",
          query: {
            type: 1,
          },
        });
      }
    })
    .catch((err) => {
      deleteLoading.value = false;
    });
};
// 话题收藏
const likeTalk = (id) => {
  api.base
    .baseCollect({
      userId: userId.value,
      sessionId: topicItem.value.sessionId,
      operationType: id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        topicItem.value.isCollected = id == 0 ? 1 : 0;
        message.success(id == 0 ? "收藏成功" : "取消成功");
      }
    });
};
// 获取高度
const getDivHeight = () => {
  nextTick(() => {
    qaHeight.value =
      leftDiv.value.offsetHeight - inputBottomDiv.value.offsetHeight;
  });
};
// 加减页数
const changeItemPage = (type) => {
  if (type && historyList.value.chatActive < historyList.value.answers.length) {
    historyList.value.chatActive++;
  }
  if (!type && historyList.value.chatActive > 1) {
    historyList.value.chatActive--;
  }
};
// 发送消息
const sendMessage = (obj) => {
  if (!obj.message) {
    message.warning("请输入信息");
    return;
  }
  if (obj.model) {
    model.value = obj.model;
  } else {
    obj.model = model.value;
  }
  if (!model.value) {
    message.warning("请选择GPT类型");
    return;
  }
  //加密时间
  let time = Math.round(new Date() / 1000);
  let postObj = {
    time: time,
    idempotentEncryData: encryptApi(JSON.stringify({ ...obj, time: time })),
  };
  let chatObj = {
    question: obj.message,
    chatActive: 1,
    answers: [
      {
        answerContent: "",
      },
    ],
    model: obj.model,
  };
  chatList.value.push(chatObj);
  let lang = store.state.lang;
  if (lang === "CN") {
    lang = "zh-CN";
  } else if (lang === "HK") {
    lang = "zh-HK";
  } else if (lang === "US") {
    lang = "EN";
  }
  if (chatType.value == "chat") {
    sendFn(postObj, lang, "/openai/chat/v4/memory/chat");
  } else {
    sendFn(postObj, lang, "/openai/chat/v4/knowledge/chat");
  }
};
const sendFn = async (postObj, lang, url) => {
  try {
    let controller = new AbortController();
    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + store.state.token,
        "Accept-Language": lang,
      },
      body: JSON.stringify(postObj),
      signal: controller.signal,
    });
    if (response.status === 500) {
      message.warning("服务器连接失败");
      chatList.value[chatList.value.length - 1].answers[
        chatList.value[chatList.value.length - 1].chatActive - 1
      ].answerContent = "服务器连接失败";
      store.commit("SET_TALK", {});
      return;
    }
    if (response.status === 504) {
      message.warning("服务器连接超时");
      chatList.value[chatList.value.length - 1].answers[
        chatList.value[chatList.value.length - 1].chatActive - 1
      ].answerContent = "服务器连接超时";
      store.commit("SET_TALK", {});
      return;
    }
    const reader = response.body.getReader();
    let flag = true;
    let data = "";
    while (flag) {
      const { done, value } = await reader.read();
      const rawData = new TextDecoder().decode(value);
      const regex = /<start>(.*?)<end>/g;
      let matches;
      const extractedData = [];
      while ((matches = regex.exec(rawData)) !== null) {
        extractedData.push(matches[1]);
      }
      const decryptedData = extractedData.map((data) => decryptApi(data));
      data += decryptedData.map((item) => item).join("");
      chatList.value[chatList.value.length - 1].answers[
        chatList.value[chatList.value.length - 1].chatActive - 1
      ].answerContent = data;
      nextTick(() => {
        scrollBottom();
      });
      if (done) {
        store.commit("SET_TALK", {});
        if (
          !data.includes("returnCode") &&
          !data.includes("!(end of answer)")
        ) {
          nextTick(() => {
            getMessage();
            getTalk();
          });
        } else if (data.includes("!(end of answer)")) {
          message.warning("当前对话记录超过限制,请结束对话或新建对话。");
          chatList.value[chatList.value.length - 1].answers[
            chatList.value[chatList.value.length - 1].chatActive - 1
          ].answerContent = "当前对话记录超过限制,请结束对话或新建对话。";
        } else if (data.includes("returnCode")) {
          let newData = JSON.parse(data);
          message.warning(newData.returnMessage);
          if (newData.returnCode == 423 || newData.returnCode == 412) {
            router.push("/login");
          } else {
            chatList.value[chatList.value.length - 1].answers[
              chatList.value[chatList.value.length - 1].chatActive - 1
            ].answerContent = newData.returnMessage;
          }
        }
        break;
      }
    }
  } catch (err) {
    console.log(err);
  }
};
// 复制
const copyFn = async (item) => {
  if (!navigator.clipboard) {
    message.warning("浏览器不支持复制到剪贴板");
    return;
  }
  try {
    await navigator.clipboard.writeText(item.question);
    message.success("复制成功");
  } catch (err) {
    message.warning("复制失败");
  }
};
const openBaseFile = (arr) => {
  saveList.value = arr;
  baseFileDialog.value = true;
};
// 微模型选择
const gerBaseFile = () => {
  api.base
    .queryFileList(
      { userId: userId.value, folderId: null },
      topicItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        baseFile.value = [...res.data.folderInfo, ...res.data.filesInfo];
      }
    });
};
// 保存筛选的文件
const saveBase = (arr) => {
  let fileList = [];
  let folderList = [];
  arr.forEach((item) => {
    if (item.fileName) {
      fileList.push(item.id);
    } else if (item.folderName) {
      folderList.push(item.id);
    }
  });
  console.log({
    userId: userId.value,
    sessionId: topicItem.value.sessionId,
    file_ids: fileList,
    folder_ids: folderList,
  });
  api.chat
    .attachmentAdd({
      userId: userId.value,
      sessionId: topicItem.value.sessionId,
      file_ids: fileList,
      folder_ids: folderList,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        inputRef.value.getBaseFileIcon();
        baseFileDialog.value = false;
      }
    });
};
// 获取对话内容
const getTalk = () => {
  if (topicItem.value == null) return;
  if (chatType.value == "base") {
    gerBaseFile();
    api.share
      .baseDetailQuery({
        userId: userId.value,
        sessionId: topicItem.value.sessionId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          if (res.data.length != 0) {
            res.data.forEach((item) => {
              item.chatActive = 1;
              item.resonType = false;
              item.resonList = [];
            });
          }
          chatList.value = res.data;
          rightType.value = chatList.value.length > 1 ? true : false;
          nextTick(() => {
            scrollBottom();
          });
        }
      });
  } else {
    api.share
      .chatDetailQuery({
        userId: userId.value,
        topicId: topicItem.value.sessionId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          res.data.forEach((item) => {
            item.chatActive = 1;
            item.resonType = false;
            item.resonList = [];
          });
          chatList.value = res.data;
          rightType.value = chatList.value.length > 1 ? true : false;
        }
      });
  }
};
// 获取默认model
const getDefauleModel = () => {
  api.chat.modelGet({ userId: userId.value }).then((res) => {
    if (res.returnCode == 200) {
      model.value = res.data;
    }
  });
};
// 打开历史
const openHistory = (data) => {
  historyList.value = data;
  historyDialog.value = true;
};
const scrollBottom = () => {
  nextTick(() => {
    containerRef.value.scrollTop = containerRef.value.scrollHeight;
  });
};
const handleClick = (e) => {
  e.preventDefault();
};
// 打开请求验证
const openCap = () => {
  peopleSel.value = [];
  leftNotes.value = "";
  treeData.value = [];
  api.base
    .customLibraryDesc({
      userId: userId.value,
      libraryId: topicItem.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        seleteUserInfo.value = res.data.userInfo;
        capDialog.value = true;
      }
    });
};
// 关闭验证
const closeCap = () => {
  capDialog.value = false;
};
// 保存验证
const saveCap = () => {
  if (treeData.value.length > 6) {
    message.warning("最多选择6条进行验证");
    return;
  }
  requestverifyLoading.value = true;
  api.share
    .postRequestverify({
      userId: userId.value,
      conversationIds: treeData.value,
      userIds: peopleSel.value,
      sessionName: topicItem.value.sessionName,
      sessionId: topicItem.value.sessionId,
      leftNotes: leftNotes.value,
    })
    .then((res) => {
      requestverifyLoading.value = false;
      if (res.returnCode == 200) {
        message.success("请求验证成功");
        // catType.value = true;
        capDialog.value = false;
      }
    });
};
// 打开文件预览
const openFileView = (item) => {
  fileItem.value = item;
  historyDialog.value = false;
  rightType.value = false;
  lookDialog.value = true;
};
// 关闭文件预览
const closeFileView = () => {
  lookDialog.value = false;
};
</script>

<style lang="less" scoped>
.chatContent {
  width: 100%;
  height: 100%;
  display: flex;
}

.talkDiv {
  overflow: auto;
}

.chatIndex {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  background-color: #ffffff;
  border-radius: 30px;
  padding: 30px;
  position: relative;
}

.fileIndex {
  width: 467px;
  height: calc(100% - 60px);
  background-color: #ffffff;
  border-radius: 30px;
  padding: 30px;
  position: relative;
  margin-right: 20px;
}

.flexCenterEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 0;
}

.marginLeft10 {
  margin-left: 10px;
}

.tipStart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tipDiv {
  margin-bottom: 12px;
}

.width1010 {
  min-width: 1010px;
  width: calc(100% - 510px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 60px);
  transition: all 0.5s ease;
}

.width10101 {
  min-width: 1010px;
  width: calc(100% - 491px);
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(0px, -50%);
  height: calc(100% - 60px);
  transition: all 0.5s ease;
}

.width10102 {
  min-width: 632px;
  width: calc(100% - 888px);
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(0px, -50%);
  height: calc(100% - 60px);
  transition: all 0.5s ease;
}

.width10103 {
  width: calc(100% - 60px);
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(0px, -50%);
  height: calc(100% - 60px);
  transition: all 0.5s ease;
}

.chatSelete {
  width: 125px;
  z-index: 8;
  position: absolute;
  left: 30px;
  top: 30px;
  box-shadow: 0 0px 20px 18px #ffffff;
  background-color: #ffffff;

  :deep(.el-select__wrapper) {
    height: 48px;
    color: #3d3d3d;
    font-size: 16px;
    font-family: "Regular";
    border-radius: 16px;
    border: 2px solid #edeef6;
  }

  :deep(.el-select__caret) {
    font-size: 20px;
    color: #8a8a8a;
  }
}

.rightIcon {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}

.rightIcon:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}

.sc {
  border: 2px solid #edeef6;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  margin-right: 12px;
  cursor: pointer;
}

.color2 {
  background-color: #3376ff;
  color: #ffffff;
  border: 2px solid #3376ff;
  position: relative;
}

.color3 {
  color: #f02d63;
}

.marginRight8 {
  margin-right: 8px;
}

.wid14 {
  width: 14px;
}

.wid21 {
  width: 21px;
}

.wid18 {
  width: 18px;
}

.wid20 {
  width: 20px;
}

.wid36 {
  width: 36px;
}

.flexRightCenter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 35px;
  position: relative;
}

.rightDivAbs {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 401px;
  height: calc(100% - 60px);
}

.iconRightCenter {
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconRightCenter:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}

.expand-transition-enter-active,
.expand-transition-leave-active {
  transition: all 0.5s ease;
}

.expand-transition-enter-from,
.expand-transition-leave-to {
  transform: translateX(10%);
  opacity: 0;
}

.expand-transition-enter-to,
.expand-transition-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.hrefDiv {
  padding: 0px 18px;
  max-height: calc(100% - 84px);
  overflow: auto;
}

.historyDialog {
  position: absolute;
  right: 444px;
  top: 30px;
  width: 353px;
  height: calc(100% - 86px);
  background-color: #f7f8ff;
  border-radius: 18px;
  padding: 13px 24px;
}

.hisOne {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 353px;
  height: calc(100% - 86px);
  background-color: #f7f8ff;
  border-radius: 18px;
  padding: 13px 24px;
}

.lineIcon {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lineIcon:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}

.historyCloseIcon {
  width: 14px;
}

.historyTopDiv {
  font-size: 20px;
  height: 29px;
  color: #3d3d3d;
  font-family: "Medium";
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
}

.hisToryItem {
  height: calc(100% - 60px);
  margin-top: 15px;
  overflow: auto;
}

.txImg {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 6px;
  line-height: 18px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  font-family: "Regular";
}

.userNmae {
  color: #3d3d3d;
  font-family: "Medium";
  font-size: 18px;
}

.historyTime {
  color: #8a8a8a;
  font-family: "Regular";
  font-size: 16px;
}

.hisCenter {
  display: flex;
  align-items: center;
}

.hisBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hisQ {
  font-size: 17px;
  font-family: "Regular";
  color: #3d3d3d;
  margin-top: 5px;
  margin-bottom: 15px;
  position: relative;
}

.hisItemText {
  font-size: 17px;
  color: #3d3d3d;
  font-family: "Regular";
  margin-top: 2px;
}

.marginRightHistory {
  margin-right: 5px;
}

.marginHis10 {
  margin: 10px 0px;
}

.closeDiv {
  cursor: pointer;
}

.capDialogClass {
  position: absolute;
  right: 100px;
  bottom: -550px;
  width: 566px;
  border-radius: 16px;
  background-color: #f7f8ff;
  z-index: 10;
  padding: 17px;
  cursor: default;
}

.crossSvug {
  width: 24px;
}

.capTitle {
  font-size: 22px;
  color: #3d3d3d;
  font-family: "Medium";
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  height: 30px;
}

.capLabel {
  position: relative;
  margin-right: 20px;
}

.capLabel::after {
  content: "*";
  position: absolute;
  color: #f02d63;
  right: -15px;
}

.labelItem {
  font-size: 18px;
  font-family: "Medium";
  color: #3d3d3d;
  margin-bottom: 12px;
  margin-top: 24px;
}

.labelTip {
  font-size: 16px;
  font-family: "Medium";
  color: #8a8a8a;
}

.catText {
  margin-bottom: 24px;

  :deep(.el-textarea__inner) {
    height: 110px;
    min-height: 110px;
    max-height: 110px;
    border-radius: 16px;
    border: 2px solid #edeef6;
    padding: 12px 20px;
    resize: none;
    outline: none;
    font-family: "Regular";
    font-size: 18px;
  }
}

.capBtnDiv {
  text-align: right;
}

.capCancel {
  height: 48px;
  color: #f02d63;
  border: 2px solid #edeef6;
  border-radius: 16px;
  font-size: 20px;
  font-family: "Regular";
  padding: 10px 20px;
}

.capSave {
  background-color: #3376ff;
  border: 0px;
  border-radius: 16px;
  height: 48px;
  font-size: 20px;
  font-family: "Regular";
  color: #fefefe;
  padding: 10px 20px;
}

.treeSelTip {
  color: #8a8a8a;
  font-family: "Medium";
  font-size: 17px;
  margin-right: 10px;
}

.treeText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
}

.fileViewDiv {
  width: calc(100% - 547px);
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
}

.rightGd {
  width: 20px;
}

.gdText {
  color: #f02d63;
}

.messageView {
  margin-bottom: 52px;
}

.fillImg {
  width: 24px;
  cursor: pointer;
}

.fillText {
  font-size: 18px;
  font-family: "Semibold";
  color: #8a8a8a;
}

.fillCenter {
  display: flex;
  align-items: center;
  margin-right: 12px;
  width: 80px;
  position: absolute;
  top: 0;
  left: 0;
}

.historyItemText {
  font-size: 17px;
  color: #3d3d3d;
  font-family: "Regular";
}

.text92 {
  text-indent: 92px;
}

.fillColor {
  color: #d9d9d9;
}

.dcflex {
  word-wrap: break-word;
}
</style>

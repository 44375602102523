export default {
    CN: {
        login1: '企业租户',
        login2: '登录NextAI Platform',
        login3: 'SSO登录',
        login4: '找回密码',
        login5: '激活',
        login6: '返回',
        login7: '请输入您的账号',
        login8: '请输入您的密码',
        login9: '登录',
        login10: '请输入您的企业邮箱账号',
        login11: '下一步',
        login12: '请输入NextAI发送的验证码',
        login13: '重新发送',
        login14: '剩余',
        login15: '秒',
        login16: '再次输入新密码',
        login17: '完成',
        login18: '请输入您的激活码',
        login19: '我已阅读并同意',
        login20: '免责声明',
        login21: '前往激活',
        login22: '更多登录方式',
        login23: '发送成功',
        login24: '密码长度不能少于6位',
        login25: '两次密码不一致',
        login26: '重置密码成功',
        login27: '激活成功',
        login28: '登录成功',
        login29: 'NextAI平台服务协议',
        login30: '我已阅读'
    },
    HK: {
        login1: "企業租戶",
        login2: "登錄NextAI Platform",
        login3: 'SSO登錄',
        login4: "找回密碼",
        login5: '啟動',
        login6: '返回',
        login7: "請輸入您的帳號",
        login8: '請輸入您的密碼',
        login9: "登錄",
        login10: '請輸入您的企業郵箱帳號',
        login11: '下一步',
        login12: '請輸入NextAI發送的驗證碼',
        login13: "重新發送",
        login14: "剩餘",
        login15: "秒",
        login16: "再次輸入新密碼",
        login17: '完成',
        login18: '請輸入您的啟動碼',
        login19: "我已閱讀並同意",
        login20: "免責聲明",
        login21: '前往啟動',
        login22: "更多登錄方式",
        login23: "發送成功",
        login24: '密碼長度不能少於6位',
        login25: '兩次密碼不一致',
        login26: '重置密碼成功',
        login27: '啟動成功',
        login28: "登錄成功",
        login29: "NextAI平臺服務協定",
        login30: '我已閱讀'
    },
    US: {
        login1: 'enterprise tenant',
        login2: 'Login to NextAI Platform',
        login3: 'SSO login',
        login4: 'Retrieve password',
        login5: 'activate',
        login6: 'return',
        login7: 'Please enter your account number',
        login8: 'Please enter your password',
        login9: 'login',
        login10: 'Please enter your business email account',
        login11: 'Next',
        login12: 'Please enter the verification code sent by NextAI',
        login13: 'resend',
        login14: 'remaining',
        login15: 's',
        login16: 'Enter a new password again',
        login17: 'done',
        login18: 'Please enter your activation code',
        login19: 'I have read and agreed',
        login20: 'Disclaimer',
        login21: 'Go to activate',
        login22: 'More ways to log in',
        login23: 'Send successful',
        login24: 'Password length cannot be less than 6 digits',
        login25: 'Password inconsistent twice',
        login26: 'Reset password successful',
        login27: 'Activation successful',
        login28: 'Login successful',
        login29: 'NextAI Platform Service Agreement',
        login30: 'I have read'
    },
};

import axios from "../server";
export default {
    // 拿到公司名称
    getCompanyName() {
        return axios({
            url: "/login/company/get",
            method: "GET",
        });
    },
    // 获得免责公告
    getDisclaimer() {
        return axios({
            url: "/login/Disclaimer/get",
            method: "GET",
        });
    },
    // 登录
    goLogin(data: any) {
        return axios({
            url: "/login/login",
            method: "POST",
            data,
        });
    },
    //忘记密码
    postforgotPasswd(data: any) {
        return axios({
            url: "/user/forgot_passwd",
            method: "POST",
            data,
        });
    },
    // 通过激活码激活
    activation(data: any) {
        return axios({
            url: "/login/activate",
            method: "POST",
            data,
        });
    },
}
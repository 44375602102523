<script setup lang="ts">
import { ref, defineProps } from "vue";
const emit = defineEmits(['handleCancel', 'handleConfirm']);
const props = defineProps({
    show: Boolean,
});
// 拒绝
const handleCancel = () => {
    emit('handleCancel')
}
//同意
const handleConfirm = () => {
    emit('handleConfirm')
}
</script>

<template>
    <el-dialog v-model="props.show" align-center :close-on-click-modal="false" :show-close="false">
        <template #header="">
            <div class="my-header">
                <div>免责声明</div>
                <el-icon class="el-icon--left" :class="'close-icon'" @click="handleCancel" style="cursor: pointer;">
                    <Close />
                </el-icon>
            </div>
        </template>
        <div class="content">这是固定内容</div>
        <template #footer>
            <div class="dialog-footer">
                <div @click="handleCancel" class="cancel">拒绝</div>
                <div @click="handleConfirm" class="confirm">同意并进入仪表盘</div>
            </div>
        </template>
    </el-dialog>
</template>



<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-dialog) {
    --el-dialog-width: 600px;
    border-radius: 30px;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 24px;
    position: relative;
}

.close-icon {
    font-size: 20px;
}

.content {
    font-size: 16px;
    color: #8A8A8A;
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #F02D63;
        padding: 6px 12px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 6px 12px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
<script setup>
import { ref } from 'vue'
import { useRouter } from "vue-router";
const router = useRouter();
const showIndex = ref(null)
// 打开智能工具
const openTool = (index) => {
    showIndex.value = index
    if (showIndex.value === 0) {
        router.push('/bot/components/word')
    } else if (showIndex.value === 1) {
        router.push('/bot/components/audio')
    } else if (showIndex.value === 2) {
        router.push('/bot/components/image')
    }else if (showIndex.value === 3) {
        router.push('/bot/components/bill')
    }else if (showIndex.value === 4) {
        router.push('/bot/components/excel')
    }
};
const toolList = ref([
    {
        name: '文档翻译',
        introduce: '跨越语言障碍，让信息自由流通',
        tag: [
            '多语种翻译',
            '多种格式支持'
        ]
    },
    {
        name: '智能音频',
        introduce: '快速识别音/视频，一键转换文字，精准支持多种语言',
        tag: [
            '语音识别',
            '多语种支持'
        ]
    },
    {
        name: '图片转文档',
        introduce: '单张图片上传，快速识别出结果',
        tag: [
            '图片识别',
            '文档导出'
        ]
    },
    {
        name: 'PDF文字提取',
        introduce: '支持PDF识别，快速识别出结果',
        tag: [
            'PDF识别',
            'PDF文字提取'
        ]
    },
    {
        name: '自动填写问答',
        introduce: '上传Excel表格，空白处自动查询答案并填写',
        tag: [
            '上传Excel',
            '自动填写答案'
        ]
    }
]);
</script>
<template>
    <div class="itemWidth" @click="openTool(index)" v-for="(item, index) in toolList" :key="index">
        <div style="display: flex" class="tit">
            <div class="addDiv">
                <img src="@/assets/bot/addTool.svg" alt="" />
            </div>
            <div class="baseItemTitle">{{ item.name }}</div>
        </div>
        <div class="tagBox">
            <div class="tagItem" v-for="(tag, tagIndex) in item.tag" :key="tagIndex">
                {{ tag }}
            </div>
        </div>
        <div class="addTip">
            {{ item.introduce }}
        </div>
        <div class="addBtn" @click="openTool(index)">
            立即体验
        </div>
    </div>
</template>
<style scoped lang="scss">
.itemWidth {
    position: relative;
    border-radius: 20px;
    background-color: #f7f8ff;
    margin-bottom: 20px;
    padding: 24px;
    
    width: 19.7%;
    height: 162px;
    box-shadow: 0px 0px 8px 0px #dbdfff;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    .addBtn{
        position: absolute;
        bottom: 30px;
        right: 20px;
        color: #8a8a8a;
        font-size: 16px;
        cursor: pointer;
        display: none;
    }
}

.itemWidth:hover {
    background-color: #eceeff;
    box-shadow: 0px 0px 8px 0px #eceeff;
    .addBtn{
        display: block;
        color: #0256ff;
    }
}

.tit {
    margin-bottom: 16px;
}

.tagBox {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-bottom: 8px;

    .tagItem {
        border-radius: 16px;
        height: 26px;
        line-height: 26px;
        padding: 0px 12px;
        color: #fff;
        font-size: 14px;
        background-color: #0256ff;
        margin-right: 12px;
    }
}

.addDiv {
    border-radius: 8px;
    width: 28px;
    height: 28px;
    margin-right: 10px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }
}

.baseItemTitle {
    font-size: 20px;
    color: #3d3d3d;
    font-family: "Bold";
}

.addTip {
    font-size: 17px;
    color: #8a8a8a;
    margin-bottom: 18px;
}
</style>
<template>
  <div class="dialogView">
    <div class="flexBetween">
      <div class="dialogTitle">权限冲突</div>
      <img :src="addClose" alt="" class="addClose" @click="closeDialog" />
    </div>
    <div class="dialogTip">
      系统检测到您的资源权限发生变化，不符合该知识库当前的资源平台配置，您可以选择使用您当前有权限的平台重新分析处理知识库中的文档数据，或者将该知识库的拥有者权限转让他人。
    </div>
    <!-- <div class="dialogTip">系统检测到您的资源权限发生变化，不符合该知识库当前的资源平台配置，您可以选择将您的权限转变为普通成员，或者退出该知识库。</div> -->
    <div class="flexBetween bottom17">
      <span class="addLabel nameLabel">资源平台</span>
      <span class="ptTip"
        >您可以选择一个或多个平台的资源分析处理您上传的文档</span
      >
    </div>
    <div class="flexBetween">
      <div
        v-for="item in ptList"
        :key="item.id"
        :class="getPt(item.id) ? 'ptDivItemActive' : 'ptDivItem'"
        @click="clickPt(item.id)"
      >
        <img :src="item.icon" alt="" class="ptImg" />
      </div>
    </div>
    <div class="btnDiv">
      <el-button class="cancelBtn">放弃</el-button>
      <!-- <el-button class="cancelBtn">退出知识库</el-button>
      <el-button class="sureBtn">转为普通成员</el-button> -->
      <el-button class="sureBtn">重新分析</el-button>
      <el-button class="sureBtn">转移权限</el-button>
    </div>
  </div>
  <div class="mask"></div>
</template>

<script setup>
import addClose from "@/assets/base/addClose.svg";
import azure from "@/assets/base/azure.svg";
import aliyun from "@/assets/base/aliyun.svg";
import baidu from "@/assets/base/baidu.svg";
import { ref, defineEmits } from "vue";
const emit = defineEmits(["closeTipDialog"]);
const ptList = ref([
  {
    id: 1,
    icon: azure,
  },
  {
    id: 2,
    icon: aliyun,
  },
  {
    id: 3,
    icon: baidu,
  },
]);
const ptActive = ref([]);
// 判断有没有这个id 有就返回true  class判断
const getPt = (id) => {
  // 查找数组中是否存在该id
  const index = ptActive.value.findIndex((item) => item === id);
  if (index !== -1) {
    return true;
  } else {
    return false;
  }
};
// 选择的平台
const clickPt = (id) => {
  // 查找数组中是否存在该id
  const index = ptActive.value.findIndex((item) => item === id);
  if (index !== -1) {
    // 如果存在，删除该id
    ptActive.value.splice(index, 1);
  } else {
    // 如果不存在，添加该id到数组中
    ptActive.value.push(id);
  }
};
const closeDialog = () => {
  emit("closeTipDialog");
};
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 10;
  opacity: 0.2;
}
.dialogView {
  position: fixed;
  width: 540px;
  border-radius: 30px;
  background-color: #ffffff;
  z-index: 200;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.addClose {
  width: 14px;
  cursor: pointer;
}
.dialogTitle {
  font-size: 20px;
  font-family: "Medium";
  color: #3d3d3d;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialogTip {
  margin: 24px 0px;
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  line-height: 28px;
}
.addLabel {
  font-size: 18px;
  font-family: "Regular";
  color: #3d3d3d;
  position: relative;
}
.nameLabel::before {
  position: absolute;
  content: "*";
  color: #f02d63;
  right: -15px;
}
.ptTip {
  color: #8a8a8a;
  font-size: 16px;
  font-family: "Medium";
}
.bottom17 {
  margin-bottom: 17px;
}
.ptImg {
  height: 25px;
}
.ptDivItem {
  width: 167px;
  height: 56px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ptDivItemActive {
  width: 167px;
  height: 56px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #dbdfff;
}
.cancelBtn {
  border: 2px solid #edeef6;
  height: 48px;
  border-radius: 16px;
  font-size: 18px;
  color: #f02d63;
  font-family: "Regular";
  padding: 12px 20px;
}
.sureBtn {
  border: 2px solid #3376ff;
  background-color: #3376ff;
  height: 48px;
  border-radius: 16px;
  font-size: 18px;
  color: #fefefe;
  font-family: "Regular";
  padding: 12px 20px;
}
.btnDiv {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>

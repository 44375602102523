<script setup>
import api from "@/api/index";
import { ref, reactive, onMounted } from "vue";
import { message, Button, UploadDragger, Icon, Select, SelectOption, Modal, Drawer } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
onMounted(() => {
    clearItem();
    getLang()
})
const { t } = useI18n();
const router = useRouter();
const store = useStore();
const fileList = ref([]);
const type = ref("upFile");
const historyType = ref(false);
const userId = ref(store.state.userId);
const langList = ref([]);
const lang = ref(null);
const fileName = ref("");
const fileId = ref(null);
const historyList = ref([]);
const chunkItem = ref({});
const chunkType = ref(false);
const offUrl = ref(`https://view.officeapps.live.com/op/embed.aspx?src=`);
// 关闭
const closeChunk = () => {
    chunkItem.value = {}
    chunkType.value = false
};
// 打开文件预览
const openChunk = (item) => {
    chunkItem.value = item;
    chunkType.value = true;
};
// 打开文档历史记录
const openHistory = () => {
    historyType.value = true
    api.bot
        .documentQuery({
            userId: userId.value,
        })
        .then((res) => {
            if (res.returnCode == 200) {
                historyList.value = res.data;
            }
        })
}
const itemDown = (item) => {
    fetchWordFile.value = item
}
const response = ref(null);
const blob = ref(null);
const fetchWordFile = async (item) => {
    try {
        response.value = await fetch(item.fileUrl)
        if (!response.value.ok) {
            throw new Error("Network response was not ok");
        }
        blob.value = await response.value.blob();
        downloadFile(blob.value, item.fileName);
    } catch (errpr) {
        console.error("There was a problem with your fetch operation:", error);
    }
};
// 下载
const downloadFile = (blob, fileName) => {
    // 创建一个指向blob的URL
    const url = URL.createObjectURL(blob);
    // 创建一个a标签用于下载
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    // 触发点击
    document.body.appendChild(a);
    a.click();
    // 清理
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
};
// 下载
const downFile = () => {
    api.bot
        .documentQuery({ userId: userId.value, fileId: fileId.value })
        .then((res) => {
            if (res.returnCode == 200) {
                itemDown.value(res.data);
            }
        });
};
// 预览
const viewFile = () => {
    api.bot
        .documentQuery({ userId: userId.value, fileId: fileId.value })
        .then((res) => {
            if (res.returnCode == 200) {
                openChunk.value(res.data);
            }
        });
};
// 上传新文档 清空所有
const clearItem = () => {
    type.value = "upFile";
    fileList.value = [];
    lang.value = null;
    fileName.value = "";
    fileId.value = null;
}
// 获取字典
const getLang = () => {
    api.bot
        .getLanguage(
            { userId: userId.value })
        .then((res) => {
            if (res.returnCode == 200) {
                langList.value = Object.entries(res.data).map(([value, key]) => ({
                    value: value,
                    key: key,
                }));
                console.log(langList.value,'语言');
                
            }
        });
};
// 切换语言
const changeLang = (e) => {
    lang.value = e;
}
const getByteLength = (str) => {
    const encoder = new TextEncoder();
    const encoded = encoder.encode(str);
    return encoded.length;
}
// 上传钩子
const beforeUpload = (file) => {
    fileName.value = file.name;
    // 文件类型
    file.fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
    let validFileTypes = ["docx"];
    if (!validFileTypes.includes(file.fileType)) {
        message.warning("不支持该文件类型上传。");
        return false;
    }
    // 大小限制
    if (file.size > 20971520 && !this.$isBeat) {
        message.warning("上传文件不能超过20MB");
        return false;
    }
    // 名称超长
    let fileNameLen = String(file.name);
    if (getByteLength(fileNameLen) > 255) {
        message.warning("文件名称过长，上传失败！");
        return false;
    }
    fileList.value = [file];
    type.value = "finish";
}
// 开始翻译
const upFile = () => {
    api.bot
        .fileTranslate(fileList.value, {
            language: lang.value,
        })
        .then((res) => {            
            if (res.returnCode == 200) {
                type.value = "qaLoading";
                if (res.data.length != 0) {
                    poill(res.data);
                    fileId.value = res.data[0];
                } else {
                    type.value = "ok";
                }
            }
        })
}
// 轮询
const poill = (data) => {
    api.bot
        .statusView({
            userId: userId.value,
            fileId: data[0],
        })
        .then((res) => {
            if (res.returnCode == 200) {
                if (res.data.status == "finish") {
                    type.value = "ok";
                } else {
                    setTimeout(() => {
                        poill(data);
                    }, 1000);
                }
            }
        });

};
const customRequest = () => {

};
// 返回上一页
const back = () => {
    router.go(-1);
};
</script>
<template>
    <div class="wordContent">
        <div class="btnRight">
            <div class="left" @click="back">
                <el-icon class="icon"><Back /></el-icon>
                返回
            </div>
            <Button type="link" @click="openHistory">历史记录</Button>
        </div>
        <!-- 上传 -->
        <div v-if="type == 'upFile'">
            <UploadDragger class="upFile" name="file" :multiple="false" :showUploadList="false"
                :customRequest="customRequest" action="#" :before-upload="beforeUpload">
                <div class="iconCenter">
                    <div>
                        <img src="@/assets/upload/word.svg" alt="" class="iconItem word">
                        <div class="iconText">docx</div>
                    </div>
                </div>
                <div class="bottomText">点击或拖拽添加</div>
            </UploadDragger>
        </div>
        <!-- 翻译 -->
        <div class="upFile" v-if="type == 'finish'">
            <div class="loadingFile">
                <img src="@/assets/upload/word.svg" alt="" class="iconItem word">
                <div>
                    <Select class="selWidth" @change="changeLang" placeholder="请选择目标语言">
                        <SelectOption v-for="(item, index) in langList" :key="index" :value="item.value">
                        </SelectOption>
                    </Select>
                </div>
                <Button class="qaBtn" @click="upFile">开始翻译</Button>
            </div>
        </div>
        <!-- 翻译中 -->
        <div class="upFile" v-if="type == 'qaLoading'">
            <div class="loadingFile">
                <loading class="loading"></loading>
                <div class="qaText">文档翻译中</div>
                <div class="qaTip">若离开页面，翻译不会取消，可到翻译历史中查看</div>
            </div>
        </div>
        <!-- 翻译成功 -->
        <div class="upFile" v-if="type == 'ok'">
            <div class="loadingFile">
                <img src="@/assets/upload/word.svg" alt="" class="iconItem word">
                <div class="iconText">{{ fileName }}</div>
                <div class="okTip">文档翻译成功</div>
                <div class="okDiv">
                    <Button type="primary" @click="downFile">文档下载</Button>
                    <Button @click="viewFile">在线预览</Button>
                    <Button @click="clearItem">上传新文档</Button>
                </div>
            </div>
        </div>
        <!-- 历史 -->
        <Drawer v-if="historyType" title="历史记录" placement="right" :closable="false" :width="500"
            :open="historyType" @close="historyType = false">
            <div>
                <div v-for="item in historyList" :key="item.fileId" class="historyDiv">
                    <img src="@/assets/upload/word.svg" alt="" class="historyIcon">
                    <div class="historyName" @click="openChunk(item)">
                        {{ item.fileName }}
                    </div>
                    <el-icon class="down" @click="itemDown(item)"><Download /></el-icon>
                </div>
            </div>
        </Drawer>
        <!-- chunk -->
        <Modal :maskClosable="false" centered :zIndex="5000" v-model="chunkType" :title="$t('wordChunk')"
            @cancel="closeChunk" :footer="null">
            <iframe :src="offUrl + encodeURIComponent(chunkItem.fileUrl)" class="chunkItem" frameborder="0"></iframe>
        </Modal>
    </div>
</template>



<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.wordContent {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 30px;
    min-width: 1430px;
    min-height: 711px;
    width: 100%;
    height: 100%;
}

.upFile {
    width: 100%;
    height: calc(100% - 30px);
}

.btnRight {
    display: flex;
    justify-content: space-between;
    font-family: "Regular";
    .left{
        font-size: 16px;
        color: #7d7d7d;
        padding-top: 4px;
        cursor: pointer;
    }
}

:deep(.ant-upload-wrapper .ant-upload-drag) {
    width: 1460px !important;
    height: 690px !important;
}

:deep(.ant-upload.ant-upload-drag) {
    background-color: #edf2ff;
}

.iconItem {
    display: block;
    width: 90px;
    margin-bottom: 20px;
}

.pdf {
    color: #eb5451;
}

.word {
    color: #458ae6;
}

.ppt {
    color: #e9643d;
}

.excel {
    color: #1fb97b;
}

.iconCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 180px;
}

.iconText {
    font-size: 16px;
    color: #000000;
    font-family: "Regular";
    margin: 20px 0px;
}

.bottomText {
    font-size: 40px;
    color: #000000;
    font-family: "Regular";
}

.loadingFile {
    width: 100%;
    padding-top: 200px;
    background-color: #edf2ff;
    height: calc(100% - 10px);
    border-radius: 8px;
    border: 1px dashed #d9d9d9;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loadingText {
    font-size: 26px;
    margin-top: 10px;
    font-family: "Regular";
    color: #000000;
}

.selWidth {
    width: 200px;
    margin: 0px 10px;
}

.qaBtn {
    width: 250px;
    text-align: center;
    line-height: 60px;
    height: 60px;
    background-color: #e1e1e1;
    border-radius: 6px;
    margin-top: 20px;
}

.qaText {
    font-size: 28px;
    color: #000000;
    font-family: "Regular";
    margin-top: 50px;
}

.qaTip {
    font-size: 23px;
    width: 60%;
    color: #c4c4c4;
    margin-top: 20px;
}

.okTip {
    font-size: 1.857143rem;
    font-family: "Regular";
    color: #7d7d7d;
}

.okDiv {
    display: flex;
    justify-content: space-around;
    width: 75%;
    margin-top: 20px;
}

.historyDiv {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 20px;
}

.historyIcon {
    width: 24px;
    height: 25px;
    display: block;
    margin-right: 10px;
    width: 25px;
}

.down {
    position: absolute;
    right: 0;
    font-size: 24px;
    width: 25px;
    margin-left: 10px;
}

.historyName {
    cursor: pointer;
    width: calc(100% - 70px);
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

:deep(.ant-modal) {
    width: 400px !important;
}

:deep(.ant-modal-body) {
    height: 500px !important;
}

:deep(.ant-modal-header) {
    border: 0;
}

.chunkItem {
    width: 100%;
    height: 100%;
}
</style>
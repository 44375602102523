import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "my-header" }

import { ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'dialog',
  props: {
    show: Boolean,
},
  emits: ['handleCancel', 'handleConfirm'],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const props = __props;
// 拒绝
const handleCancel = () => {
    emit('handleCancel')
}
//同意
const handleConfirm = () => {
    emit('handleConfirm')
}

return (_ctx: any,_cache: any) => {
  const _component_Close = _resolveComponent("Close")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: props.show,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((props.show) = $event)),
    "align-center": "",
    "close-on-click-modal": false,
    "show-close": false
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("div", null, "免责声明", -1)),
        _createVNode(_component_el_icon, {
          class: _normalizeClass(["el-icon--left", 'close-icon']),
          onClick: handleCancel,
          style: {"cursor":"pointer"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Close)
          ]),
          _: 1
        })
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", { class: "dialog-footer" }, [
        _createElementVNode("div", {
          onClick: handleCancel,
          class: "cancel"
        }, "拒绝"),
        _createElementVNode("div", {
          onClick: handleConfirm,
          class: "confirm"
        }, "同意并进入仪表盘")
      ])
    ]),
    default: _withCtx(() => [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "content" }, "这是固定内容", -1))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})
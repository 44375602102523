export default {
    CN: {
        cost: '成本概览',
        userNumber:'用户用量',
        title: '分析统计',
        data: '数据大盘',
        unit: '单位统计',
        user: '用户统计',
        link: '环比增率',
        Date: '日期范围',
        chart: '图表粒度',
        resource: "资源平台",
        CNY: 'Azure成本总额(CNY)',
        department: '部门均耗',
        group: '群组均耗',
        employee: '员工人均令牌消耗(tokens)',
        ADA: 'ADA文本嵌入',
        multiModel: '大模型输入',
        DCR: 'DCR',
        Bing: 'Bing检索',
        Blob: 'Blob存储',
        tokens: '账单API',
        serve: '令牌服务分布',
        granulity: '粒度消耗',
        wandServe: '令牌服务分布',
        text: '文本输入',
        largeModel: '大模型输出',
        dashBoard: '仪表盘',
        allTokens: '总令牌消耗(tokens)',
        member: '用户名',
        part: '部门',
        qToken: '问题令牌消耗',
        aToken: '回答令牌消耗',
        tipToken: '提示词令牌消耗',
        textToken: '文本嵌入令牌消耗',

    },
    HK: {
        title: '分析統計',
        data: '數據大盤',
        unit: '單位統計',
        user: '用戶統計',
        link: '環比增率',
        Date: '日期范圍',
        chart: '圖表粒度',
        resource: "資源平台",
        CNY: 'Azure成本總額(CNY)',
        department: '部門均耗',
        group: '群組均耗',
        employee: '員工人均令牌消耗(tokens)',
        ADA: 'ADA文本嵌入',
        multiModel: '大模型輸入',
        DCR: 'DCR',
        Bing: 'Bing檢索',
        Blob: 'Blob存儲',
        tokens: '賬單API',
        serve: '令牌服務分布',
        granulity: '粒度消耗',
        wandServe: '令牌服務分布',
        text: '文本輸入',
        largeModel: '大模型輸出',
        dashBoard: '儀表盤',
        allTokens: '總令牌消耗(tokens)',

    },
    US: {
        Title: 'Analytics Statistics',
        data: 'Data Overview',
        unit: 'Unit Statistics',
        user: 'User Statistics',
        link: 'Percentage Change',
        Date: 'Date Range',
        chart: 'Chart Granularity',
        resource: "Resource Platform",
        CNY: 'Azure Cost Total (CNY)',
        department: 'Department Average Consumption',
        group: 'Group Average Consumption',
        employee: 'Employee Average Token Consumption (tokens)',
        ADA: 'ADA Text Embedding',
        multiModel: 'Large Model Input',
        DCR: 'DCR',
        Bing: 'Bing Search',
        Blob: 'Blob Storage',
        tokens: 'billing API',
        serve: 'token service distribution',
        granulity: 'granularity consumption',
        wandServe: 'token service distribution',
        text: 'text input',
        largeModel: 'large model output',
        dashBoard: 'dashboard',
        allTokens: 'total token consumption (tokens)',
    },
};

<template>
  <div class="fileItem">
    <img class="icon" :src="getBaseFileIcon(props.item)" alt="" />
    <div class="flexDiv">
      <div class="flexCenter">
        <div class="fileName">
          {{ props.item.fileName || props.item.folderName }}
        </div>
        <img
          class="wid20"
          :src="time"
          alt=""
          v-if="props.item.fileName && props.item.expireDate == null"
        />
        <div class="lookDiv" @click="openLook(props.item)">
          <img class="lookImg" :src="look" alt="" />
        </div>
      </div>
      <div class="flexBetween">
        <div class="fileTip" v-if="props.item.fileName">
          {{
            props.item.fileName.substring(
              props.item.fileName.lastIndexOf(".") + 1
            )
          }}
          {{ formatFileSize(props.item.fileSize) }}
        </div>
        <div class="fileTip" v-else>文件夹 {{ props.item.folderSize }}</div>
        <!-- <div class="zwf">1</div> -->
        <div class="lookDiv" @click="deleteFile">
          <img class="lookImg" :src="yc" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import fileReson from "@/assets/reson/fileReson.svg";
import time from "@/assets/reson/time.svg";
import look from "@/assets/share/look.svg";
import yc from "@/assets/share/yc.svg";
import { defineEmits, defineProps } from "vue";
import { formatFileSize, getBaseFileIcon } from "@/utils/file";
const emit = defineEmits(["openLook", "deleteFile"]);
const props = defineProps(["item"]);
const deleteFile = () => {
  emit("deleteFile");
};
const openLook = (item) => {
  item.fileId=item.id
  emit("openLook",item);
};
</script>

<style lang="scss" scoped>
.fileItem {
  display: flex;
  align-items: center;
  background-color: #fefefe;
  border-radius: 20px;
  padding: 11px 23px 10px 25px;
  border: 2px solid #edeef6;
  height: 53px;
  width: 212px;
  cursor: pointer;
}
.fileItem:hover {
  background-color: #f7f8ff;
}
.icon {
  width: 31px;
  margin-right: 16px;
}
.fileName {
  color: #3d3d3d;
  font-size: 18px;
  font-family: "Semibold";
  width: calc(100% - 59px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.wid20 {
  width: 20px;
  margin: 0px 0px 0px 11px;
}
.fileTip {
  width: calc(100% - 59px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  color: #8a8a8a;
  font-family: "Regular";
}
.zwf {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #d9d9d9;
  font-size: 13px;
  color: #ffffff;
  font-family: "Semibold";
  text-align: center;
  line-height: 18px;
  margin-left: 11px;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 165px;
}
.flexCenter {
  display: flex;
  align-items: center;
  width: 165px;
}
.flexDiv {
  height: 57px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.lookDiv {
  background-color: #edeef6;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
}
.lookImg {
  width: 12px;
}
</style>

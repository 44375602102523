<script lang="ts" setup>
import * as echarts from 'echarts';
import { ref, onMounted, reactive, nextTick, defineProps, defineEmits, PropType, onUnmounted } from 'vue'
const outputValue = ref(172000)
// 上升下降判断
const down = ref(false)

const lineChartDom = ref<HTMLDivElement | null>(null);
const pieChartDom = ref<HTMLDivElement | null>(null);
// 声明两个变量来保存图表实例的引用  
let pieChartInstance: echarts.ECharts | null = null;
let lineChartInstance: echarts.ECharts | null = null;
const lineChartOption = {
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    },
    yAxis: {
        type: 'value'
    },

    series: [
        {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            color: [
                "#14AE5C",
            ],
            areaStyle: {}
        }
    ]
};
const initLineChart = () => {
    if (lineChartDom.value) {
        lineChartInstance = echarts.init(lineChartDom.value)
        lineChartInstance.setOption(lineChartOption);
    }
};
const pieChartOption = {
    tooltip: {
        trigger: 'item'

    },
    legend: {
        top: '5%',
        left: 'center'
    },
    series: [
        {
            type: 'pie',
            color: [
                "#3376ff",
                "#38aee5",
                "#767bfa",
                "#f02d63",
                "#ffc300"
            ],
            textStyle: {

            },
            radius: ['50%', '60%'],
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'center'
            },
            labelLine: {
                show: false
            },
            data: [
                { value: 1048, name: '文本嵌入' },
                { value: 735, name: '大模型输入' },
                { value: 580, name: '大模型输出' },
            ]
        }
    ]
};
const initPieChart = () => {
    if (pieChartDom.value) {
        pieChartInstance = echarts.init(pieChartDom.value)
        pieChartInstance.setOption(pieChartOption);
    }
};
// 窗口大小变化的监听器函数  
const handleResize = () => {
    if (pieChartInstance) {
        pieChartInstance.resize();
    }
    if (lineChartInstance) {
        lineChartInstance.resize();
    }
};
// 在组件挂载后初始化图表  
onMounted(() => {
    initLineChart();
    initPieChart();
    window.addEventListener('resize', handleResize);
});
//在组件卸载前移除监听器
onUnmounted(() => {
    if (pieChartInstance) {
        pieChartInstance.dispose();
    }
    if (lineChartInstance) {
        lineChartInstance.dispose();
    }
    window.removeEventListener('resize', handleResize);
});

</script>

<template>
    <div class="cardBox">
        <div class="cardRow">
            <!-- 总令牌 -->
            <div class="statistic-card8">
                <div class="statistic ellipsis">{{ $t('statistics.allTokens') }}</div>
                <div class="center8 ">
                    <el-statistic class="valueStyle1" :value="outputValue">{{ outputValue }}</el-statistic>
                </div>
                <div class="bottom8">
                    <div class="text">{{ $t('statistics.link') }}</div>
                    <!-- 比率/下降-->
                    <div class="rateDown" v-if="down">
                        <el-icon style="color: red;">
                            <CaretBottom />
                        </el-icon>
                        27%
                    </div>
                    <!-- 比率/上升-->
                    <div class="rateUp" v-else>
                        <el-icon style="color: #49CF43;">
                            <CaretTop />
                        </el-icon>
                        27%
                    </div>
                </div>
            </div>
            <!-- 部门 -->
            <div class="statistic-card4">
                <div class="statistic ellipsis">{{ $t('statistics.department') }}</div>
                <div class="center4">
                    <el-statistic class="valueStyle2" :value="outputValue" />
                </div>
                <div class="bottom4">
                    <div class="text">{{ $t('statistics.link') }}</div>
                    <!-- 比率/下降-->
                    <div class="rateDown" v-if="down">
                        <el-icon style="color: red;">
                            <CaretBottom />
                        </el-icon>
                        27%
                    </div>
                    <!-- 比率/上升-->
                    <div class="rateUp" v-else>
                        <el-icon style="color: #49CF43;">
                            <CaretTop />
                        </el-icon>
                        27%
                    </div>
                </div>
            </div>
            <!-- 群组 -->
            <div class="statistic-card4">
                <div class="statistic ellipsis">{{ $t('statistics.group') }}</div>
                <div class="center4">
                    <el-statistic class="valueStyle3" :value="outputValue" />
                </div>
                <div class="bottom4">
                    <div class="text">{{ $t('statistics.link') }}</div>
                    <!-- 比率/下降-->
                    <div class="rateDown" v-if="down">
                        <el-icon style="color: red;">
                            <CaretBottom />
                        </el-icon>
                        27%
                    </div>
                    <!-- 比率/上升-->
                    <div class="rateUp" v-else>
                        <el-icon style="color: #49CF43;">
                            <CaretTop />
                        </el-icon>
                        27%
                    </div>
                </div>
            </div>
            <!-- 员工人均令牌消耗 -->
            <div class="statistic-card8">
                <div class="statistic ellipsis">{{ $t('statistics.employee') }}</div>
                <div class="center8">
                    <el-statistic class="valueStyle4" :value="outputValue" />
                </div>
                <div class="bottom8">
                    <div class="text">{{ $t('statistics.link') }}</div>
                    <!-- 比率/下降-->
                    <div class="rateDown" v-if="down">
                        <el-icon style="color: red;">
                            <CaretBottom />
                        </el-icon>
                        27%
                    </div>
                    <!-- 比率/上升-->
                    <div class="rateUp" v-else>
                        <el-icon style="color: #49CF43;">
                            <CaretTop />
                        </el-icon>
                        27%
                    </div>
                </div>
            </div>
            <!-- ADA文本嵌入 -->
            <div class="statistic-card4">
                <div class="statistic ellipsis cardDiv">{{ $t('statistics.ADA') }}</div>
                <div class="center4">
                    <el-statistic class="valueStyle5" :value="outputValue" />
                </div>
                <div class="bottom4">
                    <div class="text">{{ $t('statistics.link') }}</div>
                    <!-- 比率/下降-->
                    <div class="rateDown" v-if="down">
                        <el-icon style="color: red;">
                            <CaretBottom />
                        </el-icon>
                        27%
                    </div>
                    <!-- 比率/上升-->
                    <div class="rateUp" v-else>
                        <el-icon style="color: #49CF43;">
                            <CaretTop />
                        </el-icon>
                        27%
                    </div>
                </div>
            </div>
            <!-- 大模型输入 -->
            <div class="statistic-card4">
                <div class="statistic ellipsis">{{ $t('statistics.multiModel') }}</div>
                <div class="center4">
                    <el-statistic class="valueStyle2" :value="outputValue" />
                </div>
                <div class="bottom4">
                    <div class="text">{{ $t('statistics.link') }}</div>
                    <!-- 比率/下降-->
                    <div class="rateDown" v-if="down">
                        <el-icon style="color: red;">
                            <CaretBottom />
                        </el-icon>
                        27%
                    </div>
                    <!-- 比率/上升-->
                    <div class="rateUp" v-else>
                        <el-icon style="color: #49CF43;">
                            <CaretTop />
                        </el-icon>
                        27%
                    </div>
                </div>
            </div>
            <!-- 大模型输出 -->
            <div class="statistic-card4">
                <div class="statistic ellipsis">{{ $t('statistics.largeModel') }}</div>
                <div class="center4">
                    <el-statistic class="valueStyle3" :value="outputValue" />
                </div>
                <div class="bottom4">
                    <div class="text">{{ $t('statistics.link') }}</div>
                    <!-- 比率/下降-->
                    <div class="rateDown" v-if="down">
                        <el-icon style="color: red;">
                            <CaretBottom />
                        </el-icon>
                        27%
                    </div>
                    <!-- 比率/上升-->
                    <div class="rateUp" v-else>
                        <el-icon style="color: #49CF43;">
                            <CaretTop />
                        </el-icon>
                        27%
                    </div>
                </div>
            </div>
            <!-- OCR -->
            <div class="statistic-card4">
                <div class="statistic ellipsis">{{ $t('statistics.DCR') }}</div>
                <div class="center4">
                    <el-statistic class="valueStyle5" :value="outputValue" />
                </div>
                <div class="bottom4">
                    <div class="text">{{ $t('statistics.link') }}</div>
                    <!-- 比率/下降-->
                    <div class="rateDown" v-if="down">
                        <el-icon style="color: red;">
                            <CaretBottom />
                        </el-icon>
                        27%
                    </div>
                    <!-- 比率/上升-->
                    <div class="rateUp" v-else>
                        <el-icon style="color: #49CF43;">
                            <CaretTop />
                        </el-icon>
                        27%
                    </div>
                </div>
            </div>
            <!-- Bing检索 -->
            <div class="statistic-card4">
                <div class="statistic ellipsis">{{ $t('statistics.Bing') }}</div>
                <div class="center4">
                    <el-statistic class="valueStyle3" :value="outputValue" />
                </div>
                <div class="bottom4">
                    <div class="text">{{ $t('statistics.link') }}</div>
                    <!-- 比率/下降-->
                    <div class="rateDown" v-if="down">
                        <el-icon style="color: red;">
                            <CaretBottom />
                        </el-icon>
                        27%
                    </div>
                    <!-- 比率/上升-->
                    <div class="rateUp" v-else>
                        <el-icon style="color: #49CF43;">
                            <CaretTop />
                        </el-icon>
                        27%
                    </div>
                </div>
            </div>
            <!-- Blob存储 -->
            <div class="statistic-card4">
                <div class="statistic ellipsis">{{ $t('statistics.Blob') }}</div>
                <div class="center4">
                    <el-statistic class="valueStyle2" :value="outputValue" />
                </div>
                <div class="bottom4">
                    <div class="text">{{ $t('statistics.link') }}</div>
                    <!-- 比率/下降-->
                    <div class="rateDown" v-if="down">
                        <el-icon style="color: red;">
                            <CaretBottom />
                        </el-icon>
                        27%
                    </div>
                    <!-- 比率/上升-->
                    <div class="rateUp" v-else>
                        <el-icon style="color: #49CF43;">
                            <CaretTop />
                        </el-icon>
                        27%
                    </div>
                </div>
            </div>
            <!-- 账单API -->
            <div class="statistic-card16">
                <div class="statistic ellipsis">{{ $t('statistics.tokens') }}</div>
                <div class="colorBox">
                    <div class="color-icon">
                        <span></span>
                    </div>
                    <div>
                        {{ $t('statistics.granulity') }}
                    </div>
                </div>
                <div id="main" ref="lineChartDom" class="id"></div>
            </div>
            <!-- 令牌服务分布 -->
            <div class="statistic-card82">
                <div class="statistic ellipsis">{{ $t('statistics.serve') }}</div>
                <div id="cake" class="cake" ref="pieChartDom">

                </div>
            </div>
        </div>
    </div>
</template>



<style scoped lang="scss">
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:deep(.valueStyle1 .el-statistic__number){
    font-size: 70px !important;
    font-weight: 600 !important;
    color: #767BFA !important;
}

:deep(.valueStyle2 .el-statistic__number){
    font-size: 40px !important;
    font-weight: 600 !important;
    color: #3376FF !important;
}

:deep(.valueStyle3 .el-statistic__number){
    font-size: 40px !important;
    font-weight: 600 !important;
    color: #767BFA !important;
}

:deep(.valueStyle4 .el-statistic__number){
    font-size: 70px !important;
    font-weight: 600 !important;
    color: #38AEE5 !important;
}

:deep(.valueStyle5 .el-statistic__number){
    font-size: 40px !important;
    font-weight: 600 !important;
    color: #38AEE5 !important;
}

.cardRow {
    display: flex;
    flex-wrap: wrap;
}

.cardDiv {
    font-size: 24px;
}

.statistic-card16 {
    width: 876px;
    height: 510px;
    border-radius: 30px;
    background-color: #f7f8ff;
    padding: 20px 24px;
    margin-bottom: 20px;
    margin: 10px;

    #main {
        margin-top: 10px;
        width: 814px;
        height: 342px;
        font-size: 20px;
    }
}

.colorBox {

    display: flex;
    width: 100%;
    height: 30px;
    color: #8A8A8A;
    font-size: 18px;
    margin-top: 20px;
    display: flex;
    justify-content: right;
}

.color-icon {
    width: 60px;
    height: 30px;
    border: #D9D9D9 2px solid;

    margin-right: 14px;

    span {
        display: block;
        width: 50px;
        height: 20px;
        background-color: #14AE5C;
        margin: 4px auto;
    }
}

.cake {
    margin: 30px auto;
    width: 390px;
    height: 390px;
}

.statistic-card8 {
    margin: 10px;
    width: 430px;
    height: 220px;
    border-radius: 30px;
    background-color: #f7f8ff;
    padding: 20px 24px;
    margin-bottom: 20px;
}

.statistic-card82 {
    margin: 10px;
    width: 430px;
    height: 510px;
    border-radius: 30px;
    background-color: #f7f8ff;
    padding: 20px 24px;
    margin-bottom: 20px;
}

.statistic-card4 {
    width: 204px;
    height: 220px;
    border-radius: 30px;
    background-color: #f7f8ff;
    padding: 20px 24px;
    margin-bottom: 20px;
    margin: 10px;
}

.center8 {
    text-align: center;
    margin-top: 6px;
}

.center4 {
    text-align: center;
    margin-top: 20px;
}

.bottom8 {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.bottom4 {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.text {
    color: #8A8A8A;
    font-size: 20px;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.rateDown {
    background-color: #FDE2E7;
    border-radius: 10px;
    text-align: center;
    font-size: 16px;
    padding: 4px;
    color: #f02D63;
}

.rateUp {
    display: flex;
    background-color: #E6F1E8;
    border-radius: 10px;
    text-align: center;
    font-size: 16px;
    padding: 4px;
    color: #49CF43;
}

.statistic {
    font-size: 22px;
    font-weight: 600;
    color: #3D3D3D;
}

.cardBox {
    margin-top: 20px;
    width: 100%;
    height: 100%;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}
</style>
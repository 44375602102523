import menu from './menu/index'
import header from './header/index'
import share from './share/index'
import bot from './bot/index'
import statistics from './statistics/index'
import manage from './manage/index'
import login from './login/index'
import log from './log/index'
export default {
    CN: {
        menu: menu.CN,
        header: header.CN,
        share: share.CN,
        bot: bot.CN,
        statistics: statistics.CN,
        login:login.CN,
        manage:manage.CN,
        log:log.CN
    },
    HK: {
        menu: menu.HK,
        header: header.HK,
        share: share.HK,
        bot: bot.HK,
        statistics: statistics.HK,
        login:login.HK,
        manage:manage.HK,
        log:log.HK
    },
    US: {
        menu: menu.US,
        header: header.US,
        share: share.US,
        bot: bot.US,
        statistics: statistics.US,
        login:login.US,
        manage:manage.US,
        log:log.US
    },
};

<script setup>
import { ref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n"
import hszImg from "@/assets/look/hsz.svg";
import goBackImg from "@/assets/look/goBack.svg";
import { useStore } from "vuex";
import api from "@/api/index";
import { message } from "ant-design-vue";
const store = useStore();
const userId = ref(store.state.userId);
const { t } = useI18n()
const deleteType = ref(false);
onMounted(() => {
  getList();
});
const status = ref('用户名')
const search = ref(
  [
    {
      value: 'userName',
      label: '用户名'
    },
    {
      value: 'roleName',
      label: '问答关键词'
    }
  ]
)
const statusBtn = () => {
  console.log('点击');
  
}
// 打开回收站
const openDelete = () => {
  deleteType.value = true;
  getList2()
};
// 关闭回收站
const closeDelete = () => {
  deleteType.value = false;
};
// 用户表格
const tableMember = ref([]);
const totalItems = ref(0);
// 分页 
const pageSize = ref(20);
const currentPage = ref(1);
// 每页显示条数
const updatePageSize = (newSize) => {
  pageSize.value = newSize;
}
// 更新当前页码
const updateCurrentPage = (newPage) => {
  currentPage.value = newPage;
}
watch(
  () => pageSize.value,
  (newVal) => {
    formList.value.perPage = newVal;
    getList()
  }
)
watch(
  () => currentPage.value,
  (newVal) => {
    formList.value.pageId = newVal;
    getList()
  }
)
const formList = ref({
  userId: userId.value,
  pageId: currentPage.value,
  perPage: pageSize.value,
  operationType: 0,
  userNamePattern: '',
  startDate: '',
  endDate: '',
})
const timeValue = ref('');
// 表单查询
const getList = () => {
  api.manage
    .getTopicListApi(formList.value)
    .then((res) => {
      if (res.returnCode == 200) {
        tableMember.value = res.data;
        totalItems.value = res.totalNum;
      }
    })
}
watch(
  () => formList.userNamePattern,
  (newVal) => {
    formList.userNamePattern = newVal;
    getList()
  }
)
// 多选
const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  multipleSelection.value = val
};
// 多选
const multipleSelection2 = ref([]);
const handleSelectionChange2 = (val) => {
  multipleSelection2.value = val;
};
const returnForm = ref({
  userId: userId.value,
  operationType: 0,
  sessionInfo: [],
})
const listFor = ref({
  sessionId: '',
  typeId: null,
})
// 归档
const returnBtn = (row) => {
  console.log(row);
  listFor.value.sessionId = row.sessionId;
  listFor.value.typeId = row.typeId;
  returnForm.value.sessionInfo.push(listFor.value)
  api.manage
    .getTopicArchiveApi(returnForm.value)
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('归档成功');
        getList()
      }
    })
};
const returnForm2 = ref({
  userId: userId.value,
  operationType: 1,
  sessionInfo: [],
})
// 删除
const deleteBtn = (row) => {
  listFor.value.sessionId = row.sessionId;
  listFor.value.typeId = row.typeId;
  returnForm2.value.sessionInfo.push(listFor.value)
  api.manage
    .getTopicArchiveApi(returnForm2.value)
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('删除成功');
        getList()
        getList2()
      }
    })
};
// 批量归档
const allReturn = () => {
  if (multipleSelection.value.length == 0) {
    message.error('请选择要归档的项');
    return;
  }
  multipleSelection.value.forEach((item) => {
    listFor.value.sessionId = item.sessionId;
    listFor.value.typeId = item.typeId;
    returnForm.value.sessionInfo.push(listFor.value)
  })
  api.manage
    .getTopicArchiveApi(returnForm.value)
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('归档成功');
        getList()
      }
    })
}
// 批量删除
const allDelete = (num) => {
  if (num == 1) {
    if (multipleSelection.value.length == 0) {
      message.error('请选择要删除的项');
      return;
    }
    multipleSelection.value.forEach((item) => {
      listFor.value.sessionId = item.sessionId;
      listFor.value.typeId = item.typeId;
      returnForm2.value.sessionInfo.push(listFor.value)
    })
  } else if (num == 2) {
    if (multipleSelection2.value.length == 0) {
      message.error('请选择要删除的项');
      return;
    }
    multipleSelection2.value.forEach((item) => {
      listFor.value.sessionId = item.sessionId;
      listFor.value.typeId = item.typeId;
      returnForm2.value.sessionInfo.push(listFor.value)
    })
  }
  api.manage
    .getTopicArchiveApi(returnForm2.value)
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('删除成功');
        getList()
        getList2()
      }
    })
};
// =====回收站=====
const statusBtn2 = () => {
  console.log('点击');
  
}
// 分页
const currentPage2 = ref(1);
const pageSize2 = ref(20);
const totalItems2 = ref(null)
const tableMember2 = ref([]);
const updatePageSize2 = (val) => {
  pageSize2.value = val;
  getList2();
};
const updateCurrentPage2 = (val) => {
  currentPage2.value = val;
  getList2();
}
watch(
  () => pageSize2.value,
  (newVal) => {
    formList2.value.perPage = newVal;
    getList2()
  }
)
watch(
  () => currentPage2.value,
  (newVal) => {
    formList2.value.pageId = newVal;
    getList2()
  }
)
// 查询
const formList2 = ref({
  userId: userId.value,
  pageId: currentPage2.value,
  perPage: pageSize2.value,
  operationType: 1,
  userNamePattern: '',
  startDate: '',
  endDate: '',
})
const getList2 = () => {
  api.manage
    .getTopicListApi(formList2.value)
    .then((res) => {
      if (res.returnCode == 200) {
        tableMember2.value = res.data;
        totalItems2.value = res.totalNum;
      }
    })
};
watch(
  () => formList2.userNamePattern,
  (newVal) => {
    formList2.userNamePattern = newVal;
    getList2()
  }
)

const returnForm3 = ref({
  userId: userId.value,
  operationType: 2,
  sessionInfo: [],
});
// 恢复
const restoreBtn = (row) => {
  listFor.value.sessionId = row.sessionId;
  listFor.value.typeId = row.typeId;
  returnForm3.value.sessionInfo.push(listFor.value)
  api.manage
    .getTopicArchiveApi(returnForm3.value)
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('恢复成功');
        getList2()
      }
    })
};
// 批量恢复
const allRestore = () => {
  if (multipleSelection2.value.length == 0) {
    message.error('请选择要恢复的项');
    return;
  }
  multipleSelection2.value.forEach((item) => {
    listFor.value.sessionId = item.sessionId;
    listFor.value.typeId = item.typeId;
    returnForm3.value.sessionInfo.push(listFor.value)
  })
  api.manage
    .getTopicArchiveApi(returnForm3.value)
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('恢复成功');
        getList2()
      }
    })
};

</script>

<template>
  <div class="main">
    <!-- 列表展示 -->
    <div v-if="!deleteType">
      <div class="menuBox">
        <div style="display: flex;">
          <!-- 用户 -->
          <div class="User">
            <div class="searchDiv">
              <el-select v-model="status" @change="statusBtn">
                <el-option v-for="(item, index) in search" :key="index" :label="item.label" :value="item.value" />
              </el-select>
              <el-input v-model="formList.userNamePattern" placeholder="请输入搜索信息"></el-input>
            </div>
          </div>
          <!-- 起止时间 -->
          <div class="User">
            <div class="searchDiv">
              <div class="time">起止时间</div>
              <el-date-picker v-model="timeValue" type="daterange" range-separator="至" start-placeholder="开始时间"
                end-placeholder="结束时间" />
            </div>
          </div>
        </div>
        <!-- 功能区 -->
        <div style="display: flex;">
          <div class="toolBox" @click="allReturn">
            批量归档
          </div>
          <div class="toolBox" @click="allDelete(1)">
            批量删除
          </div>
        </div>
      </div>
      <el-table :data="tableMember" @selection-change="handleSelectionChange" class="tableHeight">
        <el-table-column type="selection" />
        <el-table-column prop="userName" label="提问者" />
        <el-table-column prop="sessionName" label="标题" />
        <el-table-column prop="conversationNum" label="问答数" />
        <el-table-column prop="type" label="话题类型" />
        <el-table-column prop="createTime" label="创建时间" />
        <el-table-column prop="lastUpdateTime" label="最近更新" />
        <el-table-column label="操作" class="operation">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="returnBtn(scope.row)">
              归档
            </el-button>
            <el-button link type="primary" class="remove" @click="deleteBtn(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <div class="deleteDiv" @click="openDelete">
          <img :src="hszImg" alt="" class="deleteImg" />
          回收站
        </div>
        <div>
          <Pagination :background="true" :currentPage="currentPage" :pageSize="pageSize" :total="totalItems"
            @size-change="updatePageSize" @current-change="updateCurrentPage" />
        </div>
      </div>
    </div>
    <!-- 归档列表 -->
    <div v-if="deleteType">
      <div class="menuBox">
        <div style="display: flex;">
          <!-- 用户 -->
          <div class="User">
            <div class="searchDiv">
              <el-select v-model="status" @change="statusBtn2">
                <el-option v-for="(item, index) in search" :key="index" :label="item.label" :value="item.value" />
              </el-select>
              <el-input v-model="formList2.userNamePattern" placeholder="请输入搜索信息"></el-input>
            </div>
          </div>
          <!-- 起止时间 -->
          <div class="User">
            <div class="searchDiv">
              <div>起止时间</div>
              <el-date-picker v-model="timeValue" type="daterange" range-separator="至" start-placeholder="开始时间"
                end-placeholder="结束时间" />
            </div>
          </div>
        </div>
        <!-- 功能区 -->
        <div style="display: flex;">
          <div class="toolBox" @click="allRestore">
            批量恢复
          </div>
          <div class="toolBox" @click="allDelete(2)">
            批量删除
          </div>
        </div>
      </div>
      <el-table :data="tableMember2" @selection-change="handleSelectionChange2" class="tableHeight">
        <el-table-column type="selection" />
        <el-table-column prop="userName" label="提问者" />
        <el-table-column prop="sessionName" label="标题" />
        <el-table-column prop="conversationNum" label="问答数" />
        <el-table-column prop="type" label="话题类型" />
        <el-table-column prop="createTime" label="创建时间" />
        <el-table-column prop="lastUpdateTime" label="最近更新" />
        <el-table-column label="操作" class="operation">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="restoreBtn(scope.row)">
              恢复
            </el-button>
            <el-button link type="primary" class="remove" @click="deleteBtn(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <div class="gobackDiv" @click="closeDelete">
          <img :src="goBackImg" alt="" class="deleteImg" />
          返回
        </div>
        <div>
          <Pagination :background="true" :currentPage="currentPage2" :pageSize="pageSize2" :total="totalItems2"
            @size-change="updatePageSize2" @current-change="updateCurrentPage2" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.menuBox {
  :deep(.el-input__wrapper) {
    border-radius: 0 16px 16px 0;
    border-right: none !important;
  }

  :deep(.el-input) {
    --el-input-height: 42px !important;
  }
}

.searchDiv {
  :deep(.el-select) {
    width: 80px;
  }

  :deep(.el-select__wrapper) {
    width: 80px;
    padding: 0 6px;
    height: 42px !important;
    border-radius: 16px 0 0 16px !important;
    background-color: #EDEEF6;
  }
}

:deep(.el-input) {
  --el-input-height: 42px !important;
}

:deep(.el-date-editor.el-input__wrapper) {
  height: 42px !important;
}

:deep(.el-table th.el-table__cell) {
  background-color: #f7f8ff;
}

.main {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
}

.time {
  padding: 0 10px;
  background-color: #EDEEF6;
  border-radius: 16px 0 0 16px;
  height: 42px;
  color: #a8abb2;
  font-size: 14px;
  line-height: 42px;
  border: 1px solid #dcdfe6;
  border-right: none;
}

.footer {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-family: "Regular";

  .deleteDiv {
    height: 34px;
    border-radius: 12px;
    border: 1px solid #edeef6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f02d63;
    cursor: pointer;
    padding: 4px 6px;
    margin-top: 10px;

    .deleteImg {
      width: 25px;
      margin-right: 3px;
    }
  }

  .gobackDiv {
    height: 34px;
    border-radius: 12px;
    border: 1px solid #edeef6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8a8a8a;
    cursor: pointer;
    padding: 4px 6px;
    margin-top: 10px;

    .deleteImg {
      width: 25px;
      margin-right: 3px;
    }
  }
}


.menuBox {
  position: relative;
  display: flex;
  justify-content: space-between;

  .User {
    height: 42px;
    line-height: 42px;
    border-radius: 16px;
    color: #3D3D3D;
    font-size: 17px;
    margin-right: 10px;

    .searchDiv {
      display: flex;
      color: #3d3d3d;

      .searchSpan {
        color: #3D3D3D;
        font-size: 16px;
        display: flex;

        .text {
          min-width: 34px;
          white-space: nowrap;
        }
      }

      .searchIcon {
        margin-left: 3px;
      }
    }
  }

  .date {
    height: 42px;
    line-height: 42px;
    border: 2px solid #edeef6;
    border-radius: 16px;
    font-size: 18px;
    color: #8a8a8a;
    display: flex;
    font-size: 17px;
    text-align: center;
    padding: 0 0 0 10px;
    margin-right: 16px;

    div:first-child {
      margin-right: 20px;
      color: #8a8a8a;
    }

    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .searchDiv {
      margin-left: 10px;
      color: #3d3d3d;

      .searchSpan {
        color: #3D3D3D;
        font-size: 16px;
        width: 100%;
        display: flex;
        margin-top: 10px;
      }

      .searchIcon {
        margin-left: 10px;
      }
    }

  }
}

.toolBox {
  border: #edeef6 solid 2px;
  border-radius: 16px;
  font-size: 16px;
  padding: 0 10px;
  height: 42px;
  line-height: 40px;
  color: #F02D63;
  margin-right: 10px;
  cursor: pointer;
}

.tableHeight {
  margin-top: 20px;
  height: 630px;
}
</style>

<template>
  <message :message="props.textData"></message>
</template>

<script setup>
import message from "@/components/message/index.vue";
import { defineProps } from "vue";
const props = defineProps(["textData"]);
</script>

<style lang="scss" scoped></style>

<script setup>
import { ref, defineProps, defineEmits, onMounted, watch, reactive } from 'vue'
import { useStore } from "vuex";
import api from "@/api/index";
import {message} from 'ant-design-vue'
import { useI18n } from "vue-i18n"
const { t } = useI18n()
const store = useStore();
const userId = ref(store.state.userId);
const props = defineProps({
    showTool: Boolean,
    resetObj: Boolean,
});
onMounted(() => {
    getDepartment()
    getRole()
    getIps()
})
watch(
    () => props.resetObj,
    (val) => {
        init()
    }
)
// 初始化判断
const init = () => {
    if (props.resetObj == true) {
        forList.value = [{
            name: undefined,
            data: undefined,
        }];
        resetLeft()
    }
}
const resetLeft = () => {
    selList.value = [
            {
                name: "ips",
                type: false,
                label: "IP地址",
            },
            {
                name: "RoleId",
                type: false,
                label: "角色",
            },
            {
                name: "departmentId",
                type: false,
                label: "部门",
            },
            {
                name: "operationResult",
                type: false,
                label: "操作结果",
            },
        ]
}
const emit = defineEmits(['handleClose', 'handleSave']);
const handleClose = () => {
    forList.value = [{
        name: undefined,
        data: undefined,
    }];
    resetLeft()
    emit('handleClose');
}
const handleSave = () => {
    emit('handleSave', forList.value);
};
// 左侧下拉
const changeLeft = (num) => {
    selList.value.forEach((item) => {
        item.type = false;
    });
    forList.value[num].data = undefined;
    forList.value.forEach((item) => {
        if (item.name !== undefined) {
            const index = selList.value.findIndex((item1) => item1.name === item.name);
            if (index !== -1) {
                selList.value[index].type = true
            }
        }
    })
};
const selNull = ref([])
// 数组
const forList = ref([
    {
        name: undefined,
        data: undefined,
    }
]);
// 下拉
const selList = ref([
    {
        name: "ips",
        type: false,
        label: "IP地址",
    },
    {
        name: "RoleId",
        type: false,
        label: "角色",
    },
    {
        name: "departmentId",
        type: false,
        label: "部门",
    },
    {
        name: "operationResult",
        type: false,
        label: "操作结果",
    },
])
// 删除对应筛选项
const deleteForList = (index) => {
    forList.value.splice(index, 1);
    selList.value.forEach((item) => {
        item.type = false
    })
    forList.value.forEach((item) => {
        if (item.name != undefined) {
            const index = selList.value.findIndex((item1) => item1.name == item.name);
            if (index !== -1) {
                selList.value[index].type = true
            }
        }
    })
};
// 添加对应筛选项
const addPush = () => {
    if (forList.value.length == 4) {
        message.warrning("最多添加4个筛选条件")
        
        return
    }
    forList.value.push({
        name: undefined,
        data: undefined,
    })
};
const ips = ref([]);
// 获取ip
const getIps = () => {
    api.log.getIps({ userId: userId.value }).then((res) => {
        if (res.returnCode == 200) {
            ips.value = res.data;
        }
    });
};
const roleList = ref([]);
// 获取角色
const getRole = () => {
    api.log
        .queryUserRole({ userId: userId.value, pageId: 0 })
        .then((res) => {
            if (res.returnCode === 200) {
                // 不能有超级管理员
                roleList.value = res.data;
            }
        });
};
// 部门下拉
const defaultProps = ref({
    children: "children",
    label: "departmentName",
    id: "departmentId",
});
const departmentList = ref([{
    parentId: null,
    departmentName: '',
    departmentId: null,
    children: []
}]);
// 获取部门
const getDepartment = () => {
    api.log
        .getDepartmentName({ userId: userId.value, pageId: 0 })
        .then((res) => {
            if (res.returnCode === 200) {
                departmentList.value = res.data;
            }
        });
};

// 操作结果
const successList = ref([
    {
        name: '成功',
        type: 1,
    },
    {
        name: '失败',
        type: 0,
    },
]);
</script>

<template>
    <div class="logSizer">
        <el-dialog v-model="props.showTool" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>筛选条件</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleClose" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <div v-for="(item, index) in forList" :key="index" class="itemList">
                <el-select v-model="item.name" placeholder="选择筛选条件" clearable @change="changeLeft(index)">
                    <el-option v-for="(items, index) in selList" :key="index" :label="items.label" :value="items.name"
                        :disabled="items.type" />
                </el-select>
                <!-- 空 -->
                <el-select v-if="!item.name" placeholder="请选择" clearable>
                    <el-option v-for="item in selNull" :key="item" />
                </el-select>
                <!-- ip -->
                <el-select v-if="item.name == 'ips'" v-model="item.data" clearable>
                    <el-option v-for="(items, index) in ips" :key="index" :label="items" :value="items" />
                </el-select>
                <!-- 角色 -->
                <el-select v-if="item.name == 'RoleId'" v-model="item.data" clearable>
                    <el-option v-for="(items, index) in roleList" :key="index" :label="items.description"
                        :value="items.id" />
                </el-select>
                <!-- 部门 -->
                <el-tree-select v-if="item.name == 'departmentId'" :data="departmentList" v-model="item.data"
                    check-strictly :render-after-expand="false" :props="defaultProps" node-key="departmentId">
                    <template #default="{ data }">
                        <div>
                            {{ data.departmentName }}
                        </div>
                    </template>
                </el-tree-select>
                <!-- 结果 -->
                <el-select v-if="item.name == 'operationResult'" v-model="item.data" clearable>
                    <el-option v-for="(items, index) in successList" :key="index" :label="items.name"
                        :value="items.type" />
                </el-select>
                <!-- 删除 -->
                <el-icon class="deleteIcon" v-if="index != 0" @click="deleteForList(index)">
                    <CircleClose />
                </el-icon>
            </div>
            <div class="addSelClass">
                <div class="addBtn" type="link" :disabled="forList.length == 4" @click="addPush">
                    <el-icon class="addIcon">
                        <Plus></Plus>
                    </el-icon>
                    <span class="addSpan">添加筛选项</span>
                </div>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <div @click="handleClose" class="cancel">取消</div>
                    <div @click="handleSave" class="confirm">确定</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<style scoped lang="scss">
:deep(.el-dialog) {
    --el-dialog-width: 600px;
    border-radius: 30px;
    padding: 20px;
}

.logSizer {
    :deep(.el-select) {
        width: 230px !important;
        height: 40px !important;
        margin-right: 24px;
        margin-bottom: 10px;
    }

    :deep(.el-select__wrapper) {
        width: 230px !important;
        height: 40px !important;
        border-radius: 30px;
    }

    :deep(.el-input__wrapper) {
        height: 50px !important;
        width: 500px !important;
        border-radius: 12px;
    }
}


.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 18px;
    position: relative;
}

.itemList {
    margin-bottom: 10px;
}

.deleteIcon {
    font-size: 18px;
    cursor: pointer;
    margin-left: -18px;
}

.addSelClass {
    display: flex;
    align-items: center;
    color: #264095;
    font-size: 16px;
    font-family: "Regular";

    .addBtn {
        display: flex;

        .addIcon {
            display: inline-block;
            margin-top: 3px;
        }

        .addSpan {
            margin-left: 6px;
        }
    }
}

.close-icon {
    font-size: 20px;
}

.my-body {
    font-size: 16px;
    color: #8A8A8A;
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #8A8A8A;
        padding: 8px 20px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 8px 20px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
import CryptoJS from "crypto-js";
var key = CryptoJS.enc.Utf8.parse("abcd1234abcd1234"); //十六位十六进制数作为秘钥
var iv = CryptoJS.enc.Utf8.parse("asdasdasdasd1234"); //十六位十六进制数作为秘钥偏移量
// 使用 AES 进行加密
export function encryptApi(data: any) {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    }).toString();
    return encrypted;
}
// 使用 AES 进行解密
export function decryptApi(data: any) {
    if (data == "") return "";
    const decryptedWords = CryptoJS.AES.decrypt(data.toString(), key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    const decryptedMessage = decryptedWords.toString(CryptoJS.enc.Utf8);
    return decryptedMessage;
}

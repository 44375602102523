<script setup>
import { ref, reactive, onMounted, watch, onUnmounted } from 'vue'
import { Search, ArrowDown } from "@element-plus/icons-vue";
import addDialog from './components/addBot.vue'
import botTool from './components/botTool.vue'
import { useI18n } from "vue-i18n"
import { useStore } from "vuex";
import api from "@/api/index";
import { message } from "ant-design-vue";
import pinImg from "@/assets/base/pin.svg";
import pinYesImg from "@/assets/base/pinYes.svg";
import { useRouter, useRoute } from "vue-router";
const { t } = useI18n()
const store = useStore();
const router = useRouter();
const userId = ref(store.state.userId);
const loading = ref(true)
onMounted(() => {
  getList();
})
// 分页
const currentPage = ref(1);
const pageSize = ref(30);
// 列表参数
const listFomr = ref({
  userId: userId.value,
  templatePattern: "",
  pageId: currentPage.value,
  perPage: pageSize.value,
});
watch(
  () => listFomr.value.templatePattern,
  (newVal) => {
    listFomr.value.templatePattern = newVal
    getList()
  }
)
const list = ref([]);
// 获取微模型列表
const getList = () => {
  api.bot
    .templateQuery(listFomr.value)
    .then((res) => {
      if (res.returnCode == 200) {
        list.value.push(...res.data);
        loading.value = false;
      }
    });
};
const load = () => {
  currentPage.value += 1;
  console.log(currentPage.value);
}
watch(
  () => currentPage.value,
  (newVal) => {          
    listFomr.value.pageId = newVal
    getList()
  }
)
// 新建
const formType = ref('add')
const botInfo = ref({})
const showDialog = ref(false)
// 新建智能体
const openAdd = () => {
  formType.value = 'add'
  showDialog.value = true;
  botInfo.value = {
    shareUserIds: []
  }
};

// 确定新增
const submitBtn = (form) => {
  showDialog.value = false
  api.bot
    .templateAdd(form)
    .then((res) => {
      if (res.returnCode == 200) {
        message.success("新增成功");
        getList()
      }
    })
};
// 智能体修改
const templateId = ref(null)
const goBaseDetails = (item) => {
  api.chat
    .addChat({
      userId: userId.value,
      topicName: item.templateName,
      templateId: item.templateId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        // 新增对话 不算base chat
        store.commit("SET_CHATTYPE", "chat");
        store.commit("SET_TOPIC", {
          sessionName: item.templateName,
          sessionId: res.data.table_id,
        });
        router.push("/chat");
      }
    });
}
// 关闭弹窗
const closeBtn = () => {
  showDialog.value = false
};
// 修改确定
const editBtn = (form) => {
  api.bot
    .templateEdit(form)
    .then((res) => {
      if (res.returnCode == 200) {
        showDialog.value = false;
        message.success("修改成功");
        getList()
      }
    })
};
// 更多操作
const changeMore = (key, item) => {
  if (key == 1) {
    // 置顶
    api.bot
      .templateTop({
        userId: userId.value,
        templateId: item.templateId,
        isFavourite: 1,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success("置顶成功");
          getList();
        }
      });
  } else if (key == 2) {
    // 取消置顶
    api.bot
      .templateTop({
        userId: userId.value,
        templateId: item.templateId,
        isFavourite: 0,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success("取消成功");
          getList();
        }
      });
  } else if (key == 3) {
    // 删除智能体
    api.bot
      .templateDel({
        userId: userId.value,
        templateId: item.templateId,
      })
      .then((res) => {
        message.success("删除成功");
        getList();
      });
  } else if (key == 4) {
    // 编辑
    templateId.value = item.templateId
    api.bot
      .templateDetail({
        userId: userId.value,
        templateId: item.templateId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          showDialog.value = true;
          botInfo.value = res.data
          botInfo.value.templateId = templateId.value
          formType.value = 'edit'
        }
      })
  }
};
</script>

<template>
  <div class="container">
    <!-- 搜索框 -->
    <div style="display: flex;justify-content: space-between;position: relative;">
      <div style="display: flex;">
        <el-input v-model="listFomr.templatePattern" class="baseInput" placeholder="搜索微模型" :prefix-icon="Search" />
        <div class="formItem">
          {{ $t('bot.all') }}
        </div>
        <div class="formItem">
          <img src="@/assets/bot/tool.svg" alt="" class="iconImg">
          <div class="spanText">{{ $t('bot.helper') }}</div>
        </div>
        <div class="formItem">
          <img src="@/assets/bot/intellent.svg" alt="" class="iconImg">
          <div class="spanText">{{ $t('bot.tool') }}</div>
        </div>
        <div class="formItem sort">
          <img src="@/assets/bot/sort.svg" alt="">
        </div>
      </div>
      <div class="botCode">
        {{ $t('bot.code') }}
      </div>
    </div>
    <!-- 内容 -->
    <div class="box" v-infinite-scroll="load" v-loading="loading">
      <div class="itemWidth" @click="openAdd">
        <div class="flexCenter itemTile">
          <div class="addDiv">
            <img src="@/assets/base/add.svg" alt="" class="addImg" />
          </div>
          <div class="baseItemTitle">新建智能体</div>
        </div>
        <div class="addTip">
          新建一个空白的AI微模型容器；我们推荐您基于特定的部门、群组、项目或专题构建专属AI微模型。
        </div>
      </div>      
      <div class="itemWidth" v-for="(item, index) in list" :key="index" @dblclick="goBaseDetails(item)">
        <div class="flexCenter itemTile">
          <div class="flexCenter titleMax">
            <div class="logoDiv">
              <img src="@/assets/base/baselogo.svg" alt="" class="baseIconLogo" />
            </div>
            <div class="forBaseItemTitle">{{ item.templateName }}</div>
          </div>
          <div class="pinDiv" @click="changeMore(1, item)" v-if="item.isPin == 0">
            <img :src="pinImg" alt="" class="pinImg" />
          </div>
          <div class="pinYesDiv" @click="changeMore(2, item)" v-if="item.isPin == 1">
            <img :src="pinYesImg" alt="" class="pinImg" />
          </div>
        </div>
        <div class="addTip">
          {{ item.templateDesc }}
        </div>
        <!-- 数量 -->
        <div class="bottomFlexBetween">
          <div class="flexCenter">
            <img src="@/assets/base/talk.svg" alt="" class="baseIcon" />
            <div class="bottomNumberItem">{{ item.sessionNum }}</div>
            <img src="@/assets/base/member.svg" alt="" class="baseIcon" />
            <div class="bottomNumberItem">{{ item.userResults }}</div>
          </div>
          <!-- 功能区 -->
          <el-dropdown @command="changeMore($event, item)">
            <div class="moreDiv">
              <img src="@/assets/base/more.svg" alt="" class="moreIcon" />
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item :command="1" v-if="item.isPin === 0">置顶</el-dropdown-item>
                <el-dropdown-item :command="2" v-if="item.isPin === 1">取消置顶</el-dropdown-item>
                <el-dropdown-item :command="3">
                  删除
                </el-dropdown-item>
                <el-dropdown-item :command="4">
                  修改
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <botTool></botTool>
    </div>
  </div>

  <!-- 新建智能体弹窗 -->
  <addDialog :showDialog="showDialog" :botInfo="botInfo" :formType="formType" @closeBtn="closeBtn"
    @submitBtn="submitBtn" @editBtn="editBtn"></addDialog>
</template>


<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

:deep(.el-input__wrapper) {
  border-radius: 16px;
}

:deep(.el-button.is-round) {
  border-radius: 16px;
}

.container {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 30px;
  min-width: 1430px;
  min-height: 711px;
  width: 100%;
  height: 100%;

  .baseInput {
    width: 236px;
    height: 52px;
    font-size: 17px;
    color: #8a8a8a;
    font-family: "Regular";

  }

  .formItem {
    display: flex;
    border-radius: 16px;
    border: 2px solid #edeef6;
    height: 52px;
    line-height: 52px;
    padding: 0 20px;
    margin-left: 14px;
    font-size: 17px;
    color: #8a8a8a;
    cursor: pointer;

    .iconImg {
      display: inline-block;
      width: 26px;
      height: 23px;
      margin-right: 4px;
      margin-top: 14px;
    }

    .spanText {
      padding-top: -24px;
    }
  }
}

.sort {
  padding: 12px !important;

  img {
    width: 25px;
    height: 25px;
  }
}

.formItem:hover {
  border: 2px solid #DBDFFF;
  background-color: #ECEEFF;
  color: #0256FF;
}

.box {
  width: 100%;
  height: calc(100% - 74px);
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 22px;
  overflow: auto;
}

.itemWidth {
  border-radius: 20px;
  background-color: #f7f8ff;
  margin-bottom: 20px;
  padding: 24px;
  cursor: pointer;
  width: 23%;
  height: 206x;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
}

.itemWidth:hover {
  background-color: #eceeff;
  box-shadow: 0px 0px 8px 0px #eceeff;

  .pinDiv {
    width: 36px;
    height: 36px;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: #f7f8ff;
  }

  .pinYesDiv {
    background-color: #f7f8ff;
    border-radius: 12px;
  }

  .moreDiv {
    width: 36px;
    height: 36px;
    border-radius: 12px;
    background-color: #f7f8ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.flexCenter {
  display: flex;
  align-items: center;
}

.itemTile {
  height: 28px;
  margin-bottom: 24px;
  position: relative;
}

.addDiv {
  background-color: #dbdfff;
  border-radius: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.addImg {
  width: 15px;
  height: 15px;
}

.baseItemTitle {
  font-size: 20px;
  color: #0256ff;
  font-family: "Bold";
}

.addTip {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  height: 50px;
  // display: -webkit-box;
  // line-clamp: 2;
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 18px;
}

.titleMax {
  width: 100%;
}

.logoDiv {
  border-radius: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.baseIconLogo {
  width: 28px;
}

.forBaseItemTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Bold";
  width: calc(100% - 80px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pinDiv {
  display: none;
}

.pinImg {
  width: 16px;
  height: 16px;
}

.bottomFlexBetween {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.baseIcon {
  width: 25px;
}

.bottomNumberItem {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  margin-left: 3px;
  margin-right: 12px;
}

.moreDiv {
  display: none;
}

.moreIcon {
  width: 18px;
}

.botCode {
  height: 52px;
  line-height: 52px;
  padding: 0 20px;
  border-radius: 16px;
  background-color: #0256ff;
  text-align: center;
  font-size: 17px;
  color: #fff;
  cursor: pointer;
}
</style>
export default {
    CN: {
        table: {
            operationTime: '操作时间',
            userName: '用户名',
            email: '邮箱',
            ip: 'IP地址',
            department: '部门',
            operationType: '操作类型',
            operationModule: '所属模块',
            operationResult: '操作结果',
            operationContent: '操作内容',
        },

    },
    HK: {
        table: {
            operationTime: '操作时间',
            userName: '用户名',
            email: '邮箱',
            ip: 'IP地址',
            department: '部门',
            operationType: '操作类型',
            operationModule: '所属模块',
            operationResult: '操作结果',
            operationContent: '操作内容',
        },
    },
    US: {
        table: {
            operationTime: '操作时间',
            userName: '用户名',
            email: '邮箱',
            ip: 'IP地址',
            department: '部门',
            operationType: '操作类型',
            operationModule: '所属模块',
            operationResult: '操作结果',
            operationContent: '操作内容',
        },
    },
};

<template>
  <div class="tagBetWeen">
    <div class="tagCenterFlex">
      <img class="tabImg" :src="props.tagList[props.tagActive].icon" alt="" />
      <div class="tagTitle">
        {{ props.tagActive == 0 ? "正在询问AI知识库" : "正在对话AI智能体" }}
      </div>
      <el-select
        placeholder="请选择"
        class="inputSelete"
        v-if="props.tagActive == 0"
        v-model="baseId"
        @change="changeBaseId"
      >
        <el-option
          v-for="item in baseList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-select
        placeholder="请选择"
        class="inputSelete"
        v-if="props.tagActive == 1"
        v-model="templateId"
        @change="changeTemplateId"
      >
        <el-option
          v-for="item in templateList"
          :key="item.templateId"
          :label="item.templateName"
          :value="item.templateId"
        />
      </el-select>
    </div>
    <div class="sdFlex">
      <!-- <div v-if="props.tagActive == 1" class="sdDivBtn">
        <img :src="sdImg" class="sdImgBtn" alt="" />
        <div>一键完善</div>
      </div> -->
      <img :src="inputClose" alt="" @click="closeTag" class="inputClose" />
    </div>
  </div>
</template>

<script setup>
import sdImg from "@/assets/input/sd.svg";
import inputClose from "@/assets/input/inputClose.svg";
import { ref, defineProps, defineEmits, onMounted, watch } from "vue";
import { useStore } from "vuex";
import api from "@/api";
const store = useStore();
const props = defineProps(["tagList", "tagActive"]);
const emit = defineEmits(["closeTag", "changeBaseId", "changeTemplateId"]);
const userId = ref(store.state.userId);
const baseId = ref("");
const baseList = ref([]);
const templateId = ref(null);
const templateList = ref([]);
onMounted(() => {
  console.log(props.tagList);
  getBase();
  getTemplate();
});
watch(
  () => props.tagActive,
  (newValue, oldValue) => {
    if (props.tagActive == 0) {
      templateId.value = null;
    } else if (props.tagActive == 2) {
      baseId.value = null;
    }
  }
);
const closeTag = () => {
  emit("closeTag");
};
const getBase = () => {
  api.base
    .customLibraryList({
      userId: userId.value,
      namePattern: "",
      pageId: 1,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        baseList.value = res.data;
      }
    });
};
const getTemplate = () => {
  api.chat
    .getTemplate({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        templateList.value = res.data;
      }
    });
};
const changeTemplateId = () => {
  emit("changeTemplateId", templateId.value);
};
const changeBaseId = () => {
  emit("changeBaseId", baseId.value);
};
</script>

<style lang="scss" scoped>
.tagBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 17px 10px 27px;
  height: 38px;
  background-color: #f7f8ff;
  border-radius: 18px 18px 0px 0px;
  border: 2px solid #d9d9d9;
  border-bottom: 0px;
}
.tagCenterFlex {
  display: flex;
  align-items: center;
  width: calc(100% - 50px);
}
.tabImg {
  width: 25px;
  margin-right: 3px;
}
.tagTitle {
  font-size: 18px;
  font-family: "Medium";
  color: #3d3d3d;
  margin-right: 12px;
}
.inputSelete {
  max-width: 205px;
  :deep(.el-select__wrapper) {
    border-radius: 12px;
    border: 2px solid #dbdfff;
    box-shadow: 0px 0px 0px 0px #ffffff;
    font-size: 18px;
    color: #3d3d3d;
    font-family: "Medium";
    height: 36px;
    max-width: 205px;
    background-color: transparent;
  }
}
.sdFlex {
  display: flex;
  align-items: center;
}
.sdImgBtn {
  width: 20px;
  margin-right: 3px;
}
.sdDivBtn {
  display: flex;
  align-items: center;
  background-color: #dbdfff;
  border-radius: 12px;
  padding: 6px;
  height: 24px;
  color: #0256ff;
  font-size: 18px;
  font-family: "Medium";
  margin-right: 30px;
}
.inputClose {
  width: 24px;
  cursor: pointer;
}
</style>

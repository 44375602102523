<script setup>
import { ref, defineProps, onMounted, watch } from "vue";
const emit = defineEmits(['handleCancel', 'handleAdd']);
const props = defineProps({
    openShow: Boolean,
    resoureInfo: Object
});
watch(
    () => props.resoureInfo,
    (newVal) => {
        props.resoureInfo = newVal
    })
onMounted(() => {
})
const title = ref('白名单详情');
const editNum = ref(true)
// 编辑配额
const editNumBtn = () => {
    editNum.value = false;
}
const limit = ref({})
// input失焦
const newObj = ref({})
const changeInput = (e) => {
    limit.value[e.limit.limitType] = e.limit.value;
    Object.assign(newObj.value, limit.value)
};
// 保存配额编辑
const submitNumBtn = () => {
    console.log(props.resoureInfo);
    
    editNum.value = true;
    // api.manage
    //     .quotaEditApi({
    //         userId: userId.value,
    //         limits: newObj.value,
    //         departmentId: props.tableId,
    //     })
    //     .then((res) => {
    //         if (res.returnCode === 200) {
    //             message.success('配额编辑成功')
    //             getUserTable()
    //             newObj.value = {}
    //         }
    //     })
};
// 拒绝
const handleCancel = () => {
    emit('handleCancel')
}
//同意
const handleConfirm = () => {
    emit('handleAdd')
};
// 详情里面的移除按钮
const handleDelete = () => {

};
</script>

<template>
    <!-- 白名单详情 -->
    <div>
        <el-dialog v-model="props.openShow" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>{{ title }}</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleCancel" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <el-divider />
            <div class="content">
                <div class="name">{{ resoureInfo.name }}</div>
            </div>
            <div class="introduce">{{ resoureInfo.description }}</div>
            <div class="addUser">
                <div class="left">成员</div>
            </div>
            <el-table :data="resoureInfo.groups" class="table">
                <el-table-column prop="groupName" label="群组名" />
                <el-table-column prop="groupName" label="操作">
                    <template #default="scope">
                        <div class="move_tag">移除</div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="addUser">
                <div class="left">配置资源</div>
                <!-- <div class="twoBtn">
                    <div class="oneBtn">添加资源</div>
                    <div @click="editNumBtn" v-if="editNum" class="oneBtn">编辑配额</div>
                    <div @click="submitNumBtn" v-if="!editNum" class="submitBtn">完成编辑</div>
                </div> -->
            </div>
            <el-table :data="resoureInfo.resourceInfo" class="table">
                <el-table-column prop="modelType" label="资源名称" />
                <el-table-column prop="functionType" label="资源类型" />
                <el-table-column prop="limit" label="用户日配额">
                    <template #default="scope">
                        <el-input v-model="scope.row.limit.value" :disabled="editNum" @blur="changeInput(scope.row)">
                            <template #append v-if="scope.row.limit.limitType === 'limitAliImageTimes'"> 张</template>
                            <template #append v-if="scope.row.limit.limitType === 'limitBaiduImageTimes'"> 张</template>
                            <template #append v-if="scope.row.limit.limitType === 'limitDocumentChatNum'"> 个</template>
                            <template #append v-if="scope.row.limit.limitType === 'limitDocumentNum'"> 个</template>
                            <template #append v-if="scope.row.limit.limitType === 'limitImageTimes'"> 张</template>
                            <template #append v-else>令牌</template>
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="groupName" label="操作">
                    <template #default="scope">
                        <div class="move_tag">移除</div>
                    </template>
                </el-table-column>
            </el-table>
            <template #footer>
                <div class="dialog-footer">
                    <div @click="handleCancel" class="cancel">关闭详情</div>
                    <div @click="handleConfirm" class="confirm">编辑资源组</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>



<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-divider) {
    margin: 1px 0 10px !important;
    width: calc(100%) !important;
}

:deep(.el-dialog) {
    --el-dialog-width: 756px;
    border-radius: 30px;
    padding: 30px;
    max-height: 872px;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 24px;
    position: relative;
}

.twoBtn {
    display: flex;

    .oneBtn {
        width: 80px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        border-radius: 8px;
        background-color: #3376ff;
        cursor: pointer;
        margin-left: 10px;
        color: #fff;
    }
}

.submitBtn {
    width: 80px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    border-radius: 8px;
    background-color: #767bfa;
    cursor: pointer;
    margin-left: 10px;
    color: #fff;
}

.move_tag {
    width: 48px;
    padding: 4px 8px;
    background-color: #F7F8FF;
    border-radius: 8px;
    color: #F02D63;
    // cursor: pointer;
}

.close-icon {
    font-size: 20px;
}

.content {
    display: flex;
    color: #3D3D3D;

    .name {
        font-size: 24px;
        font-weight: 600;
        margin-right: 6px;
    }

    .tag {
        display: flex;
        justify-content: space-between;
        background-color: #DBDFFF;
        color: #0256FF;
        padding: 0px 6px;
        height: 28px;
        line-height: 28px;
        border-radius: 6px;
        font-size: 16px;
        margin: auto 0;
    }
}

.introduce {
    margin-top: 12px;
    font-size: 18px;
    height: 25px;
    line-height: 25px;
}

.addUser {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    .left {
        font-size: 18px;
        color: #3D3D3D;
        font-weight: 600;
        height: 36px;
        line-height: 36px;
    }

    .add {
        width: 112px;
        height: 36px;
        font-size: 18px;
        color: #fff;
        background-color: #3376FF;
        line-height: 36px;
        text-align: center;
        border-radius: 12px;
    }
}

.table {
    margin-top: 12px;
    width: 100%;
    height: 220px;
}

.form-name {
    margin-top: 12px;
    font-size: 18px;
    color: #3D3D3D;

    .el-tag {
        display: flex;
        padding: 2px 33px 2px 2px;
        width: 144px;
        height: 35px;
        line-height: 35px;
        border-radius: 30px;
        font-size: 18px;
        margin-top: 12px;

        .tag-img {
            width: 30px;
            height: 30px;
            margin-right: 6px;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    }

    .tit {
        margin-bottom: 10px;
        font-weight: 600;
    }

    .comtent {
        margin-bottom: 12px;
    }
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #8A8A8A;
        padding: 6px 12px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 6px 12px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
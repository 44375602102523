export default {
    CN: {
        chat: '新的对话',
        index: '主页',
        base: 'AI知识库',
        baseDetails: 'AI知识库',
        share: '知识共享',
        shareDetails: '知识共享',
        shareView: '知识共享',
        bot: 'AI智能体',
        statistics: '仪表盘',
        manage: '平台管理',
        login: '登录',
        lookAll: '查看全部',
        recentTopics: '最近话题'
    },
    HK: {
        chat: '新的對話',
        index: '首頁',
        base: 'AI知識庫',
        baseDetails: 'AI知識庫',
        share: '知識共享',
        shareDetails: '知識共享',
        shareView: '知識共享',
        bot: 'AI智慧體',
        statistics: '儀錶盤',
        manage: '平台管理',
        login: '登錄',
        lookAll: '查看全部',
        recentTopics: '最近話題'
    },
    US: {
        chat: 'New Conversations',
        index: 'Home',
        base: 'AI knowledge base',
        baseDetails: 'AI knowledge base',
        share: 'Knowledge Sharing',
        shareDetails: 'Knowledge Sharing',
        shareView: 'Knowledge Sharing',
        bot: 'AI agents',
        statistics: 'Dashboard',
        manage: 'Platform Management',
        login: 'Login',
        lookAll: 'See them all',
        recentTopics: 'Recent Topics'
    },
};

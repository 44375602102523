<script setup>
import { ref, defineProps, onMounted, watch } from "vue";
import { useStore } from "vuex";
import api from "@/api/index";
const store = useStore();
const userId = ref(store.state.userId);
const emit = defineEmits(['handleCancel', 'handleAddResoure', 'saveResoure']);
const props = defineProps({
    showAddResource: Boolean,
    formType: String,
    resoureInfo: Object
});
const logActive = ref(0)
const modelList = ref([])
const getUrl = ref('')
const logoAzure = require('@/assets/manage/logoAzure.svg')
const logoBaidu = require('@/assets/manage/logoBaidu.svg')
const logoAli = require('@/assets/manage/logoAli.svg')
watch(
    () => props.resoureInfo,
    (newVal) => {
        props.resoureInfo = newVal
    })
watch(
    () => props.formType,
    (newVal) => {
        props.formType = newVal
    })
onMounted(() => {
    getNewList()
    getUrl.value = 'Azure'
    getBaseSelect()
})
const rules = ref({
    configurationName: [
        { required: true, message: '配置名称不能为空', trigger: 'blur' },
    ],
    modelName: [
        { required: true, message: '模型名称不能为空', trigger: 'blur' },
    ],
    modelType: [
        { required: true, message: '功能类型不能为空', trigger: 'blur' },
    ],
    resourceGroupId: [
        { required: true, message: '资源组不能为空', trigger: 'blur' },
    ],
    apiBase: [
        { required: true, message: '终结点不能为空', trigger: 'blur' },
    ],
    apiVersion: [
        { required: true, message: '模型版本不能为空', trigger: 'blur' },
    ],
    apiKey: [
        { required: true, message: '模型密钥不能为空', trigger: 'blur' },
    ],
    apiUrl: [
        { required: true, message: '模型URL不能为空', trigger: 'blur' },
    ],
    connectionKey: [
        { required: true, message: '链接字符串不能为空', trigger: 'blur' },
    ],
    accountKey: [
        { required: true, message: '账号密钥不能为空', trigger: 'blur' },
    ],
    accountUrl: [
        { required: true, message: '账号URL不能为空', trigger: 'blur' },
    ],
    secretKey: [
        { required: true, message: '密钥不能为空', trigger: 'blur' },
    ],
    endpoint: [
        { required: true, message: '域名节点不能为空', trigger: 'blur' },
    ],
    accessKeyId: [
        { required: true, message: 'accessKeyId不能为空', trigger: 'blur' },
    ],
    accessKeySecret: [
        { required: true, message: 'accessKeySecret不能为空', trigger: 'blur' },
    ],
    accessKey: [
        { required: true, message: 'accessKey不能为空', trigger: 'blur' },
    ],
})
// 切换资源类型
const changeLog = (index) => {
    props.resoureInfo.functionType = ''
    props.resoureInfo.modelType = ''
    logActive.value = index
    if (index == 0) {
        getUrl.value = 'Azure'
    } else if (index == 2) {
        getUrl.value = 'Baidu'
    } else {
        getUrl.value = 'Ali'
    }
    getBaseSelect()
    props.resoureInfo.resourceType = index
};
// 配置下拉框
const getBaseSelect = () => {
    api.manage
        .getBaseSelectApi(getUrl.value)
        .then((res) => {
            if (res.returnCode == 200) {
                modelList.value = res.data
            }
        })
};
const newChildren = ref([])
const getSelectChildren = (arr, label) => {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].label == label) {
            newChildren.value = arr[i].children
        }
    }
};
const azureLabel = ref(null)
watch(
    () => props.resoureInfo.functionType,
    (newVal) => {
        getSelectChildren(modelList.value, newVal)
        props.resoureInfo.modelType = ''
        props.resoureInfo.resourceGroupId = ''
        azureLabel.value = newVal
    }
)
// 所属资源组查询
const newList = ref([]);
const getNewList = () => {
    api.manage
        .getBaseListApi({
            userId: userId.value
        })
        .then((res) => {
            if (res.returnCode == 200) {
                newList.value = res.data
            }
        })
}
const formRef = ref(null)
// 关闭
const handleCancel = () => {
    emit('handleCancel')
}
//新增确定
const handleUpload = async (formRef) => {
    props.resoureInfo.userId = userId.value;
    props.resoureInfo = props.resoureInfo
    await formRef.validate((valid) => {
        if (valid) {
            emit('handleAddResoure', logActive.value, props.resoureInfo)
        }
    })

};
// 修改确定
const handleUpload2 = async (formRef) => {
    props.resoureInfo.userId = userId.value;
    props.resoureInfo = props.resoureInfo
    await formRef.validate((valid) => {
        if (valid) {
            emit('saveResoure', props.resoureInfo)
        }
    })

}
// 资源配置选中
const logList = ref([
    {
        url: logoAzure,
    },
    {
        url: logoAli,
    },
    {
        url: logoBaidu,
    },
]);

</script>

<template>
    <div>
        <!-- 资源配置新增与修改 -->
        <el-dialog v-model="props.showAddResource" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div style="display: flex;">
                        <div class="tit_img">
                            <img src="@/assets/manage/addResoure.svg" alt="">
                        </div>
                        资源配置-{{ props.formType == 'add' ? '新增' : '修改' }}
                    </div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleCancel" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <div class="content">
                <el-form :model="resoureInfo" label-position="top" :rules="rules" ref="formRef">
                    <el-form-item prop="resourceType" label="资源平台">
                        <div class="form-name">
                            <div class="comtent">
                                <div v-for="(item, index) in logList" :key="index" @click="changeLog(index)"
                                    :class="logActive == index ? 'log_itemActive' : 'log_item'">
                                    <img :src=item.url alt="">
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item prop="configurationName" label="配置名称">
                        <el-input placeholder="配置名称" class="comtent" v-model="resoureInfo.configurationName"></el-input>
                    </el-form-item>
                    <el-form-item prop="functionType" label="模型类型">
                        <el-select v-model="resoureInfo.functionType" placeholder="请选择模型类型">
                            <el-option v-for="item in modelList" :key="item.value" :label="item.label"
                                :value="item.label" />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="modelType" label="功能类型">
                        <el-select v-model="resoureInfo.modelType" placeholder="请选择功能类型">
                            <el-option v-for="item in newChildren" :key="item.value" :label="item.label"
                                :value="item.label" />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="resourceGroupId" label="所属资源组">
                        <el-select v-model="resoureInfo.resourceGroupId" placeholder="请选择资源组" size="large">
                            <el-option v-for="item in newList" :key="item.id" :label="item.name" :value="item.id" />
                        </el-select>
                    </el-form-item>
                    <!-- Azure -->
                    <div v-if="logActive == 0">
                        <el-form-item v-if="['文本生成', '向量化', '文生图'].includes(azureLabel)" prop="modelName" label="模型名称">
                            <el-input placeholder="模型名称" class="comtent" v-model="resoureInfo.modelName"></el-input>
                        </el-form-item>
                        <el-form-item v-if="['文本生成', '向量化', '文本翻译', '语音转换'].includes(azureLabel)" prop="apiBase"
                            label="终结点">
                            <el-input placeholder="终结点" class="comtent" v-model="resoureInfo.apiBase"></el-input>
                        </el-form-item>
                        <el-form-item v-if="['文本生成', '向量化'].includes(azureLabel)" prop="apiVersion" label="模型版本">
                            <el-input placeholder="模型版本" class="comtent" v-model="resoureInfo.apiVersion"></el-input>
                        </el-form-item>
                        <el-form-item v-if="['文本生成', '向量化', '文生图', '光学扫描', '文本翻译', '语音转换'].includes(azureLabel)"
                            prop="apiKey" label="模型密钥">
                            <el-input placeholder="模型密钥" class="comtent" v-model="resoureInfo.apiKey"></el-input>
                        </el-form-item>
                        <el-form-item v-if="['文生图', '光学扫描'].includes(azureLabel)" prop="apiUrl" label="模型URL">
                            <el-input placeholder="模型URL" class="comtent" v-model="resoureInfo.apiUrl"></el-input>
                        </el-form-item>
                        <el-form-item v-if="['数据存储'].includes(azureLabel)" prop="connectionKey" label="连接字符串">
                            <el-input placeholder="连接字符串" class="comtent"
                                v-model="resoureInfo.connectionKey"></el-input>
                        </el-form-item>
                        <el-form-item v-if="['数据存储'].includes(azureLabel)" prop="accountKey" label="账号密钥">
                            <el-input placeholder="账号密钥" class="comtent" v-model="resoureInfo.accountKey"></el-input>
                        </el-form-item>
                        <el-form-item v-if="['数据存储'].includes(azureLabel)" prop="accountUrl" label="账号URL">
                            <el-input placeholder="账号URL" class="comtent" v-model="resoureInfo.accountUrl"></el-input>
                        </el-form-item>
                    </div>
                    <!-- 百度 -->
                    <div v-if="logActive == 2">
                        <el-form-item v-if="['文本生成', '向量化', '文生图', '数据存储'].includes(azureLabel)" prop="accessKey"
                            label="账号密钥">
                            <el-input placeholder="账号密钥" class="comtent" v-model="resoureInfo.accessKey"></el-input>
                        </el-form-item>
                        <el-form-item v-if="['文本生成', '向量化', '文生图'].includes(azureLabel)" prop="apiBase" label="终结点">
                            <el-input placeholder="终结点" class="comtent" v-model="resoureInfo.apiBase"></el-input>
                        </el-form-item>
                        <el-form-item v-if="['光学扫描', '数据存储'].includes(azureLabel)" prop="secretKey" label="密钥">
                            <el-input placeholder="密钥" class="comtent" v-model="resoureInfo.secretKey"></el-input>
                        </el-form-item>
                        <el-form-item v-if="['光学扫描'].includes(azureLabel)" prop="apiKey" label="模型密钥">
                            <el-input placeholder="模型密钥" class="comtent" v-model="resoureInfo.apiKey"></el-input>
                        </el-form-item>
                        <el-form-item v-if="['数据存储'].includes(azureLabel)" prop="endpoint" label="域名节点">
                            <el-input placeholder="域名节点" class="comtent" v-model="resoureInfo.endpoint"></el-input>
                        </el-form-item>
                    </div>
                    <!-- 阿里 -->
                    <div v-if="logActive == 1">
                        <el-form-item v-if="['文本生成', '向量化', '文生图'].includes(azureLabel)" prop="apiKey" label="模型密钥">
                            <el-input placeholder="模型密钥" class="comtent" v-model="resoureInfo.apiKey"></el-input>
                        </el-form-item>
                        <el-form-item v-if="['光学扫描'].includes(azureLabel)" prop="accessKeyId" label="accessKeyId">
                            <el-input placeholder="accessKeyId" class="comtent"
                                v-model="resoureInfo.accessKeyId"></el-input>
                        </el-form-item>
                        <el-form-item v-if="['光学扫描'].includes(azureLabel)" prop="accessKeySecret"
                            label="accessKeySecret">
                            <el-input placeholder="accessKeySecret" class="comtent"
                                v-model="resoureInfo.accessKeySecret"></el-input>
                        </el-form-item>
                        <el-form-item v-if="['数据存储'].includes(azureLabel)" prop="secretKey" label="密钥">
                            <el-input placeholder="密钥" class="comtent" v-model="resoureInfo.secretKey"></el-input>
                        </el-form-item>
                        <el-form-item v-if="['数据存储'].includes(azureLabel)" prop="accessKey" label="accessKey">
                            <el-input placeholder="accessKey" class="comtent"
                                v-model="resoureInfo.accessKey"></el-input>
                        </el-form-item>
                    </div>

                </el-form>
            </div>
            <template #footer>
                <div class="dialog-footer" v-if="props.formType == 'add'">
                    <div @click="handleCancel" class="cancel">取消</div>
                    <div @click="handleUpload(formRef)" class="confirm">新增</div>
                </div>
                <div class="dialog-footer" v-if="props.formType == 'edit'">
                    <div @click="handleCancel" class="cancel">放弃</div>
                    <div @click="handleUpload2(formRef)" class="confirm">确定</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>



<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-dialog) {
    --el-dialog-width: 520px;
    border-radius: 30px;
    height: 700px;
    padding: 30px;
    background-color: #F7F8FF;
}

:deep(.el-select__wrapper) {
    width: 450px !important;
    border-radius: 12px !important;
}

:deep(.el-select--large) {
    width: 450px !important;
}

:deep(.el-input__wrapper) {
    height: 38px !important;
    width: 428px !important;
    border-radius: 12px;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 20px;
    position: relative;

    .tit_img {
        width: 28px;
        height: 28px;
        border-radius: 8px;
        margin-right: 12px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }
    }

}

.close-icon {
    font-size: 20px;
}

.content {
    font-size: 18px;
    color: #8A8A8A;
    height: 550px;
    overflow-x: hidden;
    overflow: auto;

    .name {
        color: #3D3D3D;
        font-size: 24px;
        margin: auto 6px auto 18px;
        font-weight: 400;
    }
}


.form-name {
    font-size: 17px;
    color: #3D3D3D;

    .tit {
        margin-bottom: 8px;
        display: flex;

        div {
            margin-left: 5px;
            margin-top: 4px;
        }
    }

    .comtent {
        margin-bottom: 8px;
        display: flex;

        .log_img {
            width: 25px;
            height: 25px;
            border-radius: 8px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 8px;
            }
        }

        .log_name {
            margin-left: 8px;
            color: #8A8A8A;
            height: 25px;
            line-height: 25px;
        }

        .log_item {
            width: 132px;
            height: 42px;
            border-radius: 18px;
            padding: 10px 6px;
            background-color: #fff;
            margin-right: 10px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .log_itemActive {
            width: 132px;
            height: 42px;
            border-radius: 18px;
            padding: 10px 6px;
            background-color: #DBDFFF;
            margin-right: 10px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }

    }
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #8A8A8A;
        padding: 6px 12px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 6px 12px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
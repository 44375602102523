<template>
  <!-- 上传的文件 -->
  <!-- 文件 -->
  <div v-if="inputFileList.length != 0" class="pa">
    <img
      :src="rightReson"
      alt=""
      class="leftResonIcon"
      v-if="inputFileList.length > 5"
      @click="changeLeftReson(fileListRef)"
    />
    <div ref="fileListRef" class="listFlex">
      <div
        class="fileItem"
        v-for="(item, index) in inputFileList"
        :key="index"
        @click="openFile(item)"
      >
        <template v-if="!ifImg(item)">
          <img :src="getFileIcon(item)" alt="" class="fileImg" />
          <div class="fileRightDiv">
            <div class="fileNmae">{{ item.name || item.fileName }}</div>
            <div class="fileSize">
              {{ getFileType(item) }} {{ formatFileSize(item.fileSize) }}
            </div>
          </div>
          <img
            :src="closePng"
            @click.stop="deleteFile(item)"
            alt=""
            class="closeIcon"
          />
        </template>
        <template v-if="ifImg(item)">
          <el-popover placement="top" popper-class="fileImgPopover">
            <template #reference>
              <img :src="getImgUrl(item)" alt="" class="fileImg" />
            </template>
            <img :src="getImgUrl(item)" alt="" class="hoverImg" />
          </el-popover>
          <div class="fileRightDiv">
            <div class="fileNmae">{{ item.name }}</div>
            <div class="fileSize">
              {{ getFileType(item) }} {{ formatFileSize(item.fileSize) }}
            </div>
          </div>
          <img
            :src="closePng"
            alt=""
            class="closeIcon"
            @click.stop="deleteFile(item)"
          />
        </template>
      </div>
    </div>
    <img
      :src="rightReson"
      alt=""
      class="rightResonIcon"
      v-if="inputFileList.length > 5"
      @click="changeRightReson(fileListRef)"
    />
  </div>
  <!-- 默认全打开 -->
  <div class="tagCenter" v-if="chatType == 'chat' && tagActive == null">
    <div
      v-for="(item, index) in tagDiv"
      :key="index"
      class="tagItemDiv"
      @click="changeTag(index)"
    >
      <img class="tagItemImg" :src="item.img" alt="" />
      <span class="tagItemTitle">{{ item.label }}</span>
    </div>
  </div>
  <!-- 选择展示的 -->
  <topTitle
    v-if="chatType == 'chat' && tagActive != null"
    :tagActive="tagActive"
    :tagList="tagDiv"
    @changeBaseId="changeBaseId"
    @closeTag="closeTag"
  ></topTitle>
  <div
    :class="
      (props.rightType && props.historyDialog) || lookDialog
        ? tagActive != null
          ? 'inputDivHistoryTag'
          : 'inputDivHistory'
        : tagActive != null
        ? 'inputDivTag'
        : 'inputDiv'
    "
  >
    <el-mention
      v-model="question"
      :class="
        (props.rightType && props.historyDialog) || lookDialog
          ? 'input100'
          : 'inputDivLeft'
      "
      :options="tagDiv"
      :prefix="chatType == 'chat' ? ['@', '/'] : []"
      placeholder="继续询问针对微模型知识的任何问题"
      @select="selInput"
      @keyup.enter.stop="sendMessage"
    >
      <template #label="{ item }">
        <div class="tjDiv">
          <img class="tagItemImg" :src="item.img" alt="" />
          <div>{{ item.label }}</div>
        </div>
      </template>
    </el-mention>
    <div
      :class="
        (props.rightType && props.historyDialog) || lookDialog
          ? 'flexEnd'
          : 'flexCenter rightOptions'
      "
    >
      <!-- 对话 -->
      <div class="flexCenter">
        <uploadIndex
          type="file"
          @beforeAvatarUpload="beforeAvatarUpload"
          v-if="chatType == 'chat'"
        >
          <img :src="chatInputFile" class="chatInputFile" alt="" />
        </uploadIndex>
        <uploadIndex
          type="img"
          @beforeAvatarUpload="beforeAvatarUpload"
          v-if="chatType == 'chat'"
        >
          <img :src="chatInputImg" class="chatInputFile" alt="" />
        </uploadIndex>
        <img
          :src="seleteSvg"
          alt=""
          class="seleteImg"
          v-if="chatType == 'base'"
          @click="openBaseFile"
        />
        <el-switch v-model="isInternetOn" />
        <div class="inputText">联网搜索</div>
        <img :src="lineSvg" alt="" class="zwf" />
        <el-dropdown placement="top">
          <div class="selDiv">
            <div class="selInner">
              <img :src="sdSvg" alt="" class="sd" v-if="strategy == 2" />
              <span>{{
                strategy == 0
                  ? "简洁"
                  : strategy == 1
                  ? "详细"
                  : strategy == 2
                  ? "深度"
                  : ""
              }}</span>
              <img :src="upSvg" alt="" class="selIcon" />
            </div>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="changeMenu(0)">
                <div>简洁</div>
              </el-dropdown-item>
              <el-dropdown-item @click="changeMenu(1)">
                <div>详细</div>
              </el-dropdown-item>
              <el-dropdown-item @click="changeMenu(2)">
                <div>
                  <img :src="sdSvg" alt="" class="sd" />
                  <span>深度</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <!-- 文生图选项 -->
      <!-- <div class="flexCenter" v-else>
        <imgSelView></imgSelView>
      </div> -->
      <img :src="lineSvg" alt="" class="zwf" />
      <img
        :src="sendSvg"
        v-if="question.length == 0 || processTime != null"
        alt=""
        class="sendImg"
      />
      <img :src="sendYes" v-else class="sendImg" @click="sendMessage" />
    </div>
  </div>
</template>

<script setup>
import closePng from "@/assets/index/close.svg";
import sendYes from "@/assets/chat/sendYes.svg";
import seleteSvg from "@/assets/chat/selete.svg";
import sendSvg from "@/assets/chat/send.svg";
import lineSvg from "@/assets/chat/line.svg";
import rightReson from "@/assets/chat/rightReson.svg";
import chatInputFile from "@/assets/chat/chatInputFile.svg";
import chatInputImg from "@/assets/chat/chatInputImg.svg";
import upSvg from "@/assets/chat/up.svg";
import sdSvg from "@/assets/chat/sd.svg";
import inputBase from "@/assets/input/inputBase.svg";
import uploadIndex from "@/components/upload/index.vue";
import nImg from "@/assets/index/n.svg";
import imgSelView from "@/components/chat/imgSel.vue";
import topTitle from "@/components/chat/topTitle.vue";
import { useStore } from "vuex";
import {
  ref,
  defineProps,
  watch,
  defineEmits,
  onMounted,
  defineExpose,
} from "vue";
import { getFileIcon, formatFileSize } from "@/utils/file";
import { message } from "ant-design-vue";
import api from "@/api";
const store = useStore();
const emit = defineEmits([
  "sendMessage",
  "getDivHeight",
  "openFileView",
  "openBaseFile",
]);
const chatType = ref(store.state.chatType);
const topicItem = ref(store.state.topicItem); //点击详情很重要
const question = ref("");
const strategy = ref(0);
const isInternetOn = ref(false); //联网搜索
const regenerateFlag = ref(false); //是否为再次生成的
const conversationId = ref(false); //重新生成的id
const internetResource = ref([]); //互联网资源
const knowledgeId = ref(null); //微模型id
const userId = ref(store.state.userId);
const props = defineProps(["rightType", "historyDialog", "lookDialog"]);
const tagActive = ref(null); //tag选项
const postObj = ref({});
const inputFileList = ref([]);
const processTime = ref(null);
const fileListRef = ref(null);
const indexFileList = ref(store.state.fileList); //判断首页有没有传参
const tagDiv = ref([
  {
    label: "AI知识库",
    img: nImg,
    icon: inputBase,
    value: 0,
  },
]); //@的值
onMounted(() => {
  if (Object.keys(store.state.postObj).length != 0) {
    postObj.value = store.state.postObj;
    isInternetOn.value = postObj.value.isInternetOn;
    changeMenu(postObj.value.strategy);
    if (indexFileList.value.length != 0 && chatType.value == "chat") {
      question.value = postObj.value.message;
      beforeAvatarUpload(indexFileList.value);
    } else {
      sendMessage();
    }
  }
  if (chatType.value == "base") {
    getBaseFileIcon(topicItem.value.sessionId);
  } else {
    getChatFile();
    getTask();
  }
});
watch(
  () => store.state.chatType,
  (newValue, oldValue) => {
    chatType.value = newValue;
  }
);
watch(
  () => store.state.fileList,
  (newValue, oldValue) => {
    indexFileList.value = newValue;
  }
);
// 监听切换 然后调用接口
watch(
  () => store.state.topicItem,
  (newValue, oldValue) => {
    topicItem.value = newValue;
    if (chatType.value == "base") {
      getBaseFileIcon(topicItem.value.sessionId);
    } else {
      getChatFile();
      getTask();
    }
  }
);
const saveMessage = (item) => {
  question.value = item;
};
// 打开微模型文件筛选
const openBaseFile = () => {
  emit("openBaseFile", inputFileList.value);
};
// 获取文件类型
const getFileType = (item) => {
  let name = item.fileName || item.name;
  let fileType = name.substring(name.lastIndexOf(".") + 1);
  fileType = fileType.toLowerCase();
  return fileType;
};
// 删除文件
const deleteFile = (item) => {
  if (chatType.value == "chat") {
    api.chat
      .chatFileDelete({
        userId: userId.value,
        fileId: item.fileId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success("删除成功");
          getChatFile();
        }
      });
  } else {
    api.chat
      .baseFileDelete({
        userId: userId.value,
        sessionId: topicItem.value.sessionId,
        fileId: item.id,
        fileType: item.fileType,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success("删除成功");
          getBaseFileIcon(topicItem.value.sessionId);
        }
      });
  }
};
// 预览文件
const openFile = (item) => {
  if (
    item.fileType == "png" ||
    item.fileType == "jpg" ||
    item.fileType == "gif" ||
    item.fileType == 2 ||
    item.fileType == 1
  )
    return;
  emit("openFileView", item);
};
// 想左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
// 判断是不是图片
const ifImg = (file) => {
  if (chatType.value == "chat" && file.name) {
    const type = file.name.substring(file.name.lastIndexOf(".") + 1);
    const fileType = type.toLowerCase();
    if (fileType == "png") {
      return true;
    } else if (fileType == "jpg") {
      return true;
    } else if (fileType == "gif") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
// file转图片
const getImgUrl = (file) => {
  if (file.fileContentPath) {
    return file.fileContentPath;
  } else {
    return URL.createObjectURL(file);
  }
};
const getTask = () => {
  api.chat
    .getProcess({
      userId: userId.value,
      topicId: topicItem.value.sessionId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.data.length == 0) {
          getChatFile();
          clearInterval(processTime.value);
          processTime.value = null;
        } else {
          clearInterval(processTime.value);
          processTime.value = null;
          getChatFile();
          processTime.value = setInterval(() => {
            setTimeout(() => {
              getTask();
            }, 0);
          }, 2000);
        }
      }
    });
};
// 回显文件
const getChatFile = () => {
  api.chat
    .chatFileQuery({
      userId: userId.value,
      topicId: topicItem.value.sessionId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        inputFileList.value = res.data;
        emit("getDivHeight");
      }
    });
};
// 回显文件base
const getBaseFileIcon = (id) => {
  api.base
    .baseFileDesc({
      userId: userId.value,
      sessionId: id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        inputFileList.value = res.data;
        console.log(res.data);
        emit("getDivHeight");
      }
    });
};
defineExpose({
  saveMessage,
  getBaseFileIcon,
});
// 微模型选择
const changeBaseId = (id) => {
  knowledgeId.value = id;
};
// 发送chat
const sendMessage = () => {
  // 微模型聊天
  if (chatType.value == "base") {
    if (Object.keys(store.state.postObj).length != 0) {
      emit("sendMessage", postObj.value);
      question.value = "";
    } else {
      postObj.value = {
        userId: userId.value,
        model: null,
        message: question.value,
        libraryId: topicItem.value.knowledge_library_id,
        isInternetOn: isInternetOn.value,
        sessionId: topicItem.value.sessionId,
        strategy: strategy.value,
        regenerateFlag: 0,
        internetSource: null,
        conversationId: null,
        inValid: false,
      };
      emit("sendMessage", postObj.value);
      question.value = "";
    }
  } else {
    if (Object.keys(store.state.postObj).length != 0) {
      emit("sendMessage", postObj.value);
      question.value = "";
    } else {
      postObj.value = {
        model: null,
        message: question.value,
        topicId: topicItem.value.sessionId,
        knowledgeId: knowledgeId.value,
        userId: userId.value,
        isInternetOn: isInternetOn.value,
        regenerateFlag: 0,
        conversationId: null,
        internetResource: null,
        strategy: strategy.value,
      };
      emit("sendMessage", postObj.value);
      question.value = "";
    }
  }
};
// 切换深度
const changeMenu = (index) => {
  strategy.value = index;
};
// 关闭tag
const closeTag = () => {
  tagActive.value = null;
  emit("getDivHeight");
};
// 切换tag
const changeTag = (index) => {
  tagActive.value = index;
  emit("getDivHeight");
};
// 选择提及
const selInput = (e) => {
  tagActive.value = e.value;
  question.value = "";
  emit("getDivHeight");
};
// 上传
const beforeAvatarUpload = (fileList) => {
  api.chat
    .chatUpFile(fileList, { topicId: topicItem.value.sessionId })
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.failedFileList.length != 0) {
          res.failedFileList.forEach((item) => {
            let fileType = item.substring(item.lastIndexOf(".") + 1);
            if (fileType == "docx" || fileType == "DOCX") {
              message.warning(item + "字数过多或文件为空");
            } else {
              message.warning(item + "页数过多");
            }
          });
          getChatFile();
        } else {
          getChatFile();
        }
        getTask();
      }
    });
};
</script>

<style lang="scss" scoped>
.inputDiv {
  height: 33px;
  border: 2px solid #d9d9d9;
  border-radius: 18px;
  padding: 15px 15px 15px 30px;
  display: flex;
  align-items: center;
  position: relative;
}
.inputDivTag {
  height: 35px;
  border: 2px solid #d9d9d9;
  border-top: 0px;
  border-radius: 0px 0px 18px 18px;
  padding: 15px 15px 15px 30px;
  display: flex;
  align-items: center;
  position: relative;
}
.inputDivHistory {
  height: 84px;
  border: 2px solid #d9d9d9;
  border-radius: 18px;
  padding: 15px 15px 15px 30px;
  position: relative;
}
.inputDivHistoryTag {
  height: 86px;
  border: 2px solid #d9d9d9;
  border-radius: 0px 0px 18px 18px;
  padding: 15px 15px 15px 30px;
  position: relative;
}
.seleteImg {
  margin-right: 14px;
  width: 20px;
}
.rightOptions {
  width: 375px;
}
.inputDivLeft {
  width: calc(100% - 375px);
  margin-right: 10px;
}
.input100 {
  width: 100%;
  margin-bottom: 30px;
}
.flexEnd {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flexCenter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.tagCenter {
  display: flex;
  align-items: center;
  height: 48px;
  margin-bottom: 12px;
}
.inputText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Medium";
  margin-left: 6px;
}
.zwf {
  margin: 0px 12px;
  width: 5px;
  height: 25px;
}
.selIcon {
  margin-left: 5px;
  width: 14px;
}
.selInner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eceeff;
  height: 100%;
  border-radius: 8px;
  font-size: 18px;
  color: #0256ff;
  font-family: "Medium";
  padding: 0px 4px;
}
.selDiv {
  height: 30px;
  border: 1px solid #dbdfff;
  border-radius: 10px;
  padding: 2px;
}
.sd {
  margin-right: 5px;
  width: 16px;
}
:deep(.el-switch__core) {
  width: 38px;
  height: 22px;
  border: 1px solid #8a8a8a;
  background-color: #ffffff;
  border-radius: 16px;
}
:deep(.el-switch.is-checked .el-switch__core) {
  width: 38px;
  height: 22px;
  border: 0px;
  background-color: #3376ff;
  border-radius: 16px;
}
:deep(.el-switch__core .el-switch__action) {
  background-color: #3d3d3d;
  left: 2px;
}
:deep(.el-switch.is-checked .el-switch__core .el-switch__action) {
  background-color: #ffffff;
  left: calc(100% - 18px);
}
:deep(.el-input__wrapper) {
  border: 0px;
  box-shadow: 0px 0px 0px;
  font-size: 22px;
  font-family: "Regular";
}
.sendImg {
  width: 30px;
}

.cc {
  width: 19px;
  margin-right: 5px;
}
.fg {
  width: 25px;
  margin-right: 5px;
}

.chatInputFile {
  width: 20px;
  margin-right: 14px;
}
.tagItemDiv {
  border-radius: 16px;
  border: 2px solid #d9d9d9;
  padding: 7px 11px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  height: 30px;
  cursor: pointer;
}
.tagItemTitle {
  font-size: 20px;
  color: #8a8a8a;
  font-family: "Medium";
}
.tagItemImg {
  margin-right: 10px;
  width: 20px;
}
.tjDiv {
  width: 100px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
}
.closeIcon {
  position: absolute;
  right: 4px;
  top: 6px;
  cursor: pointer;
  width: 15px;
  z-index: 8;
}
.listFlex {
  display: flex;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
}
.fileItem {
  width: 188px;
  height: 52px;
  background-color: #f7f8ff;
  border-radius: 20px;
  padding: 13px 20px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  position: relative;
  cursor: pointer;
}
.fileImg {
  width: 40px;
}
.fileRightDiv {
  margin-left: 13px;
  width: calc(100% - 41px);
  height: 51px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.fileNmae {
  font-size: 18px;
  font-family: "Medium";
  color: #3d3d3d;
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileSize {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
}
.pa {
  position: relative;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translate(25px, -50%);
    width: 30px;
    z-index: 10;
    background: linear-gradient(180deg, #f7f8ff 0%, #fff 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0), #ffffff);
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: 35px;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
    background: linear-gradient(180deg, #f7f8ff 0%, #fff 100%);
    background: linear-gradient(to left, rgba(0, 0, 0, 0), #ffffff);
  }
}
.hoverImg {
  max-width: 300px;
  max-height: 300px;
}
</style>

<script setup>
import cardBox from './components/card.vue'
import Dialog from './components/dialog.vue'
import { ref, reactive } from 'vue'
import { useI18n } from "vue-i18n"
const { t } = useI18n()
// 导航条切换
const activeTab = ref("unit");
const tabs = [
    { name: 'dashboard', label: '成本概览', contentKey: 'User' },
    { name: 'unit', label:'用户用量', contentKey: 'Department' },
];
function setActiveTab(name) {
    activeTab.value = name;
}
// 下拉框
const value = ref('')
// 免责声明弹窗
const show = ref(false)
// 关闭弹窗
const handleCancel = () => {
    show.value = false
    // 调用拒绝进入仪表盘接口
}
// 确认弹窗
const handleConfirm = () => {
    // 调用同意进入仪表盘接口
    show.value = false
}
// 显示弹窗
const toDashboard = () => {
    show.value = true
}
const showPancel = ref(true);
// 用量表格
const useTable = ref([]);
// 分页 
const pageSize = ref(10);
const currentPage = ref(1);
const totalItems = ref(0)
// 每页显示条数
const updatePageSize = (newSize) => {
    pageSize.value = newSize;
}
// 更新当前页码
const updateCurrentPage = (newPage) => {
    currentPage.value = newPage;
};
</script>

<template>
    <div v-if="show">
        <Dialog :show="show" @handleCancel="handleCancel" @handleConfirm="handleConfirm"></Dialog>
    </div>
    <div class="container" v-else>
        <div style="position: relative;display: flex;justify-content: space-between;">
            <div class="tabs">
                <div class="tab" v-for="(tab, index) in tabs" :key="index" :class="{ active: activeTab === tab.name }"
                    @click="setActiveTab(tab.name)">
                    {{tab.label}}
                </div>
            </div>
            <div class="tip">
                <div class="tipSpan">*</div>
                <div>以下所有数据源自资源平台的账单数据，仅供参考，并不决定本平台的真实用量；以下账单均可能存在延迟，各资源API的意外泄漏也可能导致成本的增加，请您知悉。</div>
            </div>
        </div>
        <div class="flexDiv" v-if="showPancel">
            <div v-if="activeTab === 'dashboard'">
                <div class="menuBox">
                    <div style="display: flex;">
                        <!-- 资源平台 -->
                        <div class="date">
                            <div class=" ellipsis">{{ $t('statistics.resource') }}</div>
                            <div class="searchDiv">
                                <!-- 下拉框 -->
                                <el-dropdown trigger="click">
                                    <span class="searchSpan">
                                        Dropdown<el-icon class="searchIcon"><arrow-down /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item>Action 1</el-dropdown-item>
                                            <el-dropdown-item>Action 2</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                            </div>
                        </div>
                        <!-- 资源组 -->
                        <div class="date">
                            <div class=" ellipsis">资源组</div>
                            <div class="searchDiv">
                                <!-- 下拉框 -->
                                <el-dropdown trigger="click">
                                    <span class="searchSpan">
                                        Dropdown<el-icon class="searchIcon"><arrow-down /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item>Action 1</el-dropdown-item>
                                            <el-dropdown-item>Action 2</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                            </div>
                        </div>
                        <!-- 统计周期 -->
                        <div class="date">
                            <div class=" ellipsis">统计周期</div>
                            <div class="searchDiv">
                                <!-- 下拉框 -->
                                <el-dropdown trigger="click">
                                    <span class="searchSpan">
                                        Dropdown<el-icon class="searchIcon"><arrow-down /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item>Action 1</el-dropdown-item>
                                            <el-dropdown-item>Action 2</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                            </div>
                        </div>
                        <!-- 统计粒度 -->
                        <div class="date">
                            <div class=" ellipsis">统计粒度</div>
                            <div class="searchDiv">
                                <!-- 下拉框 -->
                                <el-dropdown trigger="click">
                                    <span class="searchSpan">
                                        Dropdown<el-icon class="searchIcon"><arrow-down /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item>Action 1</el-dropdown-item>
                                            <el-dropdown-item>Action 2</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="exportBtn">导出成本统计</div>
                </div>
                <!-- 统计卡片 -->
                <cardBox></cardBox>
            </div>
            <div v-if="activeTab === 'unit'">
                <div class="menuBox">
                    <div style="display: flex;">
                        <!-- 资源平台 -->
                        <div class="date">
                            <div class=" ellipsis">{{ $t('statistics.resource') }}</div>
                            <div class="searchDiv">
                                <el-dropdown trigger="click">
                                    <span class="searchSpan">
                                        Dropdown<el-icon class="searchIcon"><arrow-down /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item>Action 1</el-dropdown-item>
                                            <el-dropdown-item>Action 2</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                            </div>
                        </div>
                        <!-- 用户 -->
                        <div class="User">
                            <div class="searchDiv">
                                <el-dropdown trigger="click">
                                    <div class="searchSpan">
                                        <div class="text">用户</div>
                                        <el-icon class="searchIcon"><arrow-down /></el-icon>
                                    </div>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item>Action 1</el-dropdown-item>
                                            <el-dropdown-item>Action 2</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                            </div>
                            <el-input placeholder="请输入提问者用户名"></el-input>
                        </div>
                        <!-- 起止时间 -->
                        <div class="User">
                            <div class="searchDiv">
                                <el-dropdown trigger="click">
                                    <div class="searchSpan">
                                        <div class="text">起止时间</div>
                                        <el-icon class="searchIcon"><arrow-down /></el-icon>
                                    </div>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item>Action 1</el-dropdown-item>
                                            <el-dropdown-item>Action 2</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                            </div>
                            <el-date-picker v-model="value" type="daterange" range-separator="至"
                                start-placeholder="开始时间" end-placeholder="结束时间" />
                        </div>
                    </div>
                    <div class="exportBtn">导出用量统计</div>
                </div>
                <div class="table">
                    <el-table :data="useTable" class="tableHeight">
                        <el-table-column prop="userName" :label="$t('statistics.member')" />
                        <el-table-column prop="isActive" :label="$t('statistics.part')" />
                        <el-table-column prop="email" :label="$t('statistics.qToken')" />
                        <el-table-column prop="departmentName" :label="$t('statistics.aToken')" />
                        <el-table-column prop="address" :label="$t('statistics.tipToken')" />
                        <el-table-column prop="phone" :label="$t('statistics.textToken')" />
                    </el-table>
                    <Pagination :background="true" :currentPage="currentPage" :pageSize="pageSize" :total="totalItems"
                        @size-change="updatePageSize" @current-change="updateCurrentPage" />
                </div>
            </div>
        </div>
        <div v-else class="blankDiv">
            <div class="blank">
                <img src="@/assets/statistics/blank.svg" alt="">
            </div>
            <div class="blankText" @click="toDashboard">访问仪表盘</div>
        </div>
    </div>

</template>



<style lang="scss" scoped>
:deep(.el-tabs__item) {
    /* 样式规则 */
    font-size: 17px !important;
}
:deep(.el-input__wrapper) {
    border-radius: 0 16px 16px 0;
}

:deep(.el-input) {
    --el-input-height: 52px !important;
}
:deep(.el-date-editor.el-input__wrapper){
    height: 50px !important;
}
:deep(.el-table th.el-table__cell){
  background-color: #f7f8ff;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.tabs {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .tab {
        padding: 10px 2px;
        margin-right: 20px;
        border-bottom: 3px solid #ccc;
        font-weight: 600;
        font-size: 20px;
        color: #303133;
        margin-bottom: 16px;

        &.active {
            color: #264095;
            border-bottom: 3px solid #264095;
            font-weight: bold;
        }
    }

    .dashboard {
        margin-top: 5px;
    }

    .unit {
        margin-top: 5px;
        flex: 1;
    }
}

.tip {
    margin-top: 14px;
    height: 28px;
    font-size: 15px;
    color: #8A8A8A;
    display: flex;

    .tipSpan {
        color: #F02D63;
        margin-right: 6px;
        margin-top: 4px;
    }
}

.flexDiv {
    height: calc(100% - 66px);
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.blankDiv {
    width: 100%;
    text-align: center;

    .blank {
        width: 300px;
        height: 300px;
        margin: 180px auto 24px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .blankText {
        width: 140px;
        height: 48px;
        line-height: 48px;
        border-radius: 16px;
        margin: 0 auto;
        font-size: 18px;
        color: #fff;
        background-color: #3376FF;
        cursor: pointer;
    }
}

.tableHeight {
    height: 550px;
}

.nav {
    position: relative;
    margin-top: 10px;
    margin-left: 6px;
}

.container {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 30px;
    min-width: 1430px;
    min-height: 711px;
    width: 100%;
    height: 100%;
}

.exportBtn {
    width: 148px;
    height: 52px;
    line-height: 52px;
    border-radius: 16px;
    font-size: 17px;
    color: #3376FF;
    border: 2px solid #EDEEF6;
    text-align: center;
}

.table {
    width: 1440px;
    background-color: pink;
    margin-top: 20px;
}

.menuBox {
    position: relative;
    display: flex;
    justify-content: space-between;

    .User {
        height: 52px;
        line-height: 52px;
        border: 1px solid #edeef6;
        border-radius: 16px;
        color: #3D3D3D;
        display: flex;
        font-size: 17px;
        padding: 0 0 0 10px;
        margin-right: 16px;
        background-color: #EDEEF6;

        .searchDiv {
            margin-left: 6px;
            color: #3d3d3d;

            .searchSpan {
                color: #3D3D3D;
                font-size: 16px;
                padding: 0 10px;
                display: flex;
                margin-top: 18px;

                .text {
                    min-width: 34px;
                    white-space: nowrap;
                }
            }

            .searchIcon {
                margin-left: 3px;
            }
        }
    }

    .date {
        height: 52px;
        line-height: 52px;
        border: 2px solid #edeef6;
        border-radius: 16px;
        font-size: 18px;
        color: #8a8a8a;
        display: flex;
        font-size: 17px;
        text-align: center;
        padding: 0 0 0 10px;
        margin-right: 16px;

        div:first-child {
            margin-right: 20px;
            color: #8a8a8a;
        }

        .ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }

        .searchDiv {
            margin-left: 10px;
            color: #3d3d3d;

            .searchSpan {
                color: #3D3D3D;
                font-size: 16px;
                width: 100%;
                display: flex;
                margin-top: 18px;
            }

            .searchIcon {
                margin-left: 10px;
            }
        }

    }
}

.el-tabs__nav-scroll {
    border-bottom: none !important;
}

.custom-select {
    border: none !important;
    outline: none;
    box-shadow: none;
}
</style>
<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import { useStore } from "vuex";
const store = useStore();
const userId = ref(store.state.userId);
const props = defineProps({
    show: Boolean,
});
const emit = defineEmits(['handleClose','handleSave']);
const form = ref({
    groupName: ''
});
const formRef = ref(null)
const rules = ref({
    groupName: [
        {
            required: true,
            message: '请输入用户名',
            trigger: "blur",
        }
    ],
})
const handleClose = () => {
    emit('handleClose');
}
const handleSave = async () => {
    form.userId = userId.value;
    await formRef.value.validate((valid) => {
        if (valid) {
            emit('handleSave', form.value);
        }
    });
    
};
</script>
<template>
    <div>
        <el-dialog v-model="props.show" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div style="display: flex;"> <img class="tit_img" src="@/assets/manage/groupItem.svg" alt="">
                        <div>新增资源组</div>
                    </div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleClose" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <el-form ref="formRef" :model="form" :rules="rules" label-position="top">
                <el-form-item prop="groupName" label="资源组名称">
                    <el-input v-model="form.groupName" placeholder="资源组名称"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <div @click="handleClose" class="cancel">放弃</div>
                    <div @click="handleSave(formRef)" class="confirm">创建</div>
                </div>
            </template>
        </el-dialog>
    </div>

</template>

<style scoped lang="scss">
:deep(.el-dialog) {
    --el-dialog-width: 460px;
    border-radius: 30px;
    padding: 20px;
}
:deep(.el-input__wrapper) {
    height: 38px !important;
    width: 380px !important;
    border-radius: 12px;
}
.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 18px;
    position: relative;

    .tit_img {
        display: inline-block;
        width: 25px;
        height: 25px;
        margin-right: 6px;
    }

}
.close-icon {
    font-size: 20px;
}
.form-name {
    font-size: 16px;
    color: #3D3D3D;
    .tit {
        font-weight: 600;
    }
}



.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #F02D63;
        padding: 8px 20px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 8px 20px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
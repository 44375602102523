<script setup>
import { ref, reactive, onMounted, watch } from "vue"
import { message } from 'ant-design-vue';
import RoleDetail from '../components/roleDetail.vue'
import RoleAdd from '../components/roleAdd.vue'
import { useStore } from "vuex";
import api from "@/api/index";
import { ElMessageBox } from 'element-plus'
const store = useStore();
const userId = ref(store.state.userId);
onMounted(() => {
  getList()
});
// 分页
const currentPage = ref(1);
const pageSize = ref(20);
const status = ref('用户名')
const search = ref(
  [
    {
      value: 'userName',
      label: '用户名'
    },
    {
      value: 'roleName',
      label: '角色名'
    }
  ]
)
// 请求参数
const valueInput = ref('');
const listForm = ref({
  userId: userId.value,
  pageId: currentPage.value,
  perPage: pageSize.value,
  userName: '',
  roleName: '',
});
watch(
  () => valueInput.value,
  (newVal) => {
    listForm.value.userName = newVal;
    getList()
  }
)
watch(
  () => status.value,
  (newVal) => {
    if (newVal == 'userName') {
      listForm.value.roleName = ''
      listForm.value.userName = valueInput.value
    } else {
      listForm.value.userName = ''
      listForm.value.roleName = valueInput.value
    }
    getList()
  }
)
watch(
  () => currentPage.value,
  (newVal) => {
    currentPage.value = newVal;
    // 调用接口

  }
)
const totalItems = ref(0);
const tableDepartment = ref([])
// 角色table查询
const getList = () => {
  api.manage
    .getRoleListApi(listForm.value)
    .then((res) => {
      if (res.returnCode == 200) {
        totalItems.value = res.totalDataNum
        tableDepartment.value = res.data;
      }
    })
};
const updatePageSize = (newSize) => {
  pageSize.value = newSize;
  listForm.value.perPage = newSize;
  getList()
};
const updateCurrentPage = (newPage) => {
  currentPage.value = newPage;
  listForm.value.pageId = newPage;
  getList()
};
// 多选
const ids = ref([]);
const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  ids.value = val.map(item => item.id);
  multipleSelection.value = val
};
// 批量禁用
const removeGroup = () => {
  if (multipleSelection.value.length == 0) {
    message.error('请选择要禁用的角色');
    return;
  }

  ElMessageBox.confirm('确定要禁用这些角色吗？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(() => {
      api.manage
        .roleDisableApi({
          roleIds: ids.value,
          isActivate: 0,
        })
        .then((res) => {
          if (res.returnCode == 200) {
            message.success('禁用成功');
            getList()
          } else {
            message.error('禁用失败');
          }
        })
    })
}
// 导出角色详情
const exportRole = () => {
  const imageUrl = "/openai/user-management/roles/export";
  const a = document.createElement("a");
  a.href = imageUrl;
  a.download = "角色导出.excel";
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
// 打开详情
const openShow = ref(false);
const roleInfo = ref({});
const handleDetail = (row) => {
  openShow.value = true;
  roleInfo.value = row;
};
// 关闭详情
const handleCancel = () => {
  openShow.value = false;
};
const formType = ref('add');
// 打开角色编辑
const showEdit = ref(false);
const handleAdd = () => {
  openShow.value = false;
  showEdit.value = true;
  formType.value = 'edit';
  roleInfo.value = roleInfo.value;

};
// 关闭角色编辑
const handleClose = () => {
  showEdit.value = false;
};
// 提交角色编辑
const handleSubmit = (form) => {
  showEdit.value = false;
  // 调用提交按钮
  api.manage
    .roleEditApi(form)
    .then((res) => {
      if (res.returnCode === 200) {
        message.success('修改成功');
        getList()
      }
    })
};
// 打开角色新增
const addUser1 = () => {
  formType.value = 'add';
  showEdit.value = true;
  roleInfo.value = {};
}
// 角色新增
const addSubmit = (form) => {
  showEdit.value = false;
  // 调用提交按钮
  api.manage
    .roleAddApi(form)
    .then((res) => {
      if (res.returnCode === 200) {
        message.success('新增成功');
        getList()
      }
    })
}
// 操作工具栏
const changeMore = (key, data) => {
  if (key === 1) {
    formType.value = 'edit';
    roleInfo.value = data
    showEdit.value = true;
  } else if (key === 2) {
    // 禁用角色
    api.manage
      .roleDisableApi({
        roleIds: [data.id],
        isActivate: 0
      })
      .then((res) => {
        if (res.returnCode === 200) {
          message.success('禁用成功');
        }
      })
  } else if (key === 3) {
    if (data.id == 1 || data.id == 4) {
      message.error('超级管理员和系统管理员不能被删除');
      return;
    }
    // 删除角色
    api.manage
      .roleDeleteApi({
        userId: userId.value,
        roleIds: [data.id],
      })
      .then((res) => {
        if (res.returnCode === 200) {
          message.success('删除成功');
        }
      })
  }
};
const userIdList = ref([]);
const ids2 = ref([]);
const roleId = ref(null)
const showAddRole = ref(false);
const addDepartment = (row) => {  
  roleId.value = row.id;
  showAddRole.value = true;
};
// 确定增加角色用户
const saveUser = (arr) => {
  ids2.value = arr.map((item) => item.userId);
  showAddRole.value = false;
  api.manage
    .roleAddUserApi({
      userId: userId.value,
      userIdList: ids2.value,
      roleId: roleId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success('用户添加成功');
        getList()
      }
    })
};
</script>

<template>
  <div class="main">
    <div class="menuBox">
      <div style="display: flex;">
        <!-- 用户 -->
        <div class="User">
          <div class="searchDiv">
            <el-select v-model="status">
              <el-option v-for="(item, index) in search" :key="index" :label="item.label" :value="item.value" />
            </el-select>
            <el-input v-model="valueInput" placeholder="请输入搜索信息"></el-input>
          </div>
        </div>
      </div>
      <!-- 功能区 -->
      <div style="display: flex;" >
        <div class="toolBox" @click="exportRole">
          导出角色详情
        </div>
        <div class="toolBox" style="color: #F02D63;" @click="removeGroup">批量禁用</div>
        <div class="toolBox addUser" @click="addUser1">
          创建角色
        </div>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableDepartment" class="tableHeight" @selection-change="handleSelectionChange">
        <el-table-column type="selection" />
        <el-table-column fixed prop="description" label="角色名称" />
        <el-table-column fixed prop="userInfoList" label="成员">
          <template #default="scope">
            <AvatarGroupTwo :avatars=scope.row.userInfoList :number="5"></AvatarGroupTwo>
          </template>
        </el-table-column>
        <el-table-column fixed prop="roleScopes" label="作用域">
          <template #default="scope">
            <div v-for="(item, index) in scope.row.roleScopes" :key="index">
              <span>{{ item.departmentName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed prop="rolesNames" label="权限范围">
          <template #default="scope">
            <AvatarName :avatars=scope.row.rolesNames :number="2"></AvatarName>
          </template>
        </el-table-column>
        <el-table-column :label="$t('manage.userForm.operation')">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="handleDetail(scope.row)">
              详情
            </el-button>
            <el-button link type="primary" size="small" @click="addDepartment(scope.row)">
              添加成员
            </el-button>
            <el-dropdown @command="changeMore($event, scope.row)" trigger="click" class="changeMore">
              <div class="moreIcon">
                <img src="@/assets/base/more.svg" alt="" />
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :command="1">编辑角色</el-dropdown-item>
                  <el-dropdown-item :command="2" style="color: #F02D63;">禁用角色</el-dropdown-item>
                  <el-dropdown-item :command="3" style="color: #F02D63;">删除角色</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :currentPage="currentPage" :pageSize="pageSize" :total="totalItems" @size-change="updatePageSize"
        @current-change="updateCurrentPage" class="Pagination" />
    </div>
  </div>
  <!-- 角色详情 -->
  <RoleDetail :show="openShow" :roleInfo="roleInfo" @handleCancel="handleCancel" @handleAdd="handleAdd"></RoleDetail>
  <!-- 角色编辑 -->
  <RoleAdd :showEdit="showEdit" :formType="formType" :roleInfo="roleInfo" @addSubmit="addSubmit"
    @handleClose="handleClose" @handleSubmit="handleSubmit"></RoleAdd>
  <!-- 给角色增加用户 -->
  <addUser :show="showAddRole" @closeUser="showAddRole = false" @saveUser="saveUser" :userList="userIdList">
  </addUser>
</template>



<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.menuBox {
  :deep(.el-input__wrapper) {
    border-radius: 0 16px 16px 0;
  }

  :deep(.el-input) {
    --el-input-height: 42px !important;
  }
}

.searchDiv {
  :deep(.el-select) {
    width: 80px;
  }

  :deep(.el-select__wrapper) {
    width: 80px;
    padding: 0 6px;
    height: 42px !important;
    border-radius: 16px 0 0 16px !important;
    background-color: #EDEEF6;
  }
}

:deep(.el-date-editor.el-input__wrapper) {
  height: 50px !important;
}

:deep(.el-table th.el-table__cell) {
  background-color: #f7f8ff;
}

.main {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
}

.Pagination {
  margin-top: 10px;
}

.menuBox {
  position: relative;
  display: flex;
  justify-content: space-between;

  .User {
    height: 42px;
    line-height: 42px;
    border-radius: 16px;
    color: #3D3D3D;
    font-size: 17px;


    .searchDiv {
      display: flex;
      color: #3d3d3d;

      .searchSpan {
        color: #3D3D3D;
        font-size: 16px;
        display: flex;

        .text {
          min-width: 34px;
          white-space: nowrap;
        }
      }

      .searchIcon {
        margin-left: 3px;
      }
    }
  }

  .date {
    height: 42px;
    line-height: 42px;
    border: 2px solid #edeef6;
    border-radius: 16px;
    font-size: 18px;
    color: #8a8a8a;
    display: flex;
    font-size: 17px;
    text-align: center;
    padding: 0 0 0 10px;
    margin-right: 16px;

    div:first-child {
      margin-right: 20px;
      color: #8a8a8a;
    }

    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .searchDiv {
      // margin-left: 10px;
      color: #3d3d3d;

      .searchSpan {
        color: #3D3D3D;
        font-size: 16px;
        width: 100%;
        display: flex;
        margin-top: 18px;
      }

      .searchIcon {
        margin-left: 10px;
      }
    }

  }
}

.table {
  margin-top: 20px;
}

.toolBox {
  border: #edeef6 solid 2px;
  border-radius: 16px;
  font-size: 16px;
  padding: 0 10px;
  height: 42px;
  line-height: 40px;
  color: #8A8A8A;
  margin-right: 10px;
  cursor: pointer;
}

.tableHeight {
  height: 640px;
}

.addUser {
  background-color: #0256FF;
  color: #fff;
  margin-right: 0;
}

.changeMore {
  margin-left: 5px;

  .moreIcon {
    width: 24px;
    height: 24px;

    img {
      display: block;
      width: 16px;
      height: 16px;
      margin: 4px auto;
    }
  }
}

.exportBtn {
  width: 148px;
  height: 42px;
  line-height: 42px;
  border-radius: 16px;
  font-size: 17px;
  color: #3376FF;
  border: 2px solid #EDEEF6;
  text-align: center;
}
</style>
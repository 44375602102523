<script setup>
import { getRandomColor } from "@/utils/utils";
import { ref, defineProps, onMounted, watch } from "vue";
const emit = defineEmits(['handleCancel', 'handleAdd']);
const props = defineProps({
    show: Boolean,
    dataDetail: Object
});
watch(() => props.dataDetail, (newVal, oldValue) => {
    if (newVal !== oldValue) {
        getUserDetail(newVal)
    }
})
onMounted(() => {
    // 调用用户详情接口---暂时用用户编辑接口
    getUserDetail(props.dataDetail)
})
const form = ref({
    roleName: '12',

})
// 部门详情
const getUserDetail = (data) => {
    form.value = data
}
const title = ref('部门详情');


// 关闭
const handleCancel = () => {
    emit('handleCancel')
}
//同意
const handleConfirm = () => {
    emit('handleAdd')
};
</script>

<template>
    <div>
        <!-- 部门详情 -->
        <el-dialog v-model="props.show" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>{{ title }}</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleCancel" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <div class="content">
                <div style="display: flex; justify-content: space-between;">
                    <div style="display: flex;">
                        <div>
                            <el-avatar class="avatar"
                                src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" /></div>
                        <div class="name">{{ dataDetail.departmentName }}</div>
                    </div>
                </div>
                <el-form :model="form" class="form">
                    <el-form-item prop="name">
                        <div class="form-name">
                            <p class="tit">部门名称</p>
                            <p class="comtent">{{ dataDetail.departmentName }}</p>
                        </div>
                    </el-form-item>
                    <el-form-item prop="email">
                        <div class="form-name">
                            <p class="tit">上级部门</p>
                            <div class="el-tag">
                                <div class="tag-img"><img src="@/assets/manage/qincheng.svg" alt=""></div>
                                <div>{{ dataDetail.parenrtName }}</div>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item prop="email">
                        <div class="form-name">
                            <p class="tit">部门负责人</p>
                            <div style="display: flex;" >
                                <div class="avatar1" :style="'background-color:' + getRandomColor()">{{ dataDetail.manager.slice(0, 1) }}</div>
                                <div class="comtent">{{ dataDetail.manager }}</div>
                            </div>
                        </div>
                    </el-form-item>
                    <!-- <el-form-item prop="phone">
                        <div class="form-name">
                            <p class="tit">部门知识库</p>
                            <p class="comtent">{{ form.phone == null ? '暂无信息' : form.phone }}</p>
                        </div>
                    </el-form-item> -->
                </el-form>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <div @click="handleCancel" class="cancel">关闭详情</div>
                    <div @click="handleConfirm" class="confirm">编辑部门</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>



<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-dialog) {
    --el-dialog-width: 600px;
    border-radius: 30px;
    // height: 800px;
    padding: 30px;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    font-size: 24px;
    position: relative;
}

.close-icon {
    font-size: 20px;
}
.avatar1 {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    line-height: 30px;
    font-family: "Semibold";
    margin-right: 10px;
}
.content {
    font-size: 18px;
    color: #8A8A8A;

    .name {
        color: #3D3D3D;
        font-size: 24px;
        margin: auto 6px auto 18px;
        font-weight: 500;
    }

    .avatar {
        height: 50px;
        width: 50px;
    }

    .tag {
        display: flex;
        justify-content: space-between;
        background-color: #B8E6CD;
        color: #14AE5C;
        padding: 0px 6px;
        height: 30px;
        line-height: 30px;
        border-radius: 6px;
        margin: auto 0;

        .tag-icon {
            width: 18px;
            height: 18px;
            display: block;
            margin-top: 3px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .tag-text {
            margin-left: 4px;
        }
    }

    .tag_cuo {
        display: flex;
        justify-content: space-between;
        background-color: #FBD4DF;
        color: #F02D63;
        padding: 0px 6px;
        height: 30px;
        line-height: 30px;
        border-radius: 6px;
        margin: auto 0;

        .tag-icon {
            width: 18px;
            height: 18px;
            display: block;
            margin-top: 3px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .tag-text {
            margin-left: 4px;
        }
    }

    .reset {
        color: #0256FF;
        margin: auto 10px
    }
}

.form {
    margin-top: 10px;
}

.form-name {
    font-size: 18px;
    color: #3D3D3D;

    .el-tag {
        display: flex;
        padding: 2px 33px 2px 2px;
        height: 35px;
        line-height: 35px;
        border-radius: 30px;
        font-size: 18px;
        margin-top: 12px;

        .tag-img {
            width: 30px;
            height: 30px;
            margin-right: 6px;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    }

    .tit {
        margin-bottom: 10px;
        font-weight: 600;
    }

    .comtent {
        margin-bottom: 12px;
    }
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #8A8A8A;
        padding: 6px 12px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 6px 12px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
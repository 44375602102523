<template>
  <div>
    <!-- 文件信息 -->
    <div class="topMDiv">
      <div class="topRightDiv59" v-if="chatType == 'base'">
        <div class="flexCenter">
          <div class="topTitleName" v-if="topicItem.knowledge_name">
            <el-tooltip :content="topicItem.knowledge_name" effect="light">
              {{ topicItem.knowledge_name }}
            </el-tooltip>
          </div>
          <img
            :src="arrow"
            alt=""
            class="wid24"
            v-if="topicItem.knowledge_name"
          />
          <div class="topFileName">
            <el-tooltip :content="fileItem.fileName" effect="light">
              {{ fileItem.fileName }}
            </el-tooltip>
          </div>
        </div>
        <div class="flexCenter" v-if="Object.keys(fileDescObj).length != 0">
          <div class="topFontItem">上传人:</div>
          <div class="topTx" :style="'background-color:' + getRandomColor()">
            {{ fileDescObj.userName.slice(0, 1) }}
          </div>
          <el-tooltip :content="fileDescObj.userName" effect="light">
            <div class="topFontItem nameMax">{{ fileDescObj.userName }}</div>
          </el-tooltip>
          <img :src="topTime" alt="" class="wid25 marGin12" />
          <div class="topFontItem">
            {{ fileDescObj.createTime ? getTime(fileDescObj.createTime) : "" }}
          </div>
          <img
            :src="topYes"
            alt=""
            class="wid25 marGin12"
            v-if="fileDescObj.expireFlag == 0"
          />
          <div class="topFontItem">
            {{ fileDescObj.expireFlag == 0 ? "长期" : "" }}
          </div>
        </div>
      </div>
      <div class="topRightDivCenter" v-else>
        <div class="topFileName">
          <el-tooltip :content="fileItem.fileName" effect="light">
            {{ fileItem.fileName }}
          </el-tooltip>
        </div>
      </div>
      <div class="topRightBtn" v-if="chatType == 'base'">
        <el-dropdown @command="changeMore" v-if="props.type == 'base'">
          <div class="moreDiv">
            <img :src="hMore" alt="" class="hMore" />
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :command="1">下载文件</el-dropdown-item>
              <el-dropdown-item :command="2">删除文件</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <div class="topLook" @click="lookText">
          <img :src="topResh" alt="" class="wid25 marGin6" />
          <div class="topFontItem">
            {{ textType ? "查看文件内容" : "查看切片内容" }}
          </div>
        </div>
        <el-dropdown @command="changeSel">
          <div class="topLook">
            <div class="topFonItemF">设为过期</div>
            <img :src="topSel" alt="" class="topSel marGin9" />
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                :command="1"
                v-if="fileDescObj.expireDate == null"
                >设为过期</el-dropdown-item
              >
              <el-dropdown-item :command="3" v-else>取消设置</el-dropdown-item>
              <el-dropdown-item
                :command="2"
                v-if="fileDescObj.importantFlag == 1"
                >取消验证</el-dropdown-item
              >
              <el-dropdown-item
                :command="4"
                v-if="
                  fileDescObj.importantFlag == 0 ||
                  fileDescObj.importantFlag == null
                "
                >验证</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <img
          :src="closeFileView"
          alt=""
          class="closeFileView"
          @click="closeView"
        />
      </div>
      <div class="topRightBtn" v-else>
        <img
          :src="closeFileView"
          alt=""
          class="closeFileView"
          @click="closeView"
        />
      </div>
    </div>
    <!-- 文件展示 -->
    <div
      class="rightFileChunk"
      v-if="
        props.chunkDialog == true &&
        Object.keys(props.fileItem).length != 0 &&
        !textType
      "
    >
      <chunkView :fileItem="props.fileItem"></chunkView>
    </div>
    <!-- 文字展示 -->
    <div
      class="rightFileChunk"
      v-if="
        props.chunkDialog == true &&
        Object.keys(props.fileItem).length != 0 &&
        textType
      "
    >
      <div
        v-for="(item, index) in textInner"
        :key="index"
        class="textMessageItem"
      >
        <messageView :message="item.chunk"></messageView>
      </div>
    </div>
    <!-- 时间展示 -->
    <timeView
      v-if="timeDialog"
      @closeTime="closeTime"
      @saveTime="saveTime"
      :loading="timeLoading"
    ></timeView>
  </div>
</template>

<script setup>
import arrow from "@/assets/base/arrow.svg";
import topTime from "@/assets/base/topTime.svg";
import topYes from "@/assets/base/topYes.svg";
import topResh from "@/assets/base/topResh.svg";
import topSel from "@/assets/base/topSel.svg";
import closeFileView from "@/assets/base/closeFileView.svg";
import hMore from "@/assets/base/hMore.svg";
import chunkView from "@/components/chunk/index.vue";
import messageView from "@/components/message/index.vue";
import { ref, defineProps, defineEmits, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { getTime } from "@/utils/utils";
import { getRandomColor } from "@/utils/utils";
import api from "@/api";
import { message } from "ant-design-vue";
import timeView from "@/views/base/time.vue";
const store = useStore();
const emit = defineEmits(["closeFileView", "downFile", "deleteFile"]);
const props = defineProps(["fileItem", "chunkDialog", "type"]);
const topicItem = ref(store.state.topicItem);
const textType = ref(false);
const userId = ref(store.state.userId);
const chatType = ref(store.state.chatType);
const textInner = ref("");
const timeDialog = ref(false); //设置过期时间
const fileDescObj = ref({}); //文件描述
const timeLoading = ref(false);
const timeType = ref(null);
onMounted(() => {
  getText();
  getFileDesc();
});
watch(
  () => store.state.chatType,
  (newValue, oldValue) => {
    chatType.value = newValue;
  }
);
watch(
  () => props.fileItem,
  (newValue, oldValue) => {
    textType.value = false;
    textInner.value = "";
    timeDialog.value = false;
    fileDescObj.value = {};
    timeLoading.value = false;
    getFileDesc();
    getText();
  }
);
// 确定设置过期时间
const saveTime = (time) => {
  timeLoading.value = true;
  api.base[timeType.value == 1 ? "fileExpire" : "importantSet"]({
    userId: userId.value,
    fileId: props.fileItem.fileId || props.fileItem.id,
    expireTime: time,
    libraryId:
      topicItem.value.knowledge_library_id || topicItem.value.libraryId,
    operateType: "add",
  })
    .then((res) => {
      timeLoading.value = false;
      if (res.returnCode == 200) {
        timeDialog.value = false;
        message.success("设置成功");
        getFileDesc();
      }
    })
    .catch((err) => {
      timeLoading.value = false;
    });
};
const getFileDesc = () => {
  if (chatType.value == "base") {
    api.base
      .fileDesc({
        userId: userId.value,
        libraryId:
          topicItem.value.knowledge_library_id || topicItem.value.libraryId,
        fileId: props.fileItem.fileId || props.fileItem.id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          fileDescObj.value = res.data;
          console.log(res.data);
        }
      });
  }
};
const closeView = () => {
  emit("closeFileView");
};
const changeMore = (e) => {
  if (e == 1) {
    emit("downFile", props.fileItem);
  }
  if (e == 2) {
    emit("deleteFile");
  }
};
const changeSel = (e) => {
  if (e == 1) {
    timeType.value = 1;
    timeDialog.value = true;
  }
  if (e == 2) {
    api.base
      .importantSet({
        userId: userId.value,
        fileId: props.fileItem.fileId || props.fileItem.id,
        expireTime: null,
        libraryId:
          topicItem.value.knowledge_library_id || topicItem.value.libraryId,
        operateType: "delete",
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success("取消成功");
          getFileDesc();
        }
      });
  } else if (e == 3) {
    api.base
      .fileExpire({
        userId: userId.value,
        fileId: props.fileItem.fileId || props.fileItem.id,
        expireTime: null,
        libraryId:
          topicItem.value.knowledge_library_id || topicItem.value.libraryId,
        operateType: "delete",
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success("取消成功");
          getFileDesc();
        }
      });
  }
  if (e == 4) {
    timeType.value = 2;
    timeDialog.value = true;
  }
};
const closeTime = () => {
  timeDialog.value = false;
};
// 展示文字
const lookText = () => {
  textType.value = !textType.value;
};
// 获取文字内容
const getText = () => {
  if (chatType.value != "chat") {
    api.base
      .textView(
        {
          userId: userId.value,
          fileId: props.fileItem.fileId || props.fileItem.id,
        },
        topicItem.value.knowledge_library_id || topicItem.value.libraryId
      )
      .then((res) => {
        if (res.returnCode == 200) {
          textInner.value = res.returnMessage;
        }
      });
  }
};
</script>

<style lang="scss" scoped>
.topMDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 92px;
  padding: 0px 30px;
  border-bottom: 1px solid #e2e6ed;
  background-color: #fefefe;
  border-radius: 30px 30px 0px 0px;
}
.topRightDiv59 {
  width: calc(100% - 380px);
  height: 59px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.topRightDivCenter {
  width: calc(100% - 380px);
  height: 59px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.topTitleName {
  color: #8a8a8a;
  font-size: 20px;
  font-family: "Regular";
  max-width: 180px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.wid24 {
  width: 24px;
}
.topSel {
  width: 17px;
}
.topFileName {
  color: #3d3d3d;
  font-size: 20px;
  font-family: "Regular";
  max-width: 350px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.topFontItem {
  color: #8a8a8a;
  font-family: "Regular";
  font-size: 18px;
}
.nameMax {
  max-width: 100px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.topTx {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin: 0px 10px;
  font-size: 16px;
  color: #f7f8ff;
  text-align: center;
  font-family: "Regular";
  line-height: 18px;
}
.wid25 {
  width: 25px;
}
.marGin12 {
  margin: 0px 12px;
}
.topRightBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 380px;
}
.topLook {
  height: 44px;
  border-radius: 16px;
  border: 2px solid #edeef6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
  margin-left: 12px;
  cursor: pointer;
}
.marGin6 {
  margin-right: 6px;
}
.topFonItemF {
  color: #f02d63;
  font-family: "Regular";
  font-size: 18px;
}

.marGin9 {
  margin-left: 9px;
}
.rightFileChunk {
  padding: 12px;
  height: calc(100% - 117px);
  width: calc(100% - 24px);
  background-color: #ffffff;
  overflow: auto;
}
.closeFileView {
  width: 14px;
  margin-left: 12px;
  cursor: pointer;
}
.hMore {
  width: 18px;
}
.moreDiv {
  width: 36px;
  height: 36px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}
.moreDiv:hover {
  background-color: #f7f8ff;
}
.textMessageItem {
  background-color: #f7f8ff;
  border-radius: 12px;
  margin-bottom: 24px;
}
</style>

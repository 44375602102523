// 检查用户权限
export function hasPermission(roles: string[] | undefined, permissionId: string): boolean {
    if (!roles || roles.length === 0) {
        return false;
    }
    console.log(roles, permissionId);
    
    for (const role of roles) {
        if (role.includes(permissionId)) {
            return true;
        }
    }
    return false;
}
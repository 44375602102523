import { Commit } from 'vuex';
export default ({
    state: {
    },
    getters: {},
    mutations: {
    },
    actions: {
        myAction({ commit }: { commit: Commit }, data: any) {
            commit('changeName', data);
        }
    },
    namespaced: true
})

<template>
  <div class="chatContent">
    <!-- tag 以及gpt切换 -->
    <div
      :class="
        tagActive != null && tagList.length != 0 ? 'flexEnd' : 'flexBetween'
      "
    >
      <div class="flexCenter" v-if="tagActive == null">
        <div
          v-for="(item, index) in tagList"
          :key="index"
          class="tagItemDiv"
          @click="handleSearch(item)"
        >
          <img class="tagItemImg" :src="item.img" alt="" />
          <span class="tagItemTitle">{{ item.label }}</span>
        </div>
      </div>
      <div>
        <el-select v-model="model" class="chatSelete">
          <el-option
            v-for="item in modelList"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          />
        </el-select>
      </div>
    </div>
    <!-- 选择后 -->
    <topTitle
      v-if="tagActive != null && tagList.length != 0"
      :tagActive="tagActive"
      :tagList="tagList"
      @changeBaseId="changeBaseId"
      @changeTemplateId="changeTemplateId"
      @closeTag="closeTag"
    ></topTitle>
    <!-- 输入框 -->
    <div
      :class="tagActive == null ? 'chatInput' : 'chatInputActive'"
      class="pa"
    >
      <!-- 文件 -->
      <div v-if="fileList.length != 0" class="listFlex">
        <img
          :src="rightReson"
          alt=""
          class="leftResonIcon"
          v-if="fileList.length > 4"
          @click="changeLeftReson(fileListRef)"
        />
        <div ref="fileListRef" class="itemFlex">
          <div class="fileItem" v-for="(item, index) in fileList" :key="index">
            <template v-if="!ifImg(item)">
              <img :src="getFileIcon(item)" alt="" class="fileImg" />
              <div class="fileRightDiv">
                <div class="fileNmae">
                  {{ item.name || item.fileName || item.folderName }}
                </div>
                <div class="fileSize">
                  {{ getFileType(item) }}
                  {{ formatFileSize(item.fileSize || item.size) }}
                </div>
              </div>
              <img
                :src="closePng"
                @click.stop="deleteFile(index)"
                alt=""
                class="closeIcon"
              />
            </template>
            <template v-if="ifImg(item)">
              <el-popover placement="top" popper-class="fileImgPopover">
                <template #reference>
                  <img :src="getImgUrl(item)" alt="" class="fileImg" />
                </template>
                <img :src="getImgUrl(item)" alt="" class="hoverImg" />
              </el-popover>
              <div class="fileRightDiv">
                <div class="fileNmae">{{ item.name }}</div>
                <div class="fileSize">
                  {{ getFileType(item) }}
                  {{ formatFileSize(item.fileSize || item.size) }}
                </div>
              </div>
              <img
                :src="closePng"
                alt=""
                class="closeIcon"
                @click.stop="deleteFile(index)"
              />
            </template>
          </div>
        </div>
        <img
          :src="rightReson"
          alt=""
          class="rightResonIcon"
          v-if="fileList.length > 4"
          @click="changeRightReson(fileListRef)"
        />
      </div>
      <!-- 提示 -->
      <div class="tipFlex" v-if="fileList.length != 0">
        <tipCom
          v-for="item in messageList.slice(0, 2)"
          :key="item"
          @click="saveMessage(item)"
        >
          <el-tooltip class="box-item" effect="dark" :content="item">
            {{ item.length > 20 ? item.slice(0, 20) + "..." : item }}
          </el-tooltip>
        </tipCom>
      </div>
      <!-- 输入框 -->
      <div
        :class="{
          inputDivHeight: tagActive == null && fileList.length == 0,
          inputDivHeight100: tagActive != null && fileList.length == 0,
        }"
      >
        <el-mention
          v-model="question"
          type="textarea"
          :options="tagList"
          :prefix="['@', '/']"
          placeholder="发送消息、输入 @ 或 / 选择插件"
          :autosize="{ minRows: 1, maxRows: 4 }"
          @select="handleSearch"
          @keyup.enter.stop="send"
        >
          <template #label="{ item }">
            <div class="tjDiv">
              <img class="tagItemImg" :src="item.img" alt="" />
              <div>{{ item.label }}</div>
            </div>
          </template>
        </el-mention>
      </div>
      <!-- 底部 -->
      <div class="inputBetween">
        <!-- 左侧正常上传 -->
        <div class="flexCenter bottomLeftDiv">
          <!-- 微模型选择文件 -->
          <img
            :src="baseInput"
            alt=""
            class="baseInput"
            @click="openBaseFile"
            v-if="tagActive == 0"
          />
          <!-- 图片选择 -->
          <!-- <imgSel v-if="tagActive == 1"></imgSel> -->
          <!-- 上传文件 -->
          <uploadIndex
            type="file"
            @beforeAvatarUpload="beforeAvatarUpload"
            v-if="(tagActive == null || tagActive == 1) && chatType != 'base'"
          >
            <img :src="hImg" alt="" class="fileIcon" />
          </uploadIndex>
          <!-- 上传图片 -->
          <uploadIndex
            type="img"
            @beforeAvatarUpload="beforeAvatarUpload"
            v-if="(tagActive == null || tagActive == 1) && chatType != 'base'"
          >
            <img :src="cImg" alt="" class="marginLeft10 fileIcon" />
          </uploadIndex>
          <!-- 联网搜索 -->
          <el-switch
            class="marginLeft10"
            v-model="isInternetOn"
            v-if="tagActive == null || tagActive == 0 || tagActive == 1"
          />
          <span
            class="marginLeft10 netWorkTitle"
            v-if="tagActive == null || tagActive == 0 || tagActive == 1"
            >联网搜索</span
          >
        </div>
        <!-- 右侧发送 以及深度 -->
        <div class="flexCenter sdDiv">
          <div class="flexCenter bottomItem" v-if="tagActive != 1">
            <div
              v-for="item in options"
              :key="item"
              @click="strategy = item.value"
              :class="{
                width75: item.value === 2,
                width50: item.value !== 2,
                activeItem: strategy === item.value,
              }"
              class="allCenter textNo"
            >
              <img
                :src="sdActive"
                v-if="strategy == 2 && item.value == 2"
                alt=""
                class="sdImg"
              />
              <img
                :src="sddddPng"
                v-if="strategy != 2 && item.value == 2"
                alt=""
                class="sdImg"
              />
              {{ item.label }}
            </div>
          </div>
          <div class="iconZwf" v-if="tagActive != 1">|</div>
          <img
            :src="sendPng"
            alt=""
            class="sendImg"
            v-if="question.length == 0"
          />
          <img :src="sendYes" alt="" class="sendImg" v-else @click="send" />
        </div>
      </div>
    </div>
    <!-- 微模型选择文件 -->
    <baseFileView
      :baseFile="baseFile"
      :saveList="fileList"
      v-if="baseFileDialog"
      @cancel="baseFileDialog = false"
      @ok="saveBase"
    ></baseFileView>
  </div>
</template>

<script setup>
import nImg from "@/assets/index/n.svg";
import oImg from "@/assets/index/o.svg";
import iImg from "@/assets/index/i.svg";
import hImg from "@/assets/index/h.svg";
import cImg from "@/assets/index/c.svg";
import sendPng from "@/assets/index/send.svg";
import sddddPng from "@/assets/index/sdddd.svg";
import sdActive from "@/assets/index/sdactive.svg";
import closePng from "@/assets/index/close.svg";
import tipCom from "@/components/tip/index.vue";
import uploadIndex from "@/components/upload/index.vue";
import sendYes from "@/assets/chat/sendYes.svg";
import baseInput from "@/assets/chat/baseInput.svg";
import inputBase from "@/assets/input/inputBase.svg";
import inputBot from "@/assets/input/inputBot.svg";
import inputImg from "@/assets/input/inputImg.svg";
import topTitle from "./topTitle.vue";
import rightReson from "@/assets/chat/rightReson.svg";
import {
  ref,
  defineEmits,
  onMounted,
  watch,
  defineProps,
  defineExpose,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import baseFileView from "./baseFile.vue";
import imgSel from "./imgSel.vue";
import api from "@/api";
import { message } from "ant-design-vue";
import { getFileIcon, formatFileSize } from "@/utils/file";
const store = useStore();
const router = useRouter();
const emit = defineEmits(["changeFile", "closeChat", "handleSearch"]);
const props = defineProps(["bottomFileList", "type"]);
const options = [
  {
    label: "简洁",
    value: 0,
  },
  {
    label: "详情",
    value: 1,
  },
  {
    label: "深度",
    value: 2,
  },
]; //深度
const userId = ref(store.state.userId);
const fileListRef = ref(null);
const model = ref("GPT-4o"); //选择的模型
const modelList = ref(store.state.modelList); //模型列表
const isInternetOn = ref(true); //联网搜索
const question = ref(""); //要发送的消息
const strategy = ref(0); //选择的深度值
const tagActive = ref(null); //默认选择值
const baseId = ref(null);
const templateId = ref(null);
const topicItem = ref(store.state.topicItem);
const baseItem = ref(store.state.baseItem);
const baseDrapList = ref(store.state.baseDrapList);
const baseFile = ref([]); //选择微模型后获取文件
const baseFileDialog = ref(false);
const messageList = ref([]);
const tagList = ref([
  {
    label: "AI知识库",
    img: nImg,
    icon: inputBase,
    value: 0,
  },
  // {
  //   label: "图像生成",
  //   img: iImg,
  //   icon: inputBot,
  //   value: 1,
  // },
  {
    label: "AI智能体",
    img: oImg,
    icon: inputImg,
    value: 1,
  },
]); //@的值
const fileList = ref([]); //文件列表
const chatType = ref(store.state.chatType);
onMounted(() => {
  if (props.bottomFileList.length != 0) {
    fileList.value = props.bottomFileList;
  }
  getMessage();
  getTypeList();
  // getDefauleModel();
});
watch(
  () => store.state.chatType,
  (newValue, oldValue) => {
    chatType.value = newValue;
    getTypeList();
  }
);
watch(
  () => store.state.topicItem,
  (newValue, oldValue) => {
    topicItem.value = newValue;
  }
);
watch(
  () => store.state.baseItem,
  (newValue, oldValue) => {
    baseItem.value = newValue;
  }
);

watch(
  () => store.state.baseDrapList,
  (newValue, oldValue) => {
    baseDrapList.value = newValue;
  }
);
const saveMessage = (item) => {
  question.value = item;
};
defineExpose({
  saveMessage,
});
// 获取随机三条
const getMessage = () => {
  api.chat.randomMessage().then((res) => {
    if (res.returnCode == 200) {
      messageList.value = res.data;
    }
  });
};
// 想左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
const getTypeList = () => {
  if (chatType.value == "base") {
    tagList.value = [];
  } else {
    tagList.value = [
      {
        label: "AI知识库",
        img: nImg,
        icon: inputBase,
        value: 0,
      },
      {
        label: "AI智能体",
        img: oImg,
        icon: inputImg,
        value: 1,
      },
    ];
  }
};
// 保存微模型文件列表
const saveBase = (arr) => {
  let fileLists = [];
  let folderList = [];
  let topicList = [];
  arr.forEach((item) => {
    if (item.fileName) {
      fileLists.push(item.id);
    } else if (item.folderName) {
      folderList.push(item.id);
    } else if (item.sessionName) {
      topicList.push(item.sessionId);
    }
  });
  baseDrapList.value = { fileList: fileLists, folderList, topicList };
  fileList.value = arr;
  baseFileDialog.value = false;
  emit("changeFile", fileList.value);
};
// 打开微模型文件列表
const openBaseFile = () => {
  baseFileDialog.value = true;
};
// 关闭tag
const closeTag = () => {
  tagActive.value = null;
  baseId.value = "";
  templateId.value = "";
  fileList.value = [];
  emit("handleSearch", null);
};
// 获取默认model
const getDefauleModel = () => {
  api.chat.modelGet({ userId: userId.value }).then((res) => {
    if (res.returnCode == 200) {
      model.value = res.data;
    }
  });
};
// 删除文件
const deleteFile = (index) => {
  fileList.value.splice(index, 1);
};
// 获取文件类型
const getFileType = (item) => {
  if (item.folderName) {
    return "文件夹";
  }
  let name = item.fileName || item.name;
  let fileType = name.substring(name.lastIndexOf(".") + 1);
  fileType = fileType.toLowerCase();
  return fileType;
};
// 判断是不是图片
const ifImg = (file) => {
  if (chatType.value == "chat" && file.name) {
    const type = file.name.substring(file.name.lastIndexOf(".") + 1);
    const fileType = type.toLowerCase();
    if (fileType == "png") {
      return true;
    } else if (fileType == "jpg") {
      return true;
    } else if (fileType == "gif") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
// file转图片
const getImgUrl = (file) => {
  return URL.createObjectURL(file);
};
const handleSearch = (e) => {
  tagActive.value = e.value;
  baseId.value = "";
  templateId.value = "";
  fileList.value = [];
  emit("handleSearch", e.value);
  question.value = "";
};
// 上传
const beforeAvatarUpload = (list) => {
  fileList.value = [...fileList.value, ...list];
  emit("changeFile", fileList.value);
};
// 微模型选择
const changeBaseId = (id) => {
  baseId.value = id;
  api.base
    .queryFileList({ userId: userId.value, folderId: null }, id)
    .then((res) => {
      if (res.returnCode == 200) {
        baseDrapList.value = {};
        fileList.value = [];
        baseFile.value = [...res.data.folderInfo, ...res.data.filesInfo];
      }
    });
};
// 智能体选择
const changeTemplateId = (id) => {
  templateId.value = id;
};
// 发送
const send = () => {
  if (!model.value) {
    message.warning("请选择GPT类型");
    return;
  }
  let libraryId;
  if (tagActive.value == 0) {
    libraryId = baseId.value;
  } else {
    libraryId = baseItem.value ? baseItem.value.knowledge_library_id : "";
  }
  if (
    chatType.value == "base" ||
    tagActive.value == 0 ||
    props.type == "base"
  ) {
    api.base
      .addBase({
        userId: userId.value,
        sessionName: question.value.slice(0, 250),
        libraryId: libraryId,
        fileIds: baseDrapList.value.fileList ? baseDrapList.value.fileList : [],
        folderIds: baseDrapList.value.folderList
          ? baseDrapList.value.folderList
          : [],
        sessionId: baseDrapList.value.topicList
          ? baseDrapList.value.topicList[0]
          : "",
      })
      .then((res) => {
        if (res.returnCode == 200) {
          let obj = {
            userId: userId.value,
            model: model.value,
            message: question.value,
            libraryId: libraryId,
            isInternetOn: isInternetOn.value,
            sessionId: res.data,
            strategy: strategy.value,
            regenerateFlag: 0,
            internetSource: null,
            conversationId: null,
            inValid: false,
          };
          store.commit("SET_BASEDRAP", {});
          store.commit("SET_CHATTYPE", "base");
          store.commit("SET_TOPIC", {
            sessionName: question.value.slice(0, 250),
            sessionId: res.data,
            knowledge_library_id: libraryId,
          });
          store.commit("SET_FILELIST", []);
          store.commit("SET_TALK", obj);
          router.push("/chat");
          emit("closeChat");
        }
      });
  } else {
    // 没有文件就新增
    api.chat
      .addChat({
        userId: userId.value,
        topicName: question.value.slice(0, 250),
        templateId: templateId.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          // 新增对话 不算base chat
          store.commit("SET_CHATTYPE", "chat");
          let obj = {
            model: model.value,
            message: question.value,
            topicId: res.data.table_id,
            knowledgeId: baseId.value,
            userId: userId.value,
            isInternetOn: isInternetOn.value,
            regenerateFlag: 0,
            conversationId: null,
            internetResource: null,
            strategy: strategy.value,
          };
          store.commit("SET_TOPIC", {
            sessionName: question.value.slice(0, 250),
            sessionId: res.data.table_id,
          });
          store.commit("SET_TALK", obj);
          store.commit("SET_FILELIST", fileList.value);
          router.push("/chat");
          emit("closeChat");
        }
      });
  }
};
</script>

<style lang="scss" scoped>
.chatContent {
  width: 100%;
  height: 100%;
}
.chatSelete {
  width: 155px;
}
.tagItemTitle {
  font-size: 20px;
  color: #8a8a8a;
  font-family: "Medium";
}
.tagItemImg {
  margin-right: 10px;
  width: 20px;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.tagItemDiv {
  border-radius: 16px;
  border: 2px solid #d9d9d9;
  padding: 7px 11px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  height: 30px;
  cursor: pointer;
}
:deep(.el-select__wrapper) {
  height: 48px;
  color: #3d3d3d;
  font-size: 18px;
  font-family: "Regular";
  border-radius: 16px;
  border: 2px solid #edeef6;
}
:deep(.el-select__caret) {
  font-size: 20px;
  color: #8a8a8a;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  height: 48px;
  padding: 0px 12px;
}
.flexEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
  height: 48px;
  padding: 0px 12px;
}
.chatInput {
  border: 2px solid #d9d9d9;
  border-radius: 16px;
  width: calc(100% - 44px);
  min-height: 146px;
  padding: 18px 20px;
  position: relative;
}
.chatInputActive {
  border: 2px solid #d9d9d9;
  width: calc(100% - 44px);
  min-height: 148px;
  padding: 18px 20px;
  position: relative;
  border-radius: 0px 0px 16px 16px;
  border-top: 0px;
}
.inputBetween {
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  margin-top: 10px;
}
.marginLeft10 {
  margin-left: 10px;
}
.netWorkTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
}
:deep(.el-switch__core) {
  width: 38px;
  height: 22px;
  border: 1px solid #8a8a8a;
  background-color: #ffffff;
  border-radius: 16px;
}
:deep(.el-switch.is-checked .el-switch__core) {
  width: 38px;
  height: 22px;
  border: 0px;
  background-color: #3376ff;
  border-radius: 16px;
}
:deep(.el-switch__core .el-switch__action) {
  background-color: #3d3d3d;
  left: 2px;
}
:deep(.el-switch.is-checked .el-switch__core .el-switch__action) {
  background-color: #ffffff;
  left: calc(100% - 18px);
}
:deep(.el-textarea) {
  height: 100%;
  .el-textarea__inner {
    border: 0px;
    resize: none;
    outline: none;
    width: 100%;
    overflow: auto;
    height: 100%;
    font-size: 22px;
    font-family: "Regular";
    box-shadow: 0px 0px 0px 0px #ffffff;
  }
  .el-textarea__inner:disabled {
    background-color: #fff;
  }
  .el-textarea__inner::placeholder {
    color: #d9d9d9;
    font-size: 22px;
    font-family: "Regular";
  }
}
:deep(.el-input__wrapper) {
  box-shadow: 0px 0px 0px;
  .el-input__inner {
    border: 0px;
    resize: none;
    outline: none;
    width: 100%;
    overflow: auto;
    height: 100%;
    font-size: 22px;
    font-family: "Regular";
    box-shadow: 0px 0px 0px 0px #ffffff;
  }
  .el-input__inner:disabled {
    background-color: #fff;
  }
  .el-input__inner::placeholder {
    color: #d9d9d9;
    font-size: 22px;
    font-family: "Regular";
  }
}
.inputDivHeight {
  height: 115px;
}
.inputDivHeight100 {
  height: 105px;
}
.sdImg {
  margin-right: 10px;
  width: 17px;
}
.width50 {
  width: 50px;
}
.width75 {
  width: 75px;
}
.textNo {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  height: 31px;
  cursor: pointer;
}
.bottomItem {
  width: 176px;
  padding: 0px 2px;
  height: 34px;
  border-radius: 10px;
  border: 1px solid #dbdfff;
}
.allCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.activeItem {
  background-color: #3376ff;
  color: #ffffff;
  border-radius: 8px;
  font-size: 18px;
  font-family: "Medium";
}
.iconZwf {
  color: #d9d9d9;
  font-size: 22px;
  margin: 0px 15px;
}
.tipFlex {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.fileItem {
  width: 188px;
  min-width: 188px;
  height: 52px;
  background-color: #f7f8ff;
  border-radius: 20px;
  padding: 13px 20px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  position: relative;
  display: flex;
  align-items: center;
}
.fileImg {
  width: 40px;
}
.fileRightDiv {
  margin-left: 13px;
  width: calc(100% - 41px);
  height: 51px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.fileNmae {
  font-size: 18px;
  font-family: "Medium";
  color: #3d3d3d;
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileSize {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
}
.listFlex {
  margin-bottom: 5px;
  position: relative;
}
.itemFlex {
  display: flex;
  overflow: hidden;
}
.closeIcon {
  position: absolute;
  right: 4px;
  top: 6px;
  cursor: pointer;
  width: 15px;
}
.fileIcon {
  width: 22px;
}
.sendImg {
  width: 30px;
}
.fileFlex {
  display: flex;
  align-items: center;
  width: 100%;
}
.tjDiv {
  width: 100px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
}
.baseInput {
  width: 20px;
  cursor: pointer;
}
.sdDiv {
  width: 250px;
  display: flex;
  justify-content: flex-end;
}
.bottomLeftDiv {
  width: calc(100% - 250px);
}
:deep(.el-mention) {
  height: 100%;
}
.pa {
  position: relative;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translate(25px, -50%);
    width: 30px;
    z-index: 10;
    background: linear-gradient(180deg, #f7f8ff 0%, #fff 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0), #ffffff);
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: 35px;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
    background: linear-gradient(180deg, #f7f8ff 0%, #fff 100%);
    background: linear-gradient(to left, rgba(0, 0, 0, 0), #ffffff);
  }
}
</style>
